import { OrdersLinesAdjustments }   from "../../interface/partials/orders-lines-adjustments";
import { GTCAdjustments }           from "../../interface/partials/gtc-adjustments";
import { GTCAdjustmentsModel }      from './gtc-adjustments.model';

export class OrdersLinesAdjustmentsModel implements OrdersLinesAdjustments {
  
  public P_GTC_ADJ: GTCAdjustments[];
  public P_TOT_RECORDS: number;
  public P_ERROR_MESSAGE: string;
  
  constructor(args : Partial<OrdersLinesAdjustmentsModel>) {
    Object.assign(this, args);

    if (this.P_GTC_ADJ) {
      this.P_GTC_ADJ = this.P_GTC_ADJ.map(x => new GTCAdjustmentsModel(x));
    }

  }
}