import { 
  HttpClient, 
  HttpHeaders, 
  HttpParams}                 from '@angular/common/http';
import { Injectable }           from '@angular/core';
import { 
  Observable, 
  of, 
  throwError}                       from 'rxjs';
import { 
  catchError,
  switchMap }                         from 'rxjs/operators';
import { 
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService, 
  OuxConfigService }            from '@cisco/oux-common';
import { UserDetailsStore } from '../stores/user-details.store';
import { BaseResponse } from '../models/interface/response/base-response';
import { MetadataStore } from '../stores/metadata.store';


@Injectable({ providedIn: 'root' })
export class EmailService {

  private baseUri: string;
  private emailExportRequestUri: string;

  /**
   * Create service mapping for http exception handling
   */
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('UserService');

  constructor(private http: HttpClient,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private metadataStore: MetadataStore,
              private userDetailsStore: UserDetailsStore) {


    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.emailExportRequestUri = this.ouxConfigSvc.getAppConfigValue('apiUri').emailExportRequest;
  }

  /**
   * Responsible for retrieving the logged in user details
   * @returns user details
   */
  public sendExportRequestEmail(exportId: number): Observable<BaseResponse<any>> {

    const URL = `${this.baseUri}${this.emailExportRequestUri}`;
    const OPTIONS = this.getOptions(exportId);

    const REQUEST$ = this.http.get<BaseResponse<any>>(URL, OPTIONS)
    .pipe(
      switchMap(response => {
        if (!response || !response.success) {
          return throwError(response);
        }

        return of(response);
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('sendExportRequestEmail', error)(error);
      })
    );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
  private getOptions(exportId: number) : { headers: HttpHeaders, params : HttpParams } {

    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loginId', this.userDetailsStore.getUserId())
        .set('employeeId', this.metadataStore.getMetadataEmployeeId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
        .append('exportId', String(exportId))
    };

    return OPTIONS;
  }

}