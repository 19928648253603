export class HeroWhiteList {

  public static showHero(title : string) : boolean {

    const inValidRoutes : string[] = [
      'Test Route'
    ];

    if ( inValidRoutes.indexOf(title) == -1 ) {
      return true;
    }
    else {
      return false;
    }

  }

}