import { Injectable }             from '@angular/core';
import { 
  OuxConfigService,
  OuxWindowRefService }           from '@cisco/oux-common';
import { AppDynamicConfigModel }  from 'src/app/shared/models/concrete/app-dynamic-config.model'; 

/**
 * Supports two instances [Non-Prod & Prod]
 * Non-Prod (cisco1nonprod.saas.appdynamics.com) maps to QA
 * --> Config keys: located in app.QA.json 
 * Prod (cisco1.saas.appdynamics.com) maps to PROD
 * --> Config keys: located in app.Production.json
 */
@Injectable({
  providedIn: 'root'
})
export class AppDynamicsService {

  /**
   * Represents native window object
   */
  private windowRef : any;
  private appDynamicsConfig : AppDynamicConfigModel;

  constructor(private ouxConfigSvc : OuxConfigService,
              private ouxWindowRefSvc : OuxWindowRefService) {

    this.windowRef = this.ouxWindowRefSvc.nativeWindow;
    this.appDynamicsConfig = this.ouxConfigSvc.getAppConfigValue('appDynamicsConfig');
  }

  public insertWindowConfig() : void {
    this.windowRef['adrum-start-time'] = new Date().getTime();
    this.windowRef['adrum-config'] = this.appDynamicsConfig;
  }

}