<ng-container *ngIf="weeklyTransactionSummaryDetails">
  <oux-table
      [records]="weeklyTransactionSummaryDetails"
      [options]="tableOpts"
      [settings]="columnSettings"
      [themeOverrides]="tableThemeOverrides"
      (onSort)="sortColumn($event.key, $event.sortDirection)">
 

      <!-- Custsom Columns-->
      

      <ng-template [ouxColumn]="'BOOK_DATE'" let-record="record">
          <div>
              {{ record?.BOOK_DATE | dateFormat }}
          </div>
      </ng-template>

      <ng-template [ouxColumn]="'ACV'" let-record="record">
          <div>
              {{ record?.ACV | number:'1.2-2' }}
          </div>
      </ng-template>


  </oux-table>
</ng-container>

<!-- <ng-container *ngIf="metricTransactionLineDetails as record">
    <table>
        <thead>
        </thead>
        <tbody>
            <ng-container>
                <tr>
                    <td>
                        <div class="custom-column">
                            <div class="column__row border-none">
                              <span class="row__label">Metric ID</span>
                              <span class="row__value">{{record?.METRIC_ID }}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Fiscal Week</span>
                              <span class="row__value">{{record?.FISCAL_WEEK | fiscalWeekFormat }}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Type</span>
                              <span class="row__value">Metric</span>
                            </div>
                            <div class="column__row border-none">
                              <span class="row__label">Book Date</span>
                              <span class="row__value">{{record?.BOOKINGS_PROCESS_DATE | dateFormat}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Metric Contract Start Date</span>
                              <span class="row__value">{{record?.MAX_CONTRACT_START_DATE | dateFormat }}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Backlog Amount</span>
                              <span class="row__value">0.00</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="custom-column">
                            <div class="column__row">
                              <span class="row__label">New Logo iACV</span>
                              <span class="row__value">{{record?.NEW_LOGO_IACV_USD_AMOUNT | number:'1.2-2' }}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Upsell iACV</span>
                              <span class="row__value">{{record?.UPSELL_IACV_USD_AMOUNT | number:'1.2-2'  }}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Cross Sell iACV</span>
                              <span class="row__value">{{record?.XSELL_IACV_USD_AMOUNT | number:'1.2-2' }}</span>
                            </div>
                            <div class="column__row border-none">
                              <span class="row__label">Expansion iACV</span>
                              <span class="row__value">{{ (record?.UPSELL_IACV_USD_AMOUNT + record?.XSELL_IACV_USD_AMOUNT) | number:'1.2-2' }}</span>
                            </div>
                            <div class="column__row" *ngIf="record.METRIC_TYPE !== 'IACV'">
                              <span class="row__label">Renewal Booking</span>
                              <span class="row__value">{{record?.RENEWAL_BOOKING_USD_AMOUNT | number:'1.2-2' }}</span>
                            </div>
                        </div>
                        
                    </td>
                    <td>
                        <div class="custom-column">
                            <div class="column__row">
                              <span class="row__label">Business Entity</span>
                              <span class="row__value">{{record?.BE_CX_LEVEL_1 || 'N/A'}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Sub Business Entity</span>
                              <span class="row__value">{{record?.BE_CX_LEVEL_2 || 'N/A'}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">CX Upsell Group</span>
                              <span class="row__value">{{record?.CX_UPSELL_GROUP_NAME || 'N/A'}}</span>
                            </div>
                        </div>
                        
                    </td>
                    <td>
                        <div class="custom-column">
                            <div class="column__row">
                              <span class="row__label">AT Level 1</span>
                              <span class="row__value">{{record?.AT_LEVEL_1 || 'N/A'}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">AT Level 2</span>
                              <span class="row__value">{{record?.AT_LEVEL_2 || 'N/A'}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">AT Level 3</span>
                              <span class="row__value">{{record?.AT_LEVEL_3 || 'N/A'}}</span>
                            </div>
                            <div class="column__row">
                              <span class="row__label">Metric Type</span>
                              <span class="row__value">{{record?.METRIC_TYPE }}</span>
                            </div>

                        </div>
                    </td>
                </tr>

                
            </ng-container>
      </tbody>
    </table>



    
    
</ng-container> -->
