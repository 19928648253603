<button 
  class="btn --stateless"
  [ouxTooltip]="themeSelecterTemplate"
  [ouxTooltipOptions]="injectedThemeTooltipOpts">
  <i class="icon icon-hue" (click)="toggleThemes($event.preventDefault())"></i>
</button>

<ng-template #themeSelecterTemplate>
  <div class="theme-selector-container">
    <span class="theme-header">Themes</span>
    <oux-radio-button-item class="theme-selector" *ngFor="let item of themeRadioItems"
        [item]="item.radio" 
        [name]="'theme'" 
        [(ngModel)]="selectedTheme"
        (ngModelChange)="selectTheme()">
      <div class="theme-preview" [style.background]="'linear-gradient(135deg, ' + item.primary + ' 50%, ' + item.background + ' 50%)'"></div>
    </oux-radio-button-item>
  </div>
</ng-template>