import { OrdersLinesBacklog } from '../../interface/partials/orders-lines-backlog';
import { OrdersLinesBacklogDetails } from '../../interface/partials/orders-lines-backlog-details';
import { OrdersLinesBacklogModel } from './orders-lines-backlog.model';


export class OrdersLinesBacklogDetailsModel implements OrdersLinesBacklogDetails {
  public P_ORDER_BACKLOG: OrdersLinesBacklog[] = [];
  public P_ERROR_MESSAGE: string;
  public P_TOT_RECORDS: number;

  constructor(args : Partial<OrdersLinesBacklogDetailsModel>) {
    Object.assign(this, args);

    if (this.P_ORDER_BACKLOG) {
      this.P_ORDER_BACKLOG =  this.P_ORDER_BACKLOG.map(transactions => new OrdersLinesBacklogModel(transactions));
    }
  }
}