import { MetricOrderSearchRequest } from '../../interface/request/metric-order-search-request';

export class MetricOrderSearchRequestModel implements MetricOrderSearchRequest {

    public loginId: string;
    public userId: string;
    public employeeId: string;
    public metricId: string;
    public salesOrderNumber:string;
    public sourceSystem:string;
    public groupBy: string;
    public limit: number;
    public offset: number;
    public sortBy: string;
    public sortOrder: string
    
  constructor(args : Partial<MetricOrderSearchRequestModel>) {
    Object.assign(this, args);
  } 

}