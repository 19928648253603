/* Angular Import */
import {
  Component, Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  combineLatest,
  Subscription
}                                     from 'rxjs';
import {
  map,
  tap }                               from 'rxjs/operators';
import {
  OuxLoggerService,
  OuxThemeType,
  OuxTooltipOptions,
  OuxTooltipPosition
}                                     from '@cisco/oux-common';
import { OrderLinesStore }            from 'src/app/shared/stores/order-lines.store';
import { OrderStore }                 from 'src/app/shared/stores/order.store';
import { LoaderService }              from 'src/app/core/services';
import { LoadingType }                from 'src/app/shared/models/constants/loading-type.enum';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';



@Component({
  selector: 'direct-transaction-lines-header',
  templateUrl: './direct-transaction-lines-header.component.html',
  styleUrls: ['./direct-transaction-lines-header.component.scss'],
  host: {
    'class': 'direct-transaction-lines-header'
  }
})
export class DirectTransactionLinesHeaderComponent implements OnInit, OnDestroy {

  @Input('orderType')
  public orderType: string;

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  private subscriptions: Subscription[] = [];
  public hasOrderLinesData: boolean = false;
  public AmpIdentifierKey = AmpIdentifierKey;
  
  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private loading$ = this.loaderService.loading$.get(LoadingType.Table)
  .pipe(
    tap(loading => {
      this.hasOrderLinesData = !loading;
    })
  );

  public data$ = combineLatest([
      this.orderStore.selectedOrder$,
      this.orderLinesStore.orderLinesCount$
    ])
    .pipe(
      tap(data => this.ouxLoggerSvc.logDebug('OrderLinesViewHeaderComponent - Combined Order Lines Data Stream:', data)),
      map( ([order, count]) => ({ order, count }))
    );


  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '100px',
      'max-width': '250px'
    },
    context: {
      code : null
    }
  });


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private orderStore: OrderStore,
              private orderLinesStore: OrderLinesStore,
              private loaderService: LoaderService,
              private ouxLoggerSvc: OuxLoggerService) {

    this.subscriptions.push(this.loading$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void {

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
