import { 
  Component, 
  OnDestroy, 
  OnInit }                                                    from '@angular/core';
import { 
  ActivatedRoute, 
  ParamMap, 
  Router }                                                    from '@angular/router';
import { 
  BehaviorSubject,
  combineLatest,
  merge,
  Observable, 
  Subject, 
  Subscription }                                              from 'rxjs';
import { 
  debounceTime,
  filter, 
  finalize, 
  map,  
  startWith,  
  tap, 
  withLatestFrom}                                                       from 'rxjs/operators';
import { OuxLoggerService, OuxPageChangeEvent, OuxPaginationOptions }                                   from '@cisco/oux-common';
import { OrdersLinesRequest }                                 from 'src/app/shared/models/interface/request/orders-lines-request';
import { UserDetailsStore }                                   from 'src/app/shared/stores/user-details.store';
import { LoaderService }                                      from 'src/app/core/services';
import { LoadingType }                                        from 'src/app/shared/models/constants/loading-type.enum';
import { CreditMemoTransactionLinesAdjustmentsRequest }       from '../../models/interface/request/credit-memo-transaction-lines-adjustments-request';
import { CreditMemoTransactionLinesAdjustmentsRequestModel }  from '../../models/concrete/request/credit-memo-transaction-lines-adjustments-request.model';
import { CreditMemoTransactionLinesAdjustmentsService }       from '../../services/credit-memo-transaction-lines-adjustments.service';
import { CreditMemoTransactionLinesAdjustmentStore }          from '../../stores/credit-memo-transaction-lines-adjustment.store';



@Component({
  selector: 'credit-memo-transaction-lines',
  templateUrl: './credit-memo-transaction-lines.component.html',
  styleUrls: ['./credit-memo-transaction-lines.component.scss'],
  host: {
    'class': 'credit-memo-transaction-lines'
  }
})
export class CreditMemoTransactionLinesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public filterType$ = new BehaviorSubject<string>('revCredMemo');

  // Build adjustments http request
  private request$: Observable<CreditMemoTransactionLinesAdjustmentsRequest> = combineLatest([this.route.queryParamMap, this.filterType$.asObservable()])
    .pipe(
      filter(([params, filterType]) => params
                              && params.has('planId') 
                              && params.has('srpGoalHeaderId') 
                              && params.has('allocationId') 
      ),
      map(([params, filterType]) => {
        let request = new CreditMemoTransactionLinesAdjustmentsRequestModel({
          planId: Number(params.get('planId')),
          srpGoalHeaderId: Number(params.get('srpGoalHeaderId')),
          allocationId: Number(params.get('allocationId')),
          adjustmentType: filterType,
          nodeName: params.get('nodeName') || '',
          territoryTypeCode: '',
          erpPosFlag: ''
        });

        return request;
      })
    );

  // React to user impersonation changes - If change accurs, route back to goal attainment
  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null),
      tap(() => {
        this.router.navigate(['/goal-attainment'])
      })
    );

  /**
   * Used for CreditMemoTransactionLinesSummaryTableExportComponent
   */
  public exportRequest: OrdersLinesRequest; 

  public pageChange = new Subject<OuxPageChangeEvent>();

  public onPageChange$ = this.pageChange.asObservable()
    .pipe(
      withLatestFrom(this.request$),
      map(([pageChange, request]) => {
        request.limit = pageChange.take;
        request.offset = pageChange.skip;

        return request;
      })
    );

  public paginationOptions = new OuxPaginationOptions({
    pageSize: 10,
    totalRecords: 0,
    autoHide: false,
    numbers: { show: false },
    goto: { show: false },
    pageSizes: {
      label: 'Show',
      displayFormat: (x: number) => `${x}`
    }
  });

  constructor(private route: ActivatedRoute,
              private router: Router,
              private creditMemoAdjustmentsSvc: CreditMemoTransactionLinesAdjustmentsService,
              private creditMemoAdjustmentsStore: CreditMemoTransactionLinesAdjustmentStore,
              private loaderService: LoaderService,
              private userDetailsStore: UserDetailsStore,
              private ouxLoggerSvc: OuxLoggerService) {

  }

  public ngOnInit(): void {
    this.subscriptions.push(merge(this.request$, this.onPageChange$)
      .pipe(debounceTime(0))
      .subscribe((request: CreditMemoTransactionLinesAdjustmentsRequest) => {
        this.fetchData(request);
      })
    );

    this.subscriptions.push(this.impersonationChange$.subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];

    this.creditMemoAdjustmentsStore.clearCreditMemoLinesAdjustmentsData();
  }

  private fetchData(request: CreditMemoTransactionLinesAdjustmentsRequest): void {
    this.ouxLoggerSvc.logDebug('CreditMemoTransactionLinesComponent - Request:', request);
    this.loaderService.show(LoadingType.Table);

    // stage export request CreditMemoTransactionLinesSummaryTableExportComponent
    this.exportRequest = request;
    
    this.subscriptions.push(this.creditMemoAdjustmentsSvc.initCreditMemoAdjustmentsStream(request)
      .pipe(
        tap(response => this.paginationOptions.totalRecords = response?.total || 0),
        finalize(() => this.loaderService.hide(LoadingType.Table))
      )
      .subscribe()
    );
  }
}