import { DateRange }             from "../../interface/partials/date-range";

export class DateRangeModel implements DateRange {

  public endDate: number;
  public startDate: number;
  public week: string;

  constructor(args : Partial<DateRangeModel>) {
    Object.assign(this, args);
  }

}