import { 
  HttpClient, 
  HttpHeaders, 
  HttpParams }                  from '@angular/common/http';
import { Injectable }           from '@angular/core';
import { 
  Observable, 
  of, 
  throwError }                  from 'rxjs';
import { 
  catchError,
  map, 
  switchMap, 
  tap }                         from 'rxjs/operators';
import { 
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService, 
  OuxConfigService, 
  OuxLoggerService }            from '@cisco/oux-common';
import { UsersResponse }        from '../models/interface/response/users-response';
import { UsersModel }           from '../models/concrete/partials/users.model';
import { UserList }             from '../models/interface/partials/user-list';
import { UserDetailsStore }     from '../stores/user-details.store';



@Injectable({ providedIn: 'root' })
export class UsersSearchService {

  private baseUri: string;
  private usersUri: string;

  /**
   * Create service mapping for http exception handling
   */
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('UsersSearchService');

  constructor(private http: HttpClient,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private ouxLoggerSvc: OuxLoggerService,
              private userDetailsStore: UserDetailsStore ) {


    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.usersUri = this.ouxConfigSvc.getAppConfigValue('apiUri').users;
  }

  /**
   * Change user search event
   * @param value - Value entered my user
   * @returns user search results
   */
  public search(value: string): Observable<UserList[]> {

    const URL = `${this.baseUri}${this.usersUri}`;
    const OPTIONS = this.getOptions(value);

    const REQUEST$ = this.http.get<UsersResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          if (!response.data || !response.data.P_USERLIST) {
            return [];
          }

          let typed = new UsersModel({
            P_USERLIST: response.data.P_USERLIST
          });

          return typed.P_USERLIST;
        }),
        tap( (response : UserList[]) => {
          this.ouxLoggerSvc.logDebug('UsersSearchService: search()', response);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('search', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
   private getOptions(searchText: string) : { headers: HttpHeaders, params : HttpParams } {

    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('searchText', searchText)
        .append('userName', this.userDetailsStore.getUserId())
        .append('loginId', this.userDetailsStore.getUserId())
    };

    return OPTIONS;
  }

}