import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, finalize, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader.service';

import { OrdersLinesDetailsRequestModel } from '../../models/concrete/request/orders-lines-details-request.model';
import { LoadingType } from '../../models/constants/loading-type.enum';
import { OrdersSearchOrder } from '../../models/interface/partials/orders-search-order';
import { TransactionGroupCode } from '../../models/types/transaction-group-code.enum';
import { OrderService } from '../../services/order.service';
import { OrderStore } from '../../stores/order.store';
import { UserDetailsStore } from '../../stores/user-details.store';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';

@Component({
  selector: 'pos-transaction-details',
  templateUrl: './pos-transaction-details.component.html',
  styleUrls: ['./pos-transaction-details.component.scss'],
  host: {
    'class': 'pos-transaction-details'
  }
})
export class POSTransactionDetailsComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  private selectedOrder$ = this.orderStore.selectedOrder$
    .pipe(
      filter(order => order != null),
      tap(selectedOrder => {
        this.selectedOrder = selectedOrder;
        this.transactionGroupCode = selectedOrder.REVENUE_MULTI != 0 ? TransactionGroupCode.Revenue : TransactionGroupCode.Bookings;
      }),
    );

  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null),
      tap(() => {
        this.router.navigate(['/goal-attainment'])
      })
    );

  public selectedOrder: OrdersSearchOrder;
  public transactionGroupCode: TransactionGroupCode;
  public isNotEligibleOrder: boolean;
  public notEligibleExportRequest;

  constructor(
    private router: Router,
    private orderSvc: OrderService,
    private loaderSvc: LoaderService,
    private orderStore: OrderStore,
    private userDetailsStore: UserDetailsStore,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(this.impersonationChange$.subscribe());
  }

  public ngOnInit(): void {
    let params = this.route.snapshot.queryParamMap;
    this.isNotEligibleOrder = (params && params.has('category')) ? (params.get('category') === AmpIdentifierKey.NOTELIGIBLE ? true : false) : false;
    
    this.subscriptions.push(this.selectedOrder$
      .pipe(
        tap(() => {
          if (this.isNotEligibleOrder) {
            this.fetchNotEligibleDetails();
          } else {
            this.fetchDetails();
          }
        })
      )
      .subscribe()
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public loadTransactionGroupCode(code: TransactionGroupCode): void {
    this.transactionGroupCode = code;

    this.fetchDetails();
  }

  public fetchDetails(): void {
    this.loaderSvc.show(LoadingType.Table);

    let request = new OrdersLinesDetailsRequestModel({
      srpGoalHeaderId: this.selectedOrder.SRP_GOAL_HEADER_ID,
      allocationId: this.selectedOrder.QUOTA_ID,
      soNumber: this.selectedOrder.SO_NUMBER,
      planId: this.selectedOrder.COMP_PLAN_ID,
      erpPosFlag: this.selectedOrder.ERP_POS_FLAG,
      territoryTypeCode: '',
      transGroupCode: this.transactionGroupCode,
      transactionType: 'revenue',
      limit: 10,
      offset: 0,
      nodeName: ''
    });

    this.subscriptions.push(this.orderSvc.fetchOrderLineDetails(request)
      .pipe(
        finalize(() => this.loaderSvc.hide(LoadingType.Table))
      )
      .subscribe()
    );
  }

  public fetchNotEligibleDetails(): void {
    this.loaderSvc.show(LoadingType.Table);
    let params = this.route.snapshot.queryParamMap;

    let notEligibleRequest = {
      loginId: '',
      userId: '',
      employeeId: '',
      salesOrderNumber: this.selectedOrder.SO_NUMBER,
      trxType: (params && params.has('type')) ? params.get('type') : '',
      erpPosFlag: (params && params.has('erpPosFlag')) ? params.get('erpPosFlag') : '',
      metricType: (params && params.has('metricType')) ? params.get('metricType') : '',
      limit: 10,
      offset: 0,
      sortBy: 'employee_id',
      sortOrder: 'desc'
    }

    this.notEligibleExportRequest = notEligibleRequest;

    this.subscriptions.push(this.orderSvc.fetchNotEligibleOrderLineDetails(notEligibleRequest)
      .pipe(
        finalize(() => this.loaderSvc.hide(LoadingType.Table))
      )
      .subscribe()
    );
  }
}
