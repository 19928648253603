import { GTCCreditMemoAdjustments } from "../../interface/partials/gtc-credit-memo-adjustments";


export class GTCCreditMemoAdjustmentsModel implements GTCCreditMemoAdjustments {
  public AMOUNT: number;
  public ANNUAL_VALUE: number;
  public BILL_TO_CUSTOMER: string;
  public BOOKING: number;
  public COMMENTS: string;
  public CONTRACT_END_DATE: any;
  public CONTRACT_START_DATE: any;
  public CONTRACT_TERM: any;
  public CREATED_BY: string;
  public CREATION_DATE: any;
  public CRED_MEMO: string;
  public GS_CURRENCY_CODE: string;
  public LINE_ID: number;
  public METRIC_TYPE: string;
  public ORDER_NUMBER: number;
  public PART_NUMBER: number;
  public PERIOD_CODE: number;
  public PO_CUSTOMER_NUMBER: number;
  public PROGRAM_NAME: string;
  public REASON: string;
  public SALES_MOTION: any;
  public SHIP_TO_CUSTOMER: string;
  public TOTAL_VALUE: number;
  public TRANSACTION_DATE: number;
  public TRANSACTION_ID: number;
  public TYPE: string;

  constructor(args: Partial<GTCCreditMemoAdjustmentsModel>) {
    Object.assign(this, args);
  }

  public get COMMISSION_STATUS() : string {
    let status = 'Fully Credited';

    // if (this.COMM_REVENUE !== 0 && this.COMM_BACKLOG !== 0) {
    //   status = 'Pending backlog';
    // } 
    // else if (this.COMM_REVENUE === 0 && this.COMM_BACKLOG !== 0) {
    //   status = 'Backlog';
    // }
    // else {
    //   status = 'Fully Credited';
    // }

    return status;
  }


}