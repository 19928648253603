import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'dollarFormat'
})
/**
 * Transforms inbound value based on type
 * Accepts string | number types
 * If value is of type string - we ignore any type of transform and return it as is
 * 
 * Optional argument: Developers can choose to round values to the near dollar based on the following
 * formulas https://en.wikibooks.org/wiki/Arithmetic/Rounding
 *   
 * Ex (without argument): {{ someValue | dollarFormat }}
 * Ex (with argument): {{ someValue | dollarFormat : 'round' }}
 */
export class DollarFormatPipe implements PipeTransform {

  transform(value: string | number, roundValue?: string) : string | number {

    const format = roundValue === 'round' ? '1.0-0' : '1.2-2';

    if (typeof value === 'string') {
      // returns value as string - no conversion needed - render value in template as is
      return value;
    }

    return this.decimalPipe.transform(value, format);
  }

  constructor(private decimalPipe: DecimalPipe) { }
}