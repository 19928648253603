import { Component, OnInit, Input } from '@angular/core';
import { OrdersMetricHeaderDetailsRequestModel } from 'src/app/shared/models/concrete/request/orders-metric-header-details-request.model';
import {forkJoin, Subscription, throwError} from 'rxjs';
import { WeeklyTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/weekly-transaction-summary-details';
import { OuxConfigService, OuxLoggerService, OuxModalService, OuxThemeType, OuxToastOptions, OuxToastService } from '@cisco/oux-common';
import { ExportDefinitionsModel } from 'src/app/shared/models/concrete/partials/export-definitions.model';
import { ActivatedRoute } from '@angular/router';
import { MetricSOTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/metric-so-transaction-summary-details';
import { MetricSOTransactionLineRequestModel } from 'src/app/shared/models/concrete/request/metric-order-line-request.model';
import { OrderService } from 'src/app/shared/services/order.service';
import { MetricSOLinesSummary } from 'src/app/shared/models/interface/partials/metric-so-lines-summary';
import { GoalToCashService } from 'src/app/shared/services/goal-to-cash.service';
import { ExportType } from 'src/app/shared/models/constants/export-type.enum';
import { OfflineExportContentModel } from '../../../offline-export-modal/offline-export-content.model';
import { OfflineExportHeaderModel } from '../../../offline-export-modal/offline-export-header.model';
import { OfflineExportModalComponent } from '../../../offline-export-modal/offline-export-modal.component';
import { OfflineExportModalModel } from '../../../offline-export-modal/offline-export-modal.model';
import { OfflineExportType } from 'src/app/shared/models/types/offline-export-type.enum';
import {catchError, tap, timeout} from "rxjs/operators";
import {ExportService} from "../../../../services/export/export.service";
import {SubmitExportService} from "../../../../services/export/submit-export.service";
import {VisibilityPageType} from "../../../../models/types/visibility-page-type.enum";
import { MetricSONotEligibleLinesSummary } from 'src/app/shared/models/interface/partials/metric-so-not-eligible-lines-summary';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';

@Component({
  selector: 'metric-so-transaction-lines-summary-export',
  templateUrl: './metric-so-transaction-lines-summary-export.component.html',
  styleUrls: ['./metric-so-transaction-lines-summary-export.component.scss'],
  host: {
    'class': 'metric-so-transaction-lines-summary-export'
  }
})
export class MetricSOTransactionLinesSummaryExportComponent implements OnInit {

  @Input('metricSoTransactionSummaryDetails')
  public metricSoTransactionSummaryDetails: MetricSOTransactionSummaryDetails;

  @Input('requestParams')
  public requestParams: OrdersMetricHeaderDetailsRequestModel;

  @Input('totalRecords')
  public totalRecords: number;
  public metricsExportDetailsModel: MetricSOLinesSummary[];
  public exportDefinitionsModel: ExportDefinitionsModel[];
  public currencyCode: string;
  public AmpIdentifierKey = AmpIdentifierKey;
  public subscriptions: Subscription[] = [];
  private exportSubscription: Subscription;
  public fileName = 'Order Line Export';
 
  public exportingToast = new OuxToastOptions({
    icon: 'icon-export',
    header: 'Order Lines Export',
    message: 'Orders are being exported.',
    autoDismiss: false,
    scaleOnHover: false,
    theme: OuxThemeType.Info
  });


  public weeklyTransactionSummaryDetails: WeeklyTransactionSummaryDetails[];

  constructor(
    private goalToCashService: GoalToCashService,
    private orderSvc: OrderService,
    private ouxConfigSvc: OuxConfigService,
    private ouxToastSvc: OuxToastService,
    private route: ActivatedRoute,
    private ouxModalSvc: OuxModalService,
    private ouxLoggerSvc: OuxLoggerService,
    private exportService: ExportService,
    private submitExportService: SubmitExportService
  ) { }

  ngOnInit(): void {
 
    this.route.queryParams.subscribe(params=>{
      if(params.ampIdentifier === 'AMPLIFIER'){
        this.fileName = 'Amplifier Order Line Export'
      }
    });
  }

  ngOnDestroy(): void {
   
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
    this.exportSubscription?.unsubscribe();
    this.exportSubscription = undefined;
  }

  /* To check for duplicate export request
* Possible output - Y or N
* If Y - show a popup to the user that it is a duplicate request
* If N - submit offline export request */
  private checkDuplicateExportRequest(request): void {
    
    this.exportSubscription = this.exportService.checkForDuplicateExportRequest(request)
      .pipe(
        tap(response => {
          response.p_export_request_is_duplicate === 'N' ? this.submitExportService.queueMetricsOfflineExport(request, ExportType.OrderLine) : this.initOfflineExport(request);
        }),
        catchError(error => {
          this.ouxToastSvc.addToast(new OuxToastOptions({
            autoDismiss: true,
            dismissTimeout: 10,
            header: `Error`,
            icon: 'icon-export',
            message: `Export Request could not be submitted. Please contact IT Support`,
            theme: OuxThemeType.Warning,
            scaleOnHover: false
          }));

          return throwError(error);
        })
      )
      .subscribe({
        complete: () => {
        }
      });
  }

  // CHANGE: added only for testing purpose. Remove this once testing is done.
  public exportOffline() {
    let params = this.route.snapshot.queryParamMap;
    let metricExportRequest = new MetricSOTransactionLineRequestModel({
      goalId: this.requestParams.goalId,
      quotaId: String(this.requestParams.quotaId),
      salesOrderNumber: this.requestParams.searchValue ? this.requestParams.searchValue : params.has('orderNumber') ? params.get('orderNumber') : null,
      startDate: params.has('searchStartDate') ? this.formatDate(params.get('searchStartDate')) : null,
      endDate: params.has('searchEndDate') ? this.formatDate(params.get('searchEndDate')) : null,
      category: params.has('savTitle') ? params.get('savTitle') : null,
      myFlag: params.has('myFlag') ? (params.get('myFlag') == 'Y' ? true : false ): false,
      peType: this.requestParams.peType,
      transactionType: 'ALL',
      sourceSystem: 'SAP',
      sourcePage: this.requestParams.sourcePage,
      ampFlag: true,
      flow: OfflineExportType.OrderAmplifiers,
      limit: 10000000,
      offset: 0,
      sortBy: 'LINE_ID',
      sortOrder: 'asc'
    });
    this.initOfflineExport(metricExportRequest);
  }
  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().substring(0,10);
  }

  public exportToExcel(event: any) {
    let params = this.route.snapshot.queryParamMap;

    //  build request payload for export
    let metricExportRequest = new MetricSOTransactionLineRequestModel({
      goalId: this.requestParams.goalId,
      quotaId: String(this.requestParams.quotaId),
      //Get Order Number of the page
      salesOrderNumber: this.requestParams.searchValue ? this.requestParams.searchValue : params.has('orderNumber') ? params.get('orderNumber') : null,
      startDate: params.has('searchStartDate') ? this.formatDate(params.get('searchStartDate')) : null,
      endDate: params.has('searchEndDate') ? this.formatDate(params.get('searchEndDate')) : null,
      transactionType: 'ALL',
      sourceSystem: "SAP",
      RTMType: params.has('type') ? params.get('type') : 'All',
      sourcePage: this.requestParams.sourcePage,
      category: params.has('savTitle') ? params.get('savTitle') : null,
      myFlag: params.has('myFlag') ? (params.get('myFlag') == 'Y' ? true : false ): false,
      page: VisibilityPageType.OrderSearch,
      flow: OfflineExportType.OrderAmplifiers,
      peType: this.requestParams.peType,
      goalSheetStartDate: params.has('goalSheetStartDate') ? params.get('goalSheetStartDate') : null,
      longCategory: params.has('longCategory') ? params.get('longCategory') : null,
      exportType: this.submitExportService.getExportType('Y', params.has('ampIdentifier') ? params.get('ampIdentifier') : '',params.has('myPYFlag') ? params.get('myPYFlag') : params.has('myFlag') ? params.get('myFlag') : null),
      limit: 10000000,
      offset: 0,
      sortBy: 'LINE_ID',
      sortOrder: 'asc'
    });

    // let metricExportRequest = new MetricSOTransactionLineRequestModel({
    //   "goalId": 3173912,
    //   "quotaId": "266330",
    //   "limit": 10,
    //   "offset": 0,
    //   "sortBy": "LINE_ID",
    //   "sortOrder": "desc",
    //   "employeeId": "389210",
    //   "loginId": "alislewi",
    //   "userId": "kawaters",
    //   "salesOrderNumber": "123456"
    //   //"lineId": 1122334455
    // })



    this.ouxToastSvc.addToast(this.exportingToast);
    //  fork join api calls to fetch all lines for the order and attribute definitions
    
    let notEligibleExportRequest = {
      loginId: '',
      userId: '',
      employeeId: '',
      salesOrderNumber: metricExportRequest.salesOrderNumber,
      erpPosFlag: (params && params.has('erpPosFlag')) ? (params.get('erpPosFlag')) : '',
      trxType: (params && params.has('type')) ? (params.get('type')) : '',
      limit: 1000000,
      offset: 0,
      sortBy: 'LINE_ID',
      sortOrder: 'desc'
    }

    forkJoin({
      response: this.requestParams.ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? this.orderSvc.fetchNotEligibleMetricOrderLineDetails(notEligibleExportRequest).pipe(timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout'))) 
      : this.orderSvc.fetchMetricOrderLineDetails(metricExportRequest).pipe(timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout'))),
      upsellAdj: this.goalToCashService.fetchExportDefinitionsDetails(null).pipe(timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout')))
    }).subscribe(({ response, upsellAdj }) => {
        this.metricsExportDetailsModel = response;
        this.exportDefinitionsModel = upsellAdj;
        this.export();
      },
      (error: Error) => {
        this.ouxToastSvc.dismissToast(this.exportingToast);
        this.ouxLoggerSvc.logError(`MetricSOTransactionLinesSummaryExportComponent: Failed to load Online Export Data: System Message -> ${error}`);
        // trigger offline export when api takes > 1 min
        if (this.requestParams.ampIdentifier !== AmpIdentifierKey.NOTELIGIBLE) {
          this.checkDuplicateExportRequest(metricExportRequest);
        }
      },
      () => {

      });
  }
  // pass the export data and plan element type to goal to cash service for exporting to xlsx/csv based on the record count
  public export() {
    if (this.metricsExportDetailsModel) {
      this.ouxToastSvc.dismissToast(this.exportingToast);
      this.showExportNotification('success');
      this.goalToCashService.exportMetricSOLines(this.metricsExportDetailsModel, this.exportDefinitionsModel, this.requestParams.sourcePage, this.fileName, this.requestParams.peType, this.requestParams.myFlag, this.requestParams.ampIdentifier, this.currencyCode);
      this.metricsExportDetailsModel = null;
    }
  }

  // show export notification based on export status
  private showExportNotification(operation: string): void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: this.fileName,
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    );
  }

  // initialize offline export if the export /lines api takes > 1 min to fetch response.
  // type - Order
  // offlineRequest - same payload that is used for the Order Drill down Current View Export
  private initOfflineExport(request: MetricSOTransactionLineRequestModel): void {
    request.ampFlag = true;
    request.level = 'summary';
    let modalData = new OfflineExportModalModel({
      header: new OfflineExportHeaderModel({ icon: '', heading: '' }),
      content: new OfflineExportContentModel({ content: '' }),
      type: ExportType.OrderLine,
      offlineRequest: request
    });

    this.ouxModalSvc.openModal(OfflineExportModalComponent, modalData);
  }

}
