/* Angular Import */
import { Injectable }             from '@angular/core';
import { 
  BehaviorSubject, 
  Observable }                    from 'rxjs';
import * as moment                from 'moment'; 
/* Feature Imports */
import { 
  OuxStore,
  OuxLoggerService }              from '@cisco/oux-common';
import { OrdersSearchOrder }      from '../models/interface/partials/orders-search-order';
//import { MetricOrderViewSearchOrder } from '../models/interface/partials/metric-order-view-search-order';
import { PagedResponse }          from '../models/interface/partials/paged-response';
import { MetricOrderDetails } from '../models/interface/partials/metric-order-details';
import {MetricTransactionOrderDetails} from '../models/interface/partials/metric-transaction-order-details';
import { OrderRefreshDate } from '../models/interface/partials/order-refresh-date';
import { OrdersSearchNotEligibleOrder }      from '../models/interface/partials/order-search-not-eligible-order';

/**
 * Creates our OrdersSearchOrder State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class OrderStore extends OuxStore<PagedResponse<OrdersSearchOrder>> {

  public pagedOrders$ = this.state$;

  /**
   * Stage action stream to enable multicast strategy
   */
   private selectedOrderSubject : BehaviorSubject<OrdersSearchOrder>;
   private orderRefreshDateSubject = new BehaviorSubject<number>(null);
   private totalOrdersSubject = new BehaviorSubject<number>(null);
   private ampOrdersSubject = new BehaviorSubject<number>(null);


   private metricOrderDetailsSubject = new BehaviorSubject<MetricOrderDetails[]>(null);
   private metricTransactionOrderDetailsSubject = new BehaviorSubject<MetricTransactionOrderDetails[]>(null);
   private notEligibleOrdersSubject = new BehaviorSubject<OrdersSearchNotEligibleOrder[]>(null);
   private selectedNotEligibleOrderSubject : BehaviorSubject<OrdersSearchNotEligibleOrder>;


  /**
   * Stand up our selected order action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  public selectedOrder$ : Observable<OrdersSearchOrder>;
  public orderRefreshDate$ = this.orderRefreshDateSubject.asObservable();
  public totalOrders$ = this.totalOrdersSubject.asObservable();
  public ampOrders$ = this.ampOrdersSubject.asObservable();
  public metricOrderDetails$ = this.metricOrderDetailsSubject.asObservable();
  public metricTransactionOrderDetails$ = this.metricTransactionOrderDetailsSubject.asObservable();
  public notEligibleOrders$ = this.notEligibleOrdersSubject.asObservable();
  public selectedNotEligibleOrder$ : Observable<OrdersSearchNotEligibleOrder>;

  constructor(private ouxLoggerSvc: OuxLoggerService) {

    super (null);

    this.selectedOrderSubject = new BehaviorSubject(null);
    this.selectedOrder$ = this.selectedOrderSubject.asObservable();

    this.selectedNotEligibleOrderSubject = new BehaviorSubject(null);
    this.selectedNotEligibleOrder$ = this.selectedNotEligibleOrderSubject.asObservable();

    this.orderRefreshDateSubject = new BehaviorSubject(null);
    this.orderRefreshDate$ = this.orderRefreshDateSubject.asObservable();
  }

  public setOrders(orders: PagedResponse<OrdersSearchOrder>) : void {

    super.setState(orders);
    this.ouxLoggerSvc.logDebug('OrderStore - PagedResponse<OrdersSearchOrder>:', this.state);

  }

  public setMetricOrders(orders: MetricOrderDetails[]):void{

      this.metricOrderDetailsSubject.next(orders);
  }

  public setMetricTransactionOrders(orders: MetricTransactionOrderDetails[]){
    this.metricTransactionOrderDetailsSubject.next(orders);
  }

  // public setMetricOrderView(orders: PagedResponse<MetricOrderViewSearchOrder>) : void {

  //   super.setState(orders);
  //   this.ouxLoggerSvc.logDebug('OrderStore - PagedResponse<MetricOrderViewSearchOrderr>:', this.state);
  // }

  public setTotalOrders(total: number): void {
    this.totalOrdersSubject.next(total);
  }

  public setAmpOrders(total: number): void {
    this.ampOrdersSubject.next(total);
  }

  public setNotEligibleOrders(orders: OrdersSearchNotEligibleOrder[]) {
    this.notEligibleOrdersSubject.next(orders);
  }

 /**
   * Extracts and sets the selected Goalsheet ID
   * ============================================================
   */
  public setSelectedOrder(order : OrdersSearchOrder) : void {

    if (order == undefined || order == null) {
      return;
    }

    this.selectedOrderSubject.next(order);
    this.ouxLoggerSvc.logDebug('OrderStore: Selected Order', this.selectedOrder);
  }

  public setSelectedNotEligibleOrder(order : OrdersSearchNotEligibleOrder) : void {

    if (order == undefined || order == null) {
      return;
    }

    this.selectedNotEligibleOrderSubject.next(order);
    this.ouxLoggerSvc.logDebug('OrderStore: Selected Not Eligible Order', this.selectedNotEligibleOrder);
  }

  public setOrderRefreshDate(value: number) : void {
    this.orderRefreshDateSubject.next(value);
  }

  /**
   * Clear Order Search Data Collection
   * ============================================================
   */
  public clearOrderSearchData(): void {
    this.setState(null);
    this.setTotalOrders(null);
    this.setAmpOrders(null);
    this.setMetricOrders(null);
    this.setNotEligibleOrders(null);
  }

  public clearSelectedOrder(): void {
    this.selectedOrderSubject.next(null);
  }

  public clearSelectedNotEligibleOrder(): void {
    this.selectedNotEligibleOrderSubject.next(null);
  }

  public clearOrderRefreshDate(): void {
    this.orderRefreshDateSubject.next(null);
  }

  public clearAllOrderSearchData() : void {
    this.clearOrderSearchData();
    this.clearSelectedOrder();
    this.clearOrderRefreshDate();
    this.clearSelectedNotEligibleOrder();
  }
  
  /**
   * Convenience Methods
   * ============================================================
   */

   public get selectedOrder() : OrdersSearchOrder {
    return this.selectedOrderSubject.getValue();
  }

  public get selectedNotEligibleOrder() : OrdersSearchNotEligibleOrder {
    return this.selectedNotEligibleOrderSubject.getValue();
  }
}