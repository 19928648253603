import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OuxColumnSettings, OuxTableOptions, OuxTableThemeOverrides, OuxThemeType, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { SortEvent } from 'src/app/shared/models/concrete/partials/sort-event.model';
import { OrdersMetricHeaderDetailsRequestModel } from 'src/app/shared/models/concrete/request/orders-metric-header-details-request.model';
import { WeeklyTransactionSummaryRequestModel } from 'src/app/shared/models/concrete/request/weekly-transaction-summary-request.model';
import { MetricOrderDetails } from 'src/app/shared/models/interface/partials/metric-order-details';
import { WeeklyTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/weekly-transaction-summary-details';
import { GoalToCashService } from 'src/app/shared/services/goal-to-cash.service';

@Component({
  selector: 'metric-transaction-lines-summary',
  templateUrl: './metric-transaction-lines-summary.component.html',
  styleUrls: ['./metric-transaction-lines-summary.component.scss'],
  host:{
    'class':'metric-transaction-lines-summary'
  }
})
export class MetricTransactionLinesSummaryComponent implements OnInit {

  @Input('weeklyTransactionSummaryDetails')
  public weeklyTransactionSummaryDetails: WeeklyTransactionSummaryDetails[];

  @Input('requestParams')
  public requestParams : OrdersMetricHeaderDetailsRequestModel;

  public tableOpts : OuxTableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--fixed-header',
    formatCells: false,
    tableLayout: 'fixed',
    fixedHeight: '100%',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    trackRecordsProperty:"SAV_NAME",
    noRecordsMessage: 'There are no transactions to display at this time',
    highlightSelectedRows: false,
    missingDataMessage: ' ',
    toggleSelected: false
  });


  public columnSettings: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'Sales Order Number',
      primaryKey: 'SALES_ORDER_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true
    }),
    new OuxColumnSettings({
      header: 'Web Order ID',
      primaryKey: 'WEB_ORDER_ID',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Sales Order Line No.',
      primaryKey: 'SO_LINE_NUM',
      thAlign: 'center',
      sortable: true,
      tdAlign: 'center',
    }),
    new OuxColumnSettings({
      header: 'Book Date',
      primaryKey: 'BOOK_DATE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Product ID',
      primaryKey: 'PART_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    // new OuxColumnSettings({
    //   header: 'Annual Bookings',
    //   primaryKey: 'ACV',
    //   thAlign: 'center',
    //   tdAlign: 'center',
    //   sortable: true,
    // }),
  ];

  public tableThemeOverrides : OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  public subscriptions: Subscription[] = [];
  
  @Output('onSort')
  public handleChange : EventEmitter<{key: string, direction : 'asc' | 'desc' | ''}>;
 

  constructor( 
    private goalToCashService : GoalToCashService
  ) {this.handleChange = new EventEmitter();  }

  ngOnInit(): void {
  }

  
  public sortColumn(key : string, direction : 'asc' | 'desc' | '') {
     
    // emit sort request to consuming component
     this.handleChange.emit(new SortEvent({key: key, direction: direction}));
  
  }


}
