import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OuxTooltipOptions, OuxTooltipPosition, OuxThemeType } from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { OrdersMetricHeaderDetailsModel } from 'src/app/shared/models/concrete/partials/orders-metric-header-details.model';
import { ActivatedRoute } from '@angular/router';
import { MetricSOTransactionLineRequestModel } from 'src/app/shared/models/concrete/request/metric-order-line-request.model';
import { GoalToCashService } from 'src/app/shared/services/goal-to-cash.service';
import { OrdersMetricSOHeaderDetailsModel } from 'src/app/shared/models/concrete/partials/orders-metric-so-header-details.model';
import {AllocationTransactionType} from "../../../../models/types/allocation-transaction-type.enum";
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';

@Component({
  selector: 'metric-so-transaction-lines-header',
  templateUrl: './metric-so-transaction-lines-header.component.html',
  styleUrls: ['./metric-so-transaction-lines-header.component.scss'],
  host: {
    'class': 'metric-so-transaction-lines-header'
  }
})
export class MetricSOTransactionLinesHeaderComponent implements OnInit {

  @Input('requestParams')
  public requestParams: any;

  @Input('totalRecords')
  public totalRecords: number;

  public AmpIdentifierKey = AmpIdentifierKey;

  public savId: string;

  public subscriptions: Subscription[] = [];

  public ordersMetricHeaderData: OrdersMetricHeaderDetailsModel;

  public myFlag: string;

  public peType: string;

  public toggleBookings: boolean = false;

  public toggleBacklog: boolean = false;

  public toggleRevenue: boolean = false;

  public toggleAnnual: boolean = false;

  public metricSoHeaderDetails: OrdersMetricSOHeaderDetailsModel;

  public ampIdentifier: string;

  public metricIdLabelTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    alignAtCenter: true,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '200px',
      'max-width': '500px'
    }
  });

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '100px',
      'max-width': '250px'
    },
    context: {
      code: null
    }
  });

  constructor(private goalToCashService: GoalToCashService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.myFlag = this.requestParams.myFlag;
    this.peType = this.requestParams.peType;
    this.ampIdentifier = this.requestParams.ampIdentifier;
    this.buildCurrentRequest();
  };

  public buildCurrentRequest() {
    // build request model
    let request = new MetricSOTransactionLineRequestModel({
      quotaId: String(this.requestParams.quotaId),
      goalId: this.requestParams.goalId,
      salesOrderNumber: this.requestParams.searchValue,
      limit: 1,
      offset: 0,
      sortBy: 'SO_NUMBER',
      sortOrder: 'desc'
    })
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let totalRecordsChange = changes.totalRecords;

    if (totalRecordsChange && totalRecordsChange.currentValue !== totalRecordsChange.previousValue) {
      this.totalRecords = totalRecordsChange.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  // toggle header drill downs for bookings, backlog, revenue and annual
  public toggleBookingSplit(): void {
    this.toggleBacklog = this.toggleRevenue = this.toggleAnnual = false;
    this.toggleBookings = !this.toggleBookings;
  }

  public toggleBacklogSplit(): void {
    this.toggleBookings = this.toggleRevenue = this.toggleAnnual = false;
    this.toggleBacklog = !this.toggleBacklog
  }

  public toggleRevenueSplit(): void {
    this.toggleBacklog = this.toggleBookings = this.toggleAnnual = false;
    this.toggleRevenue = !this.toggleRevenue;
  }

  public toggleAnnualSplit(): void {
    this.toggleBacklog = this.toggleBookings = this.toggleRevenue = false;
    this.toggleAnnual = !this.toggleAnnual;
  }

}
