/* Angular Import */
import {
  Component,
  OnInit }                                  from '@angular/core';
/* Package Imports */
import { OuxNotAuthorizedPartialOptions }   from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }                  from '../../shared/services/instance.service';


@Component({
  selector: 'app-401',
  templateUrl: 'notauthorized.component.html',
  styleUrls: ['notauthorized.component.scss'],
  host: {
    'class': 'app-unauthorized'
  }
})
export class NotAuthorizedComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public options : OuxNotAuthorizedPartialOptions = new OuxNotAuthorizedPartialOptions({
      // background: ''
      // buttonLabel: 'Custom Label'
      // textColor: '#888888',
      // message: 'Some Custom Message',
      redirectUri: '/goal-attainment'
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private instanceSvc : InstanceService) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('NotAuthorizedComponent', 'app-401');
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
