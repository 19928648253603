<div class="user">
  
  <button 
    class="btn --stateless"
    (click)="toggleDropdown()"
    (blur)="close = false"
    [ouxTooltip]="injectedSettingsTooltip"
    [ouxTooltipOptions]="injectedSettingsTooltipOpts">
    <span class="toggle" [ngClass]="{ '--close' : close }">&nbsp;</span>
  </button>

  <div class="user__display">
    <ng-container *ngIf="(userImpersonationName$ | async) as userImpersonationName; else defaultUserName">
      <span class="user__name">{{ userImpersonationName }}</span>
    </ng-container>
    <ng-template #defaultUserName>
      <span class="user__name">{{ userName$ | async }}</span>
    </ng-template>
  
    <span class="user__impersonation" *ngIf="userEmployeeId$ | async as userEmployeeID">({{ userEmployeeID }})</span>
    <span id="userId" *ngIf="userId$ | async as userId" hidden="hidden">{{ userId }}</span>
  </div>

  <span class="user__avatar" [ngClass]="{ '--initials': setInitials }">
    <img 
      *ngIf="!setInitials" 
      class="img-responsive" 
      [src]="userAvatar" 
      alt="Directory photo of {{ currentUser }}"/>
    <span *ngIf="setInitials">{{ userInitial }}</span>
  </span>

</div><!-- /user -->