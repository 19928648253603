/* Angular Import */
import { Injectable }                                   from '@angular/core';
import { 
  BehaviorSubject, 
  combineLatest, 
  Observable, 
  of}                                          from 'rxjs';
import { map, tap }                                     from 'rxjs/operators';
/* Feature Imports */
import { 
  OuxStore,
  OuxLoggerService, 
  OuxModalService}                                      from '@cisco/oux-common';
import { OrdersSearchOrder }                            from '../models/interface/partials/orders-search-order';
import { Transactions }                                 from '../models/interface/partials/transactions';
import { OrderStore }                                   from './order.store';
import { DirectTransactionLineDetailsModalComponent }   from '../components/direct-transaction-lines/partials/direct-transaction-line-details-modal/direct-transaction-line-details-modal.component';
import { OrdersLinesDetails } from '../models/interface/partials/orders-lines-details';
import { OrdersLinesBacklogDetails } from '../models/interface/partials/orders-lines-backlog-details';
import { OrderLinesSummary } from '../models/interface/partials/order-lines-summary';

/**
 * Creates our Transactions State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class OrderLineTransactionsStore extends OuxStore<OrdersLinesDetails> {
  
  private orderLineBacklogDetailsSubject = new BehaviorSubject<OrdersLinesBacklogDetails>(null);

  public orderLineTransactions$ = this.state$
    .pipe(
      map(details => details?.P_TRANSACTIONS || [])
    );

  public orderAttributes$ = this.state$
    .pipe(
      map(details => details?.P_ORDER_ATTRIBUTES)
    );

  public orderLineBacklogs$ = this.orderLineBacklogDetailsSubject.asObservable()
    .pipe(
      map(details => details?.P_ORDER_BACKLOG || [])
    );

  constructor(private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService,
              private orderStore: OrderStore) {

    super (null);

  } 

  public setDetails(details: OrdersLinesDetails) : void {
    super.setState(details);
    this.ouxLoggerSvc.logDebug('OrderLineTransactionsStore - <Transactions[]>:', this.state);
  }

  public setBacklogDetails(details: OrdersLinesBacklogDetails): void {
    this.orderLineBacklogDetailsSubject.next(details);
  }

  public showTransactionDetailsModal(selectedOrderLine: OrderLinesSummary, transactionType: string) : void {
    let combinedOrderDetails$ = combineLatest([
      this.orderLineTransactions$,
      this.orderLineBacklogs$,
      of(selectedOrderLine),
      of(transactionType),
      this.orderStore.selectedOrder$
    ])
    .pipe(
      tap(data => this.ouxLoggerSvc.logDebug('Combined Order Line Transaction Data Stream:', data)),
      map(([transactions, backlogs, selectedOrderLine, transactionType, selectedOrder ]) => ({ transactions, backlogs, selectedOrderLine, transactionType, selectedOrder }))
    );

    if (selectedOrderLine) {
      this.ouxModalSvc.openModal(DirectTransactionLineDetailsModalComponent, combinedOrderDetails$);
    }
  }



  /**
   * Clear Order Lines Data Collection
   * ============================================================
   */
   public clearOrderLineTransactionsData(): void {
    this.setState(null);
    this.setBacklogDetails(null);
  }

  /**
   * Convenience Methods
   * ============================================================
   */

}