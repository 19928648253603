import {  
  HttpClient,  
  HttpHeaders }                                                 from '@angular/common/http'; 
import { Injectable }                                           from '@angular/core'; 
import {  
  Observable,  
  of,  
  throwError }                                                  from 'rxjs'; 
import {  
  catchError, 
  map,  
  switchMap }                                                   from 'rxjs/operators'; 
import {  
  OuxAuthenticationService,  
  OuxConfigService,  
  OuxExceptionsHandleError, 
  OuxExceptionsHandlerService }                                   from '@cisco/oux-common'; 
import { DirectTransactionLinesSummaryTableExportResponse }       from './direct-transaction-lines-summary-table-export-response';
import { DirectTransactionLinesSummaryTableExportModel }          from './direct-transaction-lines-summary-table-export.model';
import { MetadataStore }                                          from 'src/app/shared/stores/metadata.store';
import { UserDetailsStore }                                       from 'src/app/shared/stores/user-details.store';
import { DirectTransactionLinesSummaryTableExportRequest }        from './direct-transaction-lines-summary-table-export-request';


@Injectable({ providedIn: 'root' })
export class DirectTransactionLinesSummaryTableExportService {

  private baseUri: string;
  private orderSearchLinesUri: string;
  private orderSearchNotEligibleLinesUri: string;

  /** 
   * Create service mapping for http exception handling 
   */ 
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('DirectTransactionLinesSummaryTableExportService'); 

   constructor(private http: HttpClient, 
              private ouxAuthSvc: OuxAuthenticationService, 
              private ouxConfigSvc: OuxConfigService, 
              private ouxExceptionsSvc: OuxExceptionsHandlerService, 
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore) {

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.orderSearchLinesUri = apiUri.orderLines;
    this.orderSearchNotEligibleLinesUri = apiUri.notEligibleRegularTransactions;
  }

  public fetchData(request: DirectTransactionLinesSummaryTableExportRequest) : Observable<DirectTransactionLinesSummaryTableExportModel[]> {

    const URL = `${this.baseUri}${this.orderSearchLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<DirectTransactionLinesSummaryTableExportResponse>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response : DirectTransactionLinesSummaryTableExportResponse) => {
          let typed = (response?.data?.P_ORDER_SUMMARY || []).map(order => new DirectTransactionLinesSummaryTableExportModel(order));

          return typed;
        }),
        catchError(error => { 
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchData', error)(error); 
        }) 
      );

    return REQUEST$;
  }

  public fetchNotEligibleData(request: any): Observable<DirectTransactionLinesSummaryTableExportModel[]> {

    const URL = `${this.baseUri}${this.orderSearchNotEligibleLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<DirectTransactionLinesSummaryTableExportModel[]>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || (response?.length > 0 && !response[0])) {
            return throwError(response);
          }

          return of(response);
        }),
        catchError(error => { 
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchNotEligibleData', error)(error); 
        }) 
      );

    return REQUEST$;
  }

  /** 
   * Stages our Http Request Headers 
   */ 
   private getOptions() : { headers: HttpHeaders } { 
 
    const OPTIONS : { headers : HttpHeaders } = { 
      headers : new HttpHeaders() 
        .set('Authorization', this.ouxAuthSvc.getAuthToken()) 
        .append('Content-Type', 'application/json') 
    }; 
 
    return OPTIONS; 
  }

}