/* Angular Imports */
import { 
  Component, 
  OnInit }                                    from '@angular/core';
import { Router }                             from '@angular/router';
/* RxJs Imports */
import { Subscription }                       from 'rxjs';
/* OneUX Imports */
import {
  OuxModalService,
  OuxWindowRefService }                       from '@cisco/oux-common';
/* Feature Imports */
import { LogoutModalComponent }               from '../logout-modal/logout-modal.component';
import { ProxyModalComponent }                from '../proxy-modal/proxy-modal.component';
//import { PreferencesModalComponent }          from '../preferences-modal/preferences-modal.component';
import { MetadataStore }                      from 'src/app/shared/stores/metadata.store';
import { GoogleAnalyticsService }             from 'src/app/core/services';


@Component({
  selector: 'toolbar-settings',
  templateUrl: './toolbar-settings.component.html',
  styleUrls: ['./toolbar-settings.component.scss'],
  host: {
    'class': 'toolbar-settings'
  }
})
export class ToolbarSettingsComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];
  private windowRef : any;

  
  public isAdmin$ = this.metadataStore.isUserAdmin$;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router: Router,
              private metadataStore: MetadataStore,
              private ouxModalSvc: OuxModalService,
              private ouxWindowRefSvc: OuxWindowRefService,
              private googleAnalyticsSvc: GoogleAnalyticsService) {

    // assign our window object reference
    this.windowRef = this.ouxWindowRefSvc.nativeWindow;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  public ngOnDestroy() : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public openModal(caller: string, event : any) : void {
    switch (caller) {
      // not part of day 1 scope - will be part of future scope - do not remove - 9/28/21
      // case 'preferences' : {
      //   this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'preferences', 1);
      //   this.ouxModalSvc.openModal(PreferencesModalComponent);
      //   break;
      // }
      case 'assignProxy' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'assign proxy', 1);
        this.ouxModalSvc.openModal(ProxyModalComponent);
        break;
      }
      case 'logout' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'logout', 1);
        this.ouxModalSvc.openModal(LogoutModalComponent);
      }
    }
  }

  /**
   * Menu actions - User Settings
   */
   public goTo(caller : string, event : any) : void {
    switch (caller) {
      case 'bannersUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'manage banners', 1);
        this.router.navigate(['/manage-banners']);
        break;
      }
      case 'userBannersUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'manage user banners', 1);
        this.router.navigate(['/manage-user-banners']);
        break;
      }
      case 'feedbackUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'feedback', 1);
        this.openNewWindow('https://www.cisco.vovici.com/se/5235956522C5FFD6');
        break;
      }
    }
  }



  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private openNewWindow(url : string) : void {
    /**
     * Added rel="noreferrer noopener" to external links due to potential security/perfomance issues. 
     * Details here under "target" attribute: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
     */
    this.windowRef.open(url, '_blank', 'noopener,noreferrer');
  }

}
