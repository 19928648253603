import { 
  HttpClient, 
  HttpHeaders }                 from '@angular/common/http';
import { Injectable }           from '@angular/core';
import { 
  Observable, 
  of, 
  throwError,
  EMPTY }                       from 'rxjs';
import { 
  catchError,
  map, 
  switchMap, 
  tap }                         from 'rxjs/operators';
import { 
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService, 
  OuxConfigService, 
  OuxLoggerService }            from '@cisco/oux-common';
import { UserDetails }          from '../models/interface/partials/user-details';
import { UserDetailsResponse }  from '../models/interface/response/user-details-response';
import { UserDetailsModel }     from '../models/concrete/partials/user-details.model';


@Injectable({ providedIn: 'root' })
export class UserDetailsService {

  private baseUri: string;
  private userDetailsUri: string;

  /**
   * Create service mapping for http exception handling
   */
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('UserService');

  constructor(private http: HttpClient,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private ouxLoggerSvc: OuxLoggerService) {


    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.userDetailsUri = this.ouxConfigSvc.getAppConfigValue('apiUri').userDetails;
  }

  /**
   * Responsible for retrieving the logged in user details
   * @returns user details
   */
  public loadUserDetails(): Observable<UserDetails> {

    const URL = `${this.baseUri}${this.userDetailsUri}`;
    const OPTIONS = this.getOptions();

    const REQUEST$ = this.http.get<UserDetailsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {

          if (!response) {
            return EMPTY;
          }

          let typed = new UserDetailsModel({
            uid: response.pingFed_Response.access_token.uid || response.pingFed_Response.access_token.sub,
            accessLevel: response.pingFed_Response.access_token.accesslevel
          });

          return typed;
        }),
        tap( (response : UserDetails) => {
          this.ouxLoggerSvc.logDebug('UserDetailsService: loadUserDetails()', response);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('loadUserDetails', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
   private getOptions() : { headers: HttpHeaders } {

    const OPTIONS : { headers : HttpHeaders } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
    };

    return OPTIONS;
  }

}