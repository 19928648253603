import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { DropdownComponent } from '../dropdown.component';

@Component({
  selector: 'visibility-dropdown-item',
  templateUrl: './dropdown-item.component.html',
  styleUrls: ['./dropdown-item.component.scss'],
  host: {
    'class': 'visibility-dropdown-item',
    '(click)': 'select()'
  }
})
export class DropdownItemComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input('isSelected')
  public isSelected: string;

  @Output('onSelect')
  public onSelect = new EventEmitter<void>();

  constructor(
    @Optional() private dropdownComponent: DropdownComponent
  ) {
    
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public select(): void {
    this.onSelect.emit();

    if (this.dropdownComponent) {
      this.dropdownComponent.close();
    }
  }
}
