export class OfflineExportHeaderModel {

  public icon: string;
  public heading: string;


  constructor(options: Partial<OfflineExportHeaderModel>) {
    Object.assign(this, options);

    if (!this.icon) {
      this.icon = 'icon-export';
    }

    if (!this.heading) {
      this.heading = 'Duplicate Export Request';
    }

  }

}
