/* Angular Imports */
import { Injectable }           from '@angular/core';
import { 
  HttpInterceptor, 
  HttpRequest, 
  HttpHandler, 
  HttpEvent }                   from '@angular/common/http';
import { 
  Router, 
  NavigationExtras }            from '@angular/router';
/* RxJs Imports */
import { 
  Observable, 
  throwError }                  from 'rxjs';
import { catchError }           from 'rxjs/operators';


@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router : Router) { }

  /**
   * Added as a fallback in case silent refresh fails. 
   * If it fails, the app will still trigger the redirect back to the login screen.
   */
  intercept(request : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(error => {
          if (error.error instanceof ErrorEvent) {
            return throwError(error);
          }

          if (error.status !== 401) {
            return throwError(error);
          }

          const url = this.router.url || '';

          if (url.toLowerCase().includes('login')) {
            return throwError({ sessionTimeout: true });
          }

          if (!url || url == '/') {
            this.logout('/login', {queryParams: {sessionTimeout: true}});
          } else {
            this.logout('/login', {queryParams: {sessionTimeout: true, return: url}});
          }

          return throwError({ sessionTimeout: true });
        })
      );
  }

  public logout(url : string, extras : NavigationExtras) : void {
    window.sessionStorage.clear();

    this.router.navigate([url], extras);
  }

}