import { OrdersSearchOrder } from '../../interface/partials/orders-search-order';

export class OrdersSearchOrderModel implements OrdersSearchOrder {

  public AMP_IDENTIFIER: string;
  public ANNUAL_VALUE: number;
  public BACKLOG: number;
  public BKLG_NEW_LOGO_IACV_AMOUNT: number;
  public BKLG_UPSELL_IACV_AMOUNT: number;
  public BKLG_XSELL_IACV_AMOUNT: number
  public BOOKING: number;
  public BOOKING_ADJ_AMOUNT: number;
  public COMP_PLAN_ID: number;
  public CURRENCY_CODE: string;
  public DEAL_ID: string;
  public EMPLOYEE_ID: string;
  public END_CUSTOMER: string;
  public ERP_POS_FLAG: string;
  public EXTD_MY_PAYOUT_AMOUNT_USD: number;
  public IACV_BOOKING_AMOUNT: number;
  public METRIC_MANUAL_REVENUE_AMOUNT: number;
  public MY_PAYOUT_AMOUNT_USD: number;
  public NODE_NAME: string;
  public NODE_NAME_DESC: string;
  public ORDER_TYPE: string;
  public PEC: string;
  public PLAN_ELEMENT_DESCR: string;
  public PO_NUMBER: string;
  public QUOTA_ID: number;
  public RENEWAL_BOOKING_AMOUNT: number;
  public REVENUE: number;
  public REVENUE_MULTI: number;
  public REV_NEW_LOGO_IACV_AMOUNT: number;
  public REV_UPSELL_IACV_AMOUNT: number;
  public REV_XSELL_IACV_AMOUNT: number;
  public SELL_TYPE: string;
  public SO_DATE: string;
  public SO_NUMBER: number;
  public SRP_GOAL_HEADER_ID: number;
  public SRP_GOAL_QUOTA_ID: number;
  public TERRITORY_TYPE_CODE: string;
  public TOTAL_VALUE: number;
  public TYPE: string;
  public ACV_AMOUNT: number;
  public TCV_AMOUNT: number;
  public METRIC_FLAG: string;
  public ENABLE_ACV_TCV: string;
  public isBookingExpanded: boolean;
  public isBacklogExpanded: boolean;
  public isRevenueExpanded: boolean;
  public isAnnualExpanded: boolean;
  public NODENAMEDESC: string;

  // optional - not part of original model - currently used for export
  public REVENUE_FOR_BACKLOG?: number;

  constructor(options: Partial<OrdersSearchOrderModel>) {
    Object.assign(this, options);
  }


}
