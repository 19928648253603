<ng-container *ngIf="data">

  <oux-modal-content-container>
    <oux-modal-content-header [setOptions]="{ icon: 'icon-tags', heading: data?.ORDER_NUMBER + ' - Order Transaction Details' }"></oux-modal-content-header>
    <oux-modal-content-article>
      
      <payments-transactions-details-modal-export></payments-transactions-details-modal-export>

      <section class="grid">
  
        <div class="grid__column">
          <div class="column__row">
            <span class="label">Distributor</span>
            <span class="value">{{ data?.DISTRIBUTOR_NAME }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Bill to Customer</span>
            <span class="value">{{ data?.BILL_TO_CUSTOMER }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Ship To Customer</span>
            <span class="value">{{ data?.SHIP_TO_CUSTOMER }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">End Customer</span>
            <span class="value">{{ data?.END_CUSTOMER }}</span>
          </div><!-- /column__row -->
        </div>

        <div class="grid__column">
          <div class="column__row">
            <span class="label">Book Date</span>
            <span class="value">{{ (data?.LINE_CREATION_DATE | dateFormat) || 'Not Available' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Invoice Date</span>
            <span class="value">{{ (data?.TRANSACTION_DATE | dateFormat) || 'Not Available' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Contract Start Date</span>
            <span class="value">{{ (data?.CONTRACT_START_DATE | dateFormat) || 'Not Available' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Contract End Date</span>
            <span class="value">{{ (data?.CONTRACT_END_DATE | dateFormat) || 'Not Available' }}</span>
          </div><!-- /column__row -->
        </div>
    
        <div class="grid__column --custom-border">
          <div class="column__row">
            <span class="label">Line Amount (Original)</span>
            <span class="value --with-padding">{{ data?.SALES_VALUE | number:'1.2-2' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Line Amount (Multiplied)</span>
            <span class="value --with-padding">{{ data?.SALES_VALUE_MULTIPLIED | number:'1.2-2' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Split %</span>
            <span class="value --with-padding">{{ data?.SPLIT_PERCENT }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Sales Motion</span>
            <span class="value --with-padding">{{ data?.SALES_MOTION }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">
              Annual Value
              <i 
                class="icon-info-outline"
                [ouxTooltip]="annualValueDescTemplate"
                [ouxTooltipOptions]="templateTooltipOpts">
              </i>
              <ng-template #annualValueDescTemplate>
                <p class="tooltip__text">
                  Annual Contract Value - Bookings value from first 12 months of a Multi-Year contract. 
                  If the contract term is less than 12 months, ACV would be Bookings value for that term (equivalent to TCV).
                </p>
              </ng-template>
            </span>
            <span class="value --with-padding">{{ (data?.ANNUAL_VALUE | number:'1.2-2') || 'Not Available' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">
              Total Contract Value
              <i 
                class="icon-info-outline"
                [ouxTooltip]="totalContractValueDescTemplate"
                [ouxTooltipOptions]="templateTooltipOpts">
              </i>
              <ng-template #totalContractValueDescTemplate>
                <p class="tooltip__text">
                  Total Contract Value - Bookings value across the lifetime of the contract.
                </p>
              </ng-template>
            </span>
            <span class="value --with-padding">{{ (data?.TOTAL_VALUE | number:'1.2-2') || 'Not Available' }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Contract Term</span>
            <span class="value --with-padding">{{ data?.CONTRACT_TERM || 'Not Available' }}</span>
          </div><!-- /column__row -->
        </div>
    
        <div class="grid__column">
          <div class="column__row">
            <span class="label">Part Number</span>
            <span class="value">{{ data?.PART_NUMBER  }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Product Split %</span>
            <span class="value">{{ data?.PRODUCT_SPLIT_PERCENT }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Business Unit</span>
            <span class="value">{{ data?.BUSINESS_UNIT }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Revenue Multiplier</span>
            <a class="link" href="https://salesconnect.cisco.com/#/program/PAGE-1918">
              {{ data?.REV_MULTIPLIER_FACTOR ? data?.REV_MULTIPLIER_FACTOR : 1 }}
            </a>
          </div><!-- /column__row -->
        </div>
    
        <div class="grid__column">
          <div class="column__row">
            <span class="label">AT Level 1</span>
            <span class="value">{{ data?.AT_LEVEL_1  }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">AT Level 2</span>
            <span class="value">{{ data?.AT_LEVEL_2  }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">AT Level 3</span>
            <span class="value">{{ data?.AT_LEVEL_3  }}</span>
          </div><!-- /column__row -->
          <div class="column__row">
            <span class="label">Metric Type</span>
            <span class="value">{{ data?.METRIC_TYPE === 'ACV' ? 'Annual' : data?.METRIC_TYPE }}</span>
          </div><!-- /column__row -->
        </div>
    
      </section>

      <div class="footer">
        <oux-button 
          [options]="{ label : 'Close', style : 'flat', dataSelector : 'lines-transactions-modal-oJVlEZeAp7' }" 
          (trigger)="closeModal()"> 
        </oux-button> 
      </div>

    </oux-modal-content-article>
  </oux-modal-content-container>

</ng-container>