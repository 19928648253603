/* Angular Imports */
import { 
  Component, 
  Inject, 
  OnInit }                                  from '@angular/core';
import { Observable }                       from 'rxjs';
/* OneUX Imports */
import { 
  MODAL_DATA, 
  OuxModalService }                         from '@cisco/oux-common';
import { OrdersSearchOrder }                from 'src/app/shared/models/interface/partials/orders-search-order';
import { Transactions }                     from 'src/app/shared/models/interface/partials/transactions';
import { OrdersLinesBacklog } from 'src/app/shared/models/interface/partials/orders-lines-backlog';
import { map } from 'rxjs/operators';
import { TransactionLineDetailsGridViewModel } from '../../../transaction-line-details-grid/transaction-line-details-grid.view-model';
import { OrderLinesSummary } from 'src/app/shared/models/interface/partials/order-lines-summary';



@Component({
  selector: 'direct-transaction-line-details-modal',
  templateUrl: './direct-transaction-line-details-modal.component.html',
  styleUrls: ['./direct-transaction-line-details-modal.component.scss'],
  host: {
    'class': 'direct-transaction-line-details-modal'
  }
})
export class DirectTransactionLineDetailsModalComponent implements OnInit {
  public mappedData$ = this.data$
    .pipe(
      map(({transactions, backlogs, selectedOrderLine, transactionType, selectedOrder}) => {
        switch (transactionType) {
          case 'backlog':
            return {transactions: this.mapBacklogToViewModels(backlogs, selectedOrderLine), selectedOrderLine, transactionType, selectedOrder};
          case 'revenue':
          default:
            return {transactions: this.mapTransactionsToViewModels(transactions, selectedOrderLine), selectedOrderLine, transactionType, selectedOrder};
        }
      })
    )

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(
    private ouxModalSvc: OuxModalService,
    @Inject(MODAL_DATA) public data$ : Observable<{
        transactions: Transactions[];
        backlogs: OrdersLinesBacklog[];
        selectedOrderLine: OrderLinesSummary;
        transactionType: string;
        selectedOrder: OrdersSearchOrder;
    }>
  ) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeModal() : void {
    this.ouxModalSvc.closeModal('Closed - Direct Transaction Line Details Modal');
  }

  private mapBacklogToViewModels(backlogs: OrdersLinesBacklog[], selectedOrderLine: OrderLinesSummary): TransactionLineDetailsGridViewModel[] {
    return (backlogs || []).map(backlog => new TransactionLineDetailsGridViewModel({
      annualValue: backlog.ANNUAL_VALUE,
      atLevel1: backlog.AT_LEVEL_1,
      atLevel2: backlog.AT_LEVEL_2,
      atLevel3: backlog.AT_LEVEL_3,
      atLevel4: backlog.AT_LEVEL_4,
      bookDate: backlog.LINE_CREATION_DATE || selectedOrderLine.LINE_CREATION_DATE || selectedOrderLine.SOURCE_TRANSACTION_DATE,
      businessUnit: backlog.BUSINESS_UNIT,
      commBacklogAmt: backlog.COMM_BACKLOG_AMT,
      contractEndDate: backlog.CONTRACT_END_DATE,
      contractStartDate: backlog.CONTRACT_START_DATE,
      contractTerm: backlog.CONTRACT_TERM,
      expectedShipDate: backlog.EXPECTED_SHIP_DATE,
      extdMyPayoutAmount: null,
      metricType: backlog.METRIC_TYPE,
      myPEFlag: null,
      myPayoutAmount: null,
      myPayoutFactor: null,
      partNumber: backlog.PART_NUMBER,
      productSplit: null,
      revenueMultiplierFactor: null,
      salesMotion: null,
      splitPercent: null,
      totalValue: backlog.TOTAL_VALUE,
      transCreationDate: null,
      transactionAmount: null,
      transactionDate: null
    }));
  }

  private mapTransactionsToViewModels(transactions: Transactions[], selectedOrderLine: OrderLinesSummary): TransactionLineDetailsGridViewModel[] {
    return (transactions || []).map(transaction => new TransactionLineDetailsGridViewModel({
      annualValue: transaction.ANNUAL_VALUE,
      atLevel1: transaction.AT_LEVEL_1,
      atLevel2: transaction.AT_LEVEL_2,
      atLevel3: transaction.AT_LEVEL_3,
      atLevel4: transaction.AT_LEVEL_4,
      bookDate: transaction.SOURCE_TRANSACTION_DATE || selectedOrderLine.LINE_CREATION_DATE || selectedOrderLine.SOURCE_TRANSACTION_DATE,
      businessUnit: transaction.BUSINESS_UNIT,
      commBacklogAmt: null,
      contractEndDate: transaction.CONTRACT_END_DATE,
      contractStartDate: transaction.CONTRACT_START_DATE,
      contractTerm: transaction.CONTRACT_TERM,
      expectedShipDate: null,
      extdMyPayoutAmount: transaction.EXTD_MY_PAYOUT_AMOUNT,
      metricType: transaction.METRIC_TYPE,
      myPEFlag: transaction.MYPEFLAG,
      myPayoutAmount: transaction.MY_PAYOUT_AMOUNT,
      myPayoutFactor: transaction.MY_PAYOUT_FACTOR,
      partNumber: transaction.PART_NUMBER,
      productSplit: transaction.PRODUCT_SPLIT,
      revenueMultiplierFactor: transaction.REV_MULTIPLIER_FACTOR,
      salesMotion: transaction.SALES_MOTION,
      splitPercent: transaction.SPLIT_PERCENT,
      totalValue: transaction.TOTAL_VALUE,
      transCreationDate: transaction.TRANS_CREATION_DATE,
      transactionAmount: transaction.TRANSACTION_AMOUNT,
      transactionDate: transaction.ORIGINAL_TRANSACTION_DATE || transaction.TRANSACTION_DATE,
      nonEligibilityReason: transaction.NON_ELIGIBILITY_REASON
    }));
  }
}


