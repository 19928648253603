export enum AllocationTransactionType {
  Direct = 'Direct Orders',
  POS = 'POS Orders',
  XAAS = 'XAAS Orders',
  RevenueAdjustment = 'Revenue Adjustment',
  BookingAdjustment = 'Booking Adjustment',
  BookingAdjustments = 'Booking Adjustments',
  ManualRevenue = 'Manual Revenue',
  //Changed on 11/24 - alislewi - as Response for CredMemo from NODEALLOCSUMMARY seen as 'Credit Memo Debit Memo'
  CreditDebitMemoRebate = 'Credit/Debit Memos rebates without SO reference',
  CreditDebitMemo = 'Credit Memo Debit Memo'
}