/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }               from '@angular/core';
/* RxJS Imports */
import { 
  EMPTY, 
  Observable, 
  Subscription }                   from 'rxjs';
import { 
  catchError, 
  take, 
  tap }                            from 'rxjs/operators';
/* Package Imports */
import {
  OuxLayoutService,
  OuxRouterService,
  OuxLoggerService }               from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }         from '../../shared/services/instance.service';
import { MetadataStore }           from 'src/app/shared/stores/metadata.store';


/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    'class': 'app-header slideInDown animated'
  }
})
export class HeaderComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private header : HTMLElement | any;
  /**
   * Is side nav present
   */
  public showSideNav : boolean = null;
  /**
   * Manage Header Component Instance
   */
  public headerInstance : string = null;
  /**
   * Hide Search User's field if changeUser value is 'N' in metadata response object 
   */
  public canImpersonate$: Observable<boolean> = this.metadataStore.canUserImpersonate$
    .pipe(
      // takes first emitted value and completes - this is so we capture the initial logged in user's value once and leverage it the remainder of the applications lifecycle
      take(1),
      tap((changeUser: boolean) => {
        this.ouxLoggerSvc.logDebug('HeaderComponent - canImpersonate$:', changeUser);
      }),
      catchError((error: Error) => {
        this.ouxLoggerSvc.logDebug('HeaderComponent - canImpersonate$:', error);
        return EMPTY;
      })
    );


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private instanceSvc : InstanceService,
              private ouxLayoutSvc : OuxLayoutService,
              private ouxRouterSvc : OuxRouterService,
              private ouxLoggerSvc : OuxLoggerService,
              private metadataStore: MetadataStore) {

    this.setHeaderInstance();
    this.header = this.vcRef.element.nativeElement;

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('HeaderComponent', 'app-header');
    this.getLayoutState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Dynamically Get/Set new inboud route title
   */
  private setHeaderInstance() : void {

    this.subscriptions.push(
      this.ouxRouterSvc.getActiveRouteData()
        .subscribe(
          (data : any) => {
            this.ouxLoggerSvc.logDebug('Draw Header Instance:', data.title);
            this.headerInstance = data.title;
        })
    );

  }

  /**
   * Adds --full-width class to header template if showSideNav is false
   */
  private getLayoutState() : void {

    this.subscriptions.push(
      this.ouxLayoutSvc.getSideNavState()
        .subscribe(
          (state : boolean) => {
            this.showSideNav = state;
            this.handleLayoutChanges();
        })
    );

  }

  /**
   * Set class state based on inbound conditions
   */
  private handleLayoutChanges() : void {

    if (this.showSideNav == false) {
      this.header.classList.add('--fullwidth');
    }
    else {
      this.header.classList.remove('--fullwidth');
    }
  }

}
