import { Injectable }             from '@angular/core';
import { OuxConfigService }       from '@cisco/oux-common';
import { GoogleAnalyticsModel }   from 'src/app/shared/models/concrete/google-analytics.model';


declare let document : any;

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  private scripts : any = {};
  private trackId : GoogleAnalyticsModel;

  private scriptStore : { name : string, src : string }[] = [
    { name: 'gtagJS', src: 'https://www.googletagmanager.com/gtag/js?id=' },
    { name: 'appDynamicJS', src: 'https://cdn.appdynamics.com/adrum/adrum-latest.js' },
    { name: 'chatBotMashupJS', src: './assets/chatbot/New_Mashup.js' },
    { name: 'chatBotPegaHelperJS', src: './assets/chatbot/PegaHelper.js' },
    { name: 'chatBotPegaHelpExtensionJS', src: './assets/chatbot/PegaHelperExtension.js' }
  ];

  constructor(private configSvc : OuxConfigService) {

    this.trackId = this.configSvc.getAppConfigValue('googleAnalyticsId');

    this.scriptStore.forEach( (script : { name : string, src : string } ) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.name === 'gtagJS' ? `${script.src}${this.trackId}` : script.src
      };
    });
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public load(...scripts : string[]) {
    const promises : any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  public loadScript(name : string) {
    return new Promise( (resolve, reject) => {
      if (!this.scripts[name].loaded) {
        // load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  // IE
            script.onreadystatechange = () => {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } 
        else {  // others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({script: name, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error : any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);
      } 
      else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

}
