/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy }                          from '@angular/core';
/* RxJS Imports */
import { Subscription }                from 'rxjs';
/* Package Imports */
import { OuxRouterService }            from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }             from '../../shared/services/instance.service';


/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
  host: {
    'class': 'app-body'
  }
})
export class BodyComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];
  /**
   * Manages animation state.
   * If route title changes - trigger view animation fadeInDown
   */
  public triggerAnimation : boolean = false;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private uiRouterSvc : OuxRouterService,
              private instanceSvc : InstanceService) {

    this.subscriptions.push(
      this.uiRouterSvc.routeTitleChanged()
        .subscribe(
          (value : boolean) => {
            // If title changed
            if (value == true) {
              this.triggerAnimation = true;
            }
            setTimeout( () => {
              this.triggerAnimation = false;
            }, 1000);
        })
    );

   }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('BodyComponent', 'app-body');
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
