import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  MODAL_DATA,
  OuxModalService
} from '@cisco/oux-common';
import { OrdersSearchOrder } from 'src/app/shared/models/interface/partials/orders-search-order';
import { OrdersLinesBacklog } from 'src/app/shared/models/interface/partials/orders-lines-backlog';
import { map } from 'rxjs/operators';
import { OrderLinesSummary } from 'src/app/shared/models/interface/partials/order-lines-summary';
import { MetricSOTransactionLineDetailsGridViewModel } from '../../../metric-so-transaction-line-details-grid/metric-so-transaction-line-details-grid.view-model';
import { MetricSOTransactions } from 'src/app/shared/models/interface/partials/metric-so-transactions';
import { OrdersSearchNotEligibleOrder } from 'src/app/shared/models/interface/partials/order-search-not-eligible-order';



@Component({
  selector: 'metric-so-transaction-line-details-modal',
  templateUrl: './metric-so-transaction-line-details-modal.component.html',
  styleUrls: ['./metric-so-transaction-line-details-modal.component.scss'],
  host: {
    'class': 'metric-so-transaction-line-details-modal'
  }
})
export class MetricSOTransactionLineDetailsModalComponent implements OnInit {
  // call mapTransactionsToViewModels to map the data columns to the model
  public mappedData$ = this.data$
    .pipe(
      map(({ metricSOTransactions, selectedOrderLine, transactionType, peType, myFlag, selectedOrder }) => {
        return { metricSOTransactions: this.mapTransactionsToViewModels(metricSOTransactions, selectedOrderLine), selectedOrderLine, transactionType, peType, myFlag, selectedOrder };
      })
    )

  constructor(
    private ouxModalSvc: OuxModalService,
    @Inject(MODAL_DATA) public data$: Observable<{
      metricSOTransactions: MetricSOTransactions[];
      backlogs: OrdersLinesBacklog[];
      selectedOrderLine: OrderLinesSummary;
      transactionType: string;
      peType: string;
      myFlag: string;
      selectedOrder: OrdersSearchOrder | OrdersSearchNotEligibleOrder;
    }>
  ) { }
  ß
  ngOnInit(): void { }
  // Action on close modal window from backlog, revenue amounts
  public closeModal(): void {
    this.ouxModalSvc.closeModal('Closed - Metric SO Transaction Line Details Modal');
  }

  // Map columns to MetricSOTransactionLineDetailsGridViewModel
  private mapTransactionsToViewModels(metricSOTransactions: MetricSOTransactions[], selectedOrderLine: OrderLinesSummary): MetricSOTransactionLineDetailsGridViewModel[] {
    return (metricSOTransactions || []).map(metricSOTransaction => new MetricSOTransactionLineDetailsGridViewModel({
      BOOKING_ADJ_AMOUNT: metricSOTransaction.BOOKING_ADJ_AMOUNT || metricSOTransaction.BOOKING_ADJ_USD_AMOUNT,
      BUYING_PROGRAM_FLAG: metricSOTransaction.BUYING_PROGRAM_FLAG,
      SALESREP_NUMBER: metricSOTransaction.SALESREP_NUMBER,
      IACV_MEASURE_KEY: metricSOTransaction.IACV_MEASURE_KEY,
      GOAL_ID: metricSOTransaction.GOAL_ID,
      RENEWAL_BOOKING_AMOUNT: metricSOTransaction.RENEWAL_BOOKING_AMOUNT || metricSOTransaction.RENEWAL_BOOKING_USD_AMOUNT,
      TERRITORY_TYPE_NAME: metricSOTransaction.TERRITORY_TYPE_NAME,
      BUYING_PROGRAM_GROUP: metricSOTransaction.BUYING_PROGRAM_GROUP,
      NODE_LEVEL_6: metricSOTransaction.NODE_LEVEL_6,
      SAV_ID: metricSOTransaction.SAV_ID,
      OFFER_TYPE_NAME: metricSOTransaction.OFFER_TYPE_NAME,
      PRODUCT_FAMILY: metricSOTransaction.PRODUCT_FAMILY,
      PART_NUMBER: metricSOTransaction.PART_NUMBER,
      ELA_FLAG: metricSOTransaction.ELA_FLAG,
      NODE_LEVEL_5: metricSOTransaction.NODE_LEVEL_5,
      UPSELL_IACV_AMOUNT: metricSOTransaction.UPSELL_IACV_AMOUNT || metricSOTransaction.UPSELL_IACV_USD_AMOUNT,
      IACV_WALK_REASON_CODE: metricSOTransaction.IACV_WALK_REASON_CODE,
      CONTRACT_TERM: metricSOTransaction.CONTRACT_TERM,
      MY_PAYOUT_FACTOR: metricSOTransaction.MY_PAYOUT_FACTOR,
      MY_PAYOUT_AMOUNT: metricSOTransaction.MY_PAYOUT_AMOUNT,
      BOOKING: metricSOTransaction.BOOKING,
      METRIC_MANUAL_REVENUE_AMOUNT: metricSOTransaction.METRIC_MANUAL_REVENUE_AMOUNT,
      DATA_SOURCE: metricSOTransaction.DATA_SOURCE,
      INVOICE_DATE: metricSOTransaction.INVOICE_DATE,
      SHIP_TO_CUSTOMER_NAME: metricSOTransaction.SHIP_TO_CUSTOMER_NAME,
      PE_NAME: metricSOTransaction.PE_NAME,
      NODE_LEVEL_1: metricSOTransaction.NODE_LEVEL_1,
      SUB_BUSINESS_ENTITY: metricSOTransaction.SUB_BUSINESS_ENTITY,
      SUBSCRIPTION_REF_ID: metricSOTransaction.SUBSCRIPTION_REF_ID,
      PRODUCT_SERVICE_FLAG: metricSOTransaction.PRODUCT_SERVICE_FLAG,
      BUSINESS_UNIT: metricSOTransaction.BUSINESS_UNIT,
      METRIC_TYPE_CODE: metricSOTransaction.METRIC_TYPE_CODE,
      SO_NUMBER: metricSOTransaction.SO_NUMBER,
      SAAS_FLAG: metricSOTransaction.SAAS_FLAG,
      NODE_LEVEL_4: metricSOTransaction.NODE_LEVEL_4,
      QUOTA_ID: metricSOTransaction.QUOTA_ID || metricSOTransaction.SRP_GOAL_QUOTA_ID,
      COMP_PLAN_ID: metricSOTransaction.COMP_PLAN_ID,
      ATTRIBUTION_CODE: metricSOTransaction.ATTRIBUTION_CODE,
      NODE_LEVEL_3: metricSOTransaction.NODE_LEVEL_3,
      TOTAL_VALUE: metricSOTransaction.TOTAL_VALUE,
      METRIC_LENS_TYPE: metricSOTransaction.METRIC_LENS_TYPE,
      SALES_MOTION: metricSOTransaction.SALES_MOTION,
      SOURCE_TRANSACTION_DATE: metricSOTransaction.SOURCE_TRANSACTION_DATE,
      END_CUSTOMER_NAME: metricSOTransaction.END_CUSTOMER_NAME,
      TRANS_GROUP_CODE: metricSOTransaction.TRANS_GROUP_CODE,
      NODE_LEVEL_2: metricSOTransaction.NODE_LEVEL_2,
      XSELL_IACV_AMOUNT: metricSOTransaction.XSELL_IACV_AMOUNT || metricSOTransaction.XSELL_IACV_USD_AMOUNT,
      OFFER_PART_NUMBER: metricSOTransaction.OFFER_PART_NUMBER,
      BILL_TO_CUSTOMER_NAME: metricSOTransaction.BILL_TO_CUSTOMER_NAME,
      SPLIT_PERCENT: metricSOTransaction.SPLIT_PERCENT,
      BACKLOG: metricSOTransaction.BACKLOG,
      DEAL_ID: metricSOTransaction.DEAL_ID,
      CONTRACT_END_DATE: metricSOTransaction.CONTRACT_END_DATE,
      REVENUE: metricSOTransaction.REVENUE,
      NEW_LOGO_IACV_AMOUNT: metricSOTransaction.NEW_LOGO_IACV_AMOUNT || metricSOTransaction.NEW_LOGO_IACV_USD_AMOUNT,
      GOALING_PERIOD: metricSOTransaction.GOALING_PERIOD,
      EXP_IACV_AMOUNT: metricSOTransaction.EXP_IACV_AMOUNT,
      METRIC_TYPE: metricSOTransaction.METRIC_TYPE,
      PO_NUMBER: metricSOTransaction.PO_NUMBER,
      WPA_FLAG: metricSOTransaction.WPA_FLAG,
      IACV_BOOKING_AMOUNT: metricSOTransaction.IACV_BOOKING_AMOUNT || metricSOTransaction.IACV_BOOKING_USD_AMOUNT,
      TERRITORY_CODE: metricSOTransaction.TERRITORY_CODE,
      OFFER_NAME: metricSOTransaction.OFFER_NAME,
      ANNUAL_VALUE: metricSOTransaction.ANNUAL_VALUE,
      EMPLOYEE_ID: metricSOTransaction.EMPLOYEE_ID,
      LINE_ID: metricSOTransaction.LINE_ID,
      FISCAL_WEEK: metricSOTransaction.FISCAL_WEEK,
      BOOKING_TRANSACTION_TYPE: metricSOTransaction.BOOKING_TRANSACTION_TYPE,
      CX_UPSELL_GROUP: metricSOTransaction.CX_UPSELL_GROUP,
      CONTRACT_START_DATE: metricSOTransaction.CONTRACT_START_DATE,
      TERRITORY_TYPE_CODE: metricSOTransaction.TERRITORY_TYPE_CODE,
      BUYING_PROGRAM_NAME: metricSOTransaction.BUYING_PROGRAM_NAME,
      ATR_REFERENCE_TYPE: metricSOTransaction.ATR_REFERENCE_TYPE,
      BOOKED_FISCAL_WEEK: metricSOTransaction.BOOKED_FISCAL_WEEK,
      GS_CURRENCY_CODE: metricSOTransaction.GS_CURRENCY_CODE || metricSOTransaction.CURRENCY_CODE,
      CONTRACT_START_WEEK: metricSOTransaction.CONTRACT_START_WEEK,
      BUSINESS_ENTITY: metricSOTransaction.BUSINESS_ENTITY,
      PID_MAPPING_GROUP: metricSOTransaction.PID_MAPPING_GROUP,
      SAV_NAME: metricSOTransaction.SAV_NAME,
      TRANSACTION_DATE: metricSOTransaction.TRANSACTION_DATE,
      IACV_ASCN_BKG_TRX_KEY: metricSOTransaction.IACV_ASCN_BKG_TRX_KEY,
      NODE_NAME_DESC: metricSOTransaction.NODE_NAME_DESC,
      RESIDUAL_ANNUAL_USD_AMT: metricSOTransaction.RESIDUAL_ANNUAL_USD_AMT,
      ORG_RENEWED_USD_AMT: metricSOTransaction.ORG_RENEWED_USD_AMT,
      NON_ELIGIBILITY_REASON: metricSOTransaction.NON_ELIGIBILITY_REASON
    }));
  }
}


