import { Cell, Style } from 'exceljs';

export class ExportCellModel {
  public value: string | number | boolean | Date;
  public style: Partial<Style>; 

  constructor(args: Partial<ExportCellModel>) {
    Object.assign(this, args);
  }

  public mapToCell(cell: Cell): void {
    if (this.style?.numFmt) {
      cell.numFmt = this.style?.numFmt;
    }

    if (this.style?.font) {
      cell.font = this.style?.font;
    }

    if (this.style?.alignment) {
      cell.alignment = this.style?.alignment;
    }

    if (this.style?.fill) {
      cell.fill = this.style?.fill;
    }
  }

  public static getNumberStyles(numberOfDecimals: number): Partial<Style> {
    let decimalFormat = numberOfDecimals && numberOfDecimals > 0 ? `.${Array(numberOfDecimals + 1).join('0')}` : '';

    return {
      numFmt: `#,##0${decimalFormat}`, 
    };
  }

  public static getFillStyles(hex: string): Partial<Style> {
    let hexValue = hex?.startsWith('#') ? hex.substring(1) : hex;
    return {
      fill: {
        type: 'pattern', 
        pattern: 'solid',
        fgColor: { 
          argb: hexValue
        }
      }
    }
  }

  public static getPercentStyles(numberOfDecimals: number): Partial<Style> {
    let decimalFormat = numberOfDecimals && numberOfDecimals > 0 ? `.${Array(numberOfDecimals + 1).join('0')}` : '';

    return {
      numFmt: `0${decimalFormat}%`
    }
  }
}