import { GoalToCashAllocations }      from "../../interface/partials/goal-to-cash-allocations";
import { NodeAllocationSummary }      from "../../interface/partials/node-allocation-summary";
import { NodeAllocationSummaryModel } from "./node-allocation-summary.model";


export class GoalToCashAllocationsModel implements GoalToCashAllocations {

    public NODEALLOCSUMMARY: NodeAllocationSummary[];
    public P_ERROR_MESSAGE: string;

  constructor(args : Partial<GoalToCashAllocationsModel>) {
    Object.assign(this, args);

    if (this.NODEALLOCSUMMARY) {
      this.NODEALLOCSUMMARY = this.NODEALLOCSUMMARY.map(x => new NodeAllocationSummaryModel(x));
    }
  }

}