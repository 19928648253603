import { OrdersLinesRequest } from "../../interface/request/orders-lines-request";

export class OrdersLinesRequestModel implements OrdersLinesRequest {

    public loginId: string;
    public sourceSystem: string = 'Visibility';
    public userId: string;
    public employeeId: string;
    public srpGoalHeaderId: number;
    public planId: number;
    public allocationId: number;
    public nodeName: string = '';
    public territoryTypeCode: string = '';
    public erpPosFlag: string;
    public soNumber?: number;
    public limit: number = 10;
    public offset: number = 0;

  constructor(args : Partial<OrdersLinesRequestModel>) {
    Object.assign(this, args);
  } 

  public static createExportRequest(options: Omit<Partial<OrdersLinesRequest>, 'limit' | 'offset'>) : OrdersLinesRequest {
    return new OrdersLinesRequestModel({...options, ...{ limit: 1000000, offset: 0 }});
  }

}