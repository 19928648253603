/* Angular Import */
import { Injectable }                           from '@angular/core';
/* Feature Imports */
import { 
  OuxLoggerService, 
  OuxStore, 
  OuxWindowRefService}                          from '@cisco/oux-common';
import { UserDetails }                          from '../models/interface/partials/user-details';
import { UserDetailsModel }                     from '../models/concrete/partials/user-details.model';
import { UserList }                             from '../models/interface/partials/user-list';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { merge, Subject, BehaviorSubject, Observable } from 'rxjs';
import { UserDrillDownDetails } from '../models/interface/partials/user-drill-down-details';


/**
 * Creates our User Details injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class UserDetailsStore extends OuxStore<UserDetails> {

  private windowRef: Window;
  private readonly SESSION_STORAGE_KEY: string = 'userDetails';

  private impersonationChange = new Subject<UserList>();
  public impersonationChange$ = this.impersonationChange.asObservable();

  private drilldownUserChange = new Subject<UserDrillDownDetails>();
  public drilldownUserChange$ = this.drilldownUserChange.asObservable();

  private userChanged$ = new BehaviorSubject<boolean>(false);
  private drilldownUserChanged$ = new BehaviorSubject<boolean>(false);

  public impersonationUserId$ = this.state$
    .pipe(
      map(user => user?.drilldown?.userId || user?.impersonation?.emailId || user?.uid),
      distinctUntilChanged()
    );

  public impersonationUserFullName$ = this.state$
    .pipe(
      map(user => user && user.impersonation && user.uid != user.impersonation.emailId ? user.impersonation.fullName : ''),
      distinctUntilChanged()
    );

  constructor(private ouxWindowSvc: OuxWindowRefService,
              private ouxLoggerSvc : OuxLoggerService) {

    super (new UserDetailsModel({}));

    this.windowRef = this.ouxWindowSvc.nativeWindow;
  }

  /**
   * Default Method
   * ============================================================
   */
  public setUserDetails(userDetails: UserDetails) : void {
    // set user details state in memory
    super.setState(userDetails);
    // log inbound model
    this.ouxLoggerSvc.logDebug('UserDetailsStore: User Details', this.state);
    // set user details in session storage
    this.windowRef.sessionStorage.setItem(this.SESSION_STORAGE_KEY, this.serializeDetails(userDetails));
  }

  public setUserFullName(name: string): void {
    if (this.state) {
      this.state.name = name;

      this.setUserDetails(this.state);
    }
  }

  public setUserImpersonation(value: UserList): void {
    if (this.state) {
      this.state.impersonation = value;

      this.setUserDetails(this.state);
      this.impersonationChange.next(value);
    }
  }

  public setDrilldownUser(value: UserDrillDownDetails): void {
    if (this.state) {
      this.state.drilldown = value || null;

      this.setUserDetails(this.state);
      this.drilldownUserChange.next(value);
    }

    this.drilldownUserChange.next(value);
  }

  /**
   * Return User Details
   */
  public getUserDetails(): UserDetails {
    return this.state;
  }

  public getUserId(): string {
    return this.getUserDetails()?.uid;
  }

  public getImpersonationUserId(): string {
    let details = this.getUserDetails();

    return details?.drilldown?.userId || details?.impersonation?.emailId || details?.uid;
  }

  public getUserDetailsFromSessionStorage(): UserDetails {
    let json = this.windowRef.sessionStorage.getItem(this.SESSION_STORAGE_KEY);

    if (!json) {
      return null;
    }

    try {
      let parsed = JSON.parse(json);

      this.setUserDetails(new UserDetailsModel(parsed));

      return this.getUserDetails();
    } catch {
      // failed to parse user details or user details didn't exist
      return null;
    }
  }

  public setUserChanged(value: boolean) {
    this.userChanged$.next(value);
  }

  public getUserChangedFlag(): Observable<boolean> {
    return this.userChanged$.asObservable();
  }

  public setDrilldownUserChanged(value: boolean) {
    this.drilldownUserChanged$.next(value);
  }

  public getDrilldownUserChangedFlag(): Observable<boolean> {
    return this.drilldownUserChanged$.asObservable();
  }

/**
 * Help methods
 * ============================================================
 */
  private serializeDetails(data : UserDetails) : string {
    return JSON.stringify(data);
  }

}