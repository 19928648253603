<oux-generic-dropdown #genericDropdown [closeOnClick]="false" (onDisplay)="onDropdownDisplay()">
  <div #control class="trigger" ouxGenericDropdownTriggerContainer>
    <button *ngIf="!loading" ouxGenericDropdownTrigger>
      <div class="display">{{display || 'Select...'}}</div>
      <i class="icon icon-chevron-down"></i>
    </button>

    <oux-spinner [show]="loading" [size]="'small'"></oux-spinner>
  </div>
  <ng-template ouxGenericDropdownContent>
  <div class="visibility-dropdown" ouxThemeable [ngStyle]="{ 'min-width': listWidth$ | async }">
    <ng-content></ng-content>
  </div>
</ng-template>
</oux-generic-dropdown>