import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fiscalWeekFormat'
})
/**
 * Transforms inbound value based
 * Accepts string | number types
 * 
 * Changes format like 'FY22WK12'
 *   
 * Ex (without argument): {{ Fiscal_Week | fiscalWeekFormat }}
 */
export class FiscalWeekFormatPipe implements PipeTransform {

  constructor() { 
    
  }
  

  public transform(value: string | number) : string {

    let fiscalWeek = String(value); // Changing Fiscal Week to String irrespective of the type

    if(value){
        return ('FY' + fiscalWeek.substring(2,4) + 'WK' + fiscalWeek.substring(4)); // Extracting Year and Week and converting to desired Format
    }
    
  }

  
}