
import { BacklogMetricParamsDetails } from '../../interface/partials/backlog-metric-params-details';

export class BacklogMetricParamsDetailsModel implements BacklogMetricParamsDetails {
    public planElementName: string;
    public planId: number;
    public quotaId: number;
    public metricFlag: string;
    public calcEngine: string;
  constructor(args: Partial<BacklogMetricParamsDetailsModel>) {
    Object.assign(this, args);
  }
}