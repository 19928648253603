import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { OuxThemeType, OuxToastOptions, OuxToastService, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { forkJoin, Subscription } from 'rxjs';
import { OrdersSearchOrder } from 'src/app/shared/models/interface/partials/orders-search-order';
import { AtrExportDetailsModel } from '../../models/concrete/partials/atr-export-details.model';
import { OrderLinesSummary } from '../../models/interface/partials/order-lines-summary';
import { QuotaDetailsStore } from '../../stores/quota-details.store';
import { MetricSOTransactionLineDetailsGridViewModel } from './metric-so-transaction-line-details-grid.view-model';
import { GoalToCashService } from 'src/app/shared/services/goal-to-cash.service';

@Component({
  selector: 'metric-so-transaction-line-details-grid',
  templateUrl: './metric-so-transaction-line-details-grid.component.html',
  styleUrls: ['./metric-so-transaction-line-details-grid.component.scss'],
  host: {
    'class': 'metric-so-transaction-line-details-grid'
  }
})
export class MetricSOTransactionLineDetailsGridComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  public isMulti: boolean = false;

  public atrExportDetailsModel: AtrExportDetailsModel[];

  public infoTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right,
    theme: OuxThemeType.Background
  });

  public exportingToast = new OuxToastOptions({
    icon: 'icon-export',
    header: 'ATR Export',
    message: 'ATR details are being exported.',
    autoDismiss: false,
    scaleOnHover: false,
    theme: OuxThemeType.Info
  });

  @Input('metricSOTransactions')
  public metricSOTransactions: MetricSOTransactionLineDetailsGridViewModel[] = [];

  @Input('transactionType')
  public transactionType: string;

  @Input('peType')
  public peType: string;

  @Input('myFlag')
  public myFlag: string;

  @Input('selectedOrderLine')
  public selectedOrderLine: OrderLinesSummary;

  @Input('selectedOrder')
  public selectedOrder: OrdersSearchOrder;

  public toggleInvoice: boolean = false;

  constructor(
    private quotaDetailsStore: QuotaDetailsStore,
    private ouxToastSvc: OuxToastService,
    private goalToCashService: GoalToCashService
  ) {

  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOrder) {
      this.isMulti = this.quotaDetailsStore.isMulti(this.selectedOrder?.QUOTA_ID, this.selectedOrder?.COMP_PLAN_ID);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }


  public toggleInvoiceSplit(transaction): void {
    let isExpanded = transaction.isExpanded;
    this.metricSOTransactions.forEach(instance => instance.isExpanded = false);
    transaction.isExpanded = isExpanded ? false : true;
  }

  private showExportNotification(operation: string): void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'ATR Export',
        message: operation === 'success' ? 'Complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 2,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }

  public exportAtrExcel(transaction) {

    if (transaction.iacvBkgTrxKey === null) {
      this.ouxToastSvc.addToast(new OuxToastOptions({
        icon: 'icon-export',
        header: 'ATR Export',
        message: 'Failed to export details as there is no Booking Transaction Key for this line',
        autoDismiss: true,
        dismissTimeout: 5,
        scaleOnHover: false,
        theme: OuxThemeType.Error
      }));
    } else {
      this.ouxToastSvc.addToast(this.exportingToast);
      // Input payload to Teradata to fetch ATR details
      let atrDetailsRequest = {
        "sourceSystem": "Teradata",
        "iacvBkgTrxKey": transaction.IACV_ASCN_BKG_TRX_KEY,
        "metricLensTypeName": transaction.METRIC_LENS_TYPE,
        "serviceFlag": transaction.PRODUCT_SERVICE_FLAG === 'PRODUCT' ? 'N' : 'Y'
      };
      forkJoin({
        atrDetails: this.goalToCashService.fetchATRDetails(atrDetailsRequest)
      }).subscribe(({ atrDetails }) => {
        this.atrExportDetailsModel = atrDetails;
        if (this.atrExportDetailsModel) {
          this.ouxToastSvc.dismissToast(this.exportingToast);
          this.showExportNotification('success');
          this.goalToCashService.exportATRDetails(this.atrExportDetailsModel, transaction, this.transactionType);
          this.atrExportDetailsModel = null;
        }
      },
        error => {
          this.ouxToastSvc.dismissToast(this.exportingToast);
          this.ouxToastSvc.addToast(new OuxToastOptions({
            icon: 'icon-export',
            header: 'ATR Export',
            message: 'Failed to export details.',
            autoDismiss: true,
            dismissTimeout: 5,
            scaleOnHover: false,
            theme: OuxThemeType.Error
          }));
        });
    }
  }
}
