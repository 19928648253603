import { 
    HttpClient, 
    HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { 
    Observable, 
    of, 
    throwError } from "rxjs";
import { 
    catchError, 
    map, 
    switchMap } from "rxjs/operators";
import {
  OuxAuthenticationService,
  OuxConfigService,
  OuxExceptionsHandleError,
  OuxExceptionsHandlerService } from "@cisco/oux-common";
import { MetadataStore } from "src/app/shared/stores/metadata.store";
import { UserDetailsStore } from "src/app/shared/stores/user-details.store";

@Injectable({ providedIn: "root" })
export class PosTransactionDetailsGridExportService {
    
  private baseUri: string;
  private notEligibleTransactionsUri: string;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError: OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError("PosTransactionDetailsGridExportService");

  constructor(private http: HttpClient,
            private ouxAuthSvc: OuxAuthenticationService,
            private ouxConfigSvc: OuxConfigService,
            private ouxExceptionsSvc: OuxExceptionsHandlerService,
            private userDetailsStore: UserDetailsStore,
            private metadataStore: MetadataStore) {
    
    let apiUri = this.ouxConfigSvc.getAppConfigValue("apiUri");

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue("gatewayUri")}${this.ouxConfigSvc.getAppConfigValue("organizationUri")}${this.ouxConfigSvc.getAppConfigValue("apiVersion")}`;
    this.notEligibleTransactionsUri = apiUri.notEligibleRegularTransactions;
  }

  public fetchNotEligibleData(request: any): Observable<any[]> {
    const URL = `${this.baseUri}${this.notEligibleTransactionsUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<any[]>(URL, request, OPTIONS)
      .pipe(
        switchMap((response) => {
          if (!response || (response?.length > 0 && !response[0])) {
            return throwError(response);
          }

          return of(response);
        }),
        catchError((error) => {
          // create operation mapping for http exception handling
          return this.ouxHandleError("fetchNotEligibleData", error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
  private getOptions(): { headers: HttpHeaders } {
    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set("Authorization", this.ouxAuthSvc.getAuthToken())
        .append("Content-Type", "application/json"),
    };

    return OPTIONS;
  }
}
