/* Angular Imports */
import {
  OnInit,
  Component,
  AfterViewInit,
  OnDestroy,
  ElementRef,
  QueryList,
  ViewChildren,
  ViewContainerRef,
  Renderer2 }                           from '@angular/core';
import { Router }                       from '@angular/router';
/* RxJs Imports */
import { Subscription }                 from 'rxjs';
/* Package Imports */
import {
  OuxRouterService,
  OuxLayoutService }                    from '@cisco/oux-common';
/* Feature Imports */
import { CrumbContainerDirective }      from './directives/crumb-container.directive';
import { BreadcrumbsStore }             from './breadcrumbs.store';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  host: {
    'class': 'app-breadcrumbs fadeIn animated',
    '[class.--show]': 'showBreadcrumbs'
    // '[class.--adjust-height]': 'adjustBreadcrumbHeight'
  }
})
export class BreadcrumbsComponent implements OnInit, AfterViewInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];

  private excludedRoutes : Array<string> = [ 
    // '/goal-attainment', 
    // '/order-search/orders', 
    // '/payments/summary', 
    // '/goal-to-cash/summary',
    // '/manage-banners',
    // '/team-goal-attainment'
  ];

  // private adjustBreadcrumbHeight: boolean = null;

  /**
   * Stand up native element ref
   */
  private breadcrumbs : Node | any;

  /**
   * Crumbs model
   */
  public crumbs : any[] = new Array;
  /**
   * Manages our inbound route history
   */
  private path : string = null;
  public get getPath() : string {
    return this.path;
  };
  public set setPath(str : string) {
    this.path = str;
  };
  /**
   * Returns the list item/s as a QueryList, allowing for DOM manipulation 
   */
  @ViewChildren(CrumbContainerDirective, { read : ElementRef }) 
    public crumbContainer : QueryList<ElementRef>;

  // Responsible for hiding single level breadcrumbs
  public showBreadcrumbs : boolean = null;

  public crumbs$ = this.breadcrumbsStore.breadcrumbs$;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private router : Router,
              private ouxRouterSvc : OuxRouterService,
              private layoutSvc : OuxLayoutService,
              private renderer : Renderer2,
              private breadcrumbsStore: BreadcrumbsStore) {

    this.subscribeToRoute();

    this.breadcrumbs = this.vcRef.element.nativeElement;

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}


  ngAfterViewInit() : void {
    this.validateLinks();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public getRoutePath(index : number) : string {
    // rebuilds are route path for output
    let url = this.transformCrumbOutput(index).join('/');
    return `/${url}`;
  }

  public goToRoot() : void {
    this.router.navigate(['/dashboard']);
  }

  /**
   * Responsible for handling routing procedures 
   */
  public goTo(index : number) : void {
    let url = this.transformCrumbOutput(index);
    this.router.navigate(url);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Responsible for monitoring route changes
   */
  private subscribeToRoute() : void {
    this.subscriptions.push(
      this.ouxRouterSvc.getRouteHistory().subscribe( (history : string) => {

        this.canShowBreadcrumbs(history);

        let inbound = <string[]>history.split('/'),
            output = [];

        inbound = inbound.filter( (value : string) => value != '' );

        for (let i = 0; i < inbound.length; i++) {
          output.push(inbound[i].charAt(0).toUpperCase() + inbound[i].slice(1));
        }
        this.crumbs = output;
      })
    );
  }

  /**
   * Validates against black listed paths (ex: /manager/descriptor)
   */
  private validateLinks() : void {
    this.subscriptions.push(
      this.crumbContainer.changes
        .subscribe( () => {
          let links = this.crumbContainer.toArray();
          for (let i = 0; i < links.length; i++) {
            let link = links[i].nativeElement.getAttribute('data-route');
            if (link === '/somepath/path') {
              this.renderer.addClass(links[i].nativeElement, 'is-disabled');
            }
          }
        })
    );
  }

  private transformCrumbOutput(index : number) : string[] {
    // removes parts of our route path based on the crumbs position in the array
    let crumbs = this.crumbs.slice(0, index + 1),
        final = [];
    // loops over each item - lowercasing each items first character
    for (let i = 0; i < crumbs.length; i++) {
      final.push(crumbs[i].charAt(0).toLowerCase() + crumbs[i].slice(1));
    }
    return final;
  }

  /** 
   * Hides our Breadcrumbs Container located inside the Interface Template
   * 10/15/21 - EK - Removing for now as this is not needed
   */
  // private setBreadcrumbsState() : void {
  //   this.subscriptions.push(
  //     this.layoutSvc.getBreadcrumbsState()
  //       .subscribe( 
  //         (state : boolean) => {
  //           this.showBreadcrumbs = state;
  //       })
  //   );
  // }

  /**
   * Responsible for hiding single level breadcrumbs 
   * Example: If a user can not drill down into a child page, we want to hide the single level breadcrumb link) 
   * Result: Adds '--show' class to host instance when no excluded routes are found.
   */ 
  private canShowBreadcrumbs(history: string) : void {
    // will return -1 if inboud route doesn't match any item in the excluded list
    if (this.excludedRoutes.indexOf(history) == -1) {
      this.showBreadcrumbs = true;
    }
    else {
      this.showBreadcrumbs = false;
    }

    // this.adjustBreadcrumbHeight = this.isGoalAttainmentView(this.excludedRoutes[0], history);
  }


  // private isGoalAttainmentView(route: string, history: string) : boolean {
  //   return route === history ? true : false;
  // }

}
