<button 
  class="btn --stateless"
  [ouxTooltip]="helpTemplate"
  [ouxTooltipOptions]="injectedHelpTooltipOpts">
  <i class="icon icon-help-alt"></i>
</button>

<ng-template #helpTemplate>
  <div class="help" ouxThemeable>
    <ul class="help__list">
      <li class="item">
        <span class="item__header">Help</span>
      </li>
      <li class="item">
        <a class="item__label" (click)="goTo('trainingUrl', $event.preventDefault())">Training</a>
      </li>
      <li class="item">
        <a class="item__label" (click)="goTo('faqsUrl', $event.preventDefault())">FAQ's</a>
      </li>
      <li class="item">
        <a class="item__label" (click)="goTo('releaseNotesUrl', $event.preventDefault())">Release Notes</a>
      </li>
      <!-- <li class="item">
        <a class="item__label" (click)="showTips($event.preventDefault())">Show Tips</a>
      </li> -->
    </ul>
  </div>
</ng-template>