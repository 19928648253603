import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OuxAuthenticationService, OuxConfigService } from '@cisco/oux-common';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ProxiesModel } from '../models/concrete/partials/proxies.model';
import { Proxies } from '../models/interface/partials/proxies';
import { ProxiesResponse } from '../models/interface/response/proxies-response';
import { ProxyStore } from '../stores/proxy.store';
import { UserDetailsStore } from '../stores/user-details.store';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {
  private baseUri: string;
  private proxyUri: string;

  constructor(
    private http: HttpClient,
    private ouxAuthService: OuxAuthenticationService,
    private ouxConfigService: OuxConfigService,
    private userDetailsStore: UserDetailsStore,
    private proxyStore: ProxyStore
  ) {
    this.baseUri = `${this.ouxConfigService.getAppConfigValue('gatewayUri')}${this.ouxConfigService.getAppConfigValue('organizationUri')}${this.ouxConfigService.getAppConfigValue('apiVersion')}`;
    this.proxyUri = this.ouxConfigService.getAppConfigValue('apiUri').userProxies;
  }

  public fetch(): Observable<Proxies> {
    const URL = `${this.baseUri}${this.proxyUri}`;

    const PARAMS = new HttpParams()
      .set('loginId', this.userDetailsStore.getUserId())
      .append('userName', this.userDetailsStore.getUserId());

    const OPTIONS = {
      headers: new HttpHeaders({
        Authorization: this.ouxAuthService.getAuthToken()
      }),
      params: PARAMS
    };

    const REQUEST$ = this.http.get<ProxiesResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = new ProxiesModel(response.data);

          this.proxyStore.setProxies(typed);

          return typed;
        })
      );

    return REQUEST$;
  }

  public add(proxyUser: string): Observable<Proxies> {
    const URL = `${this.baseUri}${this.proxyUri}`;

    const OPTIONS = {
      headers: new HttpHeaders({
        Authorization: this.ouxAuthService.getAuthToken()
      })
    };

    const BODY = {
      accessType: 'ALL',
      loginId: this.userDetailsStore.getUserId(),
      userId: proxyUser
    };

    const REQUEST$ = this.http.post<ProxiesResponse>(URL, BODY, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = new ProxiesModel(response.data);

          return typed;
        })
      );

    return REQUEST$;
  }

  public delete(proxyUser: string): Observable<Proxies> {
    const URL = `${this.baseUri}${this.proxyUri}`;

    const PARAMS = new HttpParams()
      .set('loginId', this.userDetailsStore.getUserId())
      .append('userName', proxyUser);

    const OPTIONS = {
      headers: new HttpHeaders({
        Authorization: this.ouxAuthService.getAuthToken()
      }),
      params: PARAMS
    };

    const REQUEST$ = this.http.delete<ProxiesResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = new ProxiesModel(response.data);

          return typed;
        })
      );

    return REQUEST$;
  }
}