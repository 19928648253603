import { PagedResponse } from '../../interface/partials/paged-response';

export class PagedResponseModel<TResponse> implements PagedResponse<TResponse> {
  public records: TResponse[] = [];
  public total: number;

  constructor(options : Partial<PagedResponse<TResponse>>) {
    Object.assign(this, options);
  }
}
