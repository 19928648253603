import { OrdersSearchUnallocatedSummary } from '../../interface/partials/orders-search-unallocated-summary';

export class OrdersSearchUnallocatedSummaryModel implements OrdersSearchUnallocatedSummary {
  public backlog: number;
  public bookDate: number;
  public booking: number;
  public dealID: string;
  public endCustomer: string;
  public orderNumber: number;
  public poNumber: string;
  public revenue: number;
  public type: string;
  public tcv_amunt: number;
  public acv_amount: number;

  constructor(options: Partial<OrdersSearchUnallocatedSummaryModel>) {
    Object.assign(this, options);
  }
}