import { Proxies }                  from "../../interface/partials/proxies";
import { CsUserList }               from "../../interface/partials/cs-user-list";

export class ProxiesModel implements Proxies {

    public P_CSUSERLIST: CsUserList[];
    public P_ERROR_MESSAGE: string;

  constructor(args : Partial<ProxiesModel>) {
    Object.assign(this, args);
  }

}