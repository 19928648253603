/* Angular Imports */
import { 
  Component, 
  OnInit }                                    from '@angular/core';
import { Router } from '@angular/router';
/* OneUX Imports */
import {
  OuxWindowRefService,
  OuxTooltipOptions, 
  OuxTooltipPosition,
  OuxThemeType }                              from '@cisco/oux-common';
import { GoogleAnalyticsService }             from 'src/app/core/services';


@Component({
  selector: 'toolbar-export',
  templateUrl: './toolbar-export.component.html',
  styleUrls: ['./toolbar-export.component.scss'],
  host: {
    'class': 'toolbar-export'
  }
})
export class ToolbarExportComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public injectedDashboardsTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: true,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router: Router,
    private ouxWindowRefSvc: OuxWindowRefService,
    private googleAnalyticsSvc: GoogleAnalyticsService) {
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Menu actions - User Settings
   */
  public goTo() : void {
    this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'export all', 1);
    this.router.navigate(['/export-dashboard']);
  }

}
