import {
  Component,
  OnDestroy,
  OnInit }                                                            from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router }                                                            from '@angular/router';
import {
  Observable,
  Subject,
  Subscription }                                                      from 'rxjs';
import {
  filter,
  finalize,
  map,
  tap,
  withLatestFrom}                                                     from 'rxjs/operators';
import {
  OuxPageChangeEvent,
  OuxPaginationOptions }                                              from '@cisco/oux-common';
import { OrdersLinesRequestModel }                                    from 'src/app/shared/models/concrete/request/orders-lines-request.model';
import { OrdersLinesRequest }                                         from 'src/app/shared/models/interface/request/orders-lines-request';
import { OrderService }                                               from 'src/app/shared/services/order.service';
import { OrderLinesStore }                                            from 'src/app/shared/stores/order-lines.store';
import { UserDetailsStore }                                           from 'src/app/shared/stores/user-details.store';
import { LoaderService }                                              from 'src/app/core/services';
import { LoadingType }                                                from 'src/app/shared/models/constants/loading-type.enum';
import { DirectTransactionLinesSummaryTableExportRequestModel }       from './partials/direct-transaction-lines-summary-table-export/direct-transaction-lines-summary-table-export-request.model';
import { DirectTransactionLinesSummaryTableExportRequest }            from './partials/direct-transaction-lines-summary-table-export/direct-transaction-lines-summary-table-export-request';
import {AllocationTransactionType} from "../../models/types/allocation-transaction-type.enum";
import { AmpIdentifierKey }         from 'src/app/shared/models/types/amp-identifier-key.enum';



@Component({
  selector: 'direct-transaction-lines',
  templateUrl: './direct-transaction-lines.component.html',
  styleUrls: ['./direct-transaction-lines.component.scss'],
  host: {
    'class': 'direct-transaction-lines'
  }
})
export class DirectTransactionLinesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public exportRequest: DirectTransactionLinesSummaryTableExportRequest;

  public notEligibleExportRequest;

  public isNotEligibleOrder: boolean;

  public AmpIdentifierKey = AmpIdentifierKey;

  private orderLinesRequest$: Observable<OrdersLinesRequest> = this.route.queryParamMap
    .pipe(
      filter((params: ParamMap) => params && params.has('soNumber')
                              && params.has('planId')
                              && params.has('srpGoalHeaderId')
                              && params.has('allocationId')
                              && params.has('erpPosFlag')
      ),
      map(params => {
        return new OrdersLinesRequestModel({
          soNumber: Number(params.get('soNumber')),
          planId: Number(params.get('planId')),
          srpGoalHeaderId: Number(params.get('srpGoalHeaderId')),
          allocationId: Number(params.get('allocationId')),
          erpPosFlag: params.get('erpPosFlag')
        })
      })
    );

  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null),
      tap(() => {
        this.router.navigate(['/goal-attainment'])
      })
    );

  public orderType: string;

  public pageChange = new Subject<OuxPageChangeEvent>();

  public onPageChange$ = this.pageChange.asObservable()
    .pipe(
      withLatestFrom(this.orderLinesRequest$),
      map(([pageChange, request]) => {
        request.limit = pageChange.take;
        request.offset = pageChange.skip;

        return request;
      })
    );

  public paginationOptions = new OuxPaginationOptions({
    pageSize: 10,
    totalRecords: 0,
    autoHide: false,
    numbers: { show: false },
    goto: { show: false },
    pageSizes: {
      label: 'Show',
      displayFormat: (x: number) => `${x}`
    }
  });

  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderSvc: OrderService,
              private loaderSvc: LoaderService,
              private orderLinesStore: OrderLinesStore,
              private userDetailsStore: UserDetailsStore) {

  }

  public ngOnInit(): void {
    let params = this.route.snapshot.queryParamMap;
    this.isNotEligibleOrder = params.has('category') ? (params.get('category') === AmpIdentifierKey.NOTELIGIBLE ? true : false) : false;
    
    if (!this.isNotEligibleOrder) {
      this.subscriptions.push(
        this.orderLinesRequest$.subscribe((request: OrdersLinesRequest) => {
  
          // Stage direct transaction lines export request - used on DirectTransactionLinesSummaryTableExportComponent
          this.exportRequest = new DirectTransactionLinesSummaryTableExportRequestModel(request);
  
          this.fetchData(request);
      }));
  
      this.subscriptions.push(this.onPageChange$.subscribe(request => {
        this.fetchData(request);
      }));
    } else {

      let notEligibleRequest = {
        loginId: '',
        userId: '',
        employeeId: '',
        salesOrderNumber: (params && params.has('soNumber')) ? Number(params.get('soNumber')) : '',
        trxType: (params && params.has('type')) ? params.get('type') : '',
        erpPosFlag: (params && params.has('erpPosFlag')) ? params.get('erpPosFlag') : '',
        metricType: (params && params.has('metricType')) ? params.get('metricType') : '',
        limit: 10,
        offset: 0,
        sortBy: 'employee_id',
        sortOrder: 'desc'
      }

      this.notEligibleExportRequest = notEligibleRequest;

      this.fetchNotEligibleData(notEligibleRequest);

      this.subscriptions.push(this.onPageChange$.subscribe(request => {
        notEligibleRequest.limit = request.limit;
        notEligibleRequest.offset = request.offset;

        this.fetchNotEligibleData(notEligibleRequest);
      }));
    }


    this.subscriptions.push(this.impersonationChange$.subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];

    this.orderLinesStore.clearOrderLinesData();
  }

  public fetchData(request: OrdersLinesRequestModel): void {

    this.loaderSvc.show(LoadingType.Table);
    this.orderType = this.route.snapshot.queryParamMap.has('type') ? this.route.snapshot.queryParamMap.get('type') : '';
   
      if (this.orderType === AllocationTransactionType.ManualRevenue) {
        this.subscriptions.push(this.orderSvc.fetchManualRevOrderLines(request)
          .pipe(
            tap(response => this.paginationOptions.totalRecords = response?.total || 0),
            finalize(() => this.loaderSvc.hide(LoadingType.Table))
          )
          .subscribe()
        );
      } else {
        this.subscriptions.push(this.orderSvc.fetchOrderLines(request)
          .pipe(
            tap(response => this.paginationOptions.totalRecords = response?.total || 0),
            finalize(() => this.loaderSvc.hide(LoadingType.Table))
          )
          .subscribe()
        );
      }
    

  }

  public fetchNotEligibleData(request: any): void {

    this.loaderSvc.show(LoadingType.Table);

    this.subscriptions.push(this.orderSvc.fetchNotEligibleOrderLines(request)
      .pipe(
        tap(response => this.paginationOptions.totalRecords = response?.total || 0),
        finalize(() => this.loaderSvc.hide(LoadingType.Table))
      )
      .subscribe()
  );
  }
}
