/* Angular Imports */
import {
  Component,
  Inject,
  OnInit }                                  from '@angular/core';
import {EMPTY, forkJoin, of, Subscription} from 'rxjs';
import {catchError, finalize, switchMap, tap} from 'rxjs/operators';
/* OneUX Imports */
import {
  OuxModalService,
  OuxLoggerService,
  MODAL_DATA,
  OuxToastService,
  OuxToastOptions,
  OuxThemeType}                             from '@cisco/oux-common';
import { PaymentLinesService }              from '../../services/payment-lines.service';
import { OfflineExportContentModel }        from './offline-export-content.model';
import { OfflineExportHeaderModel }         from './offline-export-header.model';
import { OfflineExportModalModel }          from './offline-export-modal.model';
import { ExportType } from '../../models/constants/export-type.enum';
import { GoalToCashService } from '../../services/goal-to-cash.service';
import { OrdersGoalToCashRequestModel } from '../../models/concrete/request/orders-goal-to-cash-request.model';
import { OrdersGoalToCashRequest } from '../../models/interface/request/orders-goal-to-cash-request';
import { OrderSearchSearchType } from '../../models/types/order-search-search-type.enum';
import {OrderService} from "../../services/order.service";
import {
  CreditMemoTransactionLinesAdjustmentsService
} from "../../services/credit-memo-transaction-lines-adjustments.service";
import { VisibilityPageType } from '../../models/types/visibility-page-type.enum';
import {EmailService} from "../../services/email.service";



@Component({
  selector: 'offline-export-modal',
  templateUrl: './offline-export-modal.component.html',
  styleUrls: ['./offline-export-modal.component.scss'],
  host: {
    'class': 'offline-export-modal'
  }
})
export class OfflineExportModalComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  // define offline export request model
  private offlineRequest: any;
  private type: ExportType;
  private GTCAllRequest: any;
  private credMemoRequest: any;
  private GTCRequest: any;
  // define modal header content
  public modalHeader: OfflineExportHeaderModel;
  // define modal article content
  public modalContent: OfflineExportContentModel;


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService,
              private toastService: OuxToastService,
              private paymentLinesSvc: PaymentLinesService,
              private goalToCashSvc: GoalToCashService,
              private orderService: OrderService,
              private adjustmentsSvc: CreditMemoTransactionLinesAdjustmentsService,
              private emailService: EmailService,
              @Inject(MODAL_DATA) public data: OfflineExportModalModel) {

    if (data) {
      this.modalHeader = this.data.header;
      this.modalContent = this.data.content;
      this.offlineRequest = this.data.offlineRequest;
      this.type = this.data.type;
      this.GTCAllRequest = this.data.GTCAllRequest;
      this.credMemoRequest = this.data.credMemoRequest;
      this.GTCRequest = this.data.GTCRequest;
    }

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public onUserClickEvent(caller: string): void {
    switch (caller) {
      case 'cancel': {
        this.ouxModalSvc.closeModal('cancel');
        this.logOperation('cancel');
        break;
      }
      case 'submit': {
        this.ouxModalSvc.closeModal('submit');
        this.logOperation('submit');
        if (this.type === ExportType.Payments)
          this.queueOfflineExport();
        else if (this.type === ExportType.WeeklyOldMetric)
          this.queueMetricsOfflineExport();
        else if (this.type === ExportType.ExportAllGTC)
          this.queueOfflineAllGTCExport();
        else if (this.type === ExportType.GoalToCash)
          this.queueOfflineGTCExport();
        // Amplifier Export All
        else if (this.type === ExportType.ExportAll)
          this.queueMetricsOfflineExport();
        // Added for Amplifier Order Drill Down Export
        else if (this.type === ExportType.OrderLine)
          this.queueMetricsOfflineExport();
        // Added for Direct PE1 order Line
        else if (this.type === ExportType.Direct)
          this.queueDirectPOSExport();
        // Added for POS PE1 order Line
        else if (this.type === ExportType.POS)
          this.queueDirectPOSExport();
        // Added for CM-DM PE1 order Line
        else if (this.type === ExportType.CreditDebitMemo)
          this.queueCmDmExport();
        break;
      }
    }
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private logOperation(caller: string) : void {
    this.ouxLoggerSvc.logDebug('OfflineExportModalComponent: Run Operation ->', caller);
  }


  private queueOfflineExport(): void {
    this.subscriptions.push(
      this.paymentLinesSvc.queueOfflineExport(this.offlineRequest, VisibilityPageType.Payments)
      .pipe(
        tap(() => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission successful',
            message: 'Your export is being processed. You should receive an email with an Export Link.',
            icon: 'icon-check',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Success
          }));
        }),
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission failed',
            message: 'Your export request couldn\'t be processed. Please try again.',
            icon: 'icon-error-outline',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Error
          }));

          return EMPTY;
        }),
        switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
          catchError(error => {
            console.error('Failed to send email with sequenceId: ', sequenceId);
            return of(error);
          })
        )),
        finalize(() => {})
      )
      .subscribe()
    );

  }

  private queueMetricsOfflineExport(): void {
    this.subscriptions.push(
      this.goalToCashSvc.queueMetricsOfflineExport(this.offlineRequest,this.type)
      .pipe(
        tap(() => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission successful',
            message: 'Your export is being processed. You should receive an email with an Export Link.',
            icon: 'icon-check',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Success
          }));
        }),
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission failed',
            message: 'Your export request couldn\'t be processed. Please try again.',
            icon: 'icon-error-outline',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Error
          }));

          return EMPTY;
        }),
        switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
          catchError(error => {
            console.error('Failed to send email with sequenceId: ', sequenceId);
            return of(error);
          })
        )),
        finalize(() => {})
      )
      .subscribe()
    );

  }




  private queueOfflineAllGTCExport(): void {
    //Create a batch number for export request
    let batchId = this.generateBatchId();

    forkJoin({
      directTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.Direct, Number(batchId), true))),
      posTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.POS, Number(batchId), true))),
      xaasTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.XAAS, Number(batchId), true))),
      bookingAdjTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.BookingAdjustment, Number(batchId), true))),
      memoTrx: this.goalToCashSvc.queueOfflineAdjExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.credMemoRequest as OrdersGoalToCashRequest), OrderSearchSearchType.CreditDebitMemo, Number(batchId), true))),
      manRevTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.ManualRevenue, Number(batchId), true))),
      revAdjTrx: this.goalToCashSvc.queueOfflineExport((this.setType(OrdersGoalToCashRequestModel.createExportRequest(this.GTCAllRequest as OrdersGoalToCashRequest), OrderSearchSearchType.RevenueAdjustment, Number(batchId), true))),
    }).subscribe(({directTrx, posTrx,xaasTrx,bookingAdjTrx,memoTrx,manRevTrx,revAdjTrx})=>{

      this.toastService.addToast(new OuxToastOptions({
        icon: 'icon-export',
        header: 'Request submission successful',
        message: 'Your export is being processed. You should receive an email with an Export Link.',
        autoDismiss: true,
        dismissTimeout: 5,
        scaleOnHover: false,
        theme: OuxThemeType.Success
      }));
        // call sendEmail service
        this.emailService.sendExportRequestEmail(Number(batchId)).subscribe(
          success => {
            // handle success
          },
          error => {
            // handle error
            console.error('Failed to send email with batchId: ', batchId);
          }
        );
    },
    error => {
      // this.toastService.dismissToast(this.exportingToast);
      this.toastService.addToast(new OuxToastOptions({
        icon: 'icon-export',
        header: 'Export',
        message: 'Failed to export orders.',
        autoDismiss: true,
        dismissTimeout: 5,
        scaleOnHover: false,
        theme: OuxThemeType.Error
      }));
    });

  }

  //Used to define the Trx type of request
  public setType(value: OrdersGoalToCashRequest, type: OrderSearchSearchType, batchId: number, batch: boolean): OrdersGoalToCashRequest{

    //return the array with field 'name' set to value 'type'
    const newArr = value?.filters?.map(object => {
      if(object.name==="type")
        return {...object, value: type};
      return object;
    });

    //Set the new filters into the request
    let requestedType = OrdersGoalToCashRequestModel.setFiltersForRequest(value, newArr, batchId, batch, type)

    return requestedType;

  }


  private queueOfflineGTCExport(): void {
    this.subscriptions.push(
      this.goalToCashSvc.queueOfflineExport(this.GTCRequest)
      .pipe(
        tap(() => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission successful',
            message: 'Your export is being processed. You should receive an email with an Export Link.',
            icon: 'icon-check',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Success
          }));
        }),
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            header: 'Request submission failed',
            message: 'Your export request couldn\'t be processed. Please try again.',
            icon: 'icon-error-outline',
            autoDismiss: true,
            dismissTimeout: 10,
            scaleOnHover: false,
            theme: OuxThemeType.Error
          }));

          return EMPTY;
        }),
        switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
          catchError(error => {
            console.error('Failed to send email with sequenceId: ', sequenceId);
            return of(error);
          })
        )),
        finalize(() => {})
      )
      .subscribe()
    );

  }

  public queueDirectPOSExport(): void {
    this.subscriptions.push(
      this.orderService.queueExport(this.offlineRequest)
        .pipe(
          tap(() => {
            this.toastService.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.toastService.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),
          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              console.error('Failed to send email with sequenceId: ', sequenceId);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );
  }

  public queueCmDmExport(): void {
    this.subscriptions.push(
      this.adjustmentsSvc.queueExport(this.offlineRequest)
        .pipe(
          tap(() => {
            this.toastService.addToast(new OuxToastOptions({
              header: 'Request submission successful',
              message: 'Your export is being processed. You should receive an email with an Export Link.',
              icon: 'icon-check',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Success
            }));
          }),
          catchError(error => {
            this.toastService.addToast(new OuxToastOptions({
              header: 'Request submission failed',
              message: 'Your export request couldn\'t be processed. Please try again.',
              icon: 'icon-error-outline',
              autoDismiss: true,
              dismissTimeout: 10,
              scaleOnHover: false,
              theme: OuxThemeType.Error
            }));

            return EMPTY;
          }),
          switchMap(sequenceId => this.emailService.sendExportRequestEmail(sequenceId).pipe(
            catchError(error => {
              console.error('Failed to send email with sequenceId: ', sequenceId);
              return of(error);
            })
          )),
          finalize(() => {})
        )
        .subscribe()
    );
  }

  private generateBatchId(): string {
    //Ensure 9 digits long
      let randomPart = Math.random().toString().slice(2, 11);
      while (randomPart.length < 9) {
          randomPart += Math.floor(Math.random() * 10);
      }
      return randomPart.toString().slice(0, 9);
  
  }

}
