import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OuxBreadcrumbModel, OuxPageChangeEvent, OuxPaginationOptions } from '@cisco/oux-common';
import { combineLatest, Subscription } from 'rxjs';
import { map, tap, filter, finalize} from 'rxjs/operators';
import { BreadcrumbsStore } from 'src/app/layout/breadcrumbs/breadcrumbs.store';
import { SortEvent } from '../../models/concrete/partials/sort-event.model';
import { OrdersMetricHeaderDetailsRequestModel } from '../../models/concrete/request/orders-metric-header-details-request.model';
import { WeeklyTransactionSummaryRequestModel } from '../../models/concrete/request/weekly-transaction-summary-request.model';
import { MetricOrderDetails } from '../../models/interface/partials/metric-order-details';
import { OrdersMetricHeaderDetails } from '../../models/interface/partials/orders-metric-header-details';
import { WeeklyTransactionSummaryDetails } from '../../models/interface/partials/weekly-transaction-summary-details';
import { GoalToCashService } from '../../services/goal-to-cash.service';

@Component({
  selector: 'metric-transaction-lines',
  templateUrl: './metric-transaction-lines.component.html',
  styleUrls: ['./metric-transaction-lines.component.scss'],
  host:{
    'class':'metric-transaction-lines'
  }
})
export class MetricTransactionLinesComponent implements OnInit {

  @Input('metricTransactionLineDetails')
  public metricTransactionLineDetails: MetricOrderDetails;

  @Input('ordersMetricHeaderData')
  public ordersMetricHeaderData : OrdersMetricHeaderDetails[];

  @Input('request')
  public requestParams : OrdersMetricHeaderDetailsRequestModel;

  public weeklyTransactionSummaryDetails: WeeklyTransactionSummaryDetails[];

  public currentRequestParams : WeeklyTransactionSummaryRequestModel;

  public metricHeaderDetails : OrdersMetricHeaderDetails;

  public lastPageUsed: OuxPageChangeEvent;
  public lastSortUsed: SortEvent;

  public subscriptions : Subscription[] = [];

  public myFlag : string = 'ICC';

  constructor(private route: ActivatedRoute,
    private goalToCashService: GoalToCashService,
    private breadcrumbsStore: BreadcrumbsStore,
  ) { }

  private breadcrumbs$ = combineLatest([this.route.queryParams])
    .pipe(
      map(([params]) => {
  
        return [
          new OuxBreadcrumbModel({ 
            route: '/order-search/orders', 
            display: 'Order Search',
            queryParams: {
              searchKey: this.requestParams.searchKey,
              searchValue: this.requestParams.searchValue
            }
          }),
          new OuxBreadcrumbModel({ 
            route: '', 
            display: 'Order Lines'
          }),
        ];
      }),
      tap(breadcrumbs => {
        this.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
      })
    );

  public paginationOptions = new OuxPaginationOptions({
    pageSize: 10,
    totalRecords: 0,
    autoHide: false,
    numbers: { show: false },
    goto: { show: false },
    pageSizes: {
      sizes:[10,25, 50, 100],
      label: 'Orders to show:',
      displayFormat: (x: number) => `${x}`
    }
  });

  ngOnInit(): void {
       
    this.paginationOptions.totalRecords = 0;

    this.buildCurrentRequest();
    this.subscriptions.push(this.breadcrumbs$.subscribe());
    
  }

  public buildCurrentRequest(): void{

    let request = new WeeklyTransactionSummaryRequestModel({
      quotaId: String(this.requestParams.quotaId),
      limit: this.lastPageUsed?.take || 10,
      goalId: this.requestParams.goalId,
      offset: this.lastPageUsed?.skip || 0,
      sortBy: this.lastSortUsed?.key || 'SALES_ORDER_NUMBER',
      sortOrder: this.lastSortUsed?.direction || 'desc',
      metricId : this.requestParams.metricId
    })

    this.subscriptions.push(this.goalToCashService.fetchDetailsForWeeklyTransactionSummaryCount(request).subscribe(count=>{
      this.paginationOptions.totalRecords = count;
    }))
    
    this.subscriptions.push(this.goalToCashService.fetchDetailsForWeeklyTransactionSummary(request).subscribe(details=>{
      this.weeklyTransactionSummaryDetails = details;
    }));
    

  }

  public myPayoutFlag(myFlag : string): void {
    this.myFlag = myFlag;
  }

  public search(page: OuxPageChangeEvent, sortRequest: SortEvent): void {


    if (!page) {
      this.paginationOptions.totalRecords = 0;

      page = new OuxPageChangeEvent({
        page: 1,
        pageSize: this.lastPageUsed?.pageSize || 10,
        skip: 0,
        take: this.lastPageUsed?.take || 10 
      });
    }

    this.lastPageUsed = page;
    this.lastSortUsed = sortRequest;

    this.buildCurrentRequest();
  }

}
