import { XaasOrderLineDetails } from "../../interface/partials/xaas-order-line-details";

export class XaasOrderLineDetailsModel implements XaasOrderLineDetails {
  public ACTION: string;
  public REMAINING_TERM: number;
  public TERM: number;
  public RENEWAL_TERM: number;
  public SUBSCRIPTION_CODE: string;
  public SUBSCRIPTION_CREDIT: number;
  public SUB_REF_ID: string;
  public IMRR: number;
  public MRR: number;
  public BOOKING_RECOGNITION_TYPE: string;
  public METRIC_TYPE: string;
  public CONTRACT_START_DATE: number;
  public CONTRACT_END_DATE: number;
  public SELL_TYPE: string;
  public TERM_BOUND_CREDIT: any;
  public PART_NUMBER: string;
  public OA_PERCENT: number;
  public BACKLOG_LINE: number;
  public BOOKING_AMT: number;
  public COMM_REVENUE: number;
  public REV_MULTI: number;
  public REV_MULTIPLIER_FACTOR: number;
  public TOTAL_SALES_VALUE: number;
  public COMM_BACKLOG: number;

  constructor(args : Partial<XaasOrderLineDetailsModel>) {
    Object.assign(this, args);
  }

}