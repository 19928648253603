import { Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { OuxGenericDropdownComponent } from '@cisco/oux-common';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'visibility-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  host: {
    'class': 'visibility-dropdown'
  }
})
export class DropdownComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  public listWidth$ = new BehaviorSubject<string>('auto');

  @Input('loading')
  public loading: boolean;

  @Input('display')
  public display: string;

  @Input('disabled')
  @HostBinding('class.is-disabled')
  public disabled: boolean = false;

  @ViewChild('genericDropdown', { read: OuxGenericDropdownComponent })
  public genericDropdown: OuxGenericDropdownComponent;

  @ViewChild('control', { read: ElementRef })
  public controlElement: ElementRef<HTMLElement>;

  constructor(
  
  ) {
    
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public onDropdownDisplay(): void {
    this.listWidth$.next(`${this.controlElement.nativeElement.offsetWidth}px`);
  }

  public close(): void {
    this.genericDropdown.hidePanel();
  }
}
