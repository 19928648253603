export enum ExportType {
  ExportAllGTC = 'ExportAllGTC',
  GoalToCash = 'GoalToCash',
  WeeklyOldMetric = 'Weekly_OldMetric',
  Payments = 'Payments',
  OrderLine = 'OrderLine', //Added for Amplifier Order Drill Down Export
  ExportAll = 'EXPORT_ALL', //Added for Export All Amplifier
  // Added for Order Line PE1
  Direct = 'Direct',
  POS = 'POS',
  CreditDebitMemo = 'CreditDebitMemo'
}
