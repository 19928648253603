import { PaymentRevenueAdjustment }            from "../../interface/partials/payment-revenue-adjustment";
/**
 * Note: During development, key's with the type any returned as null in all responses.
 * These will need to be updated accordingly once values are defined.
 */
export class PaymentRevenueAdjustmentModel implements PaymentRevenueAdjustment {
  public AMP_IDENTIFIER: string;
  public ANNUAL_VALUE: any;
  public BASE_CURRENCY_CODE: string;
  public BILL_TO_CUSTOMER: string;
  public BONUS_CODE: any;
  public BONUS_PLAN_CODE: string;
  public BONUS_TYPE: any;
  public BP_FLAG: string;
  public BUYING_PROGRAM_NAME: string;
  public CONTRACT_END_DATE: number;
  public CONTRACT_NUMBER: any;
  public CONTRACT_START_DATE: number;
  public CONTRACT_TERM: any;
  public CREATED_BY: string;
  public CREATION_DATE: number;
  public CRED_MEMO: any;
  public DEAL_ID: any;
  public DOMAIN_VALUE_NAME: any;
  public ELA_FLAG: any;
  public END_CUSTOMER: string;
  public END_USER: any;
  public END_USER_COUNTRY: any;
  public END_USER_NUMBER: any;
  public EXPENSE: any;
  public EXTD_MY_PAYOUT_AMOUNT: any;
  public GOAL_ID: any;
  public HIERARCHY_NAME: string;
  public INVOICE_DATE: number;
  public METRIC_TYPE: string;
  public MYPEFLAG: string;
  public MY_PAYOUT_AMOUNT: any;
  public MY_PAYOUT_FACTOR: any;
  public OFFER_NAME: string;
  public OFFER_TYPE_NAME: any;
  public ORDER_NUMBER: number;
  public PART_NUMBER: string;
  public PERIOD_CODE: any;
  public PO_CUST: any;
  public PO_NUMBER: any;
  public PRODUCT_FAMILY: string;
  public PROGRAM_NAME: string;
  public REV_MULTIPLIER_FACTOR: number;
  public ROLLUP_FLAG: string;
  public SAAS_FLAG: string;
  public SALESREP_NUMBER: number;
  public SALES_MOTION: any;
  public SALES_VALUE: number;
  public SALES_VALUE_MULTIPLIED: number;
  public SERVICE_TYPE: any;
  public SHIP_TO_CUSTOMER: string;
  public SOURCE_TRANSACTION_DATE: number;
  public SPLIT_AGENT: string;
  public SPLIT_EFF_DATE: number;
  public SPLIT_PERCENT: number;
  public TERRITORY_CODE: string;
  public TOTAL_VALUE: any;
  public TRANSACTION_AMOUNT: number;
  public TRANSACTION_DATE: number;
  public TRANSACTION_ID: number;
  public TRANSACTION_NUMBER: any;
  public TRANS_GROUP_CODE: string;
  public TRANS_TYPE_CODE: any;
  public USER_COMMENTS: string;
  public WPA_FLAG: any;
  public TYPE: string;
  public TERRITORY: string;
  public NEW_LOGO_AMOUNT: any;
  public UPSELL_AMOUNT: any;
  public CROSS_SELL_AMOUNT: any;
  public IACV_AMOUNT: any;
  public RENEWED_AMOUNT: any;
  public NON_COMM_BOOKING: any;
  public DISPLAY_WEEK: string;
  public PAY_PERIOD: string;

  constructor(args : Partial<PaymentRevenueAdjustmentModel>) {
    Object.assign(this, args);
  }

}