import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OuxColumnSettings, OuxLoggerService, OuxTableOptions, OuxTableThemeOverrides, OuxThemeType, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { Observable, Subscription } from 'rxjs';
import { map, tap }  from 'rxjs/operators';
import { GoalingPeriod, Header } from '../../models/interface/partials/iacv-category-details';
import { PaymentSummary } from '../../models/interface/partials/payment-summary';
import { TranslateUtilsService } from '../../services/translate-utils.service';
import { PaymentStore } from '../../stores/payment.store';

@Component({
  selector: 'iacv-category-details-table',
  templateUrl: './iacv-category-details-table.component.html',
  styleUrls: ['./iacv-category-details-table.component.scss'],
  host: {
    'class': 'iacv-category-details-table'
  }
})
export class IacvCategoryDetailsTableComponent implements OnInit {

  public tableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--compact',
    formatCells: false,
    tableLayout: 'fixed',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'There are no records to display',
    trackRecordsProperty: 'planTitle',
    highlightSelectedRows: false,
    missingDataMessage: '',
    toggleSelected: false
  });

  public columnSettings;
  public columnSettingsForG2C: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'Category',
      primaryKey: 'CATEGORY'
    }),
    new OuxColumnSettings({
      header: 'Goal (todo: currency)',
      primaryKey: 'GOAL',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Bookings',
      primaryKey: 'BOOKINGS',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Backlog',
      primaryKey: 'BACKLOG',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'REVENUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Revenue Attainment',
      primaryKey: 'REVENUE_ATTAINMENT',
      thAlign: 'right',
      tdAlign: 'right'
    })
  ];

  public columnSettingsForPayments: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'Category',
      primaryKey: 'category'
    }),
    new OuxColumnSettings({
      header: 'Goal',
      primaryKey: 'goalAmt',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Weight',
      primaryKey: 'weight',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Revenue Attainment',
      primaryKey: 'revAttain',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Incentive',
      primaryKey: 'ytdComm',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Paid',
      primaryKey: 'ytdPaid',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Payment',
      primaryKey: 'paymentAmount',
      thAlign: 'right',
      tdAlign: 'right'
    })
  ];

  public columnSettingsForMY: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'Category',
      primaryKey: 'CATEGORY'
    }),
    // new OuxColumnSettings({
    //   header: 'Goal (todo: currency)',
    //   primaryKey: 'GOAL',
    //   thAlign: 'right',
    //   tdAlign: 'right'
    // }),
    // new OuxColumnSettings({
    //   header: 'Bookings',
    //   primaryKey: 'BOOKINGS',
    //   thAlign: 'right',
    //   tdAlign: 'right'
    // }),
    // new OuxColumnSettings({
    //   header: 'Backlog',
    //   primaryKey: 'BACKLOG',
    //   thAlign: 'right',
    //   tdAlign: 'right'
    // }),
    new OuxColumnSettings({
      header: 'Total iACV',
      primaryKey: 'REVENUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Total TCV',
      primaryKey: 'TCV_METRIC_USD_AMOUNT',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'MY Comp Attainment',
      primaryKey: 'REVENUE_ATTAINMENT',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Service Attainment %',
      primaryKey: 'Service_Attainment',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Incentive',
      primaryKey: 'ytdComm',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Paid',
      primaryKey: 'ytdPaid',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Payment',
      primaryKey: 'paymentAmount',
      thAlign: 'right',
      tdAlign: 'right'
    })
  ];

  public columnSettingsForMY23: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'Category',
      primaryKey: 'CATEGORY'
    }),

    // new OuxColumnSettings({
    //   header: 'Plan Element Goal',
    //   primaryKey: 'GOAL',
    //   thAlign: 'right',
    //   tdAlign: 'right'
    // }),
    new OuxColumnSettings({
      header: '$Renewed',
      primaryKey: 'REVENUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    // new OuxColumnSettings({
    //   header: 'Total TCV',
    //   primaryKey: 'TCV_METRIC_USD_AMOUNT',
    //   thAlign: 'right',
    //   tdAlign: 'right'
    // }),
    new OuxColumnSettings({
      header: 'Revenue Attainment',
      primaryKey: 'REVENUE_ATTAINMENT',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Incentive',
      primaryKey: 'ytdComm',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'YTD Paid',
      primaryKey: 'ytdPaid',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Payment',
      primaryKey: 'paymentAmount',
      thAlign: 'right',
      tdAlign: 'right'
    })
  ];
  public tableThemeOverrides: OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '500px' 
    },
    context: { 
      code : null 
    }
  });

  public details$: Observable<PaymentSummary[]> = this.paymentStore.selectedPeriodTransactionPayment$
    .pipe(
      map(details => details ? [details] : []),
      tap(details => {
        if (details) {
          this.ouxLoggerSvc.logDebug('PaymentsTransactionsDetailsTableComponent - Details:', details);
        }
      })
    );


  public goalToCashDrillDownFlag : boolean = false;
  @Input('categoryHeader')
  public categoryHeader: Header[];

  @Input('goalingPeriodDetails')
  public goalingPeriodDetails: GoalingPeriod;

  @Input('myPYFlag')
  public myPYFlag : string;

  @Input('routePath')
  public routePath : string;

  public weightDescription : string;

  public currency: String;

  public ytdIncentive : string | number;
  public ytdPaid : string | number;
  public ytdPayment : number;
  public ytdGoalCurrency : string;
  public ytdCommCurrency : string;
  public revenueType : string;
  public planElementType: string;
  //public isPeriodGPTD: boolean;

  public ytdIncentiveDetailsFlag : boolean = false;

  public periodCodeFlag : boolean;

  private subscriptions: Subscription[] = [];

  constructor(private ouxLoggerSvc: OuxLoggerService,
    private route: ActivatedRoute,
    private paymentStore: PaymentStore,
    private utilService: TranslateUtilsService) { }

  ngOnInit(): void {
    
    this.categoryHeader = this.categoryHeader ? this.categoryHeader.length ==0 ? null : this.categoryHeader : null;
    this.currency = this.categoryHeader ? this.categoryHeader[0].CURRENCY : '';
    //this.isPeriodGPTD = this.utilService.checkforGPTDFromPeriod(this.goalingPeriodDetails.GOALING_PERIOD);

    let params = this.route.snapshot.queryParamMap;

    this.planElementType = params.has('planElementType') ? params.get('planElementType') : 'iACV';

    this.revenueType = this.planElementType === 'iACV' ? 'iACV' : '$Renewed';

    if(this.routePath?.includes('goalToCash') || this.routePath?.includes('backlog')){
      this.goalToCashDrillDownFlag = true;
      this.columnSettings = this.columnSettingsForG2C;
    }
    else{
      this.goalToCashDrillDownFlag = false;
      this.columnSettings = this.columnSettingsForPayments;
    }
    if(this.myPYFlag === 'Y'){
      this.subscriptions.push(this.details$.subscribe(response=>{
        this.ytdIncentive = response[0].ytdComm;
        this.ytdPaid = response[0].ytdPaid;
        this.ytdPayment = response[0].paymentAmount;
        this.ytdGoalCurrency = response[0].goalCurrency;
        this.ytdCommCurrency = response[0].commCurrency;
        this.periodCodeFlag = this.checkForPayPeriod(response[0].goalSheet);
      }))
      
      
      this.goalToCashDrillDownFlag = true;
      if(this.periodCodeFlag){
        this.columnSettings = this.columnSettingsForMY23;
      }
      else{
        this.columnSettings = this.columnSettingsForMY;
      }
      

    }

    this.weightDescription = (this.myPYFlag === 'Y') ? "Weight given is the corresponding base metric plan element's weight." : "Weight given is the corresponding regular plan element's weight."
  }

  public checkForPayPeriod(payPeriod: string) : boolean{

    let year = payPeriod.substring(2,4);
    if(Number(year) >=23){
      return true;
    }
    else{
      return false;
    }
  }
}
