import { MaskUserDetails }            from "../interface/partials/mask-user-details";
import { MaskUserIdType }             from "../types/mask-user-id-type.enum";
import { MaskUserImageType }          from "../types/mask-user-image-type.enum";
import { MaskUserNameType }           from "../types/mask-user-name-type.enum";
import { UserRoleType }               from "../types/user-role-type.enum";


export const MASKED_USERS : MaskUserDetails[] = [
  {
    roleType: UserRoleType.GENERAL,
    userName: MaskUserNameType.GENERAL,
    employeeId: MaskUserIdType.GENERAL,
    userImage: MaskUserImageType.GENERAL
  },
  {
    roleType: UserRoleType.MANAGER,
    userName: MaskUserNameType.MANAGER,
    employeeId: MaskUserIdType.MANAGER,
    userImage: MaskUserImageType.MANAGER
  },
  {
    roleType: UserRoleType.ADMIN,
    userName: MaskUserNameType.ADMIN,
    employeeId: MaskUserIdType.ADMIN,
    userImage: MaskUserImageType.ADMIN
  }
];