import { Users }      from "../../interface/partials/users";
import { UserList }   from "../../interface/partials/user-list";
import { UserListModel } from "./user-list.model";

export class UsersModel implements Users {

  public P_USERLIST: UserList[];

  constructor(args: Partial<UsersModel>) {
    Object.assign(this, args);

    if (this.P_USERLIST) {
      this.P_USERLIST = this.P_USERLIST.map(x => new UserListModel(x));
    }
  }
}