import { MetricSOTransactionRequest } from '../../interface/request/metric-so-transaction-request';

export class MetricSOTransactionLineRequestModel implements MetricSOTransactionRequest {
  public loginId: string;
  public goalId: number;
  public userId: string;
  public employeeId: string;
  public salesOrderNumber: string;
  public quotaId: string;
  public limit: number;
  public offset: number;
  public sortBy: string;
  public sortOrder: string;
  public transactionType: string;
  public lineId: number;
  public nodeName: string;
  public category: string;
  public sourceSystem: string;
  public sourcePage: string;
  public startDate: string;
  public endDate: string;
  public ampFlag: boolean;
  public level: string;
  public type: string;
  //For Export
  public flow: string;
  public trxType: string;
  public peType: string;
  public node: string;
  public myFlag: boolean | string;
  public calcEngine: string;
  public metricFlag: string;
  public ampIdentifier: string;
  public longCategory: string;
  public goalSheetStartDate: string;
  public exportType: string;
  public page: string;
  public RTMType: string;

  constructor(args: Partial<MetricSOTransactionLineRequestModel>) {
    Object.assign(this, args);
  }
}
