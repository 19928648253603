/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }              from '@angular/core';
/* RxJS Imports */
import { Subscription }           from 'rxjs';
/* Package Imports */
import { OuxLayoutService }       from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }        from '../../shared/services/instance.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  host: {
    'class': 'app-footer slideInUp animated'
  }
})
export class FooterComponent implements OnInit, OnDestroy {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions : Subscription[] = [];
  /**
   * Stand up native element ref
   */
  private footer : Node | any;
  /**
   * Is side nav present
   */
  public showSideNav : boolean = null;
  /**
   * Is footer present
   */
  public showFooter : boolean = null;
  /**
   * Set our current year reference
   */
  public currentYear : Date | number = null;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private layoutSvc : OuxLayoutService,
              private instanceSvc : InstanceService) {

    this.footer = this.vcRef.element.nativeElement;

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('FooterComponent', 'app-footer');
    this.setLayoutStates();
    this.setCurrentYear();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  /**
   * Convenience function. Responsible for envoking all state initialization code
   */
  private setLayoutStates() : void {
    this.checkSideNavState();
    this.checkFooterState();
  }

  /**
   * Adds --full-width class to footer template if showSideNav is false
   */
  private checkSideNavState() : void {
    this.subscriptions.push(
      this.layoutSvc.getSideNavState()
        .subscribe( (state : boolean) => {
          this.showSideNav = state;
          this.handleLayoutChanges();
      })
    );
  }

  /**
   * Adds --has-footer class to interface template if showFooter is true
   */
  private checkFooterState() : void {
    this.subscriptions.push(
      this.layoutSvc.getFooterState()
        .subscribe( (state : boolean) => {
          this.showFooter = state;
        })
    );
  }

  /**
   * Set class state based on inbound conditions
   */
  private handleLayoutChanges() : void {
    if (this.showSideNav == false) {
      this.footer.classList.add('--fullwidth');
    }
    else {
      this.footer.classList.remove('--fullwidth');
    }
  }

  /**
   * Automatically update copyright date
   */
  private setCurrentYear() : void {
    const date : Date = new Date();
    this.currentYear = date.getFullYear();
  }

}
