/* Angular Imports */
import { 
  Component, 
  OnInit }                                    from '@angular/core';
/* RxJs Imports */
import {
  Subscription }                              from 'rxjs';
/* OneUX Imports */
import {
  OuxWindowRefService,
  OuxTooltipOptions, 
  OuxTooltipPosition,
  OuxThemeType }                              from '@cisco/oux-common';
import { GoogleAnalyticsService }             from 'src/app/core/services';


@Component({
  selector: 'toolbar-help',
  templateUrl: './toolbar-help.component.html',
  styleUrls: ['./toolbar-help.component.scss'],
  host: {
    'class': 'toolbar-help'
  }
})
export class ToolbarHelpComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];
  private windowRef : any;

  public injectedHelpTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: true,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxWindowRefSvc: OuxWindowRefService,
              private googleAnalyticsSvc: GoogleAnalyticsService) {

    // assign our window object reference
    this.windowRef = this.ouxWindowRefSvc.nativeWindow;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  public ngOnDestroy() : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Menu actions - User Settings
   */
   public goTo(caller : string, event : any) : void {
    switch (caller) {
      case 'trainingUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'help', 'training', 1);
        this.openNewWindow('https://salesconnect.cisco.com/digitalsellingexperience/s/visibility');
        break;
      }
      case 'faqsUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'help', 'faqs', 1);
        this.openNewWindow('https://salesconnect.cisco.com/sc/s/simple-media?vtui__mediaId=a1m8c00000plEAkAAM');
        break;
      }
      case 'releaseNotesUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'help', 'release notes', 1);
        this.openNewWindow('https://wiki.cisco.com/pages/viewpage.action?pageId=1061632486');
        break;
      }
    }
  }

  // public showTips(event : any) : void {
  //   this.googleAnalyticsSvc.eventEmitter('click', 'help', 'show tips', 1);
  //   this.windowRef.alert(`Show Tips - Placeholder Event`);
  // }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private openNewWindow(url : string) : void {
    /**
     * Added rel="noreferrer noopener" to external links due to potential security/perfomance issues. 
     * Details here under "target" attribute: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
     */
    this.windowRef.open(url, '_blank', 'noopener,noreferrer');
  }

}
