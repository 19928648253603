import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'trimString',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})
export class TrimStringPipe implements PipeTransform {

  transform(value : string) : string {
    if (!value) return;
    let output = value.replace(/\s*$/, '');
    return output;
  }

}
