import { QuotaDetails }           from "../../interface/partials/quota-details";
import { OrderQuotaDetails }      from "../../interface/partials/order-quota-details";
import { OrderQuotaDetailsModel } from './order-quota-details.model';


export class QuotaDetailsModel implements QuotaDetails {
  public o_Error_Message: string;
  public o_quota_details: OrderQuotaDetails[];

  constructor(args : Partial<QuotaDetailsModel>) {
    Object.assign(this, args);

    if (this.o_quota_details) {
      this.o_quota_details = this.o_quota_details.map(x => new OrderQuotaDetailsModel(x));
    }
  }
}