import { NgModule }                         from '@angular/core';
import { Routes, RouterModule }             from '@angular/router';
import { OuxAuthIfPossibleGuardService }    from '@cisco/oux-common';

/* Feature Imports */
import { RootLoginComponent }               from './login/login.component';


/**
 * Define Guards Routing Instructions
 */
const guardRoutes : Routes = [

  {
    path: 'login',
    component: RootLoginComponent,
    canActivate: [
      OuxAuthIfPossibleGuardService
    ],
    data: {
      title: 'Login'
    }
  }

];


@NgModule({

  imports: [
    RouterModule.forChild(guardRoutes)
  ],

  exports: [
    RouterModule
  ]

})


export class GuardsRoutingModule { }

/* Export Feature Components */
export const routedComponents = [ RootLoginComponent ];
