/* Angular Imports */
import { Injectable } from '@angular/core';

@Injectable()
export class TranslateUtilsService {

  constructor() { }

  /**
   * Creates a new array, based on a character seperated string
   * @param prop - Represents the property to transform.
   * @param sep - Represents the charactor(s) to use for separating the string
   * @param key - Represents the new interable key/value.
   */
  public buildNewArray(prop: any, sep: any, key?: string): any {
    // stand up new array
    const collection: any = new Array();
    // splits inbound string at each seperator, forming a new array of substrings
    let newArr = prop.split(sep);
    // set default prop name to key, if new name was not supplied
    let newKey = key ? key : 'key';
    // loops over each substring, pushing each value to the provided property name 
    for (let i = 0; i < newArr.length; i++) {
      collection.push({ [newKey]: newArr[i].trim() });
    }
    return collection;
  }

  // Pass period code in this format 'FY23H2' to get a boolean output if the fiscal period is >= FY23H2
  public checkforGPTDFromPeriod(period: string): boolean {
    let year = period.substring(2, 4);
    if (Number(year) >= 23) {
      if (Number(period.charAt(period.length - 1)) > 1) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  // Pass GS and Fiscal Interval in this format '2023 CS040 29-Jan-2023 to 29-Jul-2023' and 'H1' respectively to get a boolean output if the fiscal period is >= FY23H2
  public checkforGPTDFromGS(goalsheet: any, fiscalInterval: string): boolean {
    let year = goalsheet.substring(2, 4);
    if (Number(year) >= 23) {
      if (Number(fiscalInterval.charAt(fiscalInterval.length - 1)) > 1) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
}
}