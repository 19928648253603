import { ExportType } from "../../models/constants/export-type.enum";
import { OfflineExportContentModel } from "./offline-export-content.model";
import { OfflineExportHeaderModel } from "./offline-export-header.model";


export class OfflineExportModalModel {

    public header: OfflineExportHeaderModel; 
    public content: OfflineExportContentModel;
    public type: ExportType;
    public offlineRequest: any;
    public GTCAllRequest: any;
    public credMemoRequest: any;
    public GTCRequest: any;

    constructor(options: Partial<OfflineExportModalModel>) {
        Object.assign(this, options);
    }
    
}