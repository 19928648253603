<ng-container *ngIf="metricSoTransactionSummaryDetails">
  <oux-table [records]="metricSoTransactionSummaryDetails" [options]="tableOpts" [settings]="columnSettings"
    [themeOverrides]="tableThemeOverrides" (onSort)="sortColumn($event.key, $event.sortDirection)">

    <!-- To Customize columns in columnSettings-->

    <ng-template [ouxHeader]="'LINE_ID'">
      <div ellipsis [ouxTooltip]="lineIdTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        SO/POS Line Number
      </div>
    </ng-template>

    <ng-template #lineIdTooltip>
      <p class="tooltip__text">SO/POS Line Number</p>
    </ng-template>

    <ng-template [ouxHeader]="'TOTAL_VALUE'">
      <div ellipsis [ouxTooltip]="tcvTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        TCV
      </div>
    </ng-template>

    <ng-template #tcvTooltip>
      <p class="tooltip__text">TCV</p>
    </ng-template>

    <ng-container *ngIf="peType === 'IACV'">
      <ng-template [ouxHeader]="'IACV_BOOKING_AMOUNT'">
        <div ellipsis [ouxTooltip]="iacvTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          iACV
        </div>
      </ng-template>
    </ng-container>

    <ng-template #iacvTooltip>
      <p class="tooltip__text">iACV</p>
    </ng-template>

    <ng-container *ngIf="peType === 'RND'">
      <ng-template [ouxHeader]="'RENEWAL_BOOKING_AMOUNT'">
        <div ellipsis [ouxTooltip]="rndTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          $Renewed + Residual Annual
        </div>
      </ng-template>
    </ng-container>

    <ng-template #rndTooltip>
      <p class="tooltip__text">$Renewed + Residual Annual</p>
    </ng-template>

    <ng-template [ouxHeader]="'ANNUAL_VALUE'">
      <div ellipsis [ouxTooltip]="annualTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        Total Annual Bookings
      </div>
    </ng-template>

    <ng-template #annualTooltip>
      <p class="tooltip__text">Total Annual Bookings</p>
    </ng-template>

    <ng-template [ouxHeader]="'BOOKING'">
      <div ellipsis [ouxTooltip]="bookingTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        Bookings
      </div>
    </ng-template>

    <ng-template #bookingTooltip>
      <p class="tooltip__text">Bookings</p>
    </ng-template>

    <ng-template [ouxHeader]="'BACKLOG'">
      <div ellipsis [ouxTooltip]="backlogTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        Backlog
      </div>
    </ng-template>

    <ng-template #backlogTooltip>
      <p class="tooltip__text">Backlog</p>
    </ng-template>

    <ng-template [ouxHeader]="'REVENUE'">
      <div ellipsis [ouxTooltip]="revenueTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        Revenue
      </div>
    </ng-template>

    <ng-template #revenueTooltip>
      <p class="tooltip__text">Revenue</p>
    </ng-template>

    <ng-template [ouxHeader]="'COMMISSION_STATUS'">
        Commission Status
      <i class="icon-info-outline" [ouxTooltip]="commissionStatusTemplate"
        [ouxTooltipOptions]="commissionStatusTooltipOpts">
      </i>
    </ng-template>

    <ng-template [ouxHeader]="'OFFER_PART_NUMBER'">
      <div ellipsis [ouxTooltip]="offerPartTooltip" [ouxTooltipOptions]="templateTooltipOpts">
        Offer Part Number
      </div>
    </ng-template>

    <ng-template #offerPartTooltip>
      <p class="tooltip__text">Offer Part Number</p>
    </ng-template>

    <ng-template [ouxColumn]="'BOOKING'" let-record="record">
      <div *ngIf="ampIdentifier === AmpIdentifierKey.NOTELIGIBLE; else defaultBooking">
        NA
      </div>
      <ng-template #defaultBooking>
        <div *ngIf="type !== AllocationTransactionTypeManualRevenue; else naTemplate">
          {{ (record?.BACKLOG + record?.REVENUE) | number:'1.2-2'}}
        </div>
        <ng-template #naTemplate>
          {{ (record?.BOOKING) | number:'1.2-2'}}
        </ng-template>
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'BACKLOG'" let-record="record">
      <div *ngIf="ampIdentifier === AmpIdentifierKey.NOTELIGIBLE; else defaultBacklog">
        NA
      </div>
      <ng-template #defaultBacklog>
        <div *ngIf="record?.BKLG_SUBLINE_COUNT === 0">
          {{ myFlag !== 'Y' ? (record?.BACKLOG | number:'1.2-2') : 'NA' }}
        </div>
        <ng-container *ngIf="myFlag !== 'Y'; else naBacklogTemplate">
          <div *ngIf="record?.BKLG_SUBLINE_COUNT > 0">
            <a class="link" [title]="record?.BACKLOG"
              (click)="showOrderLineDetails(record, 'BKLG', $event.preventDefault())">
              {{ record?.BACKLOG | number:'1.2-2'}}
            </a>
          </div>
        </ng-container>
        <ng-template #naBacklogTemplate>
          NA
        </ng-template>
      </ng-template>
    </ng-template>


    <ng-template [ouxColumn]="'REVENUE'" let-record="record">
      <div *ngIf="record?.REV_SUBLINE_COUNT === 0">
        <span *ngIf="myFlag === 'N'">{{ record?.REVENUE | number:'1.2-2'}}</span>
        <span *ngIf="myFlag === 'Y'">{{ record?.MY_PAYOUT_AMOUNT | number:'1.2-2'}}</span>
      </div>
      <div *ngIf="record?.REV_SUBLINE_COUNT > 0 || ampIdentifier === AmpIdentifierKey.NOTELIGIBLE">
        <a class="link" [title]="myFlag === 'Y' ? record?.MY_PAYOUT_AMOUNT : record?.REVENUE"
          (click)="showOrderLineDetails(record, 'REV', $event.preventDefault())">
          <span *ngIf="myFlag === 'N'">{{ record?.REVENUE | number:'1.2-2'}}</span>
          <span *ngIf="myFlag === 'Y'">{{ record?.MY_PAYOUT_AMOUNT | number:'1.2-2'}}</span>
        </a>
      </div>
    </ng-template>

    <ng-template [ouxColumn]="'TOTAL_VALUE'" let-record="record">
      <div>
        {{ record?.TOTAL_VALUE | number:'1.2-2'}}
      </div>
    </ng-template>

    <ng-container *ngIf="peType === 'IACV'">
      <ng-template [ouxColumn]="'IACV_BOOKING_AMOUNT'" let-record="record">
        <div>
          {{ record?.IACV_BOOKING_AMOUNT | number:'1.2-2'}}
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="peType === 'RND'">
      <ng-template [ouxColumn]="'RENEWAL_BOOKING_AMOUNT'" let-record="record">
        <div>
          {{ record?.RENEWAL_BOOKING_AMOUNT | number:'1.2-2'}}
        </div>
      </ng-template>
    </ng-container>

    <ng-template [ouxColumn]="'COMMISSION_STATUS'" let-record="record">
      <div *ngIf="ampIdentifier ===  AmpIdentifierKey.NOTELIGIBLE; else defaultCommissionStatus">
        NA
      </div>
      <ng-template #defaultCommissionStatus>
        {{ record?.COMMISSION_STATUS }}
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'ANNUAL_VALUE'" let-record="record">
      <div *ngIf="record?.ANNUAL_VALUE !== 0">
        <a (click)="showDrillDown(record)">{{ record?.ANNUAL_VALUE | number:'1.2-2' }} <i
            [ngClass]="(record.isExpanded ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
      </div>
      <div *ngIf="record?.ANNUAL_VALUE === 0">{{ record?.ANNUAL_VALUE | number:'1.2-2' }}
      </div>
      <span *ngIf="record.isExpanded" class="sub-options-width pad">
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            iACV
          </span>
          <span class="sub-options value-align">
            : {{ (record?.IACV_BOOKING_AMOUNT | number:'1.2-2') || (record?.IACV_BOOKING_USD_AMOUNT | number:'1.2-2') }}
          </span>
        </span>
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            $Renewed+Residual Annual
          </span>
          <span class="sub-options value-align">
            : {{ (record?.RENEWAL_BOOKING_AMOUNT | number:'1.2-2') || (record?.RENEWAL_BOOKING_USD_AMOUNT | number:'1.2-2') }}
          </span>
        </span>
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            Non-Comm Booking
          </span>
          <span class="sub-options value-align">
            : {{ (record?.BOOKING_ADJ_AMOUNT | number:'1.2-2') || (record?.BOOKING_ADJ_USD_AMOUNT | number:'1.2-2') }}
          </span>
        </span>
      </span>
    </ng-template>

    <ng-template [ouxHeader]="'COMMISSION_STATUS'">
      Commission Status
      <i class="icon-info-outline" [ouxTooltip]="commissionStatusTemplate"
        [ouxTooltipOptions]="commissionStatusTooltipOpts">
      </i>
    </ng-template>

    <ng-template #commissionStatusTemplate>
      <p class="commission-status-table-header">Commission Status Examples and Definitions</p>
      <div class="table-container">
        <table class="commission-status-table">
          <thead>
            <tr>
              <th>Booking</th>
              <th>Backlog</th>
              <th>Revenue</th>
              <th>Status</th>
              <th>Status Definition</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>0</td>
              <td>Backlog</td>
              <td>The full value of the order is in Backlog. The backlog could be attributed to holds
                or future release date(s).</td>
            </tr>
            <tr>
              <td>100</td>
              <td>0</td>
              <td>80</td>
              <td>Pending Backlog</td>
              <td>Part of this order is still backlogged but there is a timing delay in the
                recognition of the backlog. The backlog could be attributed to holds or future release date(s).</td>
            </tr>
            <tr>
              <td>100</td>
              <td>20</td>
              <td>80</td>
              <td>Pending Backlog</td>
              <td>Part of this order is still backlogged. The backlog could be attributed to holds or
                future release date(s).</td>
            </tr>
            <tr>
              <td>100</td>
              <td>0</td>
              <td>100</td>
              <td>Fully Credited</td>
              <td>You have received full credit for this order.</td>
            </tr>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>100</td>
              <td>Fully Credited</td>
              <td>You have received full credit for this order but there is a timing delay in the
                resolution of the backlog.</td>
            </tr>
            <tr>
              <td>0</td>
              <td>0</td>
              <td>100</td>
              <td>Fully Credited</td>
              <td>You have received full credit for this revenue only transaction.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>


  </oux-table>
</ng-container>
