import { CurrencyCodeRequest } from "../../interface/request/currency-code-request";

export class CurrencyCodeRequestModel implements CurrencyCodeRequest {

  public goalID: string;
  public quotaId: string;

  constructor(args : Partial<CurrencyCodeRequestModel>) {
    Object.assign(this, args);
  } 
}