import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'momentFormat',
  pure: true
})
export class MomentFormatPipe implements PipeTransform {
  public transform(value: moment.Moment, format: string): string {
    if (!value || !value.isValid()) {
      return '';
    }

    return value.format(format);
  }
}