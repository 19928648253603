/* Angular Import */
import {
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {Subscription, throwError} from 'rxjs';
import { catchError, finalize, map, take, tap, timeout, delay } from 'rxjs/operators';
import {OuxConfigService, OuxLoggerService, OuxModalService, OuxThemeType, OuxToastOptions, OuxToastService} from '@cisco/oux-common';
/* Feature imports */
import { DirectTransactionLinesSummaryTableExportRequest } from './direct-transaction-lines-summary-table-export-request';
import { DirectTransactionLinesSummaryTableExportService } from './direct-transaction-lines-summary-table-export.service';
import { ExportService } from 'src/app/shared/services/export/export.service';
import { OrdersExportRequestModel } from 'src/app/shared/models/concrete/request/orders-export-request.model';
import { OrdersExportFiltersModel } from 'src/app/shared/models/concrete/partials/orders-export-filters.model';
import { OrderStore } from 'src/app/shared/stores/order.store';
import { OrderService } from 'src/app/shared/services/order.service';
import {OfflineExportModalModel} from "../../../offline-export-modal/offline-export-modal.model";
import {OfflineExportHeaderModel} from "../../../offline-export-modal/offline-export-header.model";
import {OfflineExportContentModel} from "../../../offline-export-modal/offline-export-content.model";
import {ExportType} from "../../../../models/constants/export-type.enum";
import {OfflineExportModalComponent} from "../../../offline-export-modal/offline-export-modal.component";
import {SubmitExportService} from "../../../../services/export/submit-export.service";
import { VisibilityPageType } from 'src/app/shared/models/types/visibility-page-type.enum';
import { OrderSearchSearchType } from 'src/app/shared/models/types/order-search-search-type.enum';
import { ExportDisplayType} from 'src/app/shared/models/types/offline-export-type.enum';
import {AllocationTransactionType} from "../../../../models/types/allocation-transaction-type.enum";
import { OrderLinesSummary } from 'src/app/shared/models/interface/partials/order-lines-summary';
import { ExportDataModel } from 'src/app/shared/services/export/export-data.model';
import { ExportCellModel } from 'src/app/shared/services/export/export-cell.model';
import { ExportValueType } from 'src/app/shared/services/export/export-value.type';
import { OrdersLinesDetails } from 'src/app/shared/models/interface/partials/orders-lines-details';
import { DirectTransactionLinesSummaryTableExportModel } from './direct-transaction-lines-summary-table-export.model';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'direct-transaction-lines-summary-table-export',
  templateUrl: './direct-transaction-lines-summary-table-export.component.html',
  styleUrls: ['./direct-transaction-lines-summary-table-export.component.scss'],
  host: {
      'class': 'direct-transaction-lines-summary-table-export'
    }
})

export class DirectTransactionLinesSummaryTableExportComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private exportSubscription: Subscription;
  private directTransactionSummaryPayload: any;

  private currency$ = this.orderStore.selectedOrder$
    .pipe(
      map(order => order?.CURRENCY_CODE)
    );

  public loading: boolean = false;

  private excelFileName: string = 'Order Line Transactions';

  private _exportRequest: DirectTransactionLinesSummaryTableExportRequest;
  private currencyVal = this.currency$;
  @Input('request')
  public set exportRequest(value: DirectTransactionLinesSummaryTableExportRequest) {
    this._exportRequest = value;
  }
  public get exportRequest(): DirectTransactionLinesSummaryTableExportRequest {
    return this._exportRequest;
  }

  @Input('notEligibleRequest')
  public notEligibleExportRequest;

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: string;

  @Input('orderType')
  public orderType: string;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxLoggerSvc: OuxLoggerService,
              private directExportSvc: DirectTransactionLinesSummaryTableExportService,
              private orderService: OrderService,
              private exportService: ExportService,
              private ouxConfigSvc: OuxConfigService,
              private toastService: OuxToastService,
              private orderStore: OrderStore,
              private ouxModalSvc: OuxModalService,
              private submitExportService: SubmitExportService,
              private exportSvc: ExportService,
              private ouxToastSvc: OuxToastService,
              private datePipe: DatePipe) {
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscriptions.forEach( (sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
    this.exportSubscription?.unsubscribe();
    this.exportSubscription = undefined;
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /* Entry point called from html Export hyperlink */
  public export(){

    // create local export if not eligible order, else call offline export
    if (this.isNotEligibleOrder) {
      this.exportTableData();
    } else {
      this.buildDirectTransactionSummaryPayload();
      this.checkDuplicateExportRequest(this.directTransactionSummaryPayload);
    }
  }

  /* To check for duplicate export request
  * Possible output - Y or N
  * If Y - show a popup to the user that it is a duplicate request
  * If N - submit offline export request */
  private checkDuplicateExportRequest(request): void {
    this.exportSubscription = this.exportService.checkForDuplicateExportRequest(request.filters)
      .pipe(
        tap(response => {
          response.p_export_request_is_duplicate === 'N' ? this.submitExportService.queueDirectPOSExport(request) : this.initOfflineExport(request);
        }),
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            autoDismiss: true,
            dismissTimeout: 10,
            header: `Error`,
            icon: 'icon-export',
            message: `Export Request could not be submitted. Please contact IT Support`,
            theme: OuxThemeType.Warning,
            scaleOnHover: false
          }));

          return throwError(error);
        })
      )
      .subscribe({
        complete: () => {
        }
      });
  }

  /* Build Direct Trx Input payload request */
  public buildDirectTransactionSummaryPayload(): void {
    this.loading = true;
    this.showExportNotification();

    this.subscriptions.push(this.currency$
      .pipe(
        timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout')),
        take(1),
        map(currency => {
          const filters = new OrdersExportFiltersModel({
            allocationId: this.exportRequest?.allocationId,
            currency: currency,
            // RTMType: this.submitExportService.getRTMType(this.exportRequest?.erpPosFlag),
            RTMType: this.orderType,
            employeeId: this.exportRequest?.employeeId,
            erpPosFlag: this.orderType === 'Direct' ? this.exportRequest?.erpPosFlag : null,
            limit: this.exportRequest?.limit || 1000000,
            loginId: this.exportRequest?.loginId,
            nodeName: this.exportRequest?.nodeName,
            offset: this.exportRequest?.offset || 0,
            planId: this.exportRequest?.planId,
            soNumber: this.exportRequest?.soNumber,
            sourceSystem: this.exportRequest?.sourceSystem,
            srpGoalHeaderId: this.exportRequest?.srpGoalHeaderId,
            territoryTypeCode: this.exportRequest?.territoryTypeCode,
            userId: this.exportRequest?.userId,
            exportType: ExportDisplayType.Regular,
            page: VisibilityPageType.OrderSearch,
            //adjustmentType values for adjustments other than Manual Revenue yet to be fixed
            adjustmentType: this.orderType !== 'Direct' ? (this.orderType === AllocationTransactionType.ManualRevenue ? 'manualREV' : null) : null,
          });

          let request = new OrdersExportRequestModel({
            sourceSystem: 'Visibility',
            type: 'Line',
            filters: filters
          });
          this.directTransactionSummaryPayload = request;
        }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => this.loading = false)
      )
      .subscribe()
    );
  }

  // Show Export notification for the Export in Order Drill Down Screen
  private showExportNotification(): void {
    this.toastService.addToast(
      new OuxToastOptions({
        header: this.orderType ? `${this.orderType} Order Line Transactions` : 'Direct Order Line Transactions',
        message: 'Submitting Export',
        icon: 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: OuxThemeType.Info
      })
    );
  }

  private showLocalExportNotification(operation: string) : void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'Order Line Transactions Export',
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }

  /* Triggered upon user confirmation in the duplicate request pop up - Export hyperlink
  * type - Direct
  * directTransactionSummaryPayload - same payload that is used for the Export */
  private initOfflineExport(request) {
    const modalData = new OfflineExportModalModel({
      header: new OfflineExportHeaderModel({ icon: '', heading: '' }),
      content: new OfflineExportContentModel({ content: '' }),
      type: ExportType.Direct,
      offlineRequest: request
    });

    this.ouxModalSvc.openModal(OfflineExportModalComponent, modalData);
  }

  public exportTableData() {
    this.loading = true;
    this.showLocalExportNotification('Pending');
    
    this.notEligibleExportRequest.limit = 1000000;

    this.subscriptions.push(
      this.directExportSvc.fetchNotEligibleData(this.notEligibleExportRequest)
      .pipe(
        tap((collection: DirectTransactionLinesSummaryTableExportModel[]) => {
          this.initializeExport(collection.map(instance => new DirectTransactionLinesSummaryTableExportModel(instance)));
        }),
        delay(5000),
        tap(() => {
          this.showLocalExportNotification('success');
        })
      )
      .subscribe((collection: DirectTransactionLinesSummaryTableExportModel[]) => {},
      // has error
      (error: Error) => {
        this.ouxLoggerSvc.logError(`DirectTransactionLinesSummaryTableExportComponent: Failed to load Export Data: System Message -> ${error}`);
      },
      // is complete
      () => {
        this.loading = false;
      })
    );
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private initializeExport(data: OrderLinesSummary[]): void {
    let exportData = new ExportDataModel({
      headers: [],
      data: []
    });

    exportData.headers = [this.columnNotEligibleHeaders()];
    exportData.data = this.columnNotEligibleData(data);

    this.exportService.exportExcel(exportData, this.excelFileName);
  }
  
  private columnNotEligibleHeaders(): string[] {
    return [
      'Non Eligibility Reason',
      'SO Number',
      'SO/POS Line Number',
      'Book Date',
      'Offer Part Number',
      'Part Number',
      'Metric Type',
      'Contract Accepted Date',
      'Contract Start Date',
      'Contract End Date',
      'Contract Term',
      'Currency Code',
      'Revenue',
      'Annual Value',
      'Total Value',
      'Node Name',
      'Territory Type',
      'Sales Level 2',
      'Sales Level 3',
      'Sales Level 4',
      'Sales Level 5',
      'Sales Level 6',
      'AT Level 1',
      'AT Level 2',
      'AT Level 3',
      'AT Level 4',
      'Deal ID',
      'PO Number',
      'Bill To Customer',
      'Ship to Customer',
      'End Customer'
    ];
  }

  private columnNotEligibleData(data: OrderLinesSummary[]): ExportValueType[][] {

    let rows: OrderLinesSummary[] = [];

    data.forEach((row: OrderLinesSummary) => rows.push(new DirectTransactionLinesSummaryTableExportModel(row)));

    return data.map((row: DirectTransactionLinesSummaryTableExportModel) => [
      row.NON_ELIGIBILITY_REASON,
      row.SO_NUMBER,
      row.LINE_ID,
      this.datePipe.transform(row.SOURCE_TRANSACTION_DATE || row.LINE_CREATION_DATE, 'dd-LLL-YYYY'),
      row.OFFER_PART_NUMBER,
      row.PART_NUMBER,
      row.METRIC_TYPE,
      this.datePipe.transform(row.CONTRACT_ACCEPTED_DATE, 'dd-LLL-YYYY'),
      this.datePipe.transform(row.CONTRACT_START_DATE, 'dd-LLL-YYYY'),
      this.datePipe.transform(row.CONTRACT_END_DATE, 'dd-LLL-YYYY'),
      new ExportCellModel({
        value: row.CONTRACT_TERM,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.CURRENCY_CODE,
      new ExportCellModel({
        value: row.REVENUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.ANNUAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.TOTAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.TERRITORY_CODE,
      row.TERRITORY_TYPE_CODE,
      row.NODE_LEVEL_2,
      row.NODE_LEVEL_3,
      row.NODE_LEVEL_4,
      row.NODE_LEVEL_5,
      row.NODE_LEVEL_6,
      row.AT_LEVEL_1,
      row.AT_LEVEL_2,
      row.AT_LEVEL_3,
      row.AT_LEVEL_4,
      row.DEAL_ID,
      row.PO_NUMBER,
      row.BILL_TO_CUSTOMER || 'NA',
      row.SHIP_TO_CUSTOMER || 'NA',
      row.END_CUSTOMER || 'NA'
    ])
  }

}
