import { OrdersExportFilters } from "../../interface/partials/orders-export-filters";

export class OrdersExportFiltersModel implements OrdersExportFilters {
  
  public employeeId?: string;
  public userId?: string;
  public loginId?: string;
  public sourceSystem?: string;
  public planId?: number;
  public srpGoalHeaderId?: number;
  public allocationId?: number;
  public nodeName?: string;
  public territoryTypeCode?: string;
  public erpPosFlag?: string;
  public soNumber?: number;
  public limit?: number;
  public offset?: number;
  public distributor?: string;
  public billToCustomer?: string;
  public shipToCustomer?: string;
  public endCustomer?: string;
  public currency?: string;
  public RTMType?: string;
  public exportType?: string;
  public page?: string;
  public adjustmentType?: string;

  constructor(args : Partial<OrdersExportFiltersModel>) {
    Object.assign(this, args);
  }

}