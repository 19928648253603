/* Angular Imports */
import {
  Component,
  OnInit }                         from '@angular/core';
import { Router }                  from '@angular/router';


@Component({
  selector: 'app-header-logo',
  templateUrl: './header-logo.component.html',
  styleUrls: ['./header-logo.component.scss'],
  host: {
    'class': 'app-header-logo'
  }
})
export class HeaderLogoComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router : Router) {}

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Route to root component
   */
  public goToRoot(event : any) : void {
    this.router.navigate(['/goal-attainment']);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////


}
