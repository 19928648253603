/* Angular Import */
import {
  Component,
  OnInit }                                    from '@angular/core';
import {
  Router,
  ActivatedRoute }                             from '@angular/router';
import {
  EMPTY,
  Observable,
  Subscription }                              from 'rxjs';
import {
  catchError,
  finalize,
  map,
  tap }                                       from 'rxjs/operators';
/* OneUX Imports */
import {
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxTooltipOptions,
  OuxTooltipPosition,
  OuxThemeType,
  OuxColumnSettings,
  OuxLoggerService,
  OuxModalService }                           from '@cisco/oux-common';
/* Feature Imports */
import { OrderStore }                         from 'src/app/shared/stores/order.store';
import { OrderLinesStore }                    from 'src/app/shared/stores/order-lines.store';
import { OrderLinesSummary }                  from 'src/app/shared/models/interface/partials/order-lines-summary';
import { OrderService }                       from 'src/app/shared/services/order.service';
import { OrdersLinesDetailsRequest }          from 'src/app/shared/models/interface/request/orders-lines-details-request';
import { OrdersLinesDetailsRequestModel }     from 'src/app/shared/models/concrete/request/orders-lines-details-request.model';
import { OrderLineTransactionsStore }         from 'src/app/shared/stores/order-line-transactions.store';
import { LoaderService }                      from 'src/app/core/services';
import { LoadingType }                        from 'src/app/shared/models/constants/loading-type.enum';
import { OrdersLinesDetails }                 from 'src/app/shared/models/interface/partials/orders-lines-details';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';



@Component({
  selector: 'direct-transaction-lines-summary-table',
  templateUrl: './direct-transaction-lines-summary-table.component.html',
  styleUrls: ['./direct-transaction-lines-summary-table.component.scss'],
  host: {
    'class': 'direct-transaction-lines-summary-table'
  }
})
export class DirectTransactionLinesSummaryTableComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////


  private subscriptions : Subscription[] = [];

  public ampIdentifier : string;

  public AmpIdentifierKey = AmpIdentifierKey;

  public tableOpts : OuxTableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--fixed-header --hover',
    formatCells: false,
    tableLayout: 'fixed',
    fixedHeight: '100%',
    loading: true,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'There are no order lines to display at this time.',
    trackRecordsProperty: 'LINE_ID',
    highlightSelectedRows: false,
    missingDataMessage: ' ',
    toggleSelected: false
  });

  public columnSettings: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'LINE_ID'
    }),
    new OuxColumnSettings({
      header: 'TCV',
      primaryKey: 'TOTAL_VALUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Total Annual Bookings',
      primaryKey: 'ANNUAL_VALUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Bookings',
      primaryKey: 'BOOKING_AMT',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Backlog',
      primaryKey: 'COMM_BACKLOG',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'COMM_REVENUE',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Customer Information',
      primaryKey: 'BILL_TO_CUSTOMER',
      width: '30.5%'
    })
  ];

  public tableThemeOverrides : OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '100px',
      'max-width': '250px'
    },
    context: {
      code : null
    }
  });

  public commissionStatusTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    alignAtCenter: true,
    position: OuxTooltipPosition.Left,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '400px',
      'max-width': '600px'
    },
    context: {
      code : null
    }
  });

  private loading$ = this.loaderSvc.loading$.get(LoadingType.Table)
    .pipe(
      tap(loading => {
        this.tableOpts.loading = loading;
      })
    );

  public lines$: Observable<OrderLinesSummary[]> = this.orderLinesStore.orderLines$
    .pipe(
      map(lines => lines && lines.records && lines.records.length > 0 ? lines.records : []),
      tap(lines => {
        this.ouxLoggerSvc.logDebug('OrderLinesSummaryTableComponent - Order Lines:', lines);
      })
    );

  public orderType: string;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router: Router,
              private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService,
              private loaderSvc: LoaderService,
              private orderSvc: OrderService,
              private orderStore: OrderStore,
              private orderLinesStore: OrderLinesStore,
              private orderLineTransactionStore: OrderLineTransactionsStore,
              private route: ActivatedRoute) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.subscriptions.push(this.loading$.subscribe());
    this.subscriptions.push(this.lines$.subscribe());
    this.orderType = this.route.snapshot.queryParamMap.has('type') ? this.route.snapshot.queryParamMap.get('type') : '';
    this.ampIdentifier = this.route.snapshot.queryParamMap.has('category') ? this.route.snapshot.queryParamMap.get('category') : '';
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];

    // clean up selected order - prevents memory leaks
    this.orderStore.clearSelectedOrder();
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  public showOrderLineDetails(record: OrderLinesSummary, transactionType: 'revenue' | 'backlog', event: any) : void {

    this.ouxLoggerSvc.logDebug(`OrderLinesSummaryTableComponent - ${record.LINE_ID} Line Selected`, record);

    let request: OrdersLinesDetailsRequest = new OrdersLinesDetailsRequestModel({
      lineId: record.LINE_ID,
      srpGoalHeaderId: this.orderStore.selectedOrder.SRP_GOAL_HEADER_ID,
      allocationId: this.orderStore.selectedOrder.QUOTA_ID,
      sequenceId: record.SEQUENCE_ID,
      soNumber: record.ORDER_NUMBER,
      planId: this.orderStore.selectedOrder.COMP_PLAN_ID,
      erpPosFlag: this.orderStore.selectedOrder.ERP_POS_FLAG,
      transactionType: transactionType
    });

    this.fetchOrderLineDetailsData(request, record);

    this.loaderSvc.show(LoadingType.Table);

    // subscribe to close event and retrieve the closed value, if any
    this.subscriptions.push(
      this.ouxModalSvc.onClose()
        .subscribe( (value: any) => { 
          this.orderLineTransactionStore.clearOrderLineTransactionsData(); 
          
          this.ouxLoggerSvc.logDebug('OrderLinesSummaryTableComponent - Modal OnClose Value:', value ? value : 'No Value Passed');
        })
    );
  }


  // initialize order lines data source
  private fetchOrderLineDetailsData(request: OrdersLinesDetailsRequest, line: OrderLinesSummary) : void {
    switch (request.transactionType) {
      case 'backlog': {
        this.subscriptions.push(this.orderSvc.fetchOrderLineBacklogDetails(request)
          .pipe(
            finalize(() => {
              this.loaderSvc.hide(LoadingType.Table);
              this.orderLineTransactionStore.showTransactionDetailsModal(line, request.transactionType)
            })
          )
          .subscribe()
        );
        break;
      }
      default: {
        let params = this.route.snapshot.queryParamMap;

        if (this.ampIdentifier === AmpIdentifierKey.NOTELIGIBLE) {
          let notEligibleRequest = {
            loginId: '',
            userId: '',
            employeeId: '',
            salesOrderNumber: (params && params.has('soNumber')) ? params.get('soNumber') : '',
            lineId: request.lineId,
            trxType: (params && params.has('type')) ? params.get('type') : '',
            erpPosFlag: (params && params.has('erpPosFlag')) ? params.get('erpPosFlag') : '',
            metricType: (params && params.has('metricType')) ? params.get('metricType') : '',
            limit: 10,
            offset: 0,
            sortBy: "employee_id",
            sortOrder: "desc"
          }

          this.subscriptions.push(this.orderSvc.fetchNotEligibleOrderLineDetails(notEligibleRequest)
          .pipe(
            tap((details: OrdersLinesDetails) => {
              this.ouxLoggerSvc.logDebug(`OrderLinesSummaryTableComponent - Tap Details:`, details);
            }),
            catchError((error: Error) => {
              this.ouxLoggerSvc.logDebug('OrderLinesSummaryTableComponent - Error Transactions:', error);

              return EMPTY;
            }),
            finalize(() => {
              this.loaderSvc.hide(LoadingType.Table);
              // open order line transactions modal
              // sets selected order line ID to populate transactions modal header
              this.orderLineTransactionStore.showTransactionDetailsModal(line, request.transactionType);
            })
          )
          .subscribe()
          );
        } else {
          this.subscriptions.push(this.orderSvc.fetchOrderLineDetails(request)
          .pipe(
            tap((details: OrdersLinesDetails) => {
              this.ouxLoggerSvc.logDebug(`OrderLinesSummaryTableComponent - Tap Details:`, details);
            }),
            catchError((error: Error) => {
              this.ouxLoggerSvc.logDebug('OrderLinesSummaryTableComponent - Error Transactions:', error);

              return EMPTY;
            }),
            finalize(() => {
              this.loaderSvc.hide(LoadingType.Table);
              // open order line transactions modal
              // sets selected order line ID to populate transactions modal header
              this.orderLineTransactionStore.showTransactionDetailsModal(line, request.transactionType);
            })
          )
          .subscribe()
          );
        } 
        break;
      }
    }

  }


}

