import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { delay, map, tap, withLatestFrom } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services';
import { LoadingType } from 'src/app/shared/models/constants/loading-type.enum';
import { OrderAttributes } from 'src/app/shared/models/interface/partials/order-attributes';
import { OrdersSearchOrder } from 'src/app/shared/models/interface/partials/orders-search-order';
import { TransactionGroupCode } from 'src/app/shared/models/types/transaction-group-code.enum';
import { OrderLineTransactionsStore } from 'src/app/shared/stores/order-line-transactions.store';
import { OrderStore } from 'src/app/shared/stores/order.store';
import { TransactionLineDetailsGridViewModel } from '../../../transaction-line-details-grid/transaction-line-details-grid.view-model';

@Component({
  selector: 'pos-transaction-details-grid',
  templateUrl: './pos-transaction-details-grid.component.html',
  styleUrls: ['./pos-transaction-details-grid.component.scss'],
  host: {
    'class': 'pos-transaction-details-grid'
  }
})
export class POSTransactionDetailsGridComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  public attributes: OrderAttributes;
  public hasTransactions: boolean;

  public loading$ = this.loaderService.loading$.get(LoadingType.Table);
  public selectedOrder$ = this.orderStore.selectedOrder$;

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  @Input('notEligibleExportRequest')
  public notEligibleExportRequest: any;
  
  public attributes$ = this.orderLineTransactionsStore.orderAttributes$
    .pipe(
      tap(attributes => this.attributes = attributes)
    );

  public showExport$ = combineLatest([
      this.orderStore.selectedOrder$,
      this.orderLineTransactionsStore.orderAttributes$
    ])
    .pipe(
      map(([order, attributes]) => {
        return order && attributes ? true : false;
      })
    );

  public transactions$ = this.orderLineTransactionsStore.orderLineTransactions$
    .pipe(
      delay(0),
      tap(transactions => this.hasTransactions = transactions?.length > 0),
      withLatestFrom(this.attributes$),
      map(([transactions, attributes]) => {
        return (transactions || []).map(transaction => new TransactionLineDetailsGridViewModel({
          annualValue: transaction.ANNUAL_VALUE,
          atLevel1: transaction.AT_LEVEL_1,
          atLevel2: transaction.AT_LEVEL_2,
          atLevel3: transaction.AT_LEVEL_3,
          atLevel4: transaction.AT_LEVEL_4,
          bookDate: transaction.SOURCE_TRANSACTION_DATE || attributes?.LINE_CREATION_DATE,
          businessUnit: transaction.BUSINESS_UNIT,
          commBacklogAmt: null,
          contractEndDate: transaction.CONTRACT_END_DATE,
          contractStartDate: transaction.CONTRACT_START_DATE,
          contractTerm: transaction.CONTRACT_TERM,
          expectedShipDate: null,
          extdMyPayoutAmount: transaction.EXTD_MY_PAYOUT_AMOUNT,
          metricType: transaction.METRIC_TYPE,
          myPEFlag: transaction.MYPEFLAG,
          myPayoutAmount: transaction.MY_PAYOUT_AMOUNT,
          myPayoutFactor: transaction.MY_PAYOUT_FACTOR,
          partNumber: transaction.PART_NUMBER,
          productSplit: transaction.PRODUCT_SPLIT,
          revenueMultiplierFactor: transaction.REV_MULTIPLIER_FACTOR,
          salesMotion: transaction.SALES_MOTION,
          splitPercent: transaction.SPLIT_PERCENT,
          totalValue: transaction.TOTAL_VALUE,
          transCreationDate: transaction.TRANS_CREATION_DATE,
          transactionAmount: transaction.TRANSACTION_AMOUNT,
          transactionDate: transaction.ORIGINAL_TRANSACTION_DATE || transaction.TRANSACTION_DATE,
          nonEligibilityReason: transaction.NON_ELIGIBILITY_REASON
        }));
      })
    );

  constructor(
    private loaderService: LoaderService,
    private orderStore: OrderStore,
    private orderLineTransactionsStore: OrderLineTransactionsStore,
  ) {
    this.subscriptions.push(this.selectedOrder$.subscribe());
    this.subscriptions.push(this.attributes$.subscribe());
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }
}
