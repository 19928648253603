import { OrdersLinesBacklog } from '../../interface/partials/orders-lines-backlog';

export class OrdersLinesBacklogModel implements OrdersLinesBacklog {
  public ANNUAL_VALUE: number;
  public AT_LEVEL_1: string;
  public AT_LEVEL_2: string;
  public AT_LEVEL_3: string;
  public AT_LEVEL_4: string;
  public BACKLOG_QTY: number;
  public BUSINESS_UNIT: string;
  public COMM_BACKLOG_AMT: number;
  public CONTRACT_END_DATE: number;
  public CONTRACT_START_DATE: number;
  public CONTRACT_TERM: number;
  public EXPECTED_SHIP_DATE: number;
  public GS_CURRENCY_CODE: string;
  public LINE_CREATION_DATE: number;
  public LINE_NUMBER: number;
  public METRIC_TYPE: string;
  public ORDER_NUMBER: number;
  public PART_NUMBER: string;
  public TOTAL_VALUE: number;
  
  constructor(args : Partial<OrdersLinesBacklogModel>) {
    Object.assign(this, args);
  }
}