<button 
  class="btn --stateless"
  [ouxTooltip]="dashboardsTemplate"
  [ouxTooltipOptions]="injectedDashboardsTooltipOpts">
  <i class="icon icon-apps"></i>
</button>

<ng-template #dashboardsTemplate>
  <div class="dashboards" ouxThemeable>
    <ul class="dashboards__list">
      <li class="item">
        <span class="item__header">Dashboards</span>
      </li>
      <li class="item">
        <a class="item__label" (click)="goTo('claimingUrl', $event.preventDefault())">Next Gen Claiming</a>
      </li>
      <li class="item">
        <a class="item__label" (click)="goTo('calendarUrl', $event.preventDefault())">Sales Incentive Calendar</a>
      </li>
      <!-- <li class="item">
        <a class="item__label" (click)="goTo('estimatorUrl', $event.preventDefault())">Sales Incentive Plan Estimator</a>
      </li> -->
    </ul>
  </div>
</ng-template>