export class OfflineExportContentModel {

  public content: string;

  constructor(options: Partial<OfflineExportContentModel>) {
    Object.assign(this, options);

    if (!this.content) {
      this.content = 'There is an export request already in progress for the same set of transactions. You should receive an email with details for the previous export request once it is processed. Do you still want to submit a new one?';
    }
  }

}
