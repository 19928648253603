
<section class="grid" *ngFor="let transaction of transactions">
  <div class="grid__column brd-none">
    <div *ngIf="transaction?.nonEligibilityReason" class="column__row">
      <span class="label">Non Eligibility Reason</span>
      <span class="value">{{ transaction?.nonEligibilityReason }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Type</span>
      <span class="value">{{transactionType == 'backlog' ? 'Backlog' : 'Revenue'}}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Book Date</span>
      <span class="value">{{ (transaction?.bookDate || selectedOrder?.SO_DATE) | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Invoice Date</span>
      <span class="value">{{ (transaction?.transactionDate) | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'backlog'">
      <span class="label">Expected Fulfillment Date</span>
      <span class="value">{{ transaction?.expectedShipDate | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Creation Date</span>
      <span class="value">{{ transaction?.transCreationDate | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.contractStartDate">
      <span class="label">Contract Start Date</span>
      <span class="value">{{ transaction?.contractStartDate | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.contractEndDate">
      <span class="label">Contract End Date</span>
      <span class="value">{{ transaction?.contractEndDate | dateFormat }}</span>
    </div><!-- /column__row -->
  </div>

  <div class="grid__column">
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Invoice Line Amount (Original)</span>
      <span class="value --with-padding">{{ transaction?.transactionAmount | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Invoice Line Amount (Multiplied)</span>
      <span class="value --with-padding">{{ transaction?.transactionAmount | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.myPEFlag == 'Y'">
      <span class="label">
        Invoice Line Amount (MY Payout Eligible) <i class="icon info-tooltip-trigger icon-info-outline" [ouxTooltip]="invoiceLineAmountMyPayoutEligibleTooltip" [ouxTooltipOptions]="infoTooltipOptions"></i>
      </span>
      <span *ngIf="isMulti" class="value --with-padding">{{ transaction?.extdMyPayoutAmount | number:'1.2-2' }}</span>
      <span *ngIf="!isMulti" class="value --with-padding">{{ transaction?.myPayoutAmount | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'backlog'">
      <span class="label">Backlog Amount</span>
      <span class="value --with-padding">{{ (transaction?.myPEFlag === 'Y' || transaction?.nonEligibilityReason) ? 'NA' : (transaction?.commBacklogAmt | number:'1.2-2') }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Split %</span>
      <span class="value --with-padding">{{ transaction?.splitPercent }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Sales Motion</span>
      <span class="value --with-padding">{{ transaction?.salesMotion }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.annualValue">
      <span class="label">
        Annual Value <i class="icon info-tooltip-trigger icon-info-outline" [ouxTooltip]="annualValueTooltip" [ouxTooltipOptions]="infoTooltipOptions"></i>
      </span>
      <span class="value --with-padding">{{ transaction?.annualValue | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.totalValue">
      <span class="label">
        Total Contract Value <i class="icon info-tooltip-trigger icon-info-outline" [ouxTooltip]="totalValueTooltip" [ouxTooltipOptions]="infoTooltipOptions"></i>
      </span>
      <span class="value --with-padding">{{ transaction?.totalValue | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.contractTerm && transactionType == 'revenue'">
      <span class="label">Contract Term</span>
      <span class="value --with-padding">{{ transaction?.contractTerm }}</span>
    </div><!-- /column__row -->
  </div>

  <div class="grid__column">
    <div class="column__row">
      <span class="label">Part Number</span>
      <span class="value">{{ transaction?.partNumber  }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transactionType == 'revenue'">
      <span class="label">Product Split %</span>
      <span class="value">{{ transaction?.productSplit }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Business Unit</span>
      <span class="value">{{ transaction?.businessUnit }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Revenue Multiplier</span>
      <a class="link" href="https://salesconnect.cisco.com/#/program/PAGE-1918">{{ transaction?.revenueMultiplierFactor || 1 }}</a>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.myPEFlag == 'Y'">
      <span class="label">MY Uplift Factor</span>
      <span class="value">{{ transaction?.myPayoutFactor || 0 }}</span>
    </div><!-- /column__row -->
    
  </div>

  <div class="grid__column">
    <div class="column__row">
      <span class="label">AT Level 1</span>
      <span class="value">{{ transaction?.atLevel1  }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">AT Level 2</span>
      <span class="value">{{ transaction?.atLevel2  }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">AT Level 3</span>
      <span class="value">{{ transaction?.atLevel3  }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Metric Type</span>
      <span class="value">{{ transaction?.metricType == 'ACV' ? 'Annual' : transaction?.metricType  }}</span>
    </div><!-- /column__row -->
  </div>
</section>

<ng-template #invoiceLineAmountMyPayoutEligibleTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Revenue Eligible for Multi Year Payout Uplift
  </div>
</ng-template>

<ng-template #annualValueTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Annual Contract Value - Bookings value from first 12 months of a Multi-Year contract. If the contract term is less than 12 months, ACV would be Bookings value for that term (equivalent to TCV)
  </div>
</ng-template>

<ng-template #totalValueTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Total Contract Value - Bookings value across the lifetime of the contract
  </div>
</ng-template>