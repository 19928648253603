/* RxJS Imports */
import { Subscription }       from 'rxjs';
/* Package Imports */
import {
  OuxBrowserSupportPartialService,
  OuxLayoutService,
  OuxLoggerService }          from '@cisco/oux-common';
/* Feature Imports */
import { LoaderService }      from '../core/services/loader.service';
import { InstanceService }    from '../shared/services/instance.service';
import { Router } from '@angular/router';

/**
 * Interface Base:
 *
 * Because an Abstract class has a runtime representation, we can use it as a
 * dependency-injection (DI) token in Angular's DI container. And, since each concrete
 * class has to implement or extend this abstract base class, it means that the base
 * class can act as the "interface" to the behavior as well.
 */

export abstract class InterfaceBase {

  /**
   * Manage subscriptions - remember to unsubscribe when component is destroyed
   */
  protected subscriptions : Subscription[] = [];

  /**
   * Is side nav present
   */
  public showSideNav : boolean;

  /**
   * Is footer present
   */
  public showFooter : boolean;


  constructor(protected readonly router : Router,
              protected readonly ouxLayoutSvc : OuxLayoutService,
              protected readonly ouxLoggerSvc : OuxLoggerService,
              protected readonly ouxBrowserSupportSvc : OuxBrowserSupportPartialService,
              protected readonly instanceSvc : InstanceService,
              protected readonly loaderSvc : LoaderService) {

  }

}
