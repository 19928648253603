import { PlanElementDetails } from "../../interface/partials/plan-element-details";

export class PlanElementDetailsModel implements PlanElementDetails {
  
  public EmpCapFlag: string;
  public EmpFlagMessage: string;
  public acvFlag: string;
  public allocationID: number;
  public attainment: string;
  public backlog: number;
  public bookings: number;
  public currency: string;
  public fiscalInterval: string;
  public goal: number;
  public goalID: number;
  public goalQuotaId: number;
  public goalSheet: string;
  public multiFlag: string;
  public nonCommBooking: number;
  public planID: number;
  public planProrationType: string;
  public planTitle: string;
  public revenueAmount: number;
  public revenueAmountmul: number;
  public weight: string;

  constructor(args : Partial<PlanElementDetailsModel>) {
    Object.assign(this, args);
  }

}
