
import { OrdersExportRequest } from '../../interface/request/orders-export-request';
import { OrdersExportFilters } from '../../interface/partials/orders-export-filters';

export class OrdersExportRequestModel implements OrdersExportRequest {
  public employeeId: string;
  public userId: string;
  public loginId: string;
  public sourceSystem: string;
  public type: string;
  public filters: OrdersExportFilters;
  public host: string;
  public page: string;

  constructor(args : Partial<OrdersExportRequestModel>) {
    Object.assign(this, args);
  }

}