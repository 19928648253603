import { Transactions }                        from "../../interface/partials/transactions";

export class TransactionsModel implements Transactions {

  public ANNUAL_VALUE: any;
  public ATCCV_FLAG: any;
  public AT_DESIGNATOR: string;
  public AT_LEVEL_1: string;
  public AT_LEVEL_2: string;
  public AT_LEVEL_3: string;
  public AT_LEVEL_4: string;
  public BONUS_PLAN_CODE: string;
  public BOOKING_SOURCE: any;
  public BOOK_TYPE: string;
  public BP_FLAG: string;
  public BUSINESS_UNIT: string;
  public BUYING_PROGRAM_NAME: string;
  public CONTRACT_ACCEPTED_DATE: number;
  public CONTRACT_END_DATE: number;
  public CONTRACT_NUMBER: any;
  public CONTRACT_START_DATE: number;
  public CONTRACT_TERM: any;
  public ELA_FLAG: any;
  public EXP_SHIP_DATE: string;
  public EXTD_MY_PAYOUT_AMOUNT: any;
  public GS_CURRENCY_CODE: string;
  public LA_SELL_TYPE: any;
  public METRIC_TYPE: string;
  public MYPEFLAG: string;
  public MY_PAYOUT_AMOUNT: any;
  public MY_PAYOUT_FACTOR: any;
  public OFFER_NAME: string;
  public OFFER_TYPE_NAME: any;
  public ORDER_NUMBER: number;
  public ORIGINAL_TRANSACTION_DATE: number;
  public PART_NUMBER: string;
  public PLAN_TITLE: string;
  public PRODUCT_FAMILY: string;
  public PRODUCT_SPLIT: number;
  public QUANTITY: number;
  public RESELLER_NAME: any;
  public REV_MULTIPLIER_FACTOR: number;
  public SAAS_FLAG: string;
  public SALES_MOTION: string;
  public SALES_VALUE: number;
  public SALES_VALUE_MULTIPLIED: number;
  public SOURCE_TRANSACTION_DATE: number;
  public SPLIT_AGENT: string;
  public SPLIT_EFFECTIVE_DATE: number;
  public SPLIT_PERCENT: number;
  public TCV_SALES_VALUE: any;
  public TERM: any;
  public TOTAL_VALUE: any;
  public TRANSACTION_AMOUNT: number;
  public TRANSACTION_DATE: number;
  public TRANS_CREATION_DATE: number;
  public TRANS_DAILY_SUMM_ID: number;
  public TRANS_TYPE_CODE: string;
  public WPA_FLAG: any;
  public NON_ELIGIBILITY_REASON: string;

  constructor(args : Partial<TransactionsModel>) {
    Object.assign(this, args);
  }

}