import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, Input } from '@angular/core';
import {OuxConfigService, OuxModalService, OuxThemeType, OuxToastOptions, OuxToastService, OuxLoggerService} from '@cisco/oux-common';
import {combineLatest, Subscription, throwError} from 'rxjs';
import { catchError, finalize, map, take, tap, timeout, delay } from 'rxjs/operators';
import { OrdersExportFiltersModel } from 'src/app/shared/models/concrete/partials/orders-export-filters.model';
import { OrdersExportRequestModel } from 'src/app/shared/models/concrete/request/orders-export-request.model';
import { OrderService } from 'src/app/shared/services/order.service';
import { OrderLineTransactionsStore } from 'src/app/shared/stores/order-line-transactions.store';
import { OrderStore } from 'src/app/shared/stores/order.store';
import {ExportService} from "../../../../services/export/export.service";
import {SubmitExportService} from "../../../../services/export/submit-export.service";
import {OfflineExportModalModel} from "../../../offline-export-modal/offline-export-modal.model";
import {OfflineExportHeaderModel} from "../../../offline-export-modal/offline-export-header.model";
import {OfflineExportContentModel} from "../../../offline-export-modal/offline-export-content.model";
import {ExportType} from "../../../../models/constants/export-type.enum";
import {OfflineExportModalComponent} from "../../../offline-export-modal/offline-export-modal.component";
import { VisibilityPageType } from 'src/app/shared/models/types/visibility-page-type.enum';
import { OrderSearchSearchType } from 'src/app/shared/models/types/order-search-search-type.enum';
import { ExportDisplayType} from 'src/app/shared/models/types/offline-export-type.enum';
import { PosTransactionDetailsGridExportService } from './pos-transaction-details-grid-export-service';
import { ExportDataModel } from 'src/app/shared/services/export/export-data.model';
import { ExportCellModel } from 'src/app/shared/services/export/export-cell.model';
import { ExportValueType } from 'src/app/shared/services/export/export-value.type';
import { OrderLinesSummary } from 'src/app/shared/models/interface/partials/order-lines-summary';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'pos-transaction-details-grid-export',
  templateUrl: './pos-transaction-details-grid-export.component.html',
  styleUrls: ['./pos-transaction-details-grid-export.component.scss'],
  host: {
    'class': 'pos-transaction-details-grid-export'
  }
})
export class POSTransactionDetailsGridExportComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];
  private exportSubscription: Subscription;
  private posTransactionSummaryPayload: any;

  public loading: boolean;

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  @Input('notEligibleExportRequest')
  public notEligibleExportRequest: any;

  private excelFileName: string = 'Order Line Transactions';

  private exportRequest$ = combineLatest([
      this.orderStore.selectedOrder$,
      this.orderLineTransactionsStore.orderAttributes$
    ])
    .pipe(
      map(([order, attributes]) => {
        let filters = new OrdersExportFiltersModel({
          allocationId: order?.QUOTA_ID,
          currency: order?.CURRENCY_CODE,
          erpPosFlag: order?.ERP_POS_FLAG,
          RTMType: this.submitExportService.getRTMType(order?.ERP_POS_FLAG),
          limit: 1000000,
          nodeName: '',
          offset: 0,
          planId: order?.COMP_PLAN_ID,
          soNumber: order?.SO_NUMBER,
          sourceSystem: 'Visibility',
          srpGoalHeaderId: order?.SRP_GOAL_HEADER_ID,
          territoryTypeCode: '',
          distributor: attributes?.DISTRIBUTOR_NAME || 'Not Available',
          billToCustomer: attributes?.BILL_TO_CUSTOMER || 'Not Available',
          shipToCustomer: attributes?.SHIP_TO_CUSTOMER || 'Not Available',
          endCustomer: attributes?.END_CUSTOMER || 'Not Available',
          exportType: ExportDisplayType.Regular,
          page: VisibilityPageType.OrderSearch
        });

        let request = new OrdersExportRequestModel({
          sourceSystem: 'Visibility',
          type: 'Line',
          filters: filters
        });

        return request;
      })
    );

  constructor(
    private orderService: OrderService,
    private toastService: OuxToastService,
    private ouxConfigSvc: OuxConfigService,
    private orderStore: OrderStore,
    private orderLineTransactionsStore: OrderLineTransactionsStore,
    private exportService: ExportService,
    private ouxModalSvc: OuxModalService,
    private submitExportService: SubmitExportService,
    private ouxToastSvc: OuxToastService,
    private posExportSvc: PosTransactionDetailsGridExportService,
    private ouxLoggerSvc: OuxLoggerService,
    private datePipe: DatePipe
  ) {
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
    this.exportSubscription?.unsubscribe();
    this.exportSubscription = undefined;
  }

  /* Entry point called from html Export hyperlink */
  public export(){

    // if not eligible order, create local export, else create offline export
    if (this.isNotEligibleOrder) {
      this.exportTableData();
    } else {
      this.buildPOSTransactionSummaryPayload();
      this.checkDuplicateExportRequest(this.posTransactionSummaryPayload);
    }
  }

  /* To check for duplicate export request
  * Possible output - Y or N
  * If Y - show a popup to the user that it is a duplicate request
  * If N - submit offline export request */
  private checkDuplicateExportRequest(request): void {
    this.exportSubscription = this.exportService.checkForDuplicateExportRequest(request.filters)
      .pipe(
        timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout')),
        tap(response => {
          response.p_export_request_is_duplicate === 'N' ? this.submitExportService.queueDirectPOSExport(request) : this.initOfflineExport(request);
        }),
        catchError(error => {
          this.toastService.addToast(new OuxToastOptions({
            autoDismiss: true,
            dismissTimeout: 10,
            header: `Error`,
            icon: 'icon-export',
            message: `Export Request could not be submitted. Please contact IT Support`,
            theme: OuxThemeType.Warning,
            scaleOnHover: false
          }));

          return throwError(error);
        })
      )
      .subscribe({
        complete: () => {
        }
      });
  }

  /* Build POS Trx Input payload request */
  public buildPOSTransactionSummaryPayload(): void {
    this.loading = true;
    this.showExportNotification();
    this.subscriptions.push(this.exportRequest$
      .pipe(
        take(1),
        map(request => {this.posTransactionSummaryPayload = request; }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => this.loading = false)
      )
      .subscribe()
    );
  }

  public exportTableData() {
    this.loading = true;
    this.showLocalExportNotification('Pending');

    this.notEligibleExportRequest.limit = 1000000;
    
    this.subscriptions.push(
      this.posExportSvc.fetchNotEligibleData(this.notEligibleExportRequest)
      .pipe(
        tap((collection: any[]) => {
          this.initializeExport(collection);
        }),
        delay(5000),
        tap(() => {
          this.showLocalExportNotification('success');
        })
      )
      .subscribe((collection: any[]) => {},
      // has error
      (error: Error) => {
        this.ouxLoggerSvc.logError(`PosTransactionDetailsGridExportComponent: Failed to load Export Data: System Message -> ${error}`);
      },
      // is complete
      () => {
        this.loading = false;
      })
    );
  }

  private showLocalExportNotification(operation: string) : void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'POS Order Line Transactions Export',
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }

  // Show Export notification for the Export in Order Drill Down Screen
  private showExportNotification(): void {
    this.toastService.addToast(
      new OuxToastOptions({
        header: 'POS Order Line Transactions',
        message: 'Submitting Export',
        icon: 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: OuxThemeType.Info
      })
    );
  }

  /* Triggered upon user confirmation in the duplicate request pop up - Export hyperlink
  * type - POS
  * posTransactionSummaryPayload - same payload that is used for the Export */
  private initOfflineExport(request) {
    const modalData = new OfflineExportModalModel({
      header: new OfflineExportHeaderModel({ icon: '', heading: '' }),
      content: new OfflineExportContentModel({ content: '' }),
      type: ExportType.POS,
      offlineRequest: request
    });

    this.ouxModalSvc.openModal(OfflineExportModalComponent, modalData);
  }

  // for local Excel export
  private initializeExport(data: any[]): void {
    let exportData = new ExportDataModel({
      headers: [],
      data: []
    });

    exportData.headers = [this.columnNotEligibleHeaders()];
    exportData.data = this.columnNotEligibleData(data);

    this.exportService.exportExcel(exportData, this.excelFileName);
  }

  private columnNotEligibleHeaders(): string[] {
    return [
      'Non Eligibility Reason',
      'SO Number',
      'SO/POS Line Number',
      'Book Date',
      'Offer Part Number',
      'Part Number',
      'Metric Type',
      'Contract Accepted Date',
      'Contract Start Date',
      'Contract End Date',
      'Contract Term',
      'Currency Code',
      'Revenue',
      'Annual Value',
      'Total Value',
      'Node Name',
      'Territory Type',
      'Sales Level 2',
      'Sales Level 3',
      'Sales Level 4',
      'Sales Level 5',
      'Sales Level 6',
      'AT Level 1',
      'AT Level 2',
      'AT Level 3',
      'AT Level 4',
      'Deal ID',
      'PO Number',
      'Bill To Customer',
      'Ship to Customer',
      'End Customer'
    ];
  }

  private columnNotEligibleData(data: OrderLinesSummary[]): ExportValueType[][] {

    let rows: OrderLinesSummary[] = [];

    // data.forEach((row: OrderLinesSummary) => rows.push(new DirectTransactionLinesSummaryTableExportModel(row)));

    return data.map((row: any) => [
      row.NON_ELIGIBILITY_REASON,
      row.SO_NUMBER,
      row.LINE_ID,
      this.datePipe.transform(row.SOURCE_TRANSACTION_DATE || row.LINE_CREATION_DATE, 'dd-LLL-YYYY'),
      row.OFFER_PART_NUMBER,
      row.PART_NUMBER,
      row.METRIC_TYPE,
      this.datePipe.transform(row.CONTRACT_ACCEPTED_DATE, 'dd-LLL-YYYY'),
      this.datePipe.transform(row.CONTRACT_START_DATE, 'dd-LLL-YYYY'),
      this.datePipe.transform(row.CONTRACT_END_DATE, 'dd-LLL-YYYY'),
      new ExportCellModel({
        value: row.CONTRACT_TERM,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.CURRENCY_CODE,
      new ExportCellModel({
        value: row.REVENUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.ANNUAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.TOTAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.TERRITORY_CODE,
      row.TERRITORY_TYPE_CODE,
      row.NODE_LEVEL_2,
      row.NODE_LEVEL_3,
      row.NODE_LEVEL_4,
      row.NODE_LEVEL_5,
      row.NODE_LEVEL_6,
      row.AT_LEVEL_1,
      row.AT_LEVEL_2,
      row.AT_LEVEL_3,
      row.AT_LEVEL_4,
      row.DEAL_ID,
      row.PO_NUMBER,
      row.BILL_TO_CUSTOMER || 'NA',
      row.SHIP_TO_CUSTOMER || 'NA',
      row.END_CUSTOMER || 'NA'
    ])
  }
}
