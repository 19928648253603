<!-- mq container -->
<div ouxDeviceDetector [ngClass]="{ 'interface' : true, '--fullwidth' : !showSideNav, '--has-footer' : showFooter }">

  <!-- primary header container -->
  <app-header></app-header>

  <!-- generic container -->
  <app-shelf></app-shelf>

  <!-- primary content container -->
  <app-body>

    <!-- primary breadcrumbs container -->
    <app-breadcrumbs></app-breadcrumbs>

    <!-- primary content outlet -->
    <router-outlet></router-outlet>

  </app-body>

  <!-- global drawer container -->
  <oux-drawer>
    <oux-drawer-header></oux-drawer-header>
  </oux-drawer>

  <!-- global toast container -->
  <oux-toast></oux-toast>

  <!-- global modal container -->
  <oux-modal></oux-modal>

  <!-- global status loader -->
  <oux-loader 
    [label]="'Crunching the latest...'"
    [show]="isLoading" 
    [themeOverrides]="{ 'backgroundColor': 'rgba(var(--background-rgb), .6)' }">
  </oux-loader>

</div>
