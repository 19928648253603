import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { OuxThemeType, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { OrdersSearchOrder } from 'src/app/shared/models/interface/partials/orders-search-order';
import { Transactions } from 'src/app/shared/models/interface/partials/transactions';
import { OrderLinesSummary } from '../../models/interface/partials/order-lines-summary';
import { QuotaDetailsStore } from '../../stores/quota-details.store';
import { TransactionLineDetailsGridViewModel } from './transaction-line-details-grid.view-model';

@Component({
  selector: 'transaction-line-details-grid',
  templateUrl: './transaction-line-details-grid.component.html',
  styleUrls: ['./transaction-line-details-grid.component.scss'],
  host: {
    'class': 'transaction-line-details-grid'
  }
})
export class TransactionLineDetailsGridComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  public isMulti: boolean = false;

  public infoTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right,
    theme: OuxThemeType.Background
  });

  @Input('transactions')
  public transactions: TransactionLineDetailsGridViewModel[] = [];

  @Input('transactionType')
  public transactionType: string;

  @Input('selectedOrderLine') 
  public selectedOrderLine: OrderLinesSummary;

  @Input('selectedOrder')
  public selectedOrder: OrdersSearchOrder;

  constructor (
    private quotaDetailsStore: QuotaDetailsStore
  ) {
    
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOrder) {
      this.isMulti = this.quotaDetailsStore.isMulti(this.selectedOrder?.QUOTA_ID, this.selectedOrder?.COMP_PLAN_ID);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }
}
