import { DirectOrders }             from "../../interface/partials/direct-orders";

export class DirectOrdersModel implements DirectOrders {
  public AMP_IDENTIFIER: string;
  public ANNUAL_VALUE: any;
  public AT_LEVEL_1: string;
  public AT_LEVEL_2: string;
  public AT_LEVEL_3: string;
  public AT_LEVEL_4: string;
  public BACKLOG: any;
  public BILL_TO_CUSTOMER: string;
  public BONUS_PLAN_CODE: string;
  public BOOKING: any;
  public BP_FLAG: string;
  public BUSINESS_UNIT: string;
  public BUYING_PROGRAM_NAME: string;
  public COMMENTS: string;
  public COMM_LINE_TYPE: any;
  public COMM_REVENUE: number;
  public CONTRACT_END_DATE: number;
  public CONTRACT_NUMBER: number;
  public CONTRACT_START_DATE: number;
  public CONTRACT_TERM: any;
  public DEAL_ID: number;
  public DISTRIBUTOR_NAME: string;
  public ELA_FLAG: string;
  public END_CUSTOMER: string;
  public EXTD_MY_PAYOUT_AMOUNT: number;
  public LINE_CREATION_DATE: number;
  public LINE_ID: number;
  public METRIC_TYPE: string;
  public MULTI_FLAG: string;
  public MYPEFLAG: string;
  public MY_PAYOUT_AMOUNT: any;
  public MY_PAYOUT_FACTOR: any;
  public MY_PAYOUT_UPLIFT_FACTOR: any;
  public OFFER_NAME: string;
  public OFFER_TYPE: any;
  public OFFER_TYPE_NAME: string;
  public ORDER_NUMBER: number;
  public PART_NUMBER: string;
  public PO_NUMBER: string;
  public PRODUCT_FAMILY: string;
  public PRODUCT_SPLIT_PERCENT: number;
  public QUANTITY: number;
  public RESELLER_NAME: string;
  public REV_MULTIPLIER_FACTOR: number;
  public SAAS_FLAG: string;
  public SALES_MOTION: string;
  public SALES_VALUE: number;
  public SALES_VALUE_MULTIPLIED: number;
  public SELL_TYPE: any;
  public SEQUENCE_ID: number;
  public SERVICE_TYPE: string;
  public SHIP_TO_CUSTOMER: string;
  public SOURCE_TRANSACTION_DATE: number;
  public SPLIT_AGENT: string;
  public SPLIT_EFFECTIVE_DATE: number;
  public SPLIT_PERCENT: number;
  public TERRITORY_CODE: any;
  public TOTAL_VALUE: number;
  public TRANSACTION_CREATION_DATE: number;
  public TRANSACTION_DATE: number;
  public TRANSACTION_NUMBER: string;
  public TRANS_DAILY_SUMM_ID: number;
  public TRANS_GROUP_CODE: number;
  public WPA_FLAG: string;
  public TERRITORY: string;
  public NEW_LOGO_AMOUNT: number;
  public UPSELL_AMOUNT: number;
  public CROSS_SELL_AMOUNT: number;
  public IACV_AMOUNT: number;
  public RENEWED_AMOUNT: number;
  public NON_COMM_BOOKING: number;
  public TYPE: string;
  public MANUAL_ADJ: number;
  public isRevExpanded: any;
  public isIacvExpanded: any;
  public PAY_PERIOD: string;
  public DISPLAY_WEEK: string;
  public CREATION_DATE: number;
  public CURRENCY_CODE: string;

  constructor(args : Partial<DirectOrdersModel>) {
    Object.assign(this, args);
  }

}
