import { GTCAdjustments }                   from "../../interface/partials/gtc-adjustments";

export class GTCAdjustmentsModel implements GTCAdjustments {
  public commBooking: number;
  public commRevenueAmt: number;
  public pec: string;
  public category: string;
  public TERRITORYCODE: string;
  public NODENAMEDESC: string;

  constructor(args: Partial<GTCAdjustmentsModel>) {
    Object.assign(this, args);
  }
}
