import { 
  HttpClient, 
  HttpHeaders, 
  HttpParams }                  from '@angular/common/http';
import { Injectable }           from '@angular/core';
import { 
  Observable, 
  of, 
  throwError }                  from 'rxjs';
import { 
  catchError,
  map, 
  switchMap, 
  tap }                         from 'rxjs/operators';
import { 
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService, 
  OuxConfigService, 
  OuxLoggerService }            from '@cisco/oux-common';
import { UserDetailsStore }     from '../stores/user-details.store';
import { MetadataResponse }     from '../models/interface/response/metadata-response';
import { Metadata }             from '../models/interface/partials/metadata';
import { MetadataModel }        from '../models/concrete/partials/metadata.model';
import { MetadataStore }        from '../stores/metadata.store';



@Injectable({ providedIn: 'root' })
export class MetadataService {

  private baseUri: string;
  private mataDataUri: string;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('MatadataService');

  constructor(private http: HttpClient,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private ouxLoggerSvc: OuxLoggerService,
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore) {


    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.mataDataUri = this.ouxConfigSvc.getAppConfigValue('apiUri').metaData;
  }

  public fetchData(impersonationUserId: string): Observable<Metadata> {

    const URL = `${this.baseUri}${this.mataDataUri}`;
    const OPTIONS = this.getOptions();

    if (impersonationUserId) {
      OPTIONS.params = OPTIONS.params.set('userName', impersonationUserId);
    }

    const REQUEST$ = this.http.get<MetadataResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response : MetadataResponse) => {
          // verify all manditory keys are present
          if (!response.data || !response.data.P_EMPINFO) {
            return {};
          }
          
          let typed = new MetadataModel(response.data)

          return typed;
        }),
        tap( (typed : Metadata) => {
          this.ouxLoggerSvc.logDebug('MatadataService: fetchData()', typed);
          // pass data to corresponding payment details store for further processing
          this.metadataStore.setMetadata(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchData', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
   private getOptions() : { headers: HttpHeaders, params : HttpParams } {

    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loginId', this.userDetailsStore.getUserId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
    };

    return OPTIONS;
  }


}