<direct-transaction-lines-header [isNotEligibleOrder]="isNotEligibleOrder" [orderType]="orderType"></direct-transaction-lines-header>

<section class="table-controls">
  <oux-pagination
    [ngClass]="(paginationOptions.totalRecords > paginationOptions.pageSize) ? 'fadeIn animated' : 'fadeIn animated'"
    [options]="paginationOptions"
    (onPageChange)="pageChange.next($event)">
    <ng-template [ouxPaginationRange] let-range>
      Showing {{ range.lower | number:'1.0-0' }} - {{ range.upper | number:'1.0-0' }} of {{ range.total | number:'1.0-0' }} {{ range.total == 1 ? 'line' : 'lines' }}
    </ng-template>
  </oux-pagination>

  <ng-container *ngIf="(paginationOptions.totalRecords > 0)">
    <direct-transaction-lines-summary-table-export [isNotEligibleOrder]="isNotEligibleOrder" 
    [notEligibleRequest]="notEligibleExportRequest" [request]="exportRequest" [orderType]="orderType"></direct-transaction-lines-summary-table-export>
  </ng-container>
</section>

<direct-transaction-lines-summary-table></direct-transaction-lines-summary-table>

