import { OrdersMetricHeaderDetailsRequest } from '../../interface/request/orders-metric-header-details-request';

export class OrdersMetricHeaderDetailsRequestModel implements OrdersMetricHeaderDetailsRequest {

  public loginId: string;
  public userId: string;
  public employeeId: string;
  public metricId: number;
  public sourceSystem: string;
  public goalId: number;
  public quotaId: number;
  public searchKey: string;
  public searchValue: string;
  public sourcePage: string;
  public peType: string;
  public myFlag: string;
  public metricFlag: string;
  public ampIdentifier: string;
  public type: string;



  constructor(args: Partial<OrdersMetricHeaderDetailsRequestModel>) {
    Object.assign(this, args);
  }

}
