import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'statusFilter',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})
/**
 * Filters on inbound resource, creating a new array based of elements
 * which pass the supplied conditional parameter.
 * 
 * Ex: Create new array based on those elements which have a status value of 'active'
 *  <li class="item" *ngFor="let item of items | statusFilter : 'active'">
 */
export class StatusFilterPipe implements PipeTransform {

  transform(items : Array<any>[], filterBy : string) : Array<any>[] {
    return items.filter((item : any) => item.status.toLocaleLowerCase() === filterBy);
  }

}
