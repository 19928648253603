import { Injectable }                               from '@angular/core';
import { BehaviorSubject, Observable }                          from 'rxjs';
import { IacvWeeklyDetailsFilterModel } from './models/iacv-weekly-details-filter.model';
import { 
  OuxStore,
  OuxLoggerService }              from '@cisco/oux-common';
import { IacvSavDetailsFilterModel } from '../goal-to-cash-iacv-transactions/models/iacv-sav-details-filter.model';


@Injectable({
  providedIn: 'root'
})
export class GoalToCashIacvTransactionsWeeklyStore{
  
  private iacvWeeklyDetailsFilterDataSubject = new BehaviorSubject<IacvWeeklyDetailsFilterModel>(null);
  public iacvWeeklyDetailsFilterData$ = this.iacvWeeklyDetailsFilterDataSubject.asObservable();

  constructor() { }

  public setIacvWeeklyDetailsFilterData(value: IacvWeeklyDetailsFilterModel | IacvSavDetailsFilterModel):void {
      this.iacvWeeklyDetailsFilterDataSubject.next(value);
  }

  /**
   * Clear selected Goalsheet
   * ============================================================
   */
   public clearIacvWeeklyDetailsFilterData(): void {
    this.iacvWeeklyDetailsFilterDataSubject.next(null);
  }

}