import { OrderLinesSummary }                   from "../../interface/partials/order-lines-summary";

export class OrderLinesSummaryModel implements OrderLinesSummary {

  public ATCCV_FLAG: any;
  public BILL_TO_CUSTOMER: string;
  public BONUS_PLAN_CODE: string;
  public BOOKING_AMT: number;
  public BOOKING_SOURCE: any;
  public BOOK_TYPE: string;
  public BP_FLAG: string;
  public BUSINESS_UNIT: string;
  public BUYING_PROGRAM_NAME: string;
  public COMM_BACKLOG: number;
  public COMM_BOOKING: number;
  public COMM_REVENUE: number;
  public CONTRACT_ACCEPTED_DATE: string;
  public CONTRACT_NUMBER: number;
  public CONTRACT_START_DATE: number;
  public DISTRIBUTOR_NAME: string;
  public ELA_FLAG: string;
  public END_CUSTOMER: string;
  public EXTD_MY_PAYOUT_AMOUNT: number;
  public GS_CURRENCY_CODE: string;
  public LA_SELL_TYPE: string;
  public LINE_CREATION_DATE: number;
  public LINE_ID: number;
  public MYPEFLAG: string;
  public MY_PAYOUT_AMOUNT: number;
  public MY_PAYOUT_FACTOR: number;
  public NON_COMM_BKG_AMT: number;
  public OFFER_NAME: string;
  public OFFER_TYPE_NAME: string;
  public ORDER_NUMBER: number;
  public PLAN_TITLE: string;
  public PRODUCT_FAMILY: string;
  public REV_MULTIPLIER_FACTOR: number;
  public SAAS_FLAG: string;
  public SALES_VALUE_MULTIPLIED: number;
  public SEQUENCE_ID: number;
  public SHIP_TO_CUSTOMER: string;
  public TCV_SALES_VALUE: any;
  public TERM: any;
  public WPA_FLAG: any;
  public TOTAL_VALUE: number;
  public ANNUAL_VALUE: number;
  public SOURCE_TRANSACTION_DATE: any;
  public CURRENCY_CODE: string;
  public CONTRACT_TERM: string;
  public OFFER_PART_NUMBER: string;
  public PART_NUMBER: string;
  public TERRITORY_CODE: string;
  public TERRITORY_TYPE_CODE: string;
  public NODE_LEVEL_2: string;
  public NODE_LEVEL_3: string;
  public NODE_LEVEL_4: string;
  public NODE_LEVEL_5: string;
  public NODE_LEVEL_6: string;
  public AT_LEVEL_1: string;
  public AT_LEVEL_2: string;
  public AT_LEVEL_3: string;
  public AT_LEVEL_4: string;
  public DEAL_ID: number;
  public PO_NUMBER: number;
  

  constructor(args : Partial<OrderLinesSummaryModel>) {
    Object.assign(this, args);

    this.REV_MULTIPLIER_FACTOR = this.REV_MULTIPLIER_FACTOR ? this.REV_MULTIPLIER_FACTOR : 0;
  }

  public get COMMISSION_STATUS() : string {
    let status = '';

    if (this.COMM_REVENUE !== 0 && this.COMM_BACKLOG !== 0) {
      status = 'Pending backlog';
    } 
    else if (this.COMM_REVENUE === 0 && this.COMM_BACKLOG !== 0) {
      status = 'Backlog';
    }
    else {
      status = 'Fully Credited';
    }

    return status;
  }

}