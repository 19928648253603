/* Angular Import */
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
/* Feature Imports */
import {
  OuxStore,
  OuxLoggerService,
  OuxModalService
} from '@cisco/oux-common';
import { PaymentsDateRangeFilterViewModel } from 'src/app/routes/payments/models/payments-date-range-filter.view-model';
import { PaymentsSearchSearchType } from 'src/app/routes/payments/models/payments-search-search-type.enum';
import { PaymentTransactionsDetailsModalComponent } from 'src/app/routes/payments/routes/payments-transactions/partials/payments-transactions-details-modal/payments-transactions-details-modal.component';
import { PaymentLinesModel } from '../models/concrete/partials/payment-lines.model';
import { DirectOrders } from '../models/interface/partials/direct-orders';
import { PaymentAdjustmentLines } from '../models/interface/partials/payment-adjustment-lines';
import { PaymentLines } from '../models/interface/partials/payment-lines';

/**
 * Creates our PaymentLines State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class PaymentLinesStore extends OuxStore<PaymentLines> {

  /**
   * Stage action stream to enable multicast strategy
   */
  private selectedTransactionSubject : BehaviorSubject<DirectOrders>;
  private adjustmentDetailsSubject = new BehaviorSubject<PaymentAdjustmentLines>(null);
  private lastSearchTypeSelectedSubject = new BehaviorSubject<PaymentsSearchSearchType>(null);
  private lastTerritoryTypeSelectedSubject = new BehaviorSubject<any>(null);

  /**
   * Stand up our transactions action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  public paymentLines$ = this.state$;
  public paymentLinesDateRanges$ = this.paymentLines$
    .pipe(
      map(lines => lines && lines.P_DATE_RANGE ? lines.P_DATE_RANGE : []),
      map(ranges => ranges.map(range => new PaymentsDateRangeFilterViewModel({
        week: range.week,
        startDate: range.startDate ? moment(range.startDate) : null,
        endDate: range.endDate ? moment(range.endDate) : null
      })))
    );

  public adjustmentDetails$ = this.adjustmentDetailsSubject.asObservable();
  public lastSearchTypeSelected$ = this.lastSearchTypeSelectedSubject.asObservable();
  public lastTerritoryTypeSelected$ = this.lastTerritoryTypeSelectedSubject.asObservable();

  /**
   * Stand up our selected transaction action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  public selectedTransaction$ : Observable<DirectOrders>;

  constructor(private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService) {

    super (new PaymentLinesModel({}));

    this.selectedTransactionSubject = new BehaviorSubject(null);
    this.selectedTransaction$ = this.selectedTransactionSubject.asObservable();
  }

  public setLines(lines: PaymentLines) : void {

    super.setState(lines);
    this.ouxLoggerSvc.logDebug('PaymentLinesStore - PaymentLines:', this.state);
  }

  public setAdjustmentDetails(adjustmentDetails: PaymentAdjustmentLines): void {
    this.adjustmentDetailsSubject.next(adjustmentDetails);
  }

 /**
   * Sets the selected transaction order number when user clicks
   * on so_number or web oerder id located Payment Transactions Summary view
   * ============================================================
   */
  public setSelectedPaymentTransaction(transaction : DirectOrders) : void {
    if (transaction == undefined || transaction == null) {
      return;
    }

    this.selectedTransactionSubject.next(transaction);
    this.ouxLoggerSvc.logDebug('PaymentLinesStore: Selected Transaction =', this.selectedTransaction);
  }

  public showTransactionDetailsModal() : void {

    if (this.selectedTransaction) {
      this.ouxModalSvc.openModal(PaymentTransactionsDetailsModalComponent, this.selectedTransaction);
    }
  }

 /**
   * Sets the last selected search type via the PaymentTransactionsFilter component 
   * located on the Payment Transactions Summary view
   * ============================================================
   */
  public setSelectedSearchType(value: PaymentsSearchSearchType) : void {
    this.lastSearchTypeSelectedSubject.next(value);
    this.ouxLoggerSvc.logDebug('PaymentLinesStore: Last Selected Transaction Search Type =', this.lastSelectedSearchType);
  }

  public setSelectedTerritoryType(value) : void {
    this.lastTerritoryTypeSelectedSubject.next(value);
    this.ouxLoggerSvc.logDebug('PaymentLinesStore: Last Selected Transaction Territory Type =', this.lastSelectedTerritoryType);
  }

  /**
   * Clear Payment Lines Data Collection
   * ============================================================
   */
   public clearPaymentLinesData(): void {
    this.setState(null);
  }

  public clearLastSelectedSearchType(): void {
    this.lastSearchTypeSelectedSubject.next(null);
  }

  public clearLastSelectedTerritoryType(): void {
    this.lastTerritoryTypeSelectedSubject.next(null);
  }

  /**
   * Convenience Methods
   * ============================================================
   */

   public get selectedTransaction() : DirectOrders {
    return this.selectedTransactionSubject.getValue();
  }

  public get lastSelectedSearchType() : PaymentsSearchSearchType {
    return this.lastSearchTypeSelectedSubject.getValue();
  }

  public get lastSelectedTerritoryType() : any {
    return this.lastTerritoryTypeSelectedSubject.getValue();
  }


}