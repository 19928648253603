/* Angular Import */
import { Injectable } from '@angular/core';
/* Feature Imports */
import { map, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import {
  OuxStore,
  OuxLoggerService
} from '@cisco/oux-common';
import { Metadata } from '../models/interface/partials/metadata';
import { MetadataModel } from '../models/concrete/partials/metadata.model';
import { EmployeeInfo } from '../models/interface/partials/emp-info';
import { UserRoleType } from '../models/types/user-role-type.enum';
import { UserDetailsStore } from './user-details.store';


/**
 * Creates our Metadata State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class MetadataStore extends OuxStore<Metadata> {

  private drilldownEmployeeIdValue: string;

  private drilldownEmployeeEmail: string;

  private loggedInUserMetadata = new BehaviorSubject<Metadata>(null);
  public loggedInUserMetadata$ = this.loggedInUserMetadata.asObservable();

  public canUserImpersonate$ = this.state$
    .pipe(
      map(metadata => {//metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 && metadata.P_EMPINFO[0].empEmail === this.userDetailsStore.getUserId() && metadata.P_EMPINFO[0].changeUser === 'Y' ? true : false),
        let changeUser: boolean = true;
        /*
          return true;
        }*/
        /**
         * Condition to check Change User action even if application is being reloaded/Hard Reloaded
         */
        if (metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0) {
          if (metadata.P_EMPINFO[0].empEmail === this.userDetailsStore.getUserId() && (metadata.P_EMPINFO[0].changeUser !== 'Y')) {
            changeUser = false;
          }
          else {
            changeUser = true;
          }
        }
        return changeUser;
        //return metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 && metadata.P_EMPINFO[0].changeUser === 'Y' ? true : false
      }),
      tap(canImpersonate => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Can Impersonate:', canImpersonate))
    );

  public checkIfMetricPilotUser$ = this.state$
    .pipe(
      map(metadata => {
        let metricPilotUser: boolean = true;
        /*
          return true;
        }*/
        /**
         * Condition to check Metric Pilot User status even if application is being reloaded/Hard Reloaded
         */
        if (metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0) {
          if (metadata.P_EMPINFO[0].empEmail === this.userDetailsStore.getUserId() && (metadata.P_EMPINFO[0].metricPilotUser !== 'Y')) {
            metricPilotUser = false;
          }
          else {
            metricPilotUser = true;
          }
        }
        return metricPilotUser;

      }),
      tap(isMetricPilotUser => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Can Impersonate:', isMetricPilotUser))
    );

  /** To check if user have access to AMplifier table */
  public checkIfAmplifierUser$ = this.state$
    .pipe(
      map(metadata => {
        let showAmplifierUser: boolean = false;

        if (metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0) {
          if (metadata.P_EMPINFO[0].showAmplifiers === 'Y') {
            return showAmplifierUser = true
          }
        }

      }),
      tap(isShowAmplifierUser => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Can Impersonate:', isShowAmplifierUser))
    );

    /** To check if user can have access to Export All Link */
  public exportAllAccess$ = this.state$
  .pipe(
    map(metadata => {
      let exportAllAccess: boolean = false;

      if (metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0) {
        if (metadata.P_EMPINFO[0].exportAllAccess === 'Y') {
          return exportAllAccess = true
        }
      }

    }),
    tap(exportAllAccess => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Can Use Export All:', exportAllAccess))
  );

  // Stream looks specifically at the logged in users metadata not the user you are impersonating. Currently utilized by IsAdminGuard
  public isLoggedInUserAdmin$ = this.loggedInUserMetadata$
    .pipe(
      map(metadata => metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 && metadata.P_EMPINFO[0].roleType === 'Admin' ? true : false),
      tap(isAdmin => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Is Admin:', isAdmin))
    );

  // Stream is used to hide Manager Banner link for none Admin users
  public isUserAdmin$ = this.state$
    .pipe(
      map(metadata => metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 && metadata.P_EMPINFO[0].roleType === 'Admin' ? true : false),
      tap(showLink => this.ouxLoggerSvc.logDebug('MetadataStore: Show Manage Banner Link:', showLink))
    );

  // Stream is used to track the logged-in or impersonated user Role Type
  public userRole$ = this.state$
    .pipe(
      map(metadata => metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 ? metadata.P_EMPINFO[0].roleType as UserRoleType : null),
      tap(userRole => this.ouxLoggerSvc.logDebug('MetadataStore: User Role:', userRole))
    );

  // Stream is used to hide Team Goal Attainment link for General users
  public showTeamGoalAttainmentLink$ = this.state$
    .pipe(
      map(metadata => metadata && metadata?.P_EMPINFO && metadata?.P_EMPINFO.length > 0 && metadata?.P_EMPINFO[0].roleType === UserRoleType.GENERAL ? false : true),
      tap(showLink => this.ouxLoggerSvc.logDebug('MetadataStore: Show Team Goal Attainment Link:', showLink))
    );

  // Stream is used to capture program level external link assignment based on user type (i.e., Individual Contributor or Manager)
  public userIncentiveEstimatorLink$ = this.state$
    .pipe(
      map(metadata => metadata && metadata?.P_EMPINFO && metadata?.P_EMPINFO.length > 0 && metadata?.P_EMPINFO[0].commLink ? metadata?.P_EMPINFO[0].commLink : 'http://wwwin.cisco.com/c/cec/organizations/sales/salescomp/salescomp-individual-plans.html'),
      tap(link => this.ouxLoggerSvc.logDebug('MetadataStore: User Incentive Estimator Link:', link))
    );

  public userFullName$ = this.state$
    .pipe(
      map(metadata => metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 ? metadata.P_EMPINFO[0]?.emp_name : null),
      tap(userName => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Full Name:', userName))
    );

  public userId$ = this.state$
    .pipe(
      map(metadata => metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 ? metadata.P_EMPINFO[0]?.empEmail : null),
      tap(userId => this.ouxLoggerSvc.logDebug('MetadataStore: Logged-in User Id:', userId))
    );

  public banners$ = this.state$
    .pipe(
      map(metadata => metadata?.P_BANNERMSG || [])
    );

  // user banner and user banner flag observable
  public userBanners$ = this.state$
    .pipe(
      map(metadata => metadata?.P_USER_BANNERMSG.length > 0 ? metadata?.P_USER_BANNERMSG[0].message || '' : false)
    );

  public userBannersFlag$ = this.state$
    .pipe(
      map(metadata => metadata?.P_USER_BANNERMSG.length > 0 ? true : false)
    );

  constructor(private ouxLoggerSvc: OuxLoggerService,
    private userDetailsStore: UserDetailsStore) {

    super(new MetadataModel({}));
  }

  /**
   * ============================================================
   */
  public setMetadata(data: Metadata): void {

    super.setState(data);
    this.ouxLoggerSvc.logDebug('MetadataStore: Metadata Model State:', this.state);
  }

  public setDrilldownUserEmployeeId(employeeId: string): void {
    this.drilldownEmployeeIdValue = employeeId;
  }

  public setDrilldownUserEmployeeEmail(empEmail: string): void {
    this.drilldownEmployeeEmail = empEmail;
  }

  public setLoggedInUserMetadata(metadata: Metadata): void {
    this.loggedInUserMetadata.next(metadata);
  }

  /**
   * Return User Details
   */
  public getMetadata(): Metadata {
    return this.state;
  }

  public getMetadataEmployeeInfo(): EmployeeInfo {
    return this.getMetadata()?.P_EMPINFO[0] || null;
  }

  public getMetadataEmployeeId(): string {
    return this.drilldownEmployeeIdValue || this.getMetadataEmployeeInfo()?.employeeID;
  }

  public getMetadataEmployeeEmail(): string {
    return this.drilldownEmployeeEmail || this.getMetadataEmployeeInfo()?.empEmail;
  }

  /**
   * Clear MetaData Collection
   * ============================================================
   */
  public clearMetaData(): void {
    this.setState(null);
  }



}
