/* Angular Import */
import {
  Component,
  OnInit,
  Output,
  EventEmitter, 
  Input}                                              from '@angular/core';
import {  
  combineLatest,
  EMPTY,
  Observable, 
  Subscription }                                              from 'rxjs';
import { 
  delay,
  map, 
  tap,
  finalize,
  catchError }                                                       from 'rxjs/operators';
/* OneUX Imports */
import { 
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxTooltipOptions,
  OuxTooltipPosition,
  OuxThemeType,
  OuxColumnSettings, 
  OuxLoggerService, 
  OuxModalService }                                           from '@cisco/oux-common';
/* Feature Imports */
import { OrderStore }                                         from 'src/app/shared/stores/order.store';
import { OrderLinesStore }                                    from 'src/app/shared/stores/order-lines.store';
import { LoaderService }                                      from 'src/app/core/services/loader.service';
import { LoadingType }                                        from 'src/app/shared/models/constants/loading-type.enum';
import { XaasOrderLinesSummary }                              from 'src/app/shared/models/interface/partials/xaas-order-lines-summary';
import { XaasOrderLineDetails }                               from 'src/app/shared/models/interface/partials/xaas-order-line-details';
import { XaasOrderLineDetailsModel }                          from 'src/app/shared/models/concrete/partials/xaas-order-line-details.model';
import { XaasTransactionLineDetailsModalComponent }           from '../xaas-transaction-line-details-modal/xaas-transaction-line-details-modal.component';
import { ActivatedRoute }                                     from '@angular/router';
import { QuotaDetailsStore }                                  from 'src/app/shared/stores/quota-details.store';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';
import { OrderLinesSummary }                              from 'src/app/shared/models/interface/partials/order-lines-summary';
import { OrderService } from 'src/app/shared/services/order.service';




@Component({
  selector: 'xaas-transaction-lines-summary-table',
  templateUrl: './xaas-transaction-lines-summary-table.component.html',
  styleUrls: ['./xaas-transaction-lines-summary-table.component.scss'],
  host: {
    'class': 'xaas-transaction-lines-summary-table'
  }
})
export class XaasTransactionLinesSummaryTableComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];
  private topSkuData: XaasOrderLinesSummary[] = [];
  private topSkuWithSubSku: XaasOrderLinesSummary[] = [];

  private isMulti$ = this.route.queryParamMap
    .pipe(
      map(params => {
        let allocationId = params.has('allocationId') ? parseFloat(params.get('allocationId')) : null;
        let planId = params.has('planId') ? parseFloat(params.get('planId')) : null;

        return allocationId && planId ? this.quotaDetailsStore.isMulti(allocationId, planId) : false;
      }),
      tap(isMulti => this.isMulti = isMulti)
    );


  @Output('showExportCTA')
  public emitExportCondition : EventEmitter<boolean> = new EventEmitter();

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  public isMulti: boolean = false;
  public hasMYPEColumns: boolean = false;
  public ampIdentifier: string;
  public AmpIdentifierKey = AmpIdentifierKey;
  
  public tableOpts : OuxTableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--fixed-header --hover',
    formatCells: false,
    tableLayout: 'fixed',
    fixedHeight: '100%',
    loading: true,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'There are no order lines to display at this time.',
    trackRecordsProperty: 'OFFER_PART_NUMBER',
    highlightSelectedRows: false,
    missingDataMessage: ' ',
    toggleSelected: false
  });

  public tableThemeOverrides : OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  private loading$ = this.loaderSvc.loading$.get(LoadingType.Table)
    .pipe(
      tap(loading => {
        this.tableOpts.loading = loading;
      })
    );

  public lines$: Observable<XaasOrderLinesSummary[]> = this.orderLinesStore.xaasOrderLines$
    .pipe(
      map(lines => lines && lines.records && lines.records.length > 0 ? lines.records : []),
      map(lines => this.filterOrderLines(lines)),
      delay(0),
      // need to update the total lines count in the header since we are manipulating the returned lines above
      tap(lines => {
        this.orderLinesStore.setXaasLinesCount(lines.length);

        if (lines.length > 0) {
          this.emitExportCondition.emit(true);
        }
        else {
          this.emitExportCondition.emit(false);
        }
      })
    );

  public columnSettings$ = this.lines$
    .pipe(
      map(lines => this.getColumnSettings(lines))
    );

  public tableData$ = combineLatest([this.columnSettings$, this.lines$])
    .pipe(
      map(([columnSettings, lines]) => ({columnSettings, lines}))
    );


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(
    private route: ActivatedRoute,
    private quotaDetailsStore: QuotaDetailsStore,
    private ouxLoggerSvc: OuxLoggerService,
    private ouxModalSvc: OuxModalService,
    private loaderSvc: LoaderService,
    private orderStore: OrderStore,
    private orderLinesStore: OrderLinesStore,
    private orderSvc: OrderService
  ) {
    this.subscriptions.push(this.isMulti$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.subscriptions.push(this.loading$.subscribe());
    this.ampIdentifier = this.route.snapshot.queryParamMap.has('category') ? this.route.snapshot.queryParamMap.get('category') : '';
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];

    // clean up selected order - prevents memory leaks
    this.orderStore.clearSelectedOrder();
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  public showOrderLineDetails(record: XaasOrderLinesSummary, event: any) : void {

    this.ouxLoggerSvc.logDebug(`XaasTransactionLinesSummaryTableComponent - ${record.OFFER_PART_NUMBER} Line Selected`, record.OFFER_PART_NUMBER);

    let lineDetails: XaasOrderLineDetails = new XaasOrderLineDetailsModel({
      ACTION: record.ACTION,
      REMAINING_TERM: record.REMAINING_TERM,
      TERM: record.TERM,
      RENEWAL_TERM: record.RENEWAL_TERM,
      SUBSCRIPTION_CODE: record.SUBSCRIPTION_CODE,
      SUBSCRIPTION_CREDIT: record.SUBSCRIPTION_CREDIT,
      SUB_REF_ID: record.SUB_REF_ID,
      IMRR: record.IMRR,
      MRR: record.MRR,
      BOOKING_RECOGNITION_TYPE: record.BOOKING_RECOGNITION_TYPE,
      METRIC_TYPE: record.METRIC_TYPE,
      CONTRACT_START_DATE: record.CONTRACT_START_DATE,
      CONTRACT_END_DATE: record.CONTRACT_END_DATE,
      SELL_TYPE: record.SELL_TYPE,
      TERM_BOUND_CREDIT: record.TERM_BOUND_CREDIT,
      PART_NUMBER: record.PART_NUMBER,
      OA_PERCENT: record.OA_PERCENT,
      BACKLOG_LINE: record.BACKLOG_LINE,
      BOOKING_AMT: record.BOOKING_AMT,
      COMM_REVENUE: record.COMM_REVENUE,
      REV_MULTI: record.REV_MULTI,
      REV_MULTIPLIER_FACTOR: record.REV_MULTIPLIER_FACTOR,
      TOTAL_SALES_VALUE: record.TOTAL_SALES_VALUE,
      COMM_BACKLOG: record.COMM_BACKLOG
    });

    let combinedLineDetails = new Object({
      selectedPartNumber: `${record.OFFER_PART_NUMBER}`,
      lineDetails: lineDetails,
      attributions: this.topSkuWithSubSku.filter(item => {
        return item.LINE_ID == record.LINE_ID && item.LINE_FLAG == record.LINE_FLAG;
      })
    });

    this.ouxModalSvc.openModal(XaasTransactionLineDetailsModalComponent, combinedLineDetails);
  }

  private filterOrderLines(lines: XaasOrderLinesSummary[]): XaasOrderLinesSummary[] {

    this.topSkuData = lines.filter(line => line.ATTRIB_FLAG == 'N');

    this.topSkuWithSubSku = lines.filter(line => line.ATTRIB_FLAG != 'N');

    let filteredLines = [...this.topSkuData];

    if (this.topSkuWithSubSku.length > 0) {
      let filtered = this.topSkuWithSubSku.filter((line, index) => {
        return this.topSkuWithSubSku.findIndex(x => x.LINE_ID === line.LINE_ID && x.LINE_FLAG === line.LINE_FLAG) === index;
      });
      
      filteredLines.push(...filtered);
    }

    filteredLines.forEach(line => {
      
      let filteredSubSkuData = this.topSkuWithSubSku.filter((item) => {
        return item.LINE_ID == line.LINE_ID;
      });

      if (!this.isMulti) {
        line.REV_MULTIPLIER_FACTOR = 1;
      }

      if (filteredSubSkuData.length > 0) {
        line.REV_MULTIPLIER_FACTOR = null;
      }
    });

    filteredLines = filteredLines.filter((line, index) => {
      return filteredLines.findIndex(x => x.ACTION === line.ACTION && x.OFFER_PART_NUMBER === line.OFFER_PART_NUMBER && x.LINE_FLAG === line.LINE_FLAG) === index;
    });

    return filteredLines;
  }

  private getColumnSettings(lines: XaasOrderLinesSummary[]): OuxColumnSettings[] {
    let columnSettings: OuxColumnSettings[] = [
      new OuxColumnSettings({
        header: 'Part Number',
        primaryKey: 'OFFER_PART_NUMBER'
      }),
      new OuxColumnSettings({
        header: 'Book Date',
        primaryKey: 'LINE_CREATION_DATE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'TCV',
        primaryKey: 'TOTAL_VALUE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Total Annual Bookings',
        primaryKey: 'ANNUAL_VALUE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Bookings',
        primaryKey: 'BOOKING_AMT',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Backlog',
        primaryKey: 'COMM_BACKLOG',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue (Original)',
        primaryKey: 'TOTAL_SALES_VALUE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue (Multiplied)',
        primaryKey: 'REV_MULTI',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue Multiplier',
        primaryKey: 'REV_MULTIPLIER_FACTOR',
        thAlign: 'right',
        tdAlign: 'right'
      })
    ];

    if (lines?.length > 0 && lines[0]?.MYPEFLAG == 'Y') {
      this.hasMYPEColumns = true;

      columnSettings.push(...[
        new OuxColumnSettings({
          header: 'Revenue (MY Payout) Eligible',
          primaryKey: 'MY_PAYOUT_AMOUNT',
          thAlign: 'right',
          tdAlign: 'right'
        }),
        new OuxColumnSettings({
          header: 'MY Uplift Factor',
          primaryKey: 'MY_PAYOUT_FACTOR',
          thAlign: 'right',
          tdAlign: 'right'
        })
      ]);
    }

    return columnSettings;
  }
}

