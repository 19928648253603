/* Angular Imports */
import {
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
/* OneUX Imports */
import {
  MODAL_DATA,
  OuxColumnSettings,
  OuxModalService,
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxThemeType,
  OuxTooltipOptions,
  OuxTooltipPosition
} from '@cisco/oux-common';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';


@Component({
  selector: 'user-banner-modal',
  templateUrl: './user-banner-modal.component.html',
  styleUrls: ['./user-banner-modal.component.scss'],
  host: {
    'class': 'user-banner-modal'
  }
})
export class UserBannerModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public tableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--compact',
    formatCells: false,
    tableLayout: 'fixed',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: '',
    trackRecordsProperty: '',
    highlightSelectedRows: false,
    missingDataMessage: '',
    toggleSelected: false
  });

  public columnSettings: OuxColumnSettings[] = [];

  public tableThemeOverrides: OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '100px',
      'max-width': '250px'
    },
    context: {
      code: null
    }
  });

  public infoTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right,
    theme: OuxThemeType.Background
  });

  public userBanners$ = this.metadataStore.userBanners$;

  public userFullName$ = this.metadataStore.userFullName$;

  public userId$ = this.metadataStore.userId$;


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxModalSvc: OuxModalService,
    private metadataStore: MetadataStore) {
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeModal(): void {
    this.ouxModalSvc.closeModal('Closed - User Banner Modal');
  }

  public linkify(message: string): string{
    var linkifiedString = message.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, '<a href="$1" target="_blank">$1</a> ')
    return linkifiedString;
  }

}


