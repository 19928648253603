import * as moment from 'moment';

export class PaymentsDateRangeFilterViewModel {
  public endDate: moment.Moment;
  public startDate: moment.Moment;
  public week: string;

  public display: string;

  constructor(args: Partial<PaymentsDateRangeFilterViewModel>) {
    Object.assign(this, args);

    this.display = `${this.week} (${this.startDate.format('DD-MMM-YYYY')} to ${this.endDate.format('DD-MMM-YYYY')})`;
  }

}