import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, Input, SimpleChanges } from '@angular/core';
import { OuxThemeType, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LoadingType } from 'src/app/shared/models/constants/loading-type.enum';
import { TransactionGroupCode } from 'src/app/shared/models/types/transaction-group-code.enum';
import { OrderLineTransactionsStore } from 'src/app/shared/stores/order-line-transactions.store';
import { OrderStore } from 'src/app/shared/stores/order.store';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';

@Component({
  selector: 'pos-transaction-details-header',
  templateUrl: './pos-transaction-details-header.component.html',
  styleUrls: ['./pos-transaction-details-header.component.scss'],
  host: {
    'class': 'pos-transaction-details-header'
  }
})
export class POSTransactionDetailsHeaderComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = true;
  public transactionGroupCodes = TransactionGroupCode;
  public AmpIdentifierKey = AmpIdentifierKey;
  
  private loading$ = this.loaderService.loading$.get(LoadingType.Table)
  .pipe(
    tap(loading => {
      this.isLoading = loading;
    })
  );

  public data$ = this.orderStore.selectedOrder$
    .pipe(
      map(order => {
        return {selectedOrder: order};
      })
    );

  public transactionsCount$ = this.orderLineTransactionsStore.orderLineTransactions$
    .pipe(
      map(transactions => transactions?.length || 0)
    );

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  @Output('onLoadTransactionGroup')
  public onLoadTransactionGroup = new EventEmitter<TransactionGroupCode>();

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  constructor(
    private orderStore: OrderStore,
    private orderLineTransactionsStore: OrderLineTransactionsStore,
    private loaderService: LoaderService
  ) {
    this.subscriptions.push(this.loading$.subscribe());
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public loadTransactionGroup(code: TransactionGroupCode): void {
    this.onLoadTransactionGroup.emit(code);
  }
}
