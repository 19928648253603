export const DEMO_USERS : Array<string> = [
  "dithiyag",
  "carmacdo",
  "jefbrews",
  "andrflor",
  "emperrin",
  "stgray",
  "erknauer",
  "ckallu",
  "katlarso",
  "maverhoe",
  "mzecchin",
  "ayde",
  "kuanfche",
  "nhagen",
  "nerconti",
  "dafleisc",
  //"vinakoll",
  //"imurray",
  "mtorchon",
  "cafoy",
  "ycha",
  "bcoonan",
  "tkamaraj",
  "kkowtha",
  "brenriqu",
  "mondeleo",
  "vabadgle",
  "morchon",
  "thernand",
  "mihays",
  "aaredden",
  "madelsmi",
  "shanliu2",
  "abondre",
  "jidibble",
  "robechu"
];
