import { Pipe, PipeTransform } from '@angular/core';

// implemenation found here https://medium.com/@thunderroid/angular-short-number-suffix-pipe-1k-2m-3b-dded4af82fb4
@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {
  transform(number: number, numberOfDecimalPlaces?: number): any {
    if (isNaN(number)) return null; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return 0;
    let abs = Math.abs(number);
    const rounder = Math.pow(100, 1);
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'k', value: 1000 }
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }

    let hasDecimal = abs % 1 != 0;
    let newValue = abs.toFixed(hasDecimal ? 1 : 0);

    if (numberOfDecimalPlaces != null) {
      if (hasDecimal) {
        numberOfDecimalPlaces = numberOfDecimalPlaces > 0 ? numberOfDecimalPlaces : 1;
      }

      newValue = abs.toFixed(numberOfDecimalPlaces);
    }

    return (isNegative ? '-' : '') + newValue + key;
  }
}