import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';
import { UserDetailsStore } from 'src/app/shared/stores/user-details.store';

@Component({
  selector: 'drill-down-user',
  templateUrl: './drill-down-user.component.html',
  styleUrls: ['./drill-down-user.component.scss'],
  host: {
    'class': 'drill-down-user',
    '[class.--show]': 'hasDrillDownUser'
  }
})
export class DrillDownUserComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions: Subscription[] = [];

  public isHovered: boolean = false;
  public drillDownUser: string = '';
  public hasDrillDownUser: boolean = false;
  public tooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right
  });

  public drillDownUser$ = this.userDetailsStore.drilldownUserChange$
    .pipe(
      tap(user => {
        this.hasDrillDownUser = user && user.userId ? true : false;
        this.drillDownUser = user && user.teamMemberNumber !== null ? "Team Member " + user.teamMemberNumber : user?.userName;
      })
    );

  constructor(
    private userDetailsStore: UserDetailsStore,
    private metadataStore: MetadataStore,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(this.drillDownUser$.subscribe());
  }

  public ngOnInit(): void {

  }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public removeDrillDownUser(): void {
    this.metadataStore.setDrilldownUserEmployeeId(null);
    this.metadataStore.setDrilldownUserEmployeeEmail(null);
    this.userDetailsStore.setDrilldownUser(null);

    // Get the current route URL
    if (this.router.url === '/goal-attainment') {
      this.router.navigate(['/goal-attainment'], {
        queryParams: { reload: new Date().getTime() }
      });
    }
    else{
      this.router.navigate(['/goal-attainment']);
    }
  }
}
