<ng-container *ngIf="requestParams">

  <metric-so-transaction-lines-header [requestParams]="requestParams"
    [totalRecords]="paginationOptions.totalRecords"></metric-so-transaction-lines-header>


  <section class="table-controls container-padding">
    <oux-pagination [options]="paginationOptions" (onPageChange)="search($event, this.lastSortUsed)">
      <ng-template [ouxPaginationRange] let-range>
        Showing {{ range.lower | number:'1.0-0' }} - {{ range.upper | number:'1.0-0' }} of {{ range.total |
        number:'1.0-0' }} {{ range.total == 1 ? 'order' : 'orders' }}
      </ng-template>
    </oux-pagination>

    <ng-container *ngIf="(paginationOptions.totalRecords > 0)">
      <metric-so-transaction-lines-summary-export
        [ngClass]="(paginationOptions.totalRecords > 0) ? '' : 'fadeIn animated'" [requestParams]="requestParams"
        [totalRecords]="paginationOptions.totalRecords">
      </metric-so-transaction-lines-summary-export>
    </ng-container>
  </section>

  <ng-container *ngIf=" metricSoTransactionSummaryDetails && metricSoTransactionSummaryDetails.length > 0 ">
    <metric-so-transaction-lines-summary [requestParams]="requestParams"
      [metricSoTransactionSummaryDetails]="metricSoTransactionSummaryDetails"
      (onSort)="search(this.lastPageUsed, $event)"></metric-so-transaction-lines-summary>
  </ng-container>

</ng-container>