import { Injectable } from '@angular/core';
import { OuxBreadcrumbModel } from '@cisco/oux-common';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsStore {
  private breadcrumbsSubject = new BehaviorSubject<OuxBreadcrumbModel[]>([]);

  public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor() {
    
  }

  public setBreadcrumbs(breadcrumbs: OuxBreadcrumbModel[]): void {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  //Append set of breadcrumbs to end of existing stored breadcrumbs
  public addBreadcrumbs(breadcrumbs: OuxBreadcrumbModel[]): void {
    this.breadcrumbs$
      .pipe(
        take(1),
        tap(crumbs => {
          let newCrumbs = [...crumbs, ...breadcrumbs];

          this.breadcrumbsSubject.next(newCrumbs);
        })
      )
      .subscribe();
  }

  public getBreadcrumbs(): OuxBreadcrumbModel[] {
    let breadcrumbs: OuxBreadcrumbModel[];
    this.breadcrumbs$
      .pipe(
        take(1),
        tap(crumbs => {
          breadcrumbs=crumbs;
        })
      ).subscribe();
      return breadcrumbs;
  }

  public removeBreadcrumbs():void {
    this.breadcrumbs$
      .pipe(
        take(1),
        tap(crumbs => {
          crumbs.pop();
          this.breadcrumbsSubject.next(crumbs);
        })
      )
      .subscribe();
  }
}