import { UserList }                   from "../../interface/partials/user-list";

export class UserListModel implements UserList {

  public emailId: string;
  public fullName: string;

  constructor(args : Partial<UserListModel>) {
    Object.assign(this, args);
  }

}