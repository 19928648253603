<ng-container *ngIf="data">

  <oux-modal-content-container>
    <oux-modal-content-header [setOptions]="{ icon: 'icon-tags', heading: data?.selectedPartNumber + ' - Part Information' }"></oux-modal-content-header>
    <oux-modal-content-article>

        <ng-container *ngIf="data; else exceptionTemplate">
          <section class="grid">
      
            <div class="grid__column brd-none">
              <div class="column__row">
                <span class="label">Action</span>
                <span class="value">{{ data?.lineDetails.ACTION }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Remaining Term</span>
                <span class="value">{{ data?.lineDetails.REMAINING_TERM | number:'1.2-2' }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Initial Term</span>
                <span class="value">{{ data?.lineDetails.TERM | number:'1.2-2' }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Renewal Term</span>
                <span class="value">{{ data?.lineDetails.RENEWAL_TERM | number:'1.2-2' }}</span>
              </div><!-- /column__row -->
            </div>
        
            <div class="grid__column">
              <div class="column__row">
                <span class="label">Subscription Credit</span>
                <span class="value --with-padding">{{ data?.lineDetails.SUBSCRIPTION_CREDIT | number:'1.2-2' }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Subscription Code</span>
                <span class="value --with-padding">{{ data?.lineDetails.SUBSCRIPTION_CODE }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Subscription Reference</span>
                <span class="value --with-padding">{{ data?.lineDetails.SUB_REF_ID }}</span>
              </div><!-- /column__row -->
            </div>
        
            <div class="grid__column">
              <div class="column__row">
                <span class="label">IMRR</span>
                <span class="value">{{ data?.lineDetails.IMRR | number:'1.2-2'  }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">MRR</span>
                <span class="value">{{ data?.lineDetails.MRR | number:'1.2-2' }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Booking Recognition Type</span>
                <span class="value">{{ data?.lineDetails.BOOKING_RECOGNITION_TYPE }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Metric Type</span>
                <span class="value">{{ data?.lineDetails.METRIC_TYPE === 'ACV' ? 'Annual' : data?.lineDetails.METRIC_TYPE }}</span>
              </div><!-- /column__row -->
            </div>
        
            <div class="grid__column">
              <div class="column__row">
                <span class="label">Contract Start Date</span>
                <span class="value">{{ data?.lineDetails.CONTRACT_START_DATE | dateFormat  }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Contract End Date</span>
                <span class="value">{{ data?.lineDetails.CONTRACT_END_DATE | dateFormat }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Sales Motion</span>
                <span class="value">{{ data?.lineDetails.SELL_TYPE }}</span>
              </div><!-- /column__row -->
              <div class="column__row">
                <span class="label">Term Bound Credit</span>
                <span class="value">{{ data?.lineDetails.TERM_BOUND_CREDIT == null ? 'Not Available' : data?.lineDetails.TERM_BOUND_CREDIT }}</span>
              </div><!-- /column__row -->
            </div>
        
          </section>

          <section class="line-details">
            <div class="line-details-header">
              <h4>Sales Value Offer Attribution</h4>

              <a class="link" href="https://salesconnect.cisco.com/#/content-detail/6f9bad43-6165-449e-975c-2d6cb20a3bf2" target="_blank">View Information on Calculation Methods</a>  
            </div>

            <oux-table 
              [records]="data?.attributions || []"
              [options]="tableOptions"
              [settings]="columnSettings"
              [themeOverrides]="tableThemeOverrides">

              <ng-template [ouxHeader]="'PART_NUMBER'">
                <div class="pad-left-sm">SKU</div>
              </ng-template>
            
              <ng-template [ouxHeader]="'OA_PERCENT'">
                <div class="custom-header">% of Total</div>
                <div class="custom-header --desc">PART NUMBER SALES VALUE</div>
              </ng-template>

              <ng-template [ouxHeader]="'REV_MULTI'">
                  Revenue (Multiplied)
                  <i  
                    class="icon-info-outline"
                    [ouxTooltip]="revenueDescTemplate"
                    [ouxTooltipOptions]="templateTooltipOpts">
                  </i>
                  <ng-template #revenueDescTemplate>
                    <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
                  </ng-template>
              </ng-template>

              <ng-template [ouxHeader]="'REV_MULTIPLIER_FACTOR'">
                <div [ngClass]="{ 'pad-right-sm': !hasMYPEColumns }" class="ellipsis">Revenue Multiplier</div>
              </ng-template>

              <ng-template [ouxHeader]="'MY_PAYOUT_FACTOR'">
                <div class="pad-right-sm ellipsis">MY Uplift Factor</div>
              </ng-template>

              <!-- start custom columns -->

              <ng-template [ouxColumn]="'PART_NUMBER'" let-record="record">
                <div class="pad-left-sm --wrap">
                  {{ record?.PART_NUMBER }}
                </div>
              </ng-template>

              <ng-template [ouxColumn]="'OA_PERCENT'" let-record="record">
                {{(((record?.SPLIT_SALES_VALUE + record?.BACKLOG_LINE) / (data?.lineDetails?.TOTAL_SALES_VALUE + data?.lineDetails?.COMM_BACKLOG)) * 100) | number:'1.2-2'}}
                <!-- {{record?.OA_PERCENT | number:'1.2-2' }} -->
              </ng-template>

              <ng-template [ouxColumn]="'BOOKING_AMT'" let-record="record">
                {{ isNotEligibleOrder ? 'NA' : ((record?.SPLIT_SALES_VALUE + record?.BACKLOG_LINE) | number:'1.2-2') }}
                <!-- {{record?.BOOKING_AMT | number:'1.2-2' }} -->
              </ng-template>

              <ng-template [ouxColumn]="'BACKLOG_LINE'" let-record="record">
                {{ (record?.MYPEFLAG === 'Y' || isNotEligibleOrder) ? 'NA' : (record?.BACKLOG_LINE | number:'1.2-2') }}
              </ng-template>

              <ng-template [ouxColumn]="'SPLIT_SALES_VALUE'" let-record="record">
                {{record?.SPLIT_SALES_VALUE | number:'1.2-2' }}
              </ng-template>

              <ng-template [ouxColumn]="'REV_MULTI'" let-record="record">
                <ng-container *ngIf="isMulti">
                  {{record?.SALES_VALUE_MULTIPLIED | number:'1.2-2'}}
                </ng-container>
                <ng-container *ngIf="!isMulti">
                  {{record?.SPLIT_SALES_VALUE | number:'1.2-2'}}
                </ng-container>
              </ng-template>

              <ng-template [ouxColumn]="'REV_MULTIPLIER_FACTOR'" let-record="record">
                <div [ngClass]="{ 'pad-right-sm': !hasMYPEColumns }" class="ellipsis">{{ record?.REV_MULTIPLIER_FACTOR == 0 ? '' : record?.REV_MULTIPLIER_FACTOR }}</div>
              </ng-template>

              <ng-template [ouxColumn]="'MY_PAYOUT_AMOUNT_1'" let-record="record">
                <ng-container *ngIf="record?.MYPEFLAG == 'Y' && isMulti">
                  {{record?.EXTD_MY_PAYOUT_AMOUNT_1 | number:'1.2-2'}}
                </ng-container>

                <ng-container *ngIf="record?.MYPEFLAG == 'Y' && !isMulti">
                  {{record?.MY_PAYOUT_AMOUNT_1 | number:'1.2-2'}}
                </ng-container>
              </ng-template>

              <ng-template [ouxColumn]="'MY_PAYOUT_FACTOR'" let-record="record">
                <div *ngIf="record?.MYPEFLAG == 'Y' && record?.SPLIT_SALES_VALUE != 0 && record?.MY_PAYOUT_FACTOR != 0" class="pad-right-sm ellipsis">
                  {{record?.MY_PAYOUT_FACTOR | number:'1.2-2'}}
                </div>
              </ng-template>
            </oux-table>
          </section>
        </ng-container>

        <ng-template #exceptionTemplate>
          <p>No additional information at this time.</p>
        </ng-template>
    
      <div class="footer">
        <oux-button 
          [options]="{ label : 'Close', style : 'flat', dataSelector : 'lines-transactions-modal-oJVlEZeAp7' }" 
          (trigger)="closeModal()"> 
        </oux-button> 
      </div>

    </oux-modal-content-article>
  </oux-modal-content-container>

</ng-container>