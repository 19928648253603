import { Injectable }             from '@angular/core';
import { UserDetailsStore }       from 'src/app/shared/stores/user-details.store';

// tslint:disable-next-line: ban-types
declare let gtag : Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private userDetailsStore : UserDetailsStore) {}

  // Ref: https://medium.com/@PurpleGreenLemon/how-to-properly-add-google-analytics-tracking-to-your-angular-web-app-bc7750713c9e
  public eventEmitter(
    eventName : string,
    eventCategory : string,
    eventLabel : string = null,
    eventValue : number = null
  ) {
    gtag('event', eventName, {
      event_category: eventCategory,
      // event_label: `${eventLabel} - ${this.userDetailsStore.getUserId()}`, removed via TA1255314
      event_label: eventLabel,
      value: eventValue
    });
  }
}