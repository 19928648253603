/* Angular Import */
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit, 
  Output}                                              from '@angular/core';
import { 
  combineLatest, 
  Subscription }                                        from 'rxjs';
import { 
  map, 
  tap }                                                 from 'rxjs/operators';
import { 
  OuxLoggerService, 
  OuxThemeType, 
  OuxTooltipOptions, 
  OuxTooltipPosition }                                  from '@cisco/oux-common';
import { OrderStore }                                   from 'src/app/shared/stores/order.store';
import { LoaderService }                                from 'src/app/core/services';
import { LoadingType }                                  from 'src/app/shared/models/constants/loading-type.enum';
import { CreditMemoTransactionLinesAdjustmentStore }    from 'src/app/shared/stores/credit-memo-transaction-lines-adjustment.store';


@Component({
  selector: 'credit-memo-transaction-lines-header',
  templateUrl: './credit-memo-transaction-lines-header.component.html',
  styleUrls: ['./credit-memo-transaction-lines-header.component.scss'],
  host: {
    'class': 'credit-memo-transaction-lines-header'
  }
})
export class CreditMemoTransactionLinesHeaderComponent implements OnInit, OnDestroy {

  public hasOrderLinesData: boolean = false;

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////
  private subscriptions: Subscription[] = [];

  private loading$ = this.loaderService.loading$.get(LoadingType.Table)
  .pipe(
    tap(loading => {
      this.hasOrderLinesData = !loading;
    })
  );

  public data$ = combineLatest([
      this.orderStore.selectedOrder$,
      this.adjustmentsStore.creditMemoAdjustmentsCount$
    ])
    .pipe(
      tap(data => this.ouxLoggerSvc.logDebug('CreditMemoTransactionLinesHeaderComponent - Combined Order Lines Data Stream:', data)),
      map( ([order, count]) => ({ order, count }))
    );

  public currency$ = this.adjustmentsStore.creditMemoAdjustmentsCurrency$;

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  @Output('triggerTypeChange')
  public emitTypeChange : EventEmitter<string> = new EventEmitter();

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private orderStore: OrderStore,
              private loaderService: LoaderService,
              private adjustmentsStore: CreditMemoTransactionLinesAdjustmentStore,
              private ouxLoggerSvc: OuxLoggerService) {

    this.subscriptions.push(this.loading$.subscribe());
    this.subscriptions.push(this.currency$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit() : void {
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public triggerTypeChange(type: string) : void {
    this.emitTypeChange.emit(type);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

}
