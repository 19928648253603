import { Injectable }                       from '@angular/core';
import { 
  OuxLoggerService, 
  OuxStore }                                from '@cisco/oux-common';
import { BehaviorSubject } from 'rxjs';
import { BacklogTransactionsFilterViewModel } from 'src/app/routes/backlog/models/backlog-transactions-filter.view-model';
import { BacklogMetricParamsDetailsModel } from '../models/concrete/partials/backlog-metric-params-details.model';
import { BacklogDetails }                   from '../models/interface/partials/backlog-details';
import { PagedResponse }                    from '../models/interface/partials/paged-response';



@Injectable({
  providedIn: 'root'
})
export class BacklogDetailsStore extends OuxStore<PagedResponse<BacklogDetails>> {
  private backlogPlanNameSubject = new BehaviorSubject<string>(null);
  public backlogPlanName$ = this.backlogPlanNameSubject.asObservable();

  private metricBacklogCategoryDetailsSubject = new BehaviorSubject<any>(null);
  public metricBacklogCategoryDetails$ = this.metricBacklogCategoryDetailsSubject;

  private backlogDetailsFilterDataSubject = new BehaviorSubject<BacklogTransactionsFilterViewModel>(null);
  public backlogDetailsFilterData$ = this.backlogDetailsFilterDataSubject.asObservable();

  public backlogDetails$ = this.state$;

  constructor(private ouxLoggerSvc: OuxLoggerService) {
    super (null)
  }

  public setBacklogDetailsFilterData(value: BacklogTransactionsFilterViewModel):void {
    this.backlogDetailsFilterDataSubject.next(value);
}

  public setBacklogDetailsData(collection: PagedResponse<BacklogDetails>) : void {
    super.setState(collection);
    this.ouxLoggerSvc.logDebug('BacklogDetailsStore', this.state);
  }

  public setBacklogPlanName(planName: string): void {
    this.backlogPlanNameSubject.next(planName);
  }

  public setMetricBacklogCategoryDetails(value : any) : void {
    this.metricBacklogCategoryDetailsSubject.next(value);
  }

  public selectedPlanName : string;
  public selectedPlanId : number;
  public selectedQuotaId : number;
  public metricFlag : string;

  public setBacklogMetricParamDetails(value: BacklogMetricParamsDetailsModel) : void {

    this.selectedPlanId = value.planId;
    this.selectedPlanName = value.planElementName;
    this.selectedQuotaId = value.quotaId;
    this.metricFlag = value.metricFlag;

  }


  public getBacklogMetricParamDetails(): BacklogMetricParamsDetailsModel {
    
    return new BacklogMetricParamsDetailsModel({
      planId : this.selectedPlanId,
      planElementName: this.selectedPlanName,
      quotaId: this.selectedQuotaId,
      metricFlag : this.metricFlag
    }
    )
  }

  /**
   * Clear selected Goalsheet
   * ============================================================
   */
  public clearBacklogDetailsData(): void {
    this.setState(null);
  }


  /**
   * Clear selected Goalsheet
   * ============================================================
   */
   public clearBacklogDetailsFilterData(): void {
    this.backlogDetailsFilterDataSubject.next(null);
  }

  /**
   * Clear selected Goalsheet
   * ============================================================
   */

  public clearBacklogMetricParamDetails(): void {
    this.selectedPlanId = null;
    this.selectedPlanName = null;
    this.selectedQuotaId = null;
    this.metricFlag = null;
  }
}