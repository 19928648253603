export enum OfflineExportType {
  GoalToCash = 'GOAL_TO_CASH',
  Payment = 'PAYMENT',
  G2CAmplifiers='G2C',
  PaymentsAmplifiers='payments',
  OrderAmplifiers='order',
  OrderLine = 'ORDER_LINE'
}

export enum ExportDisplayType {
  Regular = 'Regular',
  Metric = 'Metric',
  Amplifier = 'Amplifier',
  MY = 'MY'
}