import { Injectable }                               from '@angular/core';
import { BehaviorSubject, Observable }                          from 'rxjs';
import { IacvSavDetailsFilterModel } from './models/iacv-sav-details-filter.model';
import { 
  OuxStore,
  OuxLoggerService }              from '@cisco/oux-common';


@Injectable({
  providedIn: 'root'
})
export class GoalToCashIacvTransactionsStore{
  
  private iacvSavDetailsFilterDataSubject = new BehaviorSubject<IacvSavDetailsFilterModel>(null);
  public iacvSavDetailsFilterData$ = this.iacvSavDetailsFilterDataSubject.asObservable();

  constructor() { }

  public setIacvSavDetailsFilterData(value: IacvSavDetailsFilterModel):void {
      this.iacvSavDetailsFilterDataSubject.next(value);
  }

  /**
   * Clear selected Goalsheet
   * ============================================================
   */
   public clearIacvSavDetailsFilterData(): void {
    this.iacvSavDetailsFilterDataSubject.next(null);
  }

}