import { OrderQuotaDetails } from "../../interface/partials/order-quota-details";

export class OrderQuotaDetailsModel implements OrderQuotaDetails {
  
  public acvFlag: string;
  public iacvFlag?: string;
  public compPlanID: number;
  public multiFlag: string;
  public myPayoutFlag: string;
  public quotaID: number;

  constructor(args : Partial<OrderQuotaDetailsModel>) {
    Object.assign(this, args);
  }

}