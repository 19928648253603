import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'leadWithZero',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})
//Convert .xx% values to 0.xx%; example, .59% to 0.59%
export class LeadingZeroToPercentagePipe implements PipeTransform {

  transform(value : string) : string {
    if (!value) return;
    if( /^\./.test(value) )
      return '0' + value;
    else return value;
  }

}
