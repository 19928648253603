import {  
  HttpClient,  
  HttpHeaders }                                           from '@angular/common/http'; 
import { Injectable }                                     from '@angular/core'; 
import {  
  combineLatest,
  Observable,  
  of,  
  Subject,  
  throwError }                                            from 'rxjs'; 
import {  
  catchError, 
  defaultIfEmpty, 
  map,  
  switchMap, 
  takeUntil,  
  tap}                                                    from 'rxjs/operators'; 
import {  
  OuxAuthenticationService,  
  OuxConfigService,  
  OuxExceptionsHandleError, 
  OuxExceptionsHandlerService }                           from '@cisco/oux-common';  
import { PagedResponseModel }                             from '../models/concrete/partials/paged-response.model';  
import { PagedResponse }                                  from '../models/interface/partials/paged-response'; 
import { UserDetailsStore }                               from '../stores/user-details.store'; 
import { CreditMemoTransactionLinesAdjustmentsRequest }   from '../models/interface/request/credit-memo-transaction-lines-adjustments-request';
import { CreditMemoTransactionLinesAdjustmentsResponse }  from '../models/interface/response/credit-memo-transaction-lines-adjustments-response';
import { GTCCreditMemoAdjustmentsModel }                  from '../models/concrete/partials/gtc-credit-memo-adjustments.model';
import { GTCCreditMemoAdjustments }                       from '../models/interface/partials/gtc-credit-memo-adjustments';
import { CreditMemoTransactionLinesAdjustmentStore }      from '../stores/credit-memo-transaction-lines-adjustment.store';
import { MetadataStore }                                  from '../stores/metadata.store';
import { OrdersExportRequest }                            from '../models/interface/request/orders-export-request';
import { BaseResponse }                                   from '../models/interface/response/base-response';
import { OfflineExport } from '../models/interface/response/offline-export-response';
import { VisibilityPageType } from '../models/types/visibility-page-type.enum';



@Injectable({ providedIn: 'root' })
export class CreditMemoTransactionLinesAdjustmentsService {

  private baseUri: string;
  private adjustmentsUri: string;
  private adjustmentsCountUri: string;
  private orderExportUri: string;

  /** 
   * Create service mapping for http exception handling 
   */ 
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('CreditMemoTransactionLinesAdjustmentsService'); 

   constructor(private http: HttpClient, 
              private ouxAuthSvc: OuxAuthenticationService, 
              private ouxConfigSvc: OuxConfigService, 
              private ouxExceptionsSvc: OuxExceptionsHandlerService, 
              private userDetailsStore: UserDetailsStore,
              private creditMemoAdjustmentsStore: CreditMemoTransactionLinesAdjustmentStore,
              private metadataStore: MetadataStore) {

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.adjustmentsUri = apiUri.orderLineAdjustments;
    this.adjustmentsCountUri = apiUri.orderLineAdjustmentsCount;
    this.orderExportUri = apiUri.orderExport;
  }

  /** 
   * Stages our Http Request Headers 
   */ 
   private getOptions() : { headers: HttpHeaders } { 

    const OPTIONS : { headers : HttpHeaders } = { 
      headers : new HttpHeaders() 
        .set('Authorization', this.ouxAuthSvc.getAuthToken()) 
        .append('Content-Type', 'application/json') 
    }; 
 
    return OPTIONS; 
  }


  private fetchAdjustmentsCount(request: CreditMemoTransactionLinesAdjustmentsRequest): Observable<number> {

    let url = `${this.baseUri}${this.adjustmentsCountUri}`;
    let options = this.getOptions();

    let request$ = this.http.post<CreditMemoTransactionLinesAdjustmentsResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response : CreditMemoTransactionLinesAdjustmentsResponse) => {
          let total = response?.data?.P_TOT_RECORDS;

          return total;
        })
      );

    return request$;
  }

  private fetchCreditMemoAdjustmentsData(request: CreditMemoTransactionLinesAdjustmentsRequest): Observable<PagedResponseModel<GTCCreditMemoAdjustments>> {

    // endpoint assignment
    let url = `${this.baseUri}${this.adjustmentsUri}`;
    
    // set request header params
    let options = this.getOptions();

    // init count notifier subject
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<CreditMemoTransactionLinesAdjustmentsResponse>(url, request, options)
        .pipe(
          tap((records: CreditMemoTransactionLinesAdjustmentsResponse) => {
            if ((records?.data?.P_GTC_ADJ?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
        this.fetchAdjustmentsCount(request)
          .pipe(
            takeUntil(countNotifier),
            defaultIfEmpty(0),
            catchError(() => of(null))
          )
    ])
    .pipe(
      switchMap(([response, count]) => {
        if (!response || !response.success) {
          return throwError(response);
        }

        return of({response, count});
      }),
      map(({response, count}) => {
        let adjustments = (response?.data?.P_GTC_ADJ || []).map(x => new GTCCreditMemoAdjustmentsModel(x));

        return new PagedResponseModel<GTCCreditMemoAdjustments>({ records: adjustments, total: count || adjustments.length })
      }),
      catchError(error => { 
        // create operation mapping for http exception handling 
        return this.ouxHandleError('fetchCreditMemoAdjustments', error)(error); 
      })
    );

    return request$;
  }


  public initCreditMemoAdjustmentsStream(request: CreditMemoTransactionLinesAdjustmentsRequest) : Observable<PagedResponseModel<GTCCreditMemoAdjustments>> {
    
    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.fetchCreditMemoAdjustmentsData(request)
      .pipe(
        tap((adjustments : PagedResponse<GTCCreditMemoAdjustments>) => { 
          // pass data to corresponding order store for further processing 
          this.creditMemoAdjustmentsStore.setLines(adjustments); 
        }), 
        catchError(error => { 
          // create operation mapping for http exception handling 
          return this.ouxHandleError('initCreditMemoAdjustmentsStream()', error)(error); 
        })
      );

    return request$;
  }

  // Queue offline export
  public queueExport(request: OrdersExportRequest): Observable<number> {

    const URL = `${this.baseUri}${this.orderExportUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = request.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = request.filters.loginId = this.userDetailsStore.getUserId();
    request.userId = request.filters.userId = this.userDetailsStore.getImpersonationUserId();

    let newRequest = {...request, ...{filters: JSON.stringify(request.filters)}};

    const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: OfflineExport) => {
          let typed = response?.data?.SEQUENCE_ID ;
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('queueExport', error)(error);
        })
      );

    return REQUEST$;
  }
}