import { OrdersLinesDetailsRequest } from "../../interface/request/orders-lines-details-request";

export class OrdersLinesDetailsRequestModel implements OrdersLinesDetailsRequest {

  public loginId: string;
  public sourceSystem: string = 'Visibility';
  public userId: string;
  public employeeId: string;
  public srpGoalHeaderId: number;
  public planId: number;
  public allocationId: number;
  public nodeName: string = '';
  public territoryTypeCode: string = '';
  public erpPosFlag: string;
  public soNumber: number;
  public transGroupCode: string;
  public transactionType: string;
  public lineId: number;
  public sequenceId: number;
  public limit: number = 10;
  public offset: number = 0;
  public quotaId: string;

  constructor(args: Partial<OrdersLinesDetailsRequestModel>) {
    Object.assign(this, args);
  }

}