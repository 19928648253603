<div class="container"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
  (click)="removeDrillDownUser()"
  [ouxTooltip]="tooltipMessage"
  [ouxTooltipOptions]="tooltipOptions">
  <i *ngIf="!isHovered" class="icon icon-eye"></i>
  <i *ngIf="isHovered" class="icon icon-close"></i>
  
  <div class="name">{{drillDownUser}}</div>
</div>
  
<div class="bar">|</div>

<ng-template #tooltipMessage>
  <div class="drill-down-user-tooltip">
    <div>
      Viewing data for <span class="--bold">{{drillDownUser}}</span>. Click to remove drill down user.
    </div>
  </div>
</ng-template>