<ng-container *ngIf="(lines$ | async) as lines">
  <oux-table
    [records]="lines"
    [options]="tableOpts"
    [settings]="columnSettings"
    [themeOverrides]="tableThemeOverrides">

    <!-- start custom table headers for last two columns -->
    <ng-template [ouxHeader]="'LINE_ID'">
      <div class="pad-left-sm">SO/POS Line Number</div>
    </ng-template>

    <ng-template [ouxHeader]="'BILL_TO_CUSTOMER'">
      <div class="custom-header">Customer Information</div>
    </ng-template>

    <ng-template [ouxHeader]="'COMMISSION_STATUS'">
      Commission Status
      <i
        class="icon-info-outline"
        [ouxTooltip]="commissionStatusTemplate"
        [ouxTooltipOptions]="commissionStatusTooltipOpts">
      </i>
    </ng-template>

    <!-- /end custom table headers -->

    <!-- start custom table columns -->
    <ng-template [ouxColumn]="'LINE_ID'" let-record="record">
      <div class="pad-left-sm">
        {{ record?.LINE_ID }}
      </div>
    </ng-template>

    <ng-template [ouxColumn]="'TOTAL_VALUE'" let-record="record">
      {{ record?.TOTAL_VALUE | number:'1.2-2' }}
    </ng-template>

    <ng-template [ouxColumn]="'ANNUAL_VALUE'" let-record="record">
      {{ record?.ANNUAL_VALUE | number:'1.2-2' }}
    </ng-template>

    <ng-template [ouxColumn]="'BOOKING_AMT'" let-record="record">
      <div *ngIf="ampIdentifier ===  AmpIdentifierKey.NOTELIGIBLE; else defaultBookingAmt">
        NA
      </div>
      <ng-template #defaultBookingAmt>
        <span>{{ record?.BOOKING_AMT | number:'1.2-2' }}</span>
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'COMM_BACKLOG'" let-record="record">
      <div *ngIf="ampIdentifier ===  AmpIdentifierKey.NOTELIGIBLE; else defaultCommBacklog">
        NA
      </div>
      <ng-template #defaultCommBacklog>
        <ng-container *ngIf="record?.COMM_BACKLOG === 0 || orderType === 'Manual Revenue'; else showBacklogTransactionDetailsLinkTemplate">
          {{ ampIdentifier !== AmpIdentifierKey.MY ? (record?.COMM_BACKLOG | number:'1.2-2') : 'NA' }}
        </ng-container>
  
        <ng-template #showBacklogTransactionDetailsLinkTemplate>
          <a
            class="link"
            [title]="record?.COMM_BACKLOG"
            (click)="showOrderLineDetails(record, 'backlog', $event.preventDefault())">
              {{ record?.COMM_BACKLOG | number:'1.2-2' }}
          </a>
        </ng-template>
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'COMM_REVENUE'" let-record="record">
      <ng-container *ngIf="record?.COMM_REVENUE === 0 || orderType === 'Manual Revenue'; else showTransactionDetailsLinkTemplate">
          {{ (record?.COMM_REVENUE | number:'1.2-2') || (record?.REVENUE | number:'1.2-2') }}
        <i
          class="icon-info-outline"
          [ouxTooltip]="revenueDescTemplate"
          [ouxTooltipOptions]="templateTooltipOpts">
        </i>
        <ng-template #revenueDescTemplate>
          <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
        </ng-template>
      </ng-container>

      <ng-template #showTransactionDetailsLinkTemplate>
        <a
          class="link"
          [title]="record?.COMM_REVENUE"
          (click)="showOrderLineDetails(record, 'revenue', $event.preventDefault())">
            {{ (record?.COMM_REVENUE | number:'1.2-2') || (record?.REVENUE | number:'1.2-2') }}
        </a>
        <i
          class="icon-info-outline"
          [ouxTooltip]="revenueDescTemplate"
          [ouxTooltipOptions]="templateTooltipOpts">
        </i>
        <ng-template #revenueDescTemplate>
          <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
        </ng-template>
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'COMMISSION_STATUS'" let-record="record">
      <div *ngIf="ampIdentifier ===  AmpIdentifierKey.NOTELIGIBLE; else defaultCommissionStatus">
        NA
      </div>
      <ng-template #defaultCommissionStatus>
        {{ record?.COMMISSION_STATUS }}
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'BILL_TO_CUSTOMER'" let-record="record">
      <div class="custom-column">
        <div class="column__row">
          <span class="row__label">Bill To Customer</span>
          <span class="row__value">{{ record?.BILL_TO_CUSTOMER || 'Not Available' }}</span>
        </div>
        <div class="column__row">
          <span class="row__label">Ship To Customer</span>
          <span class="row__value">{{ record?.SHIP_TO_CUSTOMER || 'Not Available' }}</span>
        </div>
        <div class="column__row">
          <span class="row__label">End Customer</span>
          <span class="row__value">{{ record?.END_CUSTOMER || record?.END_CUSTOMER_NAME || 'Not Available' }}</span>
        </div>
      </div>
    </ng-template>
    <!-- end custom table columns -->
  </oux-table>


  <!-- tooltip templates -->
  <ng-template #commissionStatusTemplate>
    <p class="commission-status-table-header">Commission Status Examples and Definitions</p>
    <div class="table-container">
      <table class="commission-status-table">
        <thead>
          <tr>
            <th>Booking</th>
            <th>Backlog</th>
            <th>Revenue</th>
            <th>Status</th>
            <th>Status Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>100</td>
            <td>100</td>
            <td>0</td>
            <td>Backlog</td>
            <td>The full value of the order is in Backlog. The backlog could be attributed to holds
              or future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>0</td>
            <td>80</td>
            <td>Pending Backlog</td>
            <td>Part of this order is still backlogged but there is a timing delay in the
              recognition of the backlog. The backlog could be attributed to holds or future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>20</td>
            <td>80</td>
            <td>Pending Backlog</td>
            <td>Part of this order is still backlogged. The backlog could be attributed to holds or
              future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>0</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this order.</td>
          </tr>
          <tr>
            <td>100</td>
            <td>100</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this order but there is a timing delay in the
              resolution of the backlog.</td>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this revenue only transaction.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

</ng-container>
