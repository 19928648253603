import { Injectable }                               from '@angular/core';
import { BehaviorSubject }                          from 'rxjs';
import { GoalToCashAllocations }                    from '../models/interface/partials/goal-to-cash-allocations';
import { GoalToCashDetails }                        from '../models/interface/partials/goal-to-cash-details';
import { NodeSummary }                              from '../models/interface/partials/node-summary';
import { GoalToCashWeeklyDetails } from '../models/interface/partials/goalToCashWeeklyDetails';
import { GoalToCashIacvDetails } from '../models/interface/partials/goalToCashIacvDetails';
import { GoalToCashDetailsRequestModel } from '../models/concrete/request/goal-to-cash-details-request.model';
import { ParamMap } from '@angular/router';
import { GoalToCashSavWeeklyDropdownFilterCriteria } from '../models/interface/partials/goal-to-cash-sav-weekly-dropdown-filter-criteria';
import { BookingsExportDetails } from '../models/interface/partials/bookings-export-details';
import { MetricsExportDetails } from '../models/interface/partials/metrics-export-details';
import { GoalToCashIacvTransactionsStore } from 'src/app/routes/goal-to-cash/routes/goal-to-cash-iacv-transactions/goal-to-cash-iacv-transactions.store';
import { BacklogDetailsStore } from './backlog-details.store';
import { GoalToCashIacvTransactionsWeeklyStore } from 'src/app/routes/goal-to-cash/routes/goal-to-cash-iacv-transactions-weekly/goal-to-cash-iacv-transactions-weekly.store';
import { GoalsheetDetailsModel } from '../models/concrete/partials/goalsheet-details.model';
import { ExportDefinitionsDetails } from '../models/interface/partials/export-definitions-details';
import { CurrencyCodeDetails } from '../models/interface/partials/currencyCodeDetails';
import { GoalToCashIacvAllocations } from '../models/interface/partials/goal-to-cash-iacv-allocations';


@Injectable({
  providedIn: 'root'
})
export class GoalToCashStore {
  
  private goalToCashDetailsSubject = new BehaviorSubject<GoalToCashDetails>(null);
  private goalToCashAllocationsSubject = new BehaviorSubject<GoalToCashAllocations>(null);
  private goalToCashAllocationsForIacvSubject = new BehaviorSubject<GoalToCashIacvAllocations[]>(null);
  private goalToCashNodesSubject = new BehaviorSubject<NodeSummary[]>(null);
  private goalToCashBacklogNodesSubject = new BehaviorSubject<NodeSummary[]>(null);
  private goalSheetUrlSubject = new BehaviorSubject<string>(null);
  private goalSheetSubject = new BehaviorSubject<GoalsheetDetailsModel>(null);

  private goalToCashSavDetailsSubject = new BehaviorSubject<GoalToCashIacvDetails[]>(null);
  private goalToCashWeeklyDetailsSubject = new BehaviorSubject<GoalToCashWeeklyDetails[]>(null);
  
  private currencyCodeDetailsSubject = new BehaviorSubject<CurrencyCodeDetails>(null);
  private bookingsExportDetailsSubject = new BehaviorSubject<BookingsExportDetails[]>(null);
  private exportDefinitionsDetailsSubject = new BehaviorSubject<ExportDefinitionsDetails[]>(null);
  private metricsExportDetailsSubject = new BehaviorSubject<MetricsExportDetails[]>(null);
  private goalToCashSavWeeklyDropdownDetailsSubject = new BehaviorSubject<GoalToCashSavWeeklyDropdownFilterCriteria>(null);

  private territorySubject = new BehaviorSubject<GoalToCashSavWeeklyDropdownFilterCriteria>(null);

  private queryParamsForSavSubject = new BehaviorSubject<ParamMap>(null);


  public goalToCashDetails$ = this.goalToCashDetailsSubject.asObservable();
  public goalToCashAllocations$ = this.goalToCashAllocationsSubject.asObservable();
  public goalToCashAllocationsForIacv$ = this.goalToCashAllocationsForIacvSubject.asObservable();
  public goalToCashNodes$ = this.goalToCashNodesSubject.asObservable();
  public goalToCashBacklogNodes$ = this.goalToCashBacklogNodesSubject.asObservable();
  public goalSheetUrl$ = this.goalSheetUrlSubject.asObservable();
  public goalSheet$ = this.goalSheetSubject.asObservable();
  public goalToCashSavDetails$ = this.goalToCashSavDetailsSubject.asObservable();
  public goalToCashWeeklyDetails$ = this.goalToCashWeeklyDetailsSubject.asObservable();
  public goalToCashSavWeeklyDropdownDetails$ = this.goalToCashSavWeeklyDropdownDetailsSubject.asObservable();
  public queryParamsForSav$ = this.queryParamsForSavSubject.asObservable();

  constructor(private iacvSavDetailsStore: GoalToCashIacvTransactionsStore,
    private weeklyDetailsStore: GoalToCashIacvTransactionsWeeklyStore,
    private backlogFilterStore: BacklogDetailsStore) { }

  public clearAllFilterData(){

    this.iacvSavDetailsStore.clearIacvSavDetailsFilterData();
    this.weeklyDetailsStore.clearIacvWeeklyDetailsFilterData();
    this.backlogFilterStore.clearBacklogDetailsFilterData();
  }

  public setGoalToCashDetails(value: GoalToCashDetails): void {
    this.goalToCashDetailsSubject.next(value);
  }

  public setGoalToCashAllocations(value: GoalToCashAllocations): void {
    this.goalToCashAllocationsSubject.next(value);
  }

  public setGoalToCashAllocationsForIacv(value: GoalToCashIacvAllocations[]): void {
    this.goalToCashAllocationsForIacvSubject.next(value);
  }

  public setGoalToCashSavDetails(value: GoalToCashIacvDetails[]): void {
    this.goalToCashSavDetailsSubject.next(value);
  }

  public setGoalToCashWeeklyDetails(value: GoalToCashWeeklyDetails[]): void {
    this.goalToCashWeeklyDetailsSubject.next(value);
  }

  public setCurrencyCodeDetails(value: CurrencyCodeDetails): void {
    this.currencyCodeDetailsSubject.next(value);
  }

  public setBookingsExportDetails(value: BookingsExportDetails[]): void {
    this.bookingsExportDetailsSubject.next(value);
  }

  public setExportDefinitionsDetails(value: ExportDefinitionsDetails[]): void {
    this.exportDefinitionsDetailsSubject.next(value);
  }

  public setMetricsExportDetails(value: MetricsExportDetails[]): void {
    this.metricsExportDetailsSubject.next(value);
  }

  public setGoalToCashSavWeeklyDropdownDetails(value: GoalToCashSavWeeklyDropdownFilterCriteria): void {
    this.goalToCashSavWeeklyDropdownDetailsSubject.next(value);
  }

  public setGoalToCashNodes(value: NodeSummary[]): void {
    this.goalToCashNodesSubject.next(value);
  }

  public setGoalToCashBacklogNodes(value: NodeSummary[]): void{
    this.goalToCashBacklogNodesSubject.next(value);
  }

  public setGoalSheetUrl(value: string): void {
    this.goalSheetUrlSubject.next(value);
  }

  public setGoalSheet(value: GoalsheetDetailsModel): void {
    this.goalSheetSubject.next(value);
  }
  public getGoalSheet(): GoalsheetDetailsModel {
    return this.goalSheetSubject.value;
  }
  public setQueryParamsForSav(value:ParamMap): void{
    this.queryParamsForSavSubject.next(value);
  }
  public setSelectedTerritoryType(value): void{
    this.territorySubject.next(value);
  }

}