import { WeeklyTransactionSummaryRequest } from '../../interface/request/weekly-transaction-summary-request';

export class WeeklyTransactionSummaryRequestModel implements WeeklyTransactionSummaryRequest {
    public loginId: string;
    public goalId: number;
    public userId: string;
    public employeeId: string;
    public quotaId: string;
    public fiscalWeek: String[];
    public sourceSystem: string;
    public limit: number;
    public offset: number;
    public sortBy: string;
    public sortOrder: string;
    public BE_CX_LEVEL_1: string;
    public BE_CX_LEVEL_2: string;
    public BE_CX_LEVEL_3: string;
    public cxUpsellGroup: string;
    public beCxLevel1 : string;
    public beCxLevel2 : string;
    public beCxLevel3 : string;
    public transactionType : string;
    public metricId : number;
    public savId: String[];
    public beNames: String[];
    public subBeNames: String[];
    public pidNames: String[];
    public cxUpsellGroups: String[];
    public edfTransactionId : string;

  constructor(args : Partial<WeeklyTransactionSummaryRequestModel>) {
    Object.assign(this, args);
  } 
}