import { 
  Component, 
  OnChanges, 
  OnDestroy, 
  OnInit, 
  SimpleChanges }                               from '@angular/core';
import { 
  BehaviorSubject, 
  EMPTY, 
  of, 
  Subject, 
  Subscription }                                from 'rxjs';
import { 
  catchError,
  debounceTime, 
  distinctUntilChanged, 
  finalize, 
  map, 
  switchMap, 
  takeUntil, 
  tap }                                         from 'rxjs/operators';
import { 
  OuxAutocompleteThemeOverrides, 
  OuxThemeMode, 
  OuxThemeService }                             from '@cisco/oux-common';
import { UserList }                             from 'src/app/shared/models/interface/partials/user-list';
import { UsersSearchService }                   from 'src/app/shared/services/users-search.service';
import { UserDetailsStore }                     from 'src/app/shared/stores/user-details.store';
import { MetadataService } from 'src/app/shared/services/metadata.service';
import { QuotaDetailsService } from 'src/app/shared/services/quota-details.service';
import { LoaderService } from 'src/app/core/services';
import { LoadingType } from 'src/app/shared/models/constants/loading-type.enum';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';
import { GoalToCashStore } from 'src/app/shared/stores/goal-to-cash.store';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-header-user-search',
  templateUrl: './header-user-search.component.html',
  styleUrls: ['./header-user-search.component.scss'],
  host: {
    'class': 'app-header-user-search'
  }
})
export class HeaderUserSearchComponent implements OnInit, OnChanges, OnDestroy {

  private subscriptions: Subscription[] = [];
  private changed$ = new Subject();

  private doSearch = new Subject<string>();
  private search$ = this.doSearch.asObservable()
    .pipe(
      distinctUntilChanged(),
      tap(() => {
        this.loading = true;
      }),
      debounceTime(500),
      tap(() => {
        this.changed$.next();
      }),
      switchMap(search => {
        if (!search || search.length < 3) {
          this.users$.next([]);
          this.loading = false;

          return of<UserList[]>([]);
        }

        return this.usersSearchSvc.search(search.trim())
          .pipe(
            takeUntil(this.changed$),
            tap(results => {
              this.users$.next(results.slice(0, 30));
              this.loading = false;
            }),
            catchError(() => EMPTY)
          );
      }),
      finalize(() => {
        this.loading = false;
      })
    );

  public users$ = new BehaviorSubject<UserList[]>([]);
  public loading: boolean = false;
  public searchValue: string = '';

  public autocompleteTheme$ = this.ouxThemeSvc.theme$
    .pipe(
      map(theme => {
        let overrides: OuxAutocompleteThemeOverrides = {
          backgroundColor: 'var(--surface)',
          borderColor: '',
          borderColorFocused: '',
          color: '',
          placeholderColor: '',
          shadowFocused: ''
        };

        return overrides;
      })
    );

  constructor(
    private usersSearchSvc: UsersSearchService,
    private ouxThemeSvc: OuxThemeService,
    private metadataService: MetadataService,
    private quotaDetailsService: QuotaDetailsService,
    private loaderService: LoaderService,
    private userDetailsStore: UserDetailsStore,
    private goalToCashStore: GoalToCashStore,
    private metadataStore: MetadataStore,
    private router: Router
  
  ) {
    this.subscriptions.push(this.search$.subscribe());
  }

  public ngOnInit(): void {
    
  }

  public ngOnChanges(changes: SimpleChanges): void {
    
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public search(value: string): void {
    this.doSearch.next(value);
  }

  public selectUser(user: UserList): void {
    //Clear existing filters from memory
    this.goalToCashStore.clearAllFilterData(); 
    
    this.loaderService.show(LoadingType.Full);

    this.userDetailsStore.setUserChanged(true);

    this.subscriptions.push(this.metadataService.fetchData(user.emailId)
      .pipe(
        tap(() => this.userDetailsStore.setUserImpersonation(user)),
        tap(metadata => {
          let name = metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 ? metadata.P_EMPINFO[0]?.emp_name : user.fullName;
          this.userDetailsStore.setUserFullName(name);

          if (user.emailId == this.userDetailsStore.getUserId()) {
            this.metadataStore.setLoggedInUserMetadata(metadata);
          }
        }),
        switchMap(() => this.quotaDetailsService.fetchData()),
        finalize(() => this.loaderService.hide(LoadingType.Full))
      )
      .subscribe()
    );

    setTimeout(() => {
      this.searchValue = '';
      this.users$.next([]);
    }, 0);
    this.router.navigate(['/goal-attainment']);
  }
}
