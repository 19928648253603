import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OuxBreadcrumbModel, OuxPageChangeEvent, OuxPaginationOptions } from '@cisco/oux-common';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { BreadcrumbsStore } from 'src/app/layout/breadcrumbs/breadcrumbs.store';
import { SortEvent } from '../../models/concrete/partials/sort-event.model';
import { MetricSOTransactionLineRequestModel } from '../../models/concrete/request/metric-order-line-request.model';
import { OrdersMetricHeaderDetailsRequestModel } from '../../models/concrete/request/orders-metric-header-details-request.model';
import { WeeklyTransactionSummaryRequestModel } from '../../models/concrete/request/weekly-transaction-summary-request.model';
import { LoadingType } from '../../models/constants/loading-type.enum';
import { MetricOrderDetails } from '../../models/interface/partials/metric-order-details';
import { MetricSOTransactionSummaryDetails } from '../../models/interface/partials/metric-so-transaction-summary-details';
import { OrdersMetricHeaderDetails } from '../../models/interface/partials/orders-metric-header-details';
import { GoalToCashService } from '../../services/goal-to-cash.service';
import { LoaderService } from 'src/app/core/services';
import { UserDetailsStore } from '../../stores/user-details.store';
import { MetricSONotEligibleTransactionSummaryDetails } from '../../models/interface/partials/metric-so-not-eligible-transaction-summary-details';
import { AmpIdentifierKey } from '../../models/types/amp-identifier-key.enum';


@Component({
  selector: 'metric-so-transaction-lines',
  templateUrl: './metric-so-transaction-lines.component.html',
  styleUrls: ['./metric-so-transaction-lines.component.scss'],
  host: {
    'class': 'metric-so-transaction-lines'
  }
})
export class MetricSOTransactionLinesComponent implements OnInit {

  @Input('metricTransactionLineDetails')
  public metricTransactionLineDetails: MetricOrderDetails;

  @Input('ordersMetricHeaderData')
  public ordersMetricHeaderData: OrdersMetricHeaderDetails[];

  @Input('request')
  public requestParams: OrdersMetricHeaderDetailsRequestModel;

  public metricSoTransactionSummaryDetails: MetricSOTransactionSummaryDetails[];

  public metricSoHeaderDetails: MetricSOTransactionSummaryDetails[];

  public currentRequestParams: WeeklyTransactionSummaryRequestModel;

  public lastPageUsed: OuxPageChangeEvent;
  public lastSortUsed: SortEvent;

  public subscriptions: Subscription[] = [];

  public sourcePage: string;

  public peType: string;

  public AmpIdentifierKey = AmpIdentifierKey;


  constructor(private route: ActivatedRoute,
    private goalToCashService: GoalToCashService,
    private breadcrumbsStore: BreadcrumbsStore,
    private loaderSvc: LoaderService,
    private userDetailsStore: UserDetailsStore,
    private router: Router,


  ) { }

  // called whenever admin switches the user
  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null),
      tap(() => {
        this.router.navigate(['/goal-attainment'])
      })
    );

  // to manage breadcrumbs while navigating to/from the metric so screen
  private breadcrumbs$ = combineLatest([this.route.queryParams])
    .pipe(
      map(([params]) => {
        let routePath = params.sourcePage ? params.sourcePage : '';
        let nodeFilterValue = params.nodeFilterValue ? params.nodeFilterValue : '';
        if (routePath === 'g2c') {
            // if navigated to g2c transaction summary page from node summary
          if(nodeFilterValue.length > 0 ){
            return [
              new OuxBreadcrumbModel({
                route: '/goal-to-cash',
                display: 'Goal to Cash Summary'
              }),
              new OuxBreadcrumbModel({
                route: '/goal-to-cash/node-summary',
                display:  params.savTitle ?  `Node Summary  ${params.savTitle }` : 'Node Summary',
                queryParams: {
                  goalID: params.goalId || params.goalID,
                  quotaId: params.quotaId,
                  allocationID: params.quotaId,
                  planTitle: params.savTitle,
                  peType: params.peType,
                  selectedFiscalYear: params.goalSheet.substring(0,4),
                  currencyCode:params.currency,
                  goalSheetViewHeaderPlanType:params.goalSheet.substring(0,10) ,
                  goalSheetViewHeaderDateRange: params.goalSheet.substring(11),
                  goalSheetViewHeaderLink: '('+params.planProrationType+':'+ params.fiscalInterval+')',
                  planID: params.planID,
                  ampIdentifier:params.ampIdentifier,
                  myFlag: params.myFlag,
                  metricFlag: params.metricFlag,
                  calcEngine: params.calcEngine,
                  performanceType: params.performanceType
                }
              }),
              new OuxBreadcrumbModel({
                route: '/goal-to-cash/amplifiers/transactions',
                display: nodeFilterValue ? `Transactions: ${params.nodeDisplay}` : 'Transactions',
                queryParams: params
              }),

              new OuxBreadcrumbModel({
                route: '/goal-to-cash/transaction/order/lines',
                display: params.orderNumber ? `Order Details: ${params.orderNumber}` : 'Order Line',
                queryParams: params
              })
            ]
          } else {
            return [
              new OuxBreadcrumbModel({
                route: '/goal-to-cash',
                display: 'Goal to Cash Summary'
              }),
              new OuxBreadcrumbModel({
                route: '/goal-to-cash/amplifiers/transactions',
                display: params.savTitle ? `Transactions: ${params.savTitle}` : 'Transactions',
                queryParams: params
              }),
              new OuxBreadcrumbModel({
                route: '/goal-to-cash/transaction/order/lines',
                display: params.orderNumber ? `Order Details: ${params.orderNumber}` : 'Order Line',
                queryParams: params
              })
            ]
          }

        } else if (routePath === 'payments') {
          return [
            new OuxBreadcrumbModel({
              route: '/payments',
              display: 'Payments'
            }),
            new OuxBreadcrumbModel({
              route: '/payments/transactions/amplifiers',
              display: params.savTitle ? `Payment Transaction Summary: ${params.savTitle}` : 'Transactions',
              queryParams: params
            }),
            new OuxBreadcrumbModel({
              route: '/payments/transaction/order/lines',
              display: params.orderNumber ? `Order Details: ${params.orderNumber}` : 'Order Line',
              queryParams: params
            })
          ]
        } else {
          return [
            new OuxBreadcrumbModel({
              route: '/order-search/orders',
              display: 'Order Search',
              queryParams: {
                searchKey: 'so_number',
                searchValue: this.requestParams.searchValue
              }
            }),
            new OuxBreadcrumbModel({
              route: '/order-search/transaction/order/lines',
              display: params.orderNumber ? `Order Details: ${params.orderNumber}` : 'Order Details'
            })
          ]
        }
      }),
      tap(breadcrumbs => {
        this.breadcrumbsStore.setBreadcrumbs(breadcrumbs);
      })
    );

  // manage pagination options for the metric so summary table
  public paginationOptions = new OuxPaginationOptions({
    pageSize: 10,
    totalRecords: 0,
    autoHide: false,
    numbers: { show: false },
    goto: { show: false },
    pageSizes: {
      sizes: [10, 25, 50, 100],
      label: 'Orders to show:',
      displayFormat: (x: number) => `${x}`
    }
  });

  ngOnInit(): void {
    this.paginationOptions.totalRecords = 0;
    this.buildCurrentRequest();
    this.subscriptions.push(this.breadcrumbs$.subscribe());
    this.subscriptions.push(
      this.impersonationChange$.subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

  public buildCurrentRequest(): void {
    let params = this.route.snapshot.queryParamMap;

    this.loaderSvc.show(LoadingType.Full);
    // Build input payload for the metric so summary table and call the api - this is done here to get the record count and pass it to the header component to show the 'Total Order Lines' below Order Number
    let request = new MetricSOTransactionLineRequestModel({
      quotaId: String(this.requestParams.quotaId),
      goalId: this.requestParams.goalId,
      //Get Order Number of the page
      salesOrderNumber: this.requestParams.searchValue ? this.requestParams.searchValue : params.has('orderNumber') ? params.get('orderNumber') : null,
      startDate: params.has('searchStartDate') ? this.formatDate(params.get('searchStartDate')) : null,
      endDate: params.has('searchEndDate') ? this.formatDate(params.get('searchEndDate')) : null,
      limit: this.lastPageUsed?.take || 10,
      offset: this.lastPageUsed?.skip || 0,
      sortBy: this.lastSortUsed?.key || 'LINE_ID',
      sortOrder: this.lastSortUsed?.direction || 'desc',
      sourcePage: params.has('sourcePage') ? params.get('sourcePage') : null,
      type: params.has('type') ? (params.get('type') === 'ERP' ? 'Direct' : params.get('type')) : null //check for ERP from payments drill down and change it to Direct as SF has only Direct as order type.
    })

    let isNotEligibleOrder = params.has('ampIdentifier') ? (params.get('ampIdentifier') === AmpIdentifierKey.NOTELIGIBLE ? true : false) : false;
    let erpPosFlagValue = params.has('erpPosFlag') ? (params.get('erpPosFlag')) : '';
    let notEligibleRequest;
  
    if (isNotEligibleOrder) {
      notEligibleRequest = {
        loginId: '',
        userId: '',
        employeeId: '',
        salesOrderNumber: request.salesOrderNumber,
        trxType: request.type,
        erpPosFlag: erpPosFlagValue,
        limit: request.limit,
        offset: 0,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
      }

      this.subscriptions.push(this.goalToCashService.fetchDetailsForNotEligibleTransactionSummaryCount(notEligibleRequest).subscribe(count => {
        this.paginationOptions.totalRecords = count;
      }));

      this.subscriptions.push(this.goalToCashService.fetchDetailsForNotEligibleTransactionSummary(notEligibleRequest).subscribe(details => {
        this.metricSoTransactionSummaryDetails = details;
        this.loaderSvc.hide(LoadingType.Full);
      }));
    } else {
      this.subscriptions.push(this.goalToCashService.fetchDetailsForAmpSoTransactionSummaryCount(request).subscribe(count => {
        this.paginationOptions.totalRecords = count;
      }))
      
      this.subscriptions.push(this.goalToCashService.fetchDetailsForAmpSoTransactionSummary(request).subscribe(details => {
        this.metricSoTransactionSummaryDetails = details;
        this.loaderSvc.hide(LoadingType.Full);
      }));
    }
  }

  public formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toISOString().substring(0,10);
  }

  // called when user changes number of records to be shown in the page
  public search(page: OuxPageChangeEvent, sortRequest: SortEvent): void {

    if (!page) {
      this.paginationOptions.totalRecords = 0;

      page = new OuxPageChangeEvent({
        page: 1,
        pageSize: this.lastPageUsed?.pageSize || 10,
        skip: 0,
        take: this.lastPageUsed?.take || 10
      });
    }

    this.lastPageUsed = page;
    this.lastSortUsed = sortRequest;

    this.buildCurrentRequest();
  }

}
