import { BeSubBePid, FiscalWeek, CXUpsell, GoalingPeriod, Header, SavName, Territory, IacvCategoryDetails } from "../../interface/partials/iacv-category-details";


export class IacvCategoryDetailsModel implements IacvCategoryDetails {
  public P_ERROR_MESSAGE: string;
  public l_BE_SubBE_PID : BeSubBePid[];
  public l_CX_Upsell : CXUpsell[];
  public l_fiscal_week : FiscalWeek[];
  public l_goaling_period : GoalingPeriod[]; 
  public l_header : Header[];
  public l_sav_name : SavName[];
  public l_territory: Territory[];

  constructor(args : Partial<IacvCategoryDetailsModel>) {
    Object.assign(this, args);
  }
}