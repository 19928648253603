/* Angular Import */
import {
  Component,
  OnInit, 
  Input}                                                      from '@angular/core';
import {  
  Observable, 
  Subscription }                                              from 'rxjs';
import { 
  map, 
  tap }                                                       from 'rxjs/operators';
/* OneUX Imports */
import { 
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxTooltipOptions,
  OuxTooltipPosition,
  OuxThemeType,
  OuxColumnSettings, 
  OuxLoggerService, 
  OuxModalService }                                           from '@cisco/oux-common';
/* Feature Imports */
import { LoaderService }                                      from 'src/app/core/services/loader.service';
import { LoadingType }                                        from 'src/app/shared/models/constants/loading-type.enum';
import { CreditMemoTransactionLineDetailsModalComponent }     from '../credit-memo-transaction-line-details-modal/credit-memo-transaction-line-details-modal.component';
import { GTCCreditMemoAdjustments }                           from 'src/app/shared/models/interface/partials/gtc-credit-memo-adjustments';
import { CreditMemoTransactionLinesAdjustmentStore }          from 'src/app/shared/stores/credit-memo-transaction-lines-adjustment.store';
import { GTCCreditMemoAdjustmentsModel }                      from 'src/app/shared/models/concrete/partials/gtc-credit-memo-adjustments.model';


@Component({
  selector: 'credit-memo-transaction-lines-summary-table',
  templateUrl: './credit-memo-transaction-lines-summary-table.component.html',
  styleUrls: ['./credit-memo-transaction-lines-summary-table.component.scss'],
  host: {
    'class': 'credit-memo-transaction-lines-summary-table'
  }
})
export class CreditMemoTransactionLinesSummaryTableComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];

  @Input('filterType')
  public filterType: string;

  public tableOpts : OuxTableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--fixed-header --hover',
    formatCells: false,
    tableLayout: 'fixed',
    fixedHeight: '100%',
    loading: true,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'There are no lines to display at this time.',
    trackRecordsProperty: 'TRANSACTION_ID',
    highlightSelectedRows: false,
    missingDataMessage: ' ',
    toggleSelected: false
  });

  public columnSettings: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'TRANSACTION_ID'
    }),
    new OuxColumnSettings({
      header: 'Bookings',
      primaryKey: 'BOOKING',
      thAlign: 'right',
      tdAlign: 'right',
      width: '5%'
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'AMOUNT',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'right',
      tdAlign: 'right'
    }),
    new OuxColumnSettings({
      header: 'Customer Information',
      primaryKey: 'BILL_TO_CUSTOMER',
      width: '30.5%'
    })
  ];

  public tableThemeOverrides : OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  public commissionStatusTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    alignAtCenter: true,
    position: OuxTooltipPosition.Left,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '400px', 
      'max-width': '600px' 
    },
    context: { 
      code : null 
    }
  });

  private loading$ = this.loaderSvc.loading$.get(LoadingType.Table)
    .pipe(
      tap(loading => {
        this.tableOpts.loading = loading;
      })
    );

  public tableData$: Observable<GTCCreditMemoAdjustments[]> = this.creditMemoStore.creditMemoAdjustments$
    .pipe(
      map(lines => lines && lines.records && lines.records.length > 0 ? lines.records : [])
    );


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService,
              private loaderSvc: LoaderService,
              private creditMemoStore: CreditMemoTransactionLinesAdjustmentStore) {

    this.subscriptions.push(this.loading$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];

    // clean up selected order - prevents memory leaks
    this.creditMemoStore.clearCreditMemoLinesAdjustmentsData();
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  public showLineDetails(record: GTCCreditMemoAdjustments, event: any) : void {

    this.ouxLoggerSvc.logDebug(`CreditMemoTransactionLinesSummaryTableComponent - ${record.TRANSACTION_ID} Transaction Selected`, record.TRANSACTION_ID);

    let lineDetails: GTCCreditMemoAdjustments = new GTCCreditMemoAdjustmentsModel({
      AMOUNT: record.AMOUNT,
      ANNUAL_VALUE: record.ANNUAL_VALUE,
      BILL_TO_CUSTOMER: record.BILL_TO_CUSTOMER,
      BOOKING: record.BOOKING,
      COMMENTS: record.COMMENTS,
      CONTRACT_END_DATE: record.CONTRACT_END_DATE,
      CONTRACT_START_DATE: record.CONTRACT_START_DATE,
      CONTRACT_TERM: record.CONTRACT_TERM,
      CREATED_BY: record.CREATED_BY,
      CREATION_DATE: record.CREATION_DATE,
      CRED_MEMO: record.CRED_MEMO,
      GS_CURRENCY_CODE: record.GS_CURRENCY_CODE,
      LINE_ID: record.LINE_ID,
      METRIC_TYPE: record.METRIC_TYPE,
      ORDER_NUMBER: record.ORDER_NUMBER,
      PART_NUMBER: record.PART_NUMBER,
      PERIOD_CODE: record.PERIOD_CODE,
      PO_CUSTOMER_NUMBER: record.PO_CUSTOMER_NUMBER,
      PROGRAM_NAME: record.PROGRAM_NAME,
      REASON: record.REASON,
      SALES_MOTION: record.SALES_MOTION,
      SHIP_TO_CUSTOMER: record.SHIP_TO_CUSTOMER,
      TOTAL_VALUE: record.TOTAL_VALUE,
      TRANSACTION_DATE: record.TRANSACTION_DATE,
      TRANSACTION_ID: record.TRANSACTION_ID,
      TYPE: record.TYPE
    });

    let combinedLineDetails = new Object({
      selectedPartNumber: `${record.TRANSACTION_ID}`,
      lineDetails: lineDetails
    });

    this.ouxModalSvc.openModal(CreditMemoTransactionLineDetailsModalComponent, combinedLineDetails);
  }

}

