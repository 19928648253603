<ng-container *ngIf="(tableData$ | async) as lines">
  <oux-table
    [records]="lines"
    [options]="tableOpts"
    [settings]="columnSettings"
    [themeOverrides]="tableThemeOverrides">

    <!-- start custom table headers for last two columns -->
    <ng-template [ouxHeader]="'TRANSACTION_ID'">
      <div class="pad-left-sm">SO/POS Line Number</div>
    </ng-template>

    <ng-template [ouxHeader]="'COMMISSION_STATUS'">
      Commission Status
      <i  
        class="icon-info-outline"
        [ouxTooltip]="commissionStatusTemplate"
        [ouxTooltipOptions]="commissionStatusTooltipOpts">
      </i>
    </ng-template>

    <ng-template [ouxHeader]="'BILL_TO_CUSTOMER'">
      <div class="custom-header">Customer Information</div>
    </ng-template>

    <!-- /end custom table headers -->

    <!-- start custom table columns -->
    <ng-template [ouxColumn]="'TRANSACTION_ID'" let-record="record">
      <div class="pad-left-sm">
        <a 
          class="link"
          [title]="'Memo Details'"
          (click)="showLineDetails(record, $event.preventDefault())">
          N/A
        </a>
      </div>
    </ng-template>

    <ng-template [ouxColumn]="'BOOKING'" let-record="record">
      {{ (filterType == 'revCredMemo' ? 0 : (record?.AMOUNT || 0)) | number:'1.2-2' }}
    </ng-template>

    <ng-template [ouxColumn]="'AMOUNT'" let-record="record">
        {{ (filterType == 'revCredMemo' ? (record?.AMOUNT || 0) : 0) | number:'1.2-2' }}
        <i 
          class="icon-info-outline"
          [ouxTooltip]="revenueDescTemplate"
          [ouxTooltipOptions]="templateTooltipOpts">
        </i>
        <ng-template #revenueDescTemplate>
          <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
        </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'COMMISSION_STATUS'" let-record="record">
      {{ record?.COMMISSION_STATUS }}
    </ng-template>

    <ng-template [ouxColumn]="'BILL_TO_CUSTOMER'" let-record="record">
      <div class="custom-column">
        <div class="column__row">
          <span class="row__label">Bill To Customer</span>
          <span class="row__value">{{ record?.BILL_TO_CUSTOMER || 'Not Available' }}</span>
        </div>
        <div class="column__row">
          <span class="row__label">Ship To Customer</span>
          <span class="row__value">{{ record?.SHIP_TO_CUSTOMER || 'Not Available' }}</span>
        </div>
      </div>
    </ng-template>
    <!-- end custom table columns -->
  </oux-table>


  <!-- tooltip templates -->
  <ng-template #commissionStatusTemplate>
    <p class="commission-status-table-header">Commission Status Examples and Definitions</p>
    <div class="table-container">
      <table class="commission-status-table" ouxThemeable>
        <thead>
          <tr>
            <th>Booking</th>
            <th>Backlog</th>
            <th>Revenue</th>
            <th>Status</th>
            <th>Status Definition</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>100</td>
            <td>100</td>
            <td>0</td>
            <td>Backlog</td>
            <td>The full value of the order is in Backlog. The backlog could be attributed to holds
              or future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>0</td>
            <td>80</td>
            <td>Pending Backlog</td>
            <td>Part of this order is still backlogged but there is a timing delay in the
              recognition of the backlog. The backlog could be attributed to holds or future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>20</td>
            <td>80</td>
            <td>Pending Backlog</td>
            <td>Part of this order is still backlogged. The backlog could be attributed to holds or
              future release date(s).</td>
          </tr>
          <tr>
            <td>100</td>
            <td>0</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this order.</td>
          </tr>
          <tr>
            <td>100</td>
            <td>100</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this order but there is a timing delay in the
              resolution of the backlog.</td>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>100</td>
            <td>Fully Credited</td>
            <td>You have received full credit for this revenue only transaction.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>

</ng-container>
