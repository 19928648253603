<footer class="footer" *ngIf="showFooter">
  <div class="footer__links">
    <ul class="list --bare">
      <li><a class="link" href="http://www.cisco.com/cisco/web/siteassets/contacts/index.html" target="_blank">Contacts</a></li>
      <li><a class="link" href="https://secure.opinionlab.com/ccc01/o.asp?id=jBjOhqOJ" target="_blank">Feedback</a></li>
      <li><a class="link" href="http://www.cisco.com/c/en/us/about/sitemap.html" target="_blank">Site Map</a></li>
      <li><a class="link" href="http://www.cisco.com/web/siteassets/legal/terms_condition.html" target="_blank">Terms &amp; Conditions</a></li>
      <li><a class="link" href="http://www.cisco.com/web/siteassets/legal/privacy_full.html" target="_blank">Privacy Statement</a></li>
      <li><a class="link" href="http://www.cisco.com/web/siteassets/legal/privacy_full.html#cookies" target="_blank">Cookie Policy</a></li>
      <li><a class="link" href="http://www.cisco.com/web/siteassets/legal/trademark.html" target="_blank">Trademarks</a></li>
    </ul>
  </div><!-- /footer__links -->
  <div class="footer__legal">
    <div class="footer__logo">
      <a href="http://www.cisco.com" target="_blank">
        <i class="icon icon-cisco"></i>
      </a>
    </div>
    <div class="footer__copyright">
      <div>Copyright &copy; {{ currentYear }} Cisco Systems Inc.</div>
      <div>All rights reserved.</div>
    </div>
  </div><!-- /footer__legal -->
</footer>
