/* Angular Import */
import { Injectable } from '@angular/core';
import { OuxLoggerService, OuxStore } from '@cisco/oux-common';
import { map, tap } from 'rxjs/operators';

/* Feature Imports */
import { QuotaDetails } from '../models/interface/partials/quota-details';


/**
 * Creates our QuotaDetails State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class QuotaDetailsStore extends OuxStore<QuotaDetails> {

  public details$ = this.state$
    .pipe(
      map(state => state?.o_quota_details || [])
    );

  // Will be used to identify if any entities on quota details model have the iACV flag set to 'Y'
  // If yes, show Metric Details nav item, else hide in UI
  public isIACV$ = this.state$
    .pipe(
      map(stream => stream && stream.o_quota_details && stream.o_quota_details.some(x => x.iacvFlag === 'Y')),
      tap(result => this.ouxLoggerSvc.logDebug('QuotaDetailsStore: Show Metric Details Nav Item:', result))
    )

  constructor(private ouxLoggerSvc: OuxLoggerService) {
    super (null);
  }

  /**
   * ============================================================
   */
   public setQuotaDetailsData(data : QuotaDetails) : void {
    super.setState(data);
    this.ouxLoggerSvc.logDebug('QuotaDetailsStore: QuotaDetails Model State:', this.state);
  }



  /**
   * Clear selected Goalsheet
   * ============================================================
   */
  public clearQuotaDetailsData(): void {
    this.setState(null);
  }

  /**
   * Convenience Methods
   * ============================================================
   */

  public isACV(quotaId: number, compPlanId?: number): boolean {
    let details = this.state?.o_quota_details || [];

    let item = details.find(x => x.quotaID == quotaId && (!compPlanId || x.compPlanID == compPlanId));

    return item?.acvFlag == 'Y';
  }

  public isMyPayout(quotaId: number, compPlanId?: number): boolean {
    let details = this.state?.o_quota_details || [];

    let item = details.find(x => x.quotaID == quotaId && (!compPlanId || x.compPlanID == compPlanId));

    return item?.myPayoutFlag == 'Y';
  }

  public isMulti(quotaId: number, compPlanId?: number): boolean {
    let details = this.state?.o_quota_details || [];

    let item = details.find(x => x.quotaID == quotaId && (!compPlanId || x.compPlanID == compPlanId));

    return item?.multiFlag == 'Y';
  }
}