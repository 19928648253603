import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  combineLatest,
  Observable,
  of,
  Subject,
  throwError
} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  map,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import * as moment from 'moment';
import {
  OuxAuthenticationService,
  OuxConfigService,
  OuxExceptionsHandleError,
  OuxExceptionsHandlerService,
  OuxLoggerService
} from '@cisco/oux-common';
import { OrdersSearchOrderModel } from '../models/concrete/partials/orders-search-order.model';
import { OrdersSearchUnallocatedSummaryModel } from '../models/concrete/partials/orders-search-unallocated-summary.model';
import { PagedResponseModel } from '../models/concrete/partials/paged-response.model';
import { OrdersSearchOrder } from '../models/interface/partials/orders-search-order';
import { OrdersSearchUnallocatedSummary } from '../models/interface/partials/orders-search-unallocated-summary';
import { PagedResponse } from '../models/interface/partials/paged-response';
import { OrdersGoalToCashRequest } from '../models/interface/request/orders-goal-to-cash-request';
import { OrdersRequest } from '../models/interface/request/orders-request';
import { OrdersSearchUnallocatedRequest } from '../models/interface/request/orders-search-unallocated-request';
import { OrdersSearchUnallocatedResponse } from '../models/interface/response/orders-search-unallocated-response';
import { OrderStore } from '../stores/order.store';
import { UserDetailsStore } from '../stores/user-details.store';
import { OrdersGoalToCashRequestModel } from '../models/concrete/request/orders-goal-to-cash-request.model';
import { OrdersLinesAdjustmentsRequest } from '../models/interface/request/orders-lines-adjustments-request';
import { OrdersLinesAdjustments } from '../models/interface/partials/orders-lines-adjustments';
import { OrdersLinesAdjustmentsResponse } from '../models/interface/response/orders-lines-adjustments-response';
import { OrdersLinesAdjustmentsModel } from '../models/concrete/partials/orders-lines-adjustments.model';
import { OrdersLinesRequest } from '../models/interface/request/orders-lines-request';
import { OrdersLinesResponse } from '../models/interface/response/orders-lines-response';
import { OrderLinesStore } from '../stores/order-lines.store';
import { MetadataStore } from '../stores/metadata.store';
import { OrderLinesSummaryModel } from '../models/concrete/partials/order-lines-summary.model';
import { OrderLinesSummary } from '../models/interface/partials/order-lines-summary';
import { OrdersLinesDetailsRequest } from '../models/interface/request/orders-lines-details-request';
import { OrdersLinesDetailsResponse } from '../models/interface/response/orders-lines-details-response';
import { OrderLineTransactionsStore } from '../stores/order-line-transactions.store';
import { OrdersLinesDetails } from '../models/interface/partials/orders-lines-details';
import { OrdersLinesDetailsModel } from '../models/concrete/partials/orders-lines-details.model';
import { QuotaDetailsStore } from '../stores/quota-details.store';
import { OrdersLinesBacklogDetailsResponse } from '../models/interface/response/orders-lines-backlog-details-response';
import { OrdersLinesBacklogDetails } from '../models/interface/partials/orders-lines-backlog-details';
import { OrdersLinesBacklogDetailsModel } from '../models/concrete/partials/orders-lines-backlog-details.model';
import { XaasOrderLinesSummary } from '../models/interface/partials/xaas-order-lines-summary';
import { XaasOrdersLinesResponse } from '../models/interface/response/xaas-orders-lines-response';
import { XaasOrderLinesSummaryModel } from '../models/concrete/partials/xaas-order-lines-summary.model';
import { OrdersSearchTableExportModel } from '../models/concrete/partials/order-search-table-export.model';
//import {MetricOrderViewSearchTableExportModel}            from '../models/concrete/partials/metric-order-view-search-table-export';
import { OrdersExportRequest } from '../models/interface/request/orders-export-request';
import { BaseResponse } from '../models/interface/response/base-response';
import { MetricOrderSearchRequestModel } from '../models/concrete/request/metric-order-serach-request.model';
import { MetricOrderDetails } from '../models/interface/partials/metric-order-details';
import { MetricTransactionSummaryLineDetails } from '../models/interface/partials/metric-transaction-summary-line-details';
import { MetricDeatilsRequestModel } from '../models/concrete/request/metric-details-request.model';
import { OrderRefreshDate } from '../models/interface/partials/order-refresh-date';
import { MetricSOTransactionLineRequestModel } from '../models/concrete/request/metric-order-line-request.model';
import { MetricSOLineTransactionsStore } from '../stores/metric-so-line-transactions.store';
import { MetricSOLinesSummary } from '../models/interface/partials/metric-so-lines-summary';
// import { MetricOrderViewSearchUnallocatedRequest }      from '../models/interface/request/metric-order-view-search-unallocated-request';
// import { MetricOrderViewSearchUnallocatedSummary }      from '../models/interface/partials/metric-order-view-search-unallocated-summary';
// import {MetricOrderViewSearchUnallocatedSummaryModel}   from '../models/concrete/partials/metric-order-view-search-unallocated-summary-model';
//import { PagedResponseModel }                             from '../models/concrete/partials/paged-response.model';
// import { MetricOrderViewSearchOrder }                              from '../models/interface/partials/metric-order-view-search-order';
// import { MetricOrderViewSearchOrderModel }                         from '../models/concrete/partials/metric-order-view-search-order.model';
import {MetricTransactionOrderSearchModel} from '../models/concrete/request/metric-transaction-order-search-request.model';
import {MetricTransactionOrderDetails} from '../models/interface/partials/metric-transaction-order-details'
import { AllocationTransactionType } from '../models/types/allocation-transaction-type.enum';
import { OfflineExport } from '../models/interface/response/offline-export-response';
import { VisibilityPageType } from '../models/types/visibility-page-type.enum';
import { OrdersSearchNotEligibleOrderModel } from '../models/concrete/partials/order-search-not-eligible-order.model';
import { OrdersSearchNotEligibleOrder } from '../models/interface/partials/order-search-not-eligible-order';
import { MetricSONotEligibleLinesSummary } from '../models/interface/partials/metric-so-not-eligible-lines-summary';

@Injectable({ providedIn: 'root' })
export class OrderService {

  private baseUri: string;
  private refreshDateUri: string;
  private orderSearchUri: string;
  private orderSearchCountUri: string;
  private orderSearchGoalToCashUri: string;
  private orderSearchGoalToCashCountUri: string;
  private orderSearchLineAdjustmentsUri: string;
  private orderSearchLinesUri: string;
  private orderSearchOrderLineDetailsUri: string;
  private orderSearchLinesCountUri: string;
  private unallocatedUri: string;
  private metricOrderSearchUri: string;
  private subscriptionIdUri: string;
  private orderExportUri: string;
  private metricDetailsOrderSearchUri: string;
  private metricDetailsOrderSearchExportUri: string;
  private metricDetailsOrderSearchCountUri : string;
  private ampOrderLineExpansionUri : string;
  private metricOrderSearchCountUri: string;
  private orderSearchManualRevLinesUri: string;
  private orderSearchManualRevLinesCountUri: string;
  private ncOrderSearchUri: string;
  private ncMetricTransactionsUri: string;
  private ncRegularLinesUri: string;
  private ncRegularLinesCountUri: string;
  private ncRegularTransactionsUri: string;

  /**
   * Create service mapping for http exception handling
   */
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('OrderService');

   constructor(private http: HttpClient,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxLoggerSvc: OuxLoggerService,
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore,
              private orderStore: OrderStore,
              private orderLinesStore: OrderLinesStore,
              private orderLineTransactionsStore: OrderLineTransactionsStore,
              private metricSOLineTransactionsStore: MetricSOLineTransactionsStore,
              private quotaDetailsStore: QuotaDetailsStore) {

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;

    this.refreshDateUri = apiUri.refreshDate;
    this.orderSearchUri = apiUri.orderSearch;
    this.orderSearchCountUri = apiUri.orderSearchCount;
    this.orderSearchGoalToCashUri = apiUri.goalToCashFlow;
    this.orderSearchGoalToCashCountUri = apiUri.goalToCashFlowCount;
    this.orderSearchLineAdjustmentsUri = apiUri.orderLineAdjustments;
    this.orderSearchLinesUri = apiUri.orderLines;
    this.orderSearchOrderLineDetailsUri = apiUri.orderLineDetails;
    this.orderSearchLinesCountUri = apiUri.orderLinesCount;
    this.unallocatedUri = apiUri.ordersUnallocated;

    this.subscriptionIdUri = apiUri.subscriptionId;
    this.orderExportUri = apiUri.orderExport;
    this.metricDetailsOrderSearchUri = apiUri.metricDetailsOrderSearch;
    this.metricDetailsOrderSearchCountUri = apiUri.metricDetailsOrderSearchCount;
    this.metricDetailsOrderSearchExportUri = apiUri.metricDetailsOrderSearchExport;
    this.ampOrderLineExpansionUri = apiUri.ampOrderLineExpansion;
    this.metricOrderSearchUri = apiUri.metricOrderSearch;
    this.metricOrderSearchCountUri = apiUri.metricOrderSearchCount;
    this.orderSearchManualRevLinesUri = apiUri.manualRevOrderLines;
    this.orderSearchManualRevLinesCountUri = apiUri.manualRevOrderLinesCount;
    this.ncOrderSearchUri = apiUri.notEligibleOrderSearch;
    this.ncMetricTransactionsUri = apiUri.notEligibleMetricTransactions;
    this.ncRegularLinesUri = apiUri.notEligibleRegularLines;
    this.ncRegularLinesCountUri = apiUri.notEligibleRegularLinesCount;
    this.ncRegularTransactionsUri = apiUri.notEligibleRegularTransactions;
  }

  /**
   * Stages our Http Request Headers
   */
  private getOptions(): { headers: HttpHeaders } {

    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
    };

    return OPTIONS;
  }

  /**
   * Retrieves Refresh Date
   * @returns
   */
  public fetchRefreshDate(): Observable<number> {

    let url = `${this.baseUri}${this.refreshDateUri}`;
    let options = this.getOptions();

    let request$ = this.http.get<OrderRefreshDate>(url, options)
      .pipe(
        map(response => {
          if (!response) {
            return null;
          }

          this.orderStore.setOrderRefreshDate(response.refreshDate);
          return response.refreshDate;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchRefreshDate', error)(error);
        })
      );

    return request$;
  }

  /**
   * Search Orders
   * Current callers:
   *  - OrderSearchOrdersComponent
   *  - GoalToCashTransactionsComponent
   * Will store resulting data in corresponding OrderStore for further processing
   */
  public search(request: OrdersRequest | OrdersGoalToCashRequest): Observable<PagedResponse<OrdersSearchOrder>> {

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.searchOrders(request)
      .pipe(
        tap((collection: PagedResponse<OrdersSearchOrder>) => {
          // pass data to corresponding order store for further processing
          this.orderStore.setOrders(collection);
          this.ouxLoggerSvc.logDebug('OrderService: search()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('search', error)(error);
        })
      );

    return request$;
  }

  public searchNotEligibleOrders(request): Observable<OrdersSearchNotEligibleOrder[]> {
    const URL = `${this.baseUri}${this.ncOrderSearchUri}`;
    const OPTIONS = this.getOptions();

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    
    let request$ = this.http.post<OrdersSearchNotEligibleOrder[]>(URL, request, OPTIONS)
      .pipe(
        map(response => {
          if (!response) {
            return throwError(response);
          }
          this.orderStore.setNotEligibleOrders(response);
          return response;
        }),
        tap((response: OrdersSearchNotEligibleOrder[]) => {
          this.ouxLoggerSvc.logDebug('OrderService: searchNotEligibleOrders()', response);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchNotEligibleOrders', error)(error);
        })
      );

    return request$;
  }

  public searchMetricDetailsCount(request: OrdersRequest | OrdersGoalToCashRequest): Observable<number> {
    const URL = `${this.baseUri}${this.metricDetailsOrderSearchCountUri}`;
    const OPTIONS = this.getOptions();

    let urlRequest = new MetricOrderSearchRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      //metricId: request.metricId,
      salesOrderNumber: String(request.salesOrderNumber),
      sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
      //groupBy: request.groupBy,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy,
      sortOrder: request.sortOrder,
    })

    let request$ = this.http.post<{ RECORD_COUNT: number }[]>(URL, urlRequest, OPTIONS)
      .pipe(
        map(response => {
          if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
            return 0;
          }
          return response[0].RECORD_COUNT;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchMetricDetailsCount', error)(error);
        })
      );

    return request$;
  }

  public searchMetricDetails(request: OrdersRequest | OrdersGoalToCashRequest): Observable<MetricOrderDetails[]> {

    const URL = `${this.baseUri}${this.metricDetailsOrderSearchUri}`;
    const OPTIONS = this.getOptions();
    let urlRequest = new MetricOrderSearchRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      //metricId: request.metricId,
      salesOrderNumber: String(request.salesOrderNumber),
      sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
      //groupBy: request.groupBy,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy,
      sortOrder: request.sortOrder,
    })

    let request$ = this.http.post<MetricOrderDetails[]>(URL, urlRequest, OPTIONS)
      .pipe(
        map(response => {
          if (!response) {
            return 0;
          }
          this.orderStore.setMetricOrders(response);
        }),
        tap((response: any) => {
          // pass data to corresponding order store for further processing

          this.ouxLoggerSvc.logDebug('OrderService For Metric Details: search()', response);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('metricOrderDetails', error)(error);
        })
      );

    return request$;

  }
  /**
   * Fetch metric transaction table on order search on H2 2023 (Metric Flag is true)
   * @param request
   * @returns
   */
  public fetchMetricTransactionOrderSearch(request): Observable<MetricTransactionOrderDetails[]> {

    const URL = `${this.baseUri}${this.metricOrderSearchUri}`;
    const OPTIONS = this.getOptions();
    let urlRequest = new MetricTransactionOrderSearchModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      salesOrderNumber: String(request.salesOrderNumber),
      quotaId:null,
      goalId:null,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy,
      sortOrder: request.sortOrder,
    })

    let request$ = this.http.post<MetricTransactionOrderDetails[]>(URL, urlRequest, OPTIONS)
    .pipe(
      map(response => {
        if (!response) {
          return 0;
        }

        this.orderStore.setMetricTransactionOrders(response);
        return response;
      }),
      tap((response: any) => {
        // pass data to corresponding order store for further processing

        this.ouxLoggerSvc.logDebug('OrderService For Metric Details: search()', response);
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('metricOrderDetails', error)(error);
      })
    );

    return request$;
  }

   /**
       * Fetch metric transaction table on order search on H2 2023
       * @param request
       * @returns
       */
    public fetchMetricTransactionOrderSearchCount(request): Observable<any> {

      const URL = `${this.baseUri}${this.metricOrderSearchCountUri}`;
      const OPTIONS = this.getOptions();
      let urlRequest = new MetricTransactionOrderSearchModel({
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        salesOrderNumber: String(request.salesOrderNumber),
        quotaId:null,
        goalId:null,
        limit: request.limit,
        offset: request.offset,
        sortBy: request.sortBy,
        sortOrder: request.sortOrder,
      })

      let request$ = this.http.post<{ RECORD_COUNT: number }[]>(URL, urlRequest, OPTIONS)
      .pipe(
        map(response => {
          if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
            return 0;
          }


          return response[0].RECORD_COUNT;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchMetricDetailsCount', error)(error);
        })
      );

    return request$;


    }
  public searchMetricDetailsForExport(request: OrdersRequest | OrdersGoalToCashRequest): Observable<MetricOrderDetails[]> {

    const URL = `${this.baseUri}${this.metricDetailsOrderSearchUri}`;
    const OPTIONS = this.getOptions();
    let urlRequest = new MetricOrderSearchRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      //metricId: request.metricId,
      salesOrderNumber: String(request.salesOrderNumber),
      sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
      //groupBy: request.groupBy,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy,
      sortOrder: request.sortOrder,
    })

    let request$ = this.http.post<MetricOrderDetails[]>(URL, urlRequest, OPTIONS)
      .pipe(switchMap(response => {
        if (!response) {
          return throwError(response);
        }
        return of(response);
      }),
        map(response => {
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchMetricOrderSearchExport', error)(error);
        })
      );

    return request$;

  }

  public exportForMetricDetailsFilterSearch(request: MetricDeatilsRequestModel): Observable<MetricTransactionSummaryLineDetails[]> {

    const URL = `${this.baseUri}${this.metricDetailsOrderSearchExportUri}`;
    const OPTIONS = this.getOptions();

    let urlRequest = {
      loginId: this.userDetailsStore.getUserId(),
      goalId: null,
      userId: this.userDetailsStore.getImpersonationUserId(),
      metricId: null,
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      salesOrderNumber: null,
      quotaId: request.quotaId ? request.quotaId : null,
      savId: request.savId ? request.savId : null,
      fiscalWeek: null,
      sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
      beCXLevel1: request.beCXLevel1 ? request.beCXLevel1 : null,
      beCXLevel2: request.beCXLevel2 ? request.beCXLevel2 : null,
      beCXLevel3: request.beCXLevel3 ? request.beCXLevel3 : null,
      cxUpsellGroup: request.cxUpsellGroup ? request.cxUpsellGroup : null,
      goalFiscalYear: request.goalFiscalYear ? request.goalFiscalYear : null,
      planYear: request.planYear ? request.planYear : null,
      productServiceFlag: request.productServiceFlag ? request.productServiceFlag : null,
      source_system: this.ouxConfigSvc.getAppConfigValue('source_system'),
      territoryCode: request.territoryCode ? request.territoryCode : null
    }

    let request$ = this.http.post<MetricTransactionSummaryLineDetails[]>(URL, urlRequest, OPTIONS)
      .pipe(switchMap(response => {
        if (!response) {
          return throwError(response);
        }
        return of(response);
      }),
        map(response => {
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchMetricOrderSearchExport', error)(error);
        })
      );

    return request$;

  }

  /**
   * Fetch Order Search Export Data
   * Current callers:
   *  - OrderSearchTableExportComponent
   *
   * Returns: OrdersSearchOrder
   */
  public fetchOrderSearchExportData(request: OrdersRequest | OrdersGoalToCashRequest): Observable<PagedResponse<OrdersSearchOrder>> {

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.searchOrders(request)
      .pipe(
        tap((collection: PagedResponse<OrdersSearchOrder>) => {
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderSearchExportData()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderSearchExportData', error)(error);
        })
      );

    return request$;
  }

    /**
   * Fetch Order Search Not Eligible Export Data
   * Current callers:
   *  - OrderSearchTableExportComponent
   *
   * Returns: OrdersSearchNotEligibleOrder
   */
     public fetchNotEligibleOrderSearchExportData(request: any): Observable<OrdersSearchNotEligibleOrder[]> {

      request.loginId = this.userDetailsStore.getUserId();
      request.userId = this.userDetailsStore.getImpersonationUserId();
  
      let request$ = this.searchNotEligibleOrders(request)
        .pipe(
          tap((collection: OrdersSearchNotEligibleOrder[]) => {
            this.ouxLoggerSvc.logDebug('OrderService: fetchNotEligibleOrderSearchExportData()', collection);
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchNotEligibleOrderSearchExportData', error)(error);
          })
        );
  
      return request$;
    }

  /**
   * Fetch Goal To Cash Export Data
   */
  public fetchGoalToCashExportData(request: OrdersRequest | OrdersGoalToCashRequest): Observable<PagedResponse<OrdersSearchOrder>> {

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.searchOrders(request)
      .pipe(
        tap((collection: PagedResponse<OrdersSearchOrder>) => {
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderSearchExportData()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderSearchExportData', error)(error);
        })
      );

    return request$;
  }

  /**
   * Fetch Goal To Cash Export Data
   */
  public fetchGoalToCashAdjustmentExportData(request: OrdersLinesAdjustmentsRequest): Observable<PagedResponse<OrdersSearchOrder>> {

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.fetchOrderLineAdjustments(request)
      .pipe(
        tap((collection: PagedResponse<OrdersSearchOrder>) => {
          this.ouxLoggerSvc.logDebug('OrderService: fetchGoalToCashAdjustmentExportData()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchGoalToCashAdjustmentExportData', error)(error);
        })
      );

    return request$;
  }

  /**
   * Search Orders
   * Base method - Retrieves and returns resulting data set
   */
  private searchOrders(request: OrdersRequest | OrdersGoalToCashRequest): Observable<PagedResponse<OrdersSearchOrder>> {

    let isGoalToCashRequest = request instanceof OrdersGoalToCashRequestModel;
    let url = `${this.baseUri}${!isGoalToCashRequest ? this.orderSearchUri : this.orderSearchGoalToCashUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = combineLatest([
      this.http.post<OrdersSearchOrder[]>(url, request, options),
      request.offset == 0 ? this.searchCount(request).pipe(catchError(() => of(null))) : this.orderStore.totalOrders$.pipe(take(1))
    ])
      .pipe(
        map(([response, count]) => {
          let typed = (response || []).map(x => {
            let isACV = this.quotaDetailsStore.isACV(x.QUOTA_ID);
            let isMulti = this.quotaDetailsStore.isMulti(x.QUOTA_ID, x.COMP_PLAN_ID);
            let isMyPayout = this.quotaDetailsStore.isMyPayout(x.QUOTA_ID, x.COMP_PLAN_ID);
            let updates: Partial<OrdersSearchOrder> = {};

            // Added to handle Orders with TYPE = XAAS
            // if(isMyPayout && !isMulti)
            //   updates.REVENUE_FOR_BACKLOG = x.MY_PAYOUT_AMOUNT_USD;
            // else
            //   updates.REVENUE_FOR_BACKLOG = x.REVENUE;

            // BOOKING needs to be updated before the below conditions that update REVENUE


            if (isMyPayout && isMulti) {
              x.REVENUE = x.EXTD_MY_PAYOUT_AMOUNT_USD;
              updates.REVENUE_FOR_BACKLOG = x.EXTD_MY_PAYOUT_AMOUNT_USD;
            } else if (isMyPayout && !isMulti) {
              x.REVENUE = x.MY_PAYOUT_AMOUNT_USD;
              updates.REVENUE_FOR_BACKLOG = x.MY_PAYOUT_AMOUNT_USD;
            } else if (!isMyPayout && isMulti) {
              x.REVENUE = x.REVENUE_MULTI;
              updates.REVENUE_FOR_BACKLOG = x.REVENUE_MULTI;
            } else if (!isMyPayout && !isMulti) {
              x.REVENUE = x.REVENUE;
              updates.REVENUE_FOR_BACKLOG = x.REVENUE;
            }

            if (isACV && !(x.TYPE === AllocationTransactionType.BookingAdjustment || x.TYPE === AllocationTransactionType.BookingAdjustments || x.TYPE === AllocationTransactionType.ManualRevenue)) {
              updates.BOOKING = x.REVENUE + x.BACKLOG;
            }

            return new OrdersSearchOrderModel({ ...x, ...updates });
          });

          return new PagedResponseModel<OrdersSearchOrder>({
            records: typed,
            total: count || typed.length || 0
          });
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchOrders', error)(error);
        })
      );

    return request$;
  }


  /**
   * Search Order Count
   * @param request
   * @returns
   */
  public searchCount(request: OrdersRequest | OrdersGoalToCashRequest): Observable<number> {

    let isGoalToCashRequest = request instanceof OrdersGoalToCashRequestModel;
    let url = `${this.baseUri}${!isGoalToCashRequest ? this.orderSearchCountUri : this.orderSearchGoalToCashCountUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let recordsCount = 0;
    let ampRecordsCount = 0;

    let request$ = this.http.post<{ RECORD_COUNT: number, AMPLIFIER_RECORD_COUNT: number }[]>(url, request, options)
      .pipe(
        map(response => {
          if (!response || response.length == 0 || !response[0]) {
            return 0; //total records is 0
          } else {
            // update recordsCount and ampRecordsCount if they exist in the response
            if (response[0].RECORD_COUNT) {
              recordsCount = response[0].RECORD_COUNT;
            }
            if (response[0].AMPLIFIER_RECORD_COUNT) {
              ampRecordsCount = response[0].AMPLIFIER_RECORD_COUNT;
            }
            return recordsCount + ampRecordsCount; //total records is sum of regular & amplifier records
          }
        }),
        tap(value => {
          this.orderStore.setTotalOrders(recordsCount);
          this.orderStore.setAmpOrders(ampRecordsCount);
          this.ouxLoggerSvc.logDebug('OrderService: searchCount()', value);
        })
      );

    return request$;
  }

  public fetchOrderLines(request: OrdersLinesRequest): Observable<PagedResponseModel<OrderLinesSummary>> {
    let url = `${this.baseUri}${this.orderSearchLinesUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<OrdersLinesResponse>(url, request, options)
        .pipe(
          tap(records => {
            if ((records?.data?.P_ORDER_SUMMARY?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
      this.fetchOrderLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of({ response, count });
        }),
        map(({ response, count }) => {
          let typed = (response?.data?.P_ORDER_SUMMARY || [])
            .map(order => {
              let isACV = this.quotaDetailsStore.isACV(request.allocationId);
              let isMulti = this.quotaDetailsStore.isMulti(request.allocationId, request.planId);
              let updates: Partial<OrderLinesSummaryModel> = {
                BOOKING_AMT: order.COMM_BOOKING
              };

              if (isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.EXTD_MY_PAYOUT_AMOUNT;
              } else if (isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.SALES_VALUE_MULTIPLIED;
              } else if (!isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.MY_PAYOUT_AMOUNT;
              } else if (!isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.COMM_REVENUE;
              }

              if (isACV) {
                updates.BOOKING_AMT = order.COMM_REVENUE + order.COMM_BACKLOG;
              }

              return new OrderLinesSummaryModel({ ...order, ...updates });
            });

          return new PagedResponseModel<OrderLinesSummary>({
            records: typed,
            total: count || typed.length
          });
        }),
        tap(paged => {
          // pass data to corresponding order lines store for further processing
          this.orderLinesStore.setLines(paged);
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderLines()', paged);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderLines', error)(error);
        })
      );

    return request$;
  }

  public fetchNotEligibleOrderLines(request: any): Observable<PagedResponseModel<OrderLinesSummary>> {
    let url = `${this.baseUri}${this.ncRegularLinesUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<any>(url, request, options)
        .pipe(
          tap(records => {
            if ((records?.length || 0) === 0) {
              countNotifier.next();
            }
          })
        ),
      this.fetchNotEligibleOrderLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || (response?.length > 0 && !response[0])) {
            return throwError(response);
          }

          return of({ response, count });
        }),
        map(({ response, count }) => {
          let typed = (response || [])
            .map(order => {
              return new OrderLinesSummaryModel(order);
            });

          return new PagedResponseModel<OrderLinesSummary>({
            records: typed,
            total: count || typed.length
          });
        }),
        tap(paged => {
          // pass data to corresponding order lines store for further processing
          this.orderLinesStore.setLines(paged);
          this.ouxLoggerSvc.logDebug('OrderService: fetchNotEligibleOrderLines()', paged);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchNotEligibleOrderLines', error)(error);
        })
      );

    return request$;
  }

  public fetchNotEligibleXaasOrderLines(request: any): Observable<PagedResponseModel<XaasOrderLinesSummary>> {
    let url = `${this.baseUri}${this.ncRegularLinesUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<any>(url, request, options)
        .pipe(
          tap(records => {
            if ((records?.length || 0) === 0) {
              countNotifier.next();
            }
          })
        ),
      this.fetchNotEligibleOrderLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || (response?.length > 0 && !response[0])) {
            return throwError(response);
          }

          return of({ response, count });
        }),
        map(({ response, count }) => {
          let typed = (response || [])
            .map(order => {
              return new XaasOrderLinesSummaryModel(order);
            });

          return new PagedResponseModel<XaasOrderLinesSummary>({
            records: typed,
            total: count || typed.length
          });
        }),
        tap(paged => {
          // pass data to corresponding order lines store for further processing
          this.orderLinesStore.setXaasLines(paged);
          this.ouxLoggerSvc.logDebug('OrderService: fetchNotEligibleXaasOrderLines()', paged);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchNotEligibleXaasOrderLines', error)(error);
        })
      );

    return request$;
  }

  public fetchManualRevOrderLines(request: OrdersLinesRequest): Observable<PagedResponseModel<OrderLinesSummary>> {
    let url = `${this.baseUri}${this.orderSearchManualRevLinesUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<OrdersLinesResponse>(url, request, options)
        .pipe(
          tap(records => {
            if ((records?.data?.P_ORDER_SUMMARY?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
      this.fetchManualRevOrderLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of({ response, count });
        }),
        map(({ response, count }) => {
          let typed = (response?.data?.P_ORDER_SUMMARY || [])
            .map(order => {
              let isACV = this.quotaDetailsStore.isACV(request.allocationId);
              let isMulti = this.quotaDetailsStore.isMulti(request.allocationId, request.planId);
              let updates: Partial<OrderLinesSummaryModel> = {
                BOOKING_AMT: order.COMM_BOOKING
              };

              if (isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.EXTD_MY_PAYOUT_AMOUNT;
              } else if (isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.SALES_VALUE_MULTIPLIED;
              } else if (!isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.MY_PAYOUT_AMOUNT;
              } else if (!isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.COMM_REVENUE;
              }

              if (isACV) {
                // updates.BOOKING_AMT = order.COMM_REVENUE + order.COMM_BACKLOG;
              }

              return new OrderLinesSummaryModel({ ...order, ...updates });
            });

          return new PagedResponseModel<OrderLinesSummary>({
            records: typed,
            total: count || typed.length
          });
        }),
        tap(paged => {
          // pass data to corresponding order lines store for further processing
          this.orderLinesStore.setLines(paged);
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderLines()', paged);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderLines', error)(error);
        })
      );

    return request$;
  }

  public fetchXaasOrderLines(request: OrdersLinesRequest): Observable<PagedResponseModel<XaasOrderLinesSummary>> {
    let url = `${this.baseUri}${this.orderSearchLinesUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();
    let countNotifier = new Subject<void>();

    let request$ = combineLatest([
      this.http.post<XaasOrdersLinesResponse>(url, request, options)
        .pipe(
          tap(records => {
            if ((records?.data?.P_ORDER_SUMMARY?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
      this.fetchOrderLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of({ response, count });
        }),
        map(({ response, count }) => {
          let typed = (response?.data?.P_ORDER_SUMMARY || [])
            .map(order => {
              let isACV = this.quotaDetailsStore.isACV(request.allocationId);
              let isMulti = this.quotaDetailsStore.isMulti(request.allocationId, request.planId);
              let updates: Partial<XaasOrderLinesSummaryModel> = {
                BOOKING_AMT: order.COMM_BOOKING
              };

              if (isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.EXTD_MY_PAYOUT_AMOUNT;
              } else if (isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.SALES_VALUE_MULTIPLIED;
              } else if (!isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.MY_PAYOUT_AMOUNT;
              } else if (!isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.COMM_REVENUE;
              }

              if (isACV) {
                updates.BOOKING_AMT = order.COMM_REVENUE + order.COMM_BACKLOG;
              }

              return new XaasOrderLinesSummaryModel({ ...order, ...updates });
            });

          return new PagedResponseModel<XaasOrderLinesSummary>({
            records: typed,
            total: count || typed.length
          });
        }),
        tap(paged => {
          // pass data to corresponding order lines store for further processing
          this.orderLinesStore.setXaasLines(paged);
          this.ouxLoggerSvc.logDebug('OrderService: fetchXaasOrderLines()', paged);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchXaasOrderLines', error)(error);
        })
      );

    return request$;
  }

  public fetchOrderLinesCount(request: OrdersLinesRequest): Observable<number> {
    let url = `${this.baseUri}${this.orderSearchLinesCountUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersLinesResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: OrdersLinesResponse) => {
          let total = response?.data?.P_TOT_RECORDS;

          return total;
        })
      );

    return request$;
  }

  public fetchNotEligibleOrderLinesCount(request: OrdersLinesRequest): Observable<number> {
    let url = `${this.baseUri}${this.ncRegularLinesCountUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<{ RECORD_COUNT: number }[]>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || response.length === 0 || !response[0] || !response[0].RECORD_COUNT) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: any) => {
          let total = response[0]?.RECORD_COUNT;

          return total;
        })
      );

    return request$;
  }


  public fetchManualRevOrderLinesCount(request: OrdersLinesRequest): Observable<number> {
    let url = `${this.baseUri}${this.orderSearchManualRevLinesCountUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersLinesResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: OrdersLinesResponse) => {
          let total = response?.data?.P_TOT_RECORDS;

          return total;
        })
      );

    return request$;
  }

  public fetchOrderLineDetails(request: OrdersLinesDetailsRequest): Observable<OrdersLinesDetails> {
    let url = `${this.baseUri}${this.orderSearchOrderLineDetailsUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersLinesDetailsResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: OrdersLinesDetailsResponse) => {
          let typed = new OrdersLinesDetailsModel(response?.data);

          return typed;
        }),
        tap(details => {
          // pass data to corresponding order lines store for further processing
          this.orderLineTransactionsStore.setDetails(details);
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderLineDetails()', details);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderLineDetails', error)(error);
        })
      );

    return request$;
  }

  public fetchNotEligibleOrderLineDetails(request: any): Observable<OrdersLinesDetails> {
    let url = `${this.baseUri}${this.ncRegularTransactionsUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.employeeId = this.metadataStore.getMetadataEmployeeId();

    let options = this.getOptions();

    let request$ = this.http.post<any>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response[0]) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: any) => {
          let typed = new OrdersLinesDetailsModel({});
          typed.P_TRANSACTIONS = response;

          return typed;
        }),
        tap(details => {
          // pass data to corresponding order lines store for further processing
          this.orderLineTransactionsStore.setDetails(details);
          this.ouxLoggerSvc.logDebug('OrderService: fetchNotEligibleOrderLineDetails()', details);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchNotEligibleOrderLineDetails', error)(error);
        })
      );

    return request$;
  }

  public fetchOrderLineBacklogDetails(request: OrdersLinesDetailsRequest): Observable<OrdersLinesBacklogDetails> {
    let url = `${this.baseUri}${this.orderSearchOrderLineDetailsUri}`;

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersLinesBacklogDetailsResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: OrdersLinesBacklogDetailsResponse) => {
          let typed = new OrdersLinesBacklogDetailsModel(response?.data);

          return typed;
        }),
        tap(details => {
          // pass data to corresponding order lines store for further processing
          this.orderLineTransactionsStore.setBacklogDetails(details);
          this.ouxLoggerSvc.logDebug('OrderService: fetchOrderLineBacklogDetails()', details);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderLineBacklogDetails', error)(error);
        })
      );

    return request$;
  }


 /* Order Line API Call */

  public fetchMetricOrderLineDetails(request: MetricSOTransactionLineRequestModel): Observable<MetricSOLinesSummary[]> {
    let url = `${this.baseUri}${this.ampOrderLineExpansionUri}`;

    // TODO: uncomment after actual input data is available
    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<MetricSOLinesSummary>(url, request, options)
      .pipe(
        switchMap(response => {
          return of(response);
        }),
        map((response: MetricSOLinesSummary) => {
          return response;
        }),
        tap(details => {
          // pass data to metricSOLineTransactionsStore for further processing
          this.metricSOLineTransactionsStore.setDetails(details);
          this.ouxLoggerSvc.logDebug('OrderService: fetchMetricOrderLineDetails()', details);
        }),
        catchError(error => {
          // error handling
          return this.ouxHandleError('fetchMetricOrderLineDetails', error)(error);
        })
      );

    return request$;
  }

  public fetchNotEligibleMetricOrderLineDetails(request: any): Observable<MetricSOLinesSummary[]> {
    let url = `${this.baseUri}${this.ncMetricTransactionsUri}`;
    
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.employeeId = this.metadataStore.getMetadataEmployeeId();

    let options = this.getOptions();

    let request$ = this.http.post<MetricSOLinesSummary>(url, request, options)
      .pipe(
        switchMap(response => {
          return of(response);
        }),
        map((response: MetricSOLinesSummary) => {
          return response;
        }),
        tap(details => {
          // pass data to metricSOLineTransactionsStore for further processing
          this.metricSOLineTransactionsStore.setDetails(details);
          this.ouxLoggerSvc.logDebug('OrderService: fetchNotEligibleMetricOrderLineDetails()', details);
        }),
        catchError(error => {
          // error handling
          return this.ouxHandleError('fetchNotEligibleMetricOrderLineDetails', error)(error);
        })
      );

    return request$;
  }

  /**
   * Search Adjustments
   * Current callers:
   *  - GoalToCashTransactionsComponent
   */
  public searchAdjustments(request: OrdersLinesAdjustmentsRequest): Observable<OrdersLinesAdjustments> {

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let request$ = this.fetchOrderLineAdjustments(request)
      .pipe(
        tap((collection: PagedResponseModel<OrdersSearchOrder>) => {
          // pass data to corresponding order store for further processing
          this.orderStore.setOrders(collection);
          this.ouxLoggerSvc.logDebug('OrderService: searchAdjustments()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchAdjustments', error)(error);
        })
      );

    return request$;
  }

  /**
   * Fetch Order Line Adjustments
   */
  private fetchOrderLineAdjustments(request: OrdersLinesAdjustmentsRequest): Observable<PagedResponseModel<OrdersSearchOrder>> {

    let url = `${this.baseUri}${this.orderSearchLineAdjustmentsUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersLinesAdjustmentsResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response: OrdersLinesAdjustmentsResponse) => {
          let typed = new OrdersLinesAdjustmentsModel(response.data);

          return typed;
        }),
        map((adjustments: OrdersLinesAdjustments) => {
          let isACV = this.quotaDetailsStore.isACV(request.allocationId);
          /**
           * Responsible for mapping OrdersLinesAdjustmentsModel to conform with
           * OrderModel so we can leverage the same data object in our corresponding order store for further processing
           */
          let orders = (adjustments.P_GTC_ADJ || []).map(x => new OrdersSearchOrderModel({
            BACKLOG: null,
            BOOKING: !isACV ? x.commBooking : x.commRevenueAmt,
            COMP_PLAN_ID: null,
            CURRENCY_CODE: null,
            DEAL_ID: null,
            EMPLOYEE_ID: null,
            END_CUSTOMER: null,
            ERP_POS_FLAG: null,
            EXTD_MY_PAYOUT_AMOUNT_USD: null,
            MY_PAYOUT_AMOUNT_USD: null,
            NODE_NAME: x.TERRITORYCODE,
            NODE_NAME_DESC: request.nodeName ? request.nodeName : x.NODENAMEDESC ? x.NODENAMEDESC : null,
            ORDER_TYPE: null,
            PEC: x.category,
            PLAN_ELEMENT_DESCR: x.pec,
            PO_NUMBER: null,
            QUOTA_ID: null,
            REVENUE: x.commRevenueAmt,
            REVENUE_MULTI: null,
            SELL_TYPE: null,
            SO_DATE: null,
            SO_NUMBER: null,
            SRP_GOAL_HEADER_ID: null,
            SRP_GOAL_QUOTA_ID: null,
            TERRITORY_TYPE_CODE: null,
            TYPE: 'Credit/Debit Memo'
          }));

          let typed = new PagedResponseModel<OrdersSearchOrder>({ records: orders, total: orders.length });
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchOrderLineAdjustments', error)(error);
        })
      );

    return request$;
  }

  /**
   * Fetch Orders Unallocated
   * @param request
   * @returns
   */
  public fetchUnallocated(request: OrdersSearchUnallocatedRequest): Observable<OrdersSearchUnallocatedSummary[]> {

    let url = `${this.baseUri}${this.unallocatedUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersSearchUnallocatedResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = (response?.data?.P_ORDER_SUMMARY || []).map(x => new OrdersSearchUnallocatedSummaryModel(x));

          return typed;
        }),
        tap(unallocated => {
          /**
           * Responsible for mapping UnallocatedSummaryModel to conform to
           * OrderModel so we can leverage the same data object in our corresponding order store for further processing
           */
          let orders = unallocated.map(x => new OrdersSearchOrderModel({
            BACKLOG: x.backlog,
            BOOKING: x.booking,
            COMP_PLAN_ID: null,
            CURRENCY_CODE: null,
            DEAL_ID: x.dealID,
            EMPLOYEE_ID: null,
            END_CUSTOMER: x.endCustomer,
            ERP_POS_FLAG: null,
            EXTD_MY_PAYOUT_AMOUNT_USD: null,
            MY_PAYOUT_AMOUNT_USD: null,
            NODE_NAME: null,
            NODE_NAME_DESC: null,
            ORDER_TYPE: null,
            PEC: 'Not my order', // is a fixed value at this time 8/27/2021
            PLAN_ELEMENT_DESCR: null,
            PO_NUMBER: x.poNumber,
            QUOTA_ID: null,
            REVENUE: x.revenue,
            REVENUE_MULTI: null,
            SELL_TYPE: null,
            SO_DATE: moment(x.bookDate).format('DD-MMM-YYYY'),
            SO_NUMBER: x.orderNumber,
            SRP_GOAL_HEADER_ID: null,
            SRP_GOAL_QUOTA_ID: null,
            TERRITORY_TYPE_CODE: null,
            TYPE: x.type,
            TCV_AMOUNT: x.tcv_amunt,
            ACV_AMOUNT: x.acv_amount
          }));

          this.orderStore.setOrders(new PagedResponseModel<OrdersSearchOrder>({ records: orders, total: orders.length }));
          this.ouxLoggerSvc.logDebug('OrderService: fetchUnallocated()', { records: orders, total: orders.length });
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchUnallocated', error)(error);
        })
      );

    return request$;
  }



  /**
   * Export Feature
   * Order Search - Table Export
   */
  public fetchAllUnallocated(request: OrdersSearchUnallocatedRequest): Observable<OrdersSearchTableExportModel[]> {

    let url = `${this.baseUri}${this.unallocatedUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersSearchUnallocatedResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = (response?.data?.P_ORDER_SUMMARY || []).map(x => new OrdersSearchUnallocatedSummaryModel(x));

          return typed;
        }),
        map(unallocated => {
          /**
           * Responsible for mapping UnallocatedSummaryModel to conform to
           * OrderModel so we can leverage the same data object in our corresponding order store for further processing
           */
          let orders = unallocated.map(x => new OrdersSearchTableExportModel({
            BACKLOG: x.backlog,
            BOOKING: x.booking,
            COMP_PLAN_ID: null,
            CURRENCY_CODE: null,
            DEAL_ID: x.dealID,
            EMPLOYEE_ID: null,
            END_CUSTOMER: x.endCustomer,
            ERP_POS_FLAG: null,
            EXTD_MY_PAYOUT_AMOUNT_USD: null,
            MY_PAYOUT_AMOUNT_USD: null,
            NODE_NAME: null,
            NODE_NAME_DESC: null,
            ORDER_TYPE: null,
            PEC: 'Not my order', // is a fixed value at this time 8/27/2021
            PLAN_ELEMENT_DESCR: null,
            PO_NUMBER: x.poNumber,
            QUOTA_ID: null,
            REVENUE: x.revenue,
            REVENUE_MULTI: null,
            SELL_TYPE: null,
            SO_DATE: moment(x.bookDate).format('DD-MMM-YYYY'),
            SO_NUMBER: x.orderNumber,
            SRP_GOAL_HEADER_ID: null,
            SRP_GOAL_QUOTA_ID: null,
            TERRITORY_TYPE_CODE: null,
            TYPE: x.type
          }));


          return orders;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchUnallocated', error)(error);
        })
      );

    return request$;
  }

  /**
    * Fetch Orders by Subscription ID
    * @param request
    * @returns
    */
  public fetchSubscriptionId(request: OrdersRequest): Observable<any> {
    // todo: can't find any valid input for this request so not sure what the response looks like

    let url = `${this.baseUri}${this.subscriptionIdUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let options = this.getOptions();

    let request$ = this.http.post<OrdersSearchUnallocatedResponse>(url, request, options)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = (response?.data?.P_ORDER_SUMMARY || []).map(x => new OrdersSearchUnallocatedSummaryModel(x));

          return typed;
        }),
        tap(unallocated => {
          let orders = unallocated.map(x => new OrdersSearchOrderModel({
            BACKLOG: x.backlog,
            BOOKING: x.booking,
            COMP_PLAN_ID: null,
            CURRENCY_CODE: null,
            DEAL_ID: x.dealID,
            EMPLOYEE_ID: null,
            END_CUSTOMER: x.endCustomer,
            ERP_POS_FLAG: null,
            EXTD_MY_PAYOUT_AMOUNT_USD: null,
            MY_PAYOUT_AMOUNT_USD: null,
            NODE_NAME: null,
            NODE_NAME_DESC: null,
            ORDER_TYPE: null,
            PEC: null,
            PLAN_ELEMENT_DESCR: null,
            PO_NUMBER: x.poNumber,
            QUOTA_ID: null,
            REVENUE: x.revenue,
            REVENUE_MULTI: null,
            SELL_TYPE: null,
            SO_DATE: moment(x.bookDate).format('DD-MMM-YYYY'),
            SO_NUMBER: x.orderNumber,
            SRP_GOAL_HEADER_ID: null,
            SRP_GOAL_QUOTA_ID: null,
            TERRITORY_TYPE_CODE: null,
            TYPE: x.type
          }));

          this.orderStore.setOrders(new PagedResponseModel<OrdersSearchOrder>({ records: orders, total: orders.length }));
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchSubscriptionId', error)(error);
        })
      );

    return request$;
  }
  public queueExport(request: OrdersExportRequest): Observable<number> {
    const URL = `${this.baseUri}${this.orderExportUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = request.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = request.filters.loginId = this.userDetailsStore.getUserId();
    request.userId = request.filters.userId = this.userDetailsStore.getImpersonationUserId();

    let newRequest = { ...request, ...{ filters: JSON.stringify(request.filters) }};

    const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: OfflineExport) => {
          let typed = response?.data?.SEQUENCE_ID ;
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('search', error)(error);
        })
      );

    return REQUEST$;
  }
}


