/* Angular Imports */
import {
  Component,
  OnInit }                              from '@angular/core';
import { Subscription }                 from 'rxjs';
import {
  filter,
  map,
  withLatestFrom }                      from 'rxjs/operators';
/* OneUX Imports */
import {
  OuxThemeType,
  OuxTooltipOptions,
  OuxTooltipPosition,
  OuxWindowRefService }                 from '@cisco/oux-common';
import { GoogleAnalyticsService }       from 'src/app/core/services';
import { UserDetailsStore }             from 'src/app/shared/stores/user-details.store';
import { ToolbarSettingsComponent }     from '../toolbar-settings/toolbar-settings.component';
import { MetadataStore }                from 'src/app/shared/stores/metadata.store';
import { DemoUserStore }                from 'src/app/shared/stores/demo-user.store';



@Component({
  selector: 'toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
  host: {
    'class': 'toolbar-user'
  }
})
export class ToolbarUserComponent implements OnInit {

  private subscriptions: Subscription[] = [];

  /**
   * Represents native window object
   */
  private windowRef : any;

  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null)
    );

  public userName$ = this.userDetailsStore.state$
  .pipe(
    map(user => user ? user.name || user.uid : '')
  );

  public userId$ = this.userDetailsStore.state$
    .pipe(
      map(user => user ? user.uid : '')
    );

  public userImpersonationName$ = this.userDetailsStore.impersonationUserFullName$
    .pipe(
      withLatestFrom(this.demoUserStore.state$, this.metadataStore.userRole$),
      map(([user, mask, role]) => {
        if (mask) {
          return this.demoUserStore.getMaskedUserDetailsByRoleType(role)?.userName;
        }
        return user;
      })
    );

  public userEmployeeId$ = this.userDetailsStore.state$
    .pipe(
      withLatestFrom(this.demoUserStore.state$, this.metadataStore.userRole$),
      map(([user, mask, role]) => {
        if (mask) {
          this.setUserAvatar(this.demoUserStore.getMaskedUserDetailsByRoleType(role)?.userImage, mask);
          return this.demoUserStore.getMaskedUserDetailsByRoleType(role)?.employeeId;
        }

        this.setUserAvatar(this.metadataStore.getMetadataEmployeeEmail());
        return this.metadataStore.getMetadataEmployeeId();
      })
    );

  public userEmployeeID: string;

  /**
   * Represents the users CEC username
   */
  public currentUser : string = null;
  /**
   * Represents the users parsed initials
   * Note: Will only show if the users image is not available
   */
  public userInitial : string = null;
  /**
   * Represents the users CEC directory image
   */
  public userAvatar : any;
  /**
   * Determines if the users initials should show in place of their image
   */
  public setInitials : boolean = false;
  /**
   * Represents the injected tooltip component
   */
  public injectedSettingsTooltip = ToolbarSettingsComponent;
  public injectedSettingsTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: true,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '200px',
      'max-width': '250px'
    },
    context: {
      code : null
    }
  });

  /**
   * Manages dropdown state
   */
  public close : boolean = false;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private windowRefSvc: OuxWindowRefService,
              private googleAnalyticsSvc: GoogleAnalyticsService,
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore,
              private demoUserStore: DemoUserStore) {

    this.windowRef = this.windowRefSvc.nativeWindow;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    /**
     * Updates user name, id, and avatar image when impersonating a user
     */
    this.subscriptions.push(
      this.impersonationChange$.subscribe(() => {
        this.setCurrentUser();
      })
    );

    this.setCurrentUser();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }


  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public toggleDropdown() : void {
    this.close = !this.close;
    this.googleAnalyticsSvc.eventEmitter('click', 'settings', 'toggle dropdown', 1);
  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private setCurrentUser(): void {
    this.currentUser = this.userDetailsStore.getUserId();
  }

  private setUserAvatar(username : string, isDemoUser? : boolean) : void {
    this.userInitial = username.charAt(0).toUpperCase();

    if (isDemoUser) {
      this.userAvatar = username; // refers to: /assets/images/demo-users/image_name.png
      return;
    }

    this.userAvatar = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`;
    this.validateImageSrc(username);
  }

  private validateImageSrc(username : string) : void {
    let img = new Image();
    img.onload = () => {};
    img.onerror = () => {
      this.setInitials = true;
    };
    img.src = `//wwwin.cisco.com/dir/photo/std/${username}.jpg`;
  }

}


