<oux-autocomplete #autocomplete 
    [icon]="'icon-search'" 
    [bordered]="false" 
    [placeholder]="'Search User\'s...'" 
    [maxHeight]="'50vh'" 
    [loading]="loading"
    [themeOverrides]="autocompleteTheme$ | async" 
    [updateSearchOnSelect]="true"
    (onSearch)="search($event)" 
    [(ngModel)]="searchValue">
  <oux-autocomplete-option *ngFor="let user of users$ | async" (select)="selectUser(user)">
    <oux-autocomplete-highlight 
      [display]="user.fullName + ' (' + user.emailId + ')'" 
      [breakSearchValue]="false"
      [highlight]="'allMatches'" 
      [search]="autocomplete.search">
    </oux-autocomplete-highlight>
  </oux-autocomplete-option>
</oux-autocomplete>