import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(
    private metadataStore: MetadataStore,
    private router: Router
  ) {
    
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.metadataStore.isLoggedInUserAdmin$
      .pipe(
        switchMap(isAdmin => {
          if (!isAdmin) {
            return of(this.router.createUrlTree(['/unauthorized']));
          }

          return of(isAdmin)
        })
      );
  }
}