import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Proxies } from '../models/interface/partials/proxies';

@Injectable({
  providedIn: 'root'
})
export class ProxyStore {
  private proxiesSubject = new BehaviorSubject<Proxies>(null);

  public proxies$ = this.proxiesSubject.asObservable();

  constructor() { }

  public setProxies(value: Proxies): void {
    this.proxiesSubject.next(value);
  }
}