import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { RouterModule }        from '@angular/router';
/* Package Imports */
import { 
  OuxLoginModule, 
  OuxThemeModule }             from '@cisco/oux-common';
/* Feature Imports */
import {
  GuardsRoutingModule,
  routedComponents }           from './guards-routing.module';


@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    GuardsRoutingModule,
    OuxLoginModule,
    OuxThemeModule
  ],

  declarations: [
    routedComponents
  ],

  exports: [
    RouterModule
  ]

})

export class GuardsModule { }
