import {
  Pipe,
  PipeTransform }                  from '@angular/core';
import { DatePipe }                from '@angular/common';
import { DATE_TIME_FORMAT }        from '../models/constants';

@Pipe({
  name: 'dateTimeFormat'
})
/**
 * Transforms inbound date and time into specified format via DATE_TIME_FORMAT
 * 
 * Ex: {{ record.service.expirationDate | dateFormat }}
 */
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(value : any, args? : any) : any {
    return super.transform(value, DATE_TIME_FORMAT.DATE_TIME_FMT);
  }
}
