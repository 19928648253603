import {
  HttpClient,
  HttpHeaders,
  HttpParams }                                        from '@angular/common/http';
import { Injectable }                                 from '@angular/core';
import { DatePipe }                                   from '@angular/common';
import {
  OuxAuthenticationService,
  OuxConfigService,
  OuxExceptionsHandleError,
  OuxExceptionsHandlerService}                        from '@cisco/oux-common';
import {
  Observable,
  of,
  throwError }                                        from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap }                                               from 'rxjs/operators';
import { GoalToCashAllocationsModel }                 from '../models/concrete/partials/goal-to-cash-allocations.model';
import { GoalToCashDetailsModel }                     from '../models/concrete/partials/goal-to-cash-details.model';
import { NodeSummaryModel }                           from '../models/concrete/partials/node-summary.model';
import { GoalToCashAllocations }                      from '../models/interface/partials/goal-to-cash-allocations';
import { GoalToCashDetails }                          from '../models/interface/partials/goal-to-cash-details';
import { NodeSummary }                                from '../models/interface/partials/node-summary';
import { GoalToCashAllocationsRequest }               from '../models/interface/request/goal-to-cash-allocations-request';
import { GoalToCashDetailsRequest }                   from '../models/interface/request/goal-to-cash-details-request';
import { GoalToCashNodeAllocationsRequest }           from '../models/interface/request/goal-to-cash-node-allocations-request';
import { GoalToCashNodesRequest }                     from '../models/interface/request/goal-to-cash-nodes-request';
import { GoalToCashAllocationsResponse }              from '../models/interface/response/goal-to-cash-allocations-response';
import { GoalToCashDetailsResponse }                  from '../models/interface/response/goal-to-cash-details-response';
import { GoalToCashNodesResponse }                    from '../models/interface/response/goal-to-cash-nodes-response';
import { GoalToCashStore }                            from '../stores/goal-to-cash.store';
import { UserDetailsStore }                           from '../stores/user-details.store';
import { MetadataStore }                              from 'src/app/shared/stores/metadata.store';
import { GoalToCashIacvAllocations } from '../models/interface/partials/goal-to-cash-iacv-allocations';
import { GoalToCashIacvAllocationsModel } from '../models/concrete/partials/goal-to-cash-iacv-allocations.model';
import { type } from 'os';
import { TYPED_NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { GoalToCashSavDetailsResponse } from '../models/interface/response/goal-to-cash-sav-details-response';
import { IacvSavDetailsModel } from '../models/concrete/partials/iacv-sav-details.model';
import { GoalToCashWeeklyDetails } from '../models/interface/partials/goalToCashWeeklyDetails';
import { GoalToCashIacvDetails } from '../models/interface/partials/goalToCashIacvDetails';
import { WeeklyTransactionSummaryRequestModel } from '../models/concrete/request/weekly-transaction-summary-request.model';
import { WeeklyTransactionSummaryDetails } from '../models/interface/partials/weekly-transaction-summary-details';
import { GoalToCashSavWeeklyDropdownFilterCriteria, SavName } from '../models/interface/partials/goal-to-cash-sav-weekly-dropdown-filter-criteria';
import { GoalToCashSavWeeklyDropdownFilterCriteriaResponse } from '../models/interface/response/goal-to-cash-sav-weekly-dropdown-filter-criteria-response';
import { GoalToCashSavWeeklyDropdownRequest } from '../models/interface/request/goal-to-cash-sav-weekly-dropdown-request';
import { GoalToCashSavWeeklyDropdownFilterModel } from '../models/concrete/partials/goal-to-cash-sav-weekly-dropdown-filter.model';
import { BookingsExportRequest } from '../models/interface/request/bookings-export-request';
import { BookingsExportDetails } from '../models/interface/partials/bookings-export-details';
import { BookingsExportDetailsModel } from '../models/concrete/partials/bookings-export-details.model';
import { MetricsExportRequest } from '../models/interface/request/metrics-export-request';
import { MetricsExportDetailsModel } from '../models/concrete/partials/metrics-export-details.model';
import { MetricsExportDetails } from '../models/interface/partials/metrics-export-details';
import { ExportDataModel } from './export/export-data.model';
import { ExportService } from './export/export.service';
import * as moment from 'moment';
import { MetricType } from '../models/types/metric-type.enum';
import { FiscalWeekFormatPipe } from '../pipes/fiscal-week-format.pipe';
import { IacvWeeklyDetailsFilterModel } from 'src/app/routes/goal-to-cash/routes/goal-to-cash-iacv-transactions-weekly/models/iacv-weekly-details-filter.model';
import { BacklogTransactionsFilterViewModel } from 'src/app/routes/backlog/models/backlog-transactions-filter.view-model';
import { GoalToCashDropdownSavListResponse } from '../models/interface/response/goal-to-cash-dropdown-sav-list';
import { ExportDefinitionsRequest } from '../models/interface/request/export-definitions-request';
import { ExportDefinitionsModel } from '../models/concrete/partials/export-definitions.model';
import { ExportDefinitionsDetails } from '../models/interface/partials/export-definitions-details';
import { RoundUpDecimalPipe } from '../pipes/round-up-decimal';
import { ExportCellModel } from './export/export-cell.model';
import { ExportDefinitionType } from '../models/types/export-definitions.enum';
import { CurrencyCodeRequest } from '../models/interface/request/currency-code-request';
import { CurrencyCodeDetails } from '../models/interface/partials/currencyCodeDetails';
import { CurrencyCodeResponse } from '../models/interface/response/currency-code-details-respons';
import { BaseResponse } from '../models/interface/response/base-response';
import { MetricSOTransactionSummaryDetails } from '../models/interface/partials/metric-so-transaction-summary-details';
import { MetricSOTransactionLineRequestModel } from '../models/concrete/request/metric-order-line-request.model';
import { MetricSOLinesSummary } from '../models/interface/partials/metric-so-lines-summary';
import { AtrExportDetailsModel } from '../models/concrete/partials/atr-export-details.model';
import { AtrDetailsRequest } from '../models/interface/request/atr-details-request';
import { OfflineGoalToCashExportRequest } from '../models/interface/request/offline-goal-to-cash-export-request';
import { AmplifiersAllocationsRequest } from '../models/interface/request/amplifiers-allocations-request';
import { ExportType } from '../models/constants/export-type.enum';
import { MetricSOTransactionLineDetailsGridViewModel } from '../components/metric-so-transaction-line-details-grid/metric-so-transaction-line-details-grid.view-model';
import { OfflineExport } from '../models/interface/response/offline-export-response';
import { VisibilityPageType } from '../models/types/visibility-page-type.enum';
import { AmpIdentifierKey } from '../models/types/amp-identifier-key.enum';
import { MetricSONotEligibleTransactionSummaryDetails } from '../models/interface/partials/metric-so-not-eligible-transaction-summary-details';
import { MetricSONotEligibleLinesSummary } from '../models/interface/partials/metric-so-not-eligible-lines-summary';
import { url } from 'inspector';



@Injectable({ providedIn: 'root' })
export class GoalToCashService {

  private baseUri: string;
  private goalToCashDetailsUri: string;
  private goalToCashAllocationsUri: string;
  private goalToCashAllocationAmplifierUri: string;
  private iacvAllocationsUri: string;
  private amplifiersAllocationsUri: string;
  private goalToCashNodesUri: string;
  private goalToCashDropdownUri: string;
  private goalToCashDropdownSavListUri: string;
  private goalToCashIacvNodesUri:string;
  private goalToCashSavDetailsUri: string;
  private goalToCashWeeklyDetailsUri: string;
  private metricsCurrencyCodeDetailsUri: string;
  private goalToCashWeeklyCountUri: string;
  private goalToCashBookingsExportCountUri: string;
  public weeklyTransactionSummaryUri: string;
  public weeklyTransactionSummaryCountUri: string;
  public bookingsExportDetailsUri: string;
  public metricsExportDetailsUri: string;
  private exportDefinitionsDetailsUri: string;
  private offlineExportUri: string;
  public ampOrderTransactionSummaryUri: string;
  public ampOrderTransactionSummaryCountUri: string;
  public atrExportDetailsUri: string;
  public notEligibleMetricLinesUri: string;
  public notEligibleMetricLinesCount: number;
  public AmpIdentifierKey = AmpIdentifierKey;

  /**
   * Create service mapping for http exception handling
   */
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('GoalToCashService');

  constructor(private http: HttpClient,
              private datePipe: DatePipe,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxAuthSvc: OuxAuthenticationService,
              private ouxConfigSvc: OuxConfigService,
              private userDetailsStore: UserDetailsStore,
              private goalToCashStore: GoalToCashStore,
              private metadataStore: MetadataStore,
              private exportService: ExportService,
              private fiscalWeekFormat: FiscalWeekFormatPipe,
              private roundUpDecimal: RoundUpDecimalPipe) {

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.goalToCashDetailsUri = apiUri.goalToCashDetails;
    this.goalToCashAllocationsUri = apiUri.goalToCashAllocations;
    this.goalToCashAllocationAmplifierUri = apiUri.goalToCashAllocationsAmplifiers;
    this.iacvAllocationsUri = apiUri.iacvAllocations;
    this.amplifiersAllocationsUri = apiUri.paymentAllocations;
    this.goalToCashNodesUri = apiUri.goalToCashNodes;
    this.goalToCashDropdownUri = apiUri.goalToCashSavWeeklyDropdown;
    this.goalToCashDropdownSavListUri = apiUri.savListDropdownDetails;
    this.goalToCashIacvNodesUri = apiUri.goalToCashIacvNodes;
    this.goalToCashSavDetailsUri = apiUri.goalToCashSavDetails;
    this.goalToCashWeeklyDetailsUri = apiUri.goalToCashWeeklyDetails;
    this.goalToCashWeeklyCountUri = apiUri.goalToCashWeeklyCount;
    this.weeklyTransactionSummaryUri = apiUri.weeklyTransactionSummary;
    this.weeklyTransactionSummaryCountUri = apiUri.weeklyTransactionSummaryCount;
    this.bookingsExportDetailsUri = apiUri.bookingsExportDetails;
    this.goalToCashBookingsExportCountUri = apiUri.bookingsExportCount;
    this.metricsExportDetailsUri = apiUri.metricsExportDetails;
    this.exportDefinitionsDetailsUri = apiUri.exportDefinitionsDetails;
    this.metricsCurrencyCodeDetailsUri=apiUri.metricsCurrencyCode;
    this.offlineExportUri = apiUri.orderExport;
    this.ampOrderTransactionSummaryUri = apiUri.ampOrderTransactionSummary;
    this.ampOrderTransactionSummaryCountUri = apiUri.ampOrderTransactionSummaryCount;
    this.atrExportDetailsUri = apiUri.atrExportDetails;
    this.notEligibleMetricLinesUri = apiUri.notEligibleMetricLines;
    this.notEligibleMetricLinesCount = apiUri.notEligibleMetricLinesCount;
  }

  public fetchDetails(request: GoalToCashDetailsRequest): Observable<GoalToCashDetails> {

    const URL = `${this.baseUri}${this.goalToCashDetailsUri}`;
    const OPTIONS = this.getOptions(request);

    const REQUEST$ = this.http.get<GoalToCashDetailsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = new GoalToCashDetailsModel(response.data);

          return typed;
        }),
        tap( (typed : GoalToCashDetails) => {
          // pass data to corresponding goal to cash details store for further processing
          this.goalToCashStore.setGoalToCashDetails(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetch', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   *
   * Fetching Details for IACV Transactions
   */
  public fetchDetailsForIACV(request: GoalToCashDetailsRequest): Observable<IacvSavDetailsModel[]> {
    const OPTIONS = this.getOptionsForIACV();

    //Period Code extraction
    const yearMatch : RegExp = /(\d+)/;
    const periodMatch : RegExp = /(\d+$)/;
    let year = request.periodCode ? Number(request.periodCode.match(yearMatch)[0]) : null;
    let period = request.periodCode ? Number(request.periodCode.match(periodMatch)[0]) : null

    let urlRequest:any;
    if(request.performanceType.includes('IND') || request.performanceType.includes('TEAM')){
      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "savId": request.savId? request.savId : null,
        "year": year,
        "period": period,
        "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
        "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
        "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
        "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
        "transactionType": request.transactionType ? request.transactionType : 'ALL'
      }
    }
    else{
      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
        "savId": request.savId? request.savId : null,
        "year": year,
        "period": period,
        "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
        "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
        "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
        "territory": request.territory? request.territory : null,
        "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
        "transactionType": request.transactionType ? request.transactionType : 'ALL'
      }
    }

    if(request.lockDate){
      urlRequest["lockDate"] = request.lockDate;
    }

    if(request.performanceType.includes("IND") || request.performanceType.includes("TEAM")){
      const URL = `${this.baseUri}${this.goalToCashSavDetailsUri}`;
      const REQUEST$ = this.http.post<GoalToCashIacvDetails[]>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          this.goalToCashStore.setGoalToCashSavDetails(response);
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForIacvSavDetails', error)(error);
        })
      );
      return REQUEST$;
    }
    else{
      const URL = `${this.baseUri}${this.goalToCashWeeklyDetailsUri}`;
      const REQUEST$ = this.http.post<GoalToCashWeeklyDetails[]>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          this.goalToCashStore.setGoalToCashWeeklyDetails(response);
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForIacvWeeklyDetails', error)(error);
        })
      );
      return REQUEST$;
    }


  }


  /**
   *
   * Fetching Details for IACV Weekly Transactions
   */
   public fetchDetailsForIacvWeekly(request: GoalToCashDetailsRequest): Observable<IacvSavDetailsModel[]> {
    const OPTIONS = this.getOptionsForIACV();
    let urlRequest:any;

    //Period Code extraction
    const yearMatch : RegExp = /(\d+)/;
    const periodMatch : RegExp = /(\d+$)/;
    let year = request.periodCode ? Number(request.periodCode.match(yearMatch)[0]) : null;
    let period = request.periodCode ? Number(request.periodCode.match(periodMatch)[0]) : null;

      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "duration": request.duration ? request.duration : null,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
        "savId": request.savId? request.savId : null,
        "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
        "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
        "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
        "territory": request.territory? request.territory : null,
        "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
        "transactionType": request.transactionType ? request.transactionType : 'ALL',
        "year": year,
        "period": period,
        "limit": request.limit || 10,
        "offset": request.offset || 0,
        "sortBy": request.sortBy? request.sortBy : null,
        "sortOrder": request.sortOrder? request.sortOrder : null,
      }
    if(request.lockDate){
      urlRequest["lockDate"] = request.lockDate;
    }

      const URL = `${this.baseUri}${this.goalToCashWeeklyDetailsUri}`;
      const REQUEST$ = this.http.post<GoalToCashWeeklyDetails[]>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          this.goalToCashStore.setGoalToCashWeeklyDetails(response);
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForIacvWeeklyDetails', error)(error);
        })
      );
      return REQUEST$;
    //}


  }

  /**
   *
   * Fetching Currency Code for Metrics Export
   */
   public fetchCurrencyCode(request: CurrencyCodeRequest): Observable<CurrencyCodeDetails> {
    const OPTIONS = this.getOptionsForIACV();
    let urlRequest:any;

      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
      }

      const URL = `${this.baseUri}${this.metricsCurrencyCodeDetailsUri}`;
      const REQUEST$ = this.http.post<CurrencyCodeResponse>(URL, urlRequest, OPTIONS)
    .pipe(
      switchMap(response => {
        if (!response) {
          return throwError(response);
        }
        return of(response);
      }),
      map((response: CurrencyCodeResponse) => {
        if(!response){
          return throwError(response);
        }

        return response;
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('fetchData', error)(error);
      })
    );
      return REQUEST$;
    //}


  }



   /**
   *
   * Fetching Details for Bookings Export
   */
    public fetchBookingsExportDetails(request: BookingsExportRequest): Observable<BookingsExportDetailsModel[]> {
      const OPTIONS = this.getOptionsForIACV();
      let urlRequest:any;


        urlRequest ={
          "loginId": this.userDetailsStore.getUserId(),
          "goalId": Number(request.goalID),
          "userId": this.userDetailsStore.getImpersonationUserId(),
          "employeeId": this.metadataStore.getMetadataEmployeeId(),
          "quotaId": request.quotaId,
          "sourceSystem": this.ouxConfigSvc.getAppConfigValue('source_system'),
          "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
          "savId": request.savId? request.savId : null,
          "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
          "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
          "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
          "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
          "salesOrderNumber": request.salesOrderNumber? request.salesOrderNumber : null,
          "metricId": request.metricId? request.metricId : null,
          "transactionType": request.transactionType ? request.transactionType : 'ALL',
          "limit": request.limit? request.limit : null,
          "offset": request.offset? request.offset : null,
          "territory": request.territory? request.territory : null,
        }

        const URL = `${this.baseUri}${this.bookingsExportDetailsUri}`;
        const REQUEST$ = this.http.post<BookingsExportDetails[]>(URL, urlRequest ,OPTIONS)
        .pipe(
          switchMap(response => {
            if (!response) {
              return throwError(response);
            }
            return of(response);
          }),
          map(response => {
            this.goalToCashStore.setBookingsExportDetails(response);
            return response;
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchAllocationsForBookingsExportDetails', error)(error);
          })
        );
        return REQUEST$;
      //}


    }



     /**
   *
   * Fetching Details for Export Definitions Export
   */
      public fetchExportDefinitionsDetails(request: ExportDefinitionsRequest): Observable<ExportDefinitionsModel[]> {
        const OPTIONS = this.getOptionsForIACV();
        let urlRequest:any;


          urlRequest ={
            "loginId": this.userDetailsStore.getUserId(),
            "userId": this.userDetailsStore.getImpersonationUserId(),
            "employeeId": this.metadataStore.getMetadataEmployeeId()
          }

          const URL = `${this.baseUri}${this.exportDefinitionsDetailsUri}`;
          const REQUEST$ = this.http.post<ExportDefinitionsDetails[]>(URL, urlRequest ,OPTIONS)
          .pipe(
            switchMap(response => {
              if (!response) {
                return throwError(response);
              }
              return of(response);
            }),
            map(response => {
              this.goalToCashStore.setExportDefinitionsDetails(response);
              return response;
            }),
            catchError(error => {
              // create operation mapping for http exception handling
              return this.ouxHandleError('fetchResultForExportDefinitionsExportDetails', error)(error);
            })
          );
          return REQUEST$;
        //}


      }



     /**
   *
   * Fetching Details for Metrics Export
   */
   public fetchMetricsExportDetails(request: MetricsExportRequest): Observable<MetricsExportDetailsModel[]> {
    const OPTIONS = this.getOptionsForIACV();
    let urlRequest:any;

     //Period Code extraction
     const yearMatch : RegExp = /(\d+)/;
     const periodMatch : RegExp = /(\d+$)/;
     let year = request.periodCode ? Number(request.periodCode.match(yearMatch)[0]) : null;
     let period = request.periodCode ? Number(request.periodCode.match(periodMatch)[0]) : null;


      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "lockDate": request.lockDate? this.toDateTime(Number(request.lockDate)) : null,
        "sourceSystem": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
        "savId": request.savId? request.savId : null,
        "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
        "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
        "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
        "territory": request.territory? request.territory : null,
        "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
        "metricId": request.metricId? request.metricId : null,
        "transactionType": request.transactionType ? request.transactionType : 'ALL',
        "year": year,
        "period": period,
      }

      const URL = `${this.baseUri}${this.metricsExportDetailsUri}`;
      const REQUEST$ = this.http.post<MetricsExportDetails[]>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          this.goalToCashStore.setMetricsExportDetails(response);
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForMetricsExportDetails', error)(error);
        })
      );
      return REQUEST$;
    //}


  }


  /**
   *
   * Fetching Count for Bookings Export
   */
   public fetchBookingsExportCount(request: GoalToCashDetailsRequest): Observable<number>{
    const OPTIONS = this.getOptionsForIACV();

    let urlRequest:any;

    urlRequest ={
      "loginId": this.userDetailsStore.getUserId(),
      "goalId": Number(request.goalID),
      "userId": this.userDetailsStore.getImpersonationUserId(),
      "employeeId": this.metadataStore.getMetadataEmployeeId(),
      "quotaId": request.quotaId,
      "sourceSystem": this.ouxConfigSvc.getAppConfigValue('source_system'),
      "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
      "savId": request.savId? request.savId : null,
      "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
      "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
      "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
      "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
      // "salesOrderNumber": request.salesOrderNumber? request.salesOrderNumber : null,
      // "metricId": request.metricId? request.metricId : null,
      "transactionType": request.transactionType ? request.transactionType : 'ALL',
      "limit": request.limit? request.limit : null,
      "offset": request.offset? request.offset : null,
      //"territory": request.territory? request.territory : null,
    }

    if(request.lockDate){
      urlRequest["lockDate"] = request.lockDate;
    }

      const URL = `${this.baseUri}${this.goalToCashBookingsExportCountUri}`;

      const REQUEST$ = this.http.post<{RECORD_COUNT: number}[]>(URL, urlRequest, OPTIONS)
        .pipe(
          map(response => {
            if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
              return 0;
            }

            return response[0].RECORD_COUNT;
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchBookingsExportCount', error)(error);
          })
        );

      return REQUEST$;


  }


  /**
   *
   * Fetching Count for Weekly Transactions
   */
   public fetchWeeklyTransactionsCount(request: GoalToCashDetailsRequest): Observable<number>{
    const OPTIONS = this.getOptionsForIACV();

    //Period Code extraction
    const yearMatch : RegExp = /(\d+)/;
    const periodMatch : RegExp = /(\d+$)/;
    let year = request.periodCode ? Number(request.periodCode.match(yearMatch)[0]) : null;
    let period = request.periodCode ? Number(request.periodCode.match(periodMatch)[0]) : null;

    let urlRequest:any;
    urlRequest ={
      "loginId": this.userDetailsStore.getUserId(),
      "goalId": Number(request.goalID),
      "userId": this.userDetailsStore.getImpersonationUserId(),
      "employeeId": this.metadataStore.getMetadataEmployeeId(),
      "quotaId": request.quotaId,
      "duration": request.duration ? request.duration : null,
      "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
      "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
      "savId": request.savId? request.savId : null,
      "year": year,
      "period": period,
      "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
      "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
      "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
      "territory": request.territory? request.territory : null,
      "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
      "transactionType": request.transactionType ? request.transactionType : 'ALL',
    }

    if(request.lockDate){
      urlRequest["lockDate"] = request.lockDate;
    }

      const URL = `${this.baseUri}${this.goalToCashWeeklyCountUri}`;

      const REQUEST$ = this.http.post<{RECORD_COUNT: number}[]>(URL, urlRequest, OPTIONS)
        .pipe(
          map(response => {
            if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
              return 0;
            }

            return response[0].RECORD_COUNT;
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchCountforIacvWeeklyCount', error)(error);
          })
        );

      return REQUEST$;


  }

  /**
   *
   * Fetching Dropdown filters for SAV & Weekly Pages
   */

  public fetchDropdownFilterCriteraDetails(request: GoalToCashSavWeeklyDropdownRequest): Observable<GoalToCashSavWeeklyDropdownFilterCriteria>{

    const OPTIONS = this.getOptionsForIACV();
    let URL = `${this.baseUri}${this.goalToCashDropdownUri}`;

    let urlRequest ={
      "loginId": this.userDetailsStore.getUserId(),
      "goalId": Number(request.goalID),
      "userId": this.userDetailsStore.getImpersonationUserId(),
      "employeeId": this.metadataStore.getMetadataEmployeeId(),
      "quotaId": request.quotaId,
      "source_system": this.ouxConfigSvc.getAppConfigValue('source_system')
    }

    const REQUEST$ = this.http.post<GoalToCashSavWeeklyDropdownFilterCriteriaResponse>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          let typed =  new GoalToCashSavWeeklyDropdownFilterModel(response.data);

          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForDropdownFilterCriteriaDetails', error)(error);
        })
      );
      return REQUEST$;
    //return request$;
  }

  /**
   *
   * Fetching Dropdown filters for SAV & Weekly Pages
   */

   public fetchDropdownSavListDetails(request: GoalToCashDetailsRequest): Observable<SavName[]>{

    const OPTIONS = this.getOptionsForIACV();
    let URL = `${this.baseUri}${this.goalToCashDropdownSavListUri}`;

    //Period Code extraction
    const yearMatch : RegExp = /(\d+)/;
    const periodMatch : RegExp = /(\d+$)/;
    let year = request.periodCode ? Number(request.periodCode.match(yearMatch)[0]) : null;
    let period = request.periodCode ? Number(request.periodCode.match(periodMatch)[0]) : null;

    let urlRequest ={
      "loginId": this.userDetailsStore.getUserId(),
        "goalId": Number(request.goalID),
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "duration": request.duration ? request.duration : null,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "fiscalWeek": request.fiscalWeek? request.fiscalWeek : null,
        "savId": request.savId? request.savId : null,
        "beCxLevel1": request.beCxLevel1? request.beCxLevel1 : null,
        "beCxLevel2": request.beCxLevel2? request.beCxLevel2 : null,
        "beCxLevel3": request.beCxLevel3? request.beCxLevel3 : null,
        "territory": request.territory? request.territory : null,
        "cxUpsellGroup": request.cxUpsellGroup? request.cxUpsellGroup : null,
        "transactionType": request.transactionType ? request.transactionType : 'ALL',
        "year": year,
        "period": period,
        "limit": request.limit || 10,
        "offset": request.offset || 0,
        "sortBy": request.sortBy? request.sortBy : null,
        "sortOrder": request.sortOrder? request.sortOrder : null,
    }

    const REQUEST$ = this.http.post<GoalToCashDropdownSavListResponse>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {

          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchResultforDropdownSavList', error)(error);
        })
      );
      return REQUEST$;
    //return request$;
  }



  public fetchDetailsForWeeklyTransactionSummary(request:WeeklyTransactionSummaryRequestModel):Observable<WeeklyTransactionSummaryDetails[]>{


    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.weeklyTransactionSummaryUri}`;

    let urlRequest = new WeeklyTransactionSummaryRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      goalId: Number(request.goalId)? Number(request.goalId) : null,
      quotaId: request.quotaId ? request.quotaId : null,
      savId: request.savId ? request.savId : null,
      fiscalWeek: request.fiscalWeek ? request.fiscalWeek : null,
      sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy ? request.sortBy :'SOURCE_SYSTEM',
      sortOrder: request.sortOrder ? request.sortOrder : 'desc',
      beCxLevel1: request.beCxLevel1? request.beCxLevel1 : null,
      beCxLevel2: request.beCxLevel2? request.beCxLevel2 : null,
      beCxLevel3: request.beCxLevel3? request.beCxLevel3 : null,
      cxUpsellGroup: request.cxUpsellGroup? request.cxUpsellGroup : null,
      metricId : request.metricId ? request.metricId : null,
      transactionType : request.transactionType ? request.transactionType : 'ALL',
      edfTransactionId: request.edfTransactionId ? request.edfTransactionId : null
    })

    /*let urlRequest = new WeeklyTransactionSummaryRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      goalId: Number(request.goalId),
      quotaId: request.quotaId,
      savId: request.savId,
      fiscalWeek: request.fiscalWeek,
      sourceSystem: request.sourceSystem,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy,
      sortOrder: request.sortOrder,
      beCxLevel1: request.BE_CX_LEVEL_1? request.BE_CX_LEVEL_1 : null,
      beCxLevel2: request.BE_CX_LEVEL_2? request.BE_CX_LEVEL_2 : null,
      //beCxLevel3: request.BE_CX_LEVEL_3? request.BE_CX_LEVEL_3 : null,
      cxUpsellGroup: request.cxUpsellGroup? request.cxUpsellGroup : null,
      transactionType : request.transactionType
    })*/
      const REQUEST$ = this.http.post<any>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),

        map(response => {
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForWeeklyTransactionSummaryDetails', error)(error);
        })
      );
      return REQUEST$;
  }

   /**
   * Fetches Transactions for the week record count
   * Method is call this.fetchDetailsData() above
   * @param request
   * @returns
   */
    public fetchDetailsForWeeklyTransactionSummaryCount(request: WeeklyTransactionSummaryRequestModel): Observable<number> {


      const OPTIONS = this.getOptionsForIACV();
      const URL = `${this.baseUri}${this.weeklyTransactionSummaryCountUri}`;

      let urlRequest = new WeeklyTransactionSummaryRequestModel({
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        goalId: Number(request.goalId)? Number(request.goalId) : null,
        quotaId: request.quotaId ? request.quotaId : null,
        savId: request.savId ? request.savId : null,
        fiscalWeek: request.fiscalWeek ? request.fiscalWeek : null,
        sourceSystem: this.ouxConfigSvc.getAppConfigValue('source_system'),
        limit: request.limit,
        offset: request.offset,
        sortBy: request.sortBy ? request.sortBy :'SOURCE_SYSTEM',
        sortOrder: request.sortOrder ? request.sortOrder : 'desc',
        beCxLevel1: request.beCxLevel1? request.beCxLevel1 : null,
        beCxLevel2: request.beCxLevel2? request.beCxLevel2 : null,
        beCxLevel3: request.beCxLevel3? request.beCxLevel3 : null,
        cxUpsellGroup: request.cxUpsellGroup? request.cxUpsellGroup : null,
        metricId : request.metricId ? request.metricId : null,
        transactionType : request.transactionType ? request.transactionType : 'ALL',
        edfTransactionId: request.edfTransactionId ? request.edfTransactionId : null
      })

      const REQUEST$ = this.http.post<{RECORD_COUNT: number}[]>(URL, urlRequest, OPTIONS)
        .pipe(
          map(response => {
            if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
              return 0;
            }

            return response[0].RECORD_COUNT;
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchDetailsForWeeklyTransactionSummaryCount', error)(error);
          })
        );

      return REQUEST$;
    }
  // api service call to metric so drill down summary table count
  public fetchDetailsForAmpSoTransactionSummaryCount(request: MetricSOTransactionLineRequestModel): Observable<number> {


    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.ampOrderTransactionSummaryCountUri}`;

    let urlRequest = new MetricSOTransactionLineRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      goalId: Number(request.goalId) ? Number(request.goalId) : null,
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      quotaId: request.quotaId,
      salesOrderNumber: request.salesOrderNumber,
      startDate: request.startDate,
      endDate: request.endDate,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy ? request.sortBy : 'SOURCE_SYSTEM',
      sortOrder: request.sortOrder ? request.sortOrder : 'desc',
      sourcePage: request.sourcePage,
      type: request.type
    })
    // let urlRequest = new MetricSOTransactionLineRequestModel({
    //   "goalId": 3173912,
    //   "quotaId": "266330",
    //   "limit": 10,
    //   "offset": 0,
    //   "sortBy": "LINE_ID",
    //   "sortOrder": "desc",
    //   "employeeId": "389210",
    //   "loginId": "alislewi",
    //   "userId": "kawaters",
    //   "salesOrderNumber": "123456",
    //   "lineId": 1122334455
    // })

    const REQUEST$ = this.http.post<{ RECORD_COUNT: number }[]>(URL, urlRequest, OPTIONS)
      .pipe(
        map(response => {
          if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
            return 0;
          }

          return response[0].RECORD_COUNT;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchDetailsForAmpSoTransactionSummaryCount', error)(error);
        })
      );

    return REQUEST$;
  }
  // api service call to amplifiers so drill down summary table
  public fetchDetailsForAmpSoTransactionSummary(request: MetricSOTransactionLineRequestModel): Observable<MetricSOTransactionSummaryDetails[]> {


    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.ampOrderTransactionSummaryUri}`;

    let urlRequest = new MetricSOTransactionLineRequestModel({
      loginId: this.userDetailsStore.getUserId(),
      goalId: Number(request.goalId) ? Number(request.goalId) : null,
      userId: this.userDetailsStore.getImpersonationUserId(),
      employeeId: this.metadataStore.getMetadataEmployeeId(),
      quotaId: request.quotaId,
      salesOrderNumber: request.salesOrderNumber,
      startDate: request.startDate,
      endDate: request.endDate,
      limit: request.limit,
      offset: request.offset,
      sortBy: request.sortBy ? request.sortBy : 'SOURCE_SYSTEM',
      sortOrder: request.sortOrder ? request.sortOrder : 'desc',
      sourcePage: request.sourcePage,
      type: request.type
    })
    // let urlRequest = new MetricSOTransactionLineRequestModel({
    //   "goalId": 3173912,
    //   "quotaId": "266330",
    //   "limit": 10,
    //   "offset": 0,
    //   "sortBy": "LINE_ID",
    //   "sortOrder": "desc",
    //   "employeeId": "389210",
    //   "loginId": "alislewi",
    //   "userId": "kawaters",
    //   "salesOrderNumber": "123456",
    //   "lineId": 1122334455
    // })

    const REQUEST$ = this.http.post<any>(URL, urlRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),

        map(response => {
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchDetailsForAmpSoTransactionSummary', error)(error);
        })
      );
    return REQUEST$;
  }

  // api service call to metric so (not eligible) drill down summary table count
  public fetchDetailsForNotEligibleTransactionSummaryCount(request: MetricSOTransactionLineRequestModel): Observable<number> {

    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.notEligibleMetricLinesCount}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.employeeId = this.metadataStore.getMetadataEmployeeId();

    const REQUEST$ = this.http.post<{ RECORD_COUNT: number }[]>(URL, request, OPTIONS)
      .pipe(
        map(response => {
          if (!response || response.length == 0 || !response[0] || !response[0].RECORD_COUNT) {
            return 0;
          }

          return response[0].RECORD_COUNT;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchDetailsForNotEligibleTransactionSummaryCount', error)(error);
        })
      );

    return REQUEST$;
  }

  // api service call to metric so (not eligible) drill down summary table
  public fetchDetailsForNotEligibleTransactionSummary(request: MetricSOTransactionLineRequestModel): Observable<MetricSONotEligibleTransactionSummaryDetails[]> {

    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.notEligibleMetricLinesUri}`;

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.employeeId = this.metadataStore.getMetadataEmployeeId();

    const REQUEST$ = this.http.post<any>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),

        map(response => {
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchDetailsForNotEligibleTransactionSummary', error)(error);
        })
      );
    return REQUEST$;
  }

  public fetchAllocationsAmplifier(request: GoalToCashAllocationsRequest): Observable<GoalToCashAllocations>{
      const URL = `${this.baseUri}${this.goalToCashAllocationAmplifierUri}`;
      const OPTIONS = this.getOptions(request);

      const REQUEST$ = this.http.get<GoalToCashAllocationsResponse>(URL, OPTIONS)
        .pipe(
          switchMap(response => {
            if (!response || !response.success) {
              return throwError(response);
            }

            return of(response);
          }),
          map(response => {
            let typed = response.data ? new GoalToCashAllocationsModel(response.data) : null;

            return typed;
          }),
          tap( (typed : GoalToCashAllocations) => {
            // pass data to corresponding goal to cash store for further processing
            this.goalToCashStore.setGoalToCashAllocations(typed);
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchAllocations', error)(error);
          })
        );

      return REQUEST$;
    }

  public fetchAllocations(request: GoalToCashAllocationsRequest): Observable<GoalToCashAllocations> {

    const URL = `${this.baseUri}${this.goalToCashAllocationsUri}`;
    const OPTIONS = this.getOptions(request);

    const REQUEST$ = this.http.get<GoalToCashAllocationsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = response.data ? new GoalToCashAllocationsModel(response.data) : null;

          return typed;
        }),
        tap( (typed : GoalToCashAllocations) => {
          // pass data to corresponding goal to cash store for further processing
          this.goalToCashStore.setGoalToCashAllocations(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsAmplifiers', error)(error);
        })
      );

    return REQUEST$;
  }

  public fetchAllocationsForIACV(request: GoalToCashAllocationsRequest): Observable<GoalToCashIacvAllocations[]> {
    const URL = `${this.baseUri}${this.iacvAllocationsUri}`;
    const OPTIONS = this.getOptionsForIACV();

    let urlRequest:any;
    if(request.lockDate){
      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": request.goalID,
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system'),
        "lockDate":request.lockDate
      }
    }
    else{
      urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": request.goalID,
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "source_system": this.ouxConfigSvc.getAppConfigValue('source_system')
      }
    }
    const REQUEST$ = this.http.post<GoalToCashIacvAllocations[]>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          // let typed = response ? new GoalToCashIacvAllocationsModel(response) : null;
          this.goalToCashStore.setGoalToCashAllocationsForIacv(response);
          return response;
        }),
        /*tap( (typed : GoalToCashAllocations) => {
          // pass data to corresponding goal to cash store for further processing
          this.goalToCashStore.setGoalToCashAllocations(typed);
        }),*/
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForIacv', error)(error);
        })
      );

    return REQUEST$;
  }

  public fetchAllocationsForAmplifiers(request: AmplifiersAllocationsRequest): Observable<any> {
    const URL = `${this.baseUri}${this.amplifiersAllocationsUri}`;
    const OPTIONS = this.getOptionsForIACV();

    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.employeeId = this.metadataStore.getMetadataEmployeeId();

    const REQUEST$ = this.http.post<any>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          // let typed = response ? new GoalToCashIacvAllocationsModel(response) : null;
          // this.goalToCashStore.setGoalToCashAllocationsForIacv(response);
          return response;
        }),
        /*tap( (typed : GoalToCashAllocations) => {
          // pass data to corresponding goal to cash store for further processing
          this.goalToCashStore.setGoalToCashAllocations(typed);
        }),*/
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocationsForAmplifiers', error)(error);
        })
      );

    return REQUEST$;
  }

  public fetchNodes(request: GoalToCashNodesRequest): Observable<NodeSummary[]> {


    if(request.quotaId && request.source_system){

      let OPTIONS = this.getOptionsForIACV();
      const URL = `${this.baseUri}${this.goalToCashIacvNodesUri}`;
      let urlRequest ={
        "loginId": this.userDetailsStore.getUserId(),
        "goalId": request.goalID,
        "userId": this.userDetailsStore.getImpersonationUserId(),
        "employeeId": this.metadataStore.getMetadataEmployeeId(),
        "quotaId": request.quotaId,
        "source_system":request.source_system
      }

      const REQUEST$ = this.http.post<GoalToCashNodesResponse>(URL, urlRequest ,OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          return response;
          //let typed = (response?.data?.NODESUMMARY || []).map(node => new NodeSummaryModel(node));

        }),
        tap( (typed : any) => {
          // pass data to corresponding goal to cash store for further processing
          this.fetchBacklogForNodes(request, typed).subscribe(); // Calling Oracle Data for Backlog Values
          this.goalToCashStore.setGoalToCashNodes(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling

          return this.ouxHandleError('fetchAllocationsForIacv', error)(error);
        })
      );

      return REQUEST$;
    }
    else{
      let OPTIONS = this.getOptions(request);
      const URL = `${this.baseUri}${this.goalToCashNodesUri}`;
      const REQUEST$ = this.http.get<GoalToCashNodesResponse>(URL, OPTIONS)
        .pipe(
          switchMap(response => {
            if (!response || !response.success) {
              return throwError(response);
            }

            return of(response);
          }),
          map( (response: GoalToCashNodesResponse) => {
            let typed = (response?.data?.NODESUMMARY || []).map(node => new NodeSummaryModel(node));

            return typed;
          }),
          tap( (typed: NodeSummary[]) => {
            // pass data to corresponding goal to cash nodes store for further processing
            this.goalToCashStore.setGoalToCashNodes(typed);
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('fetchNodes', error)(error);
          })
        );
        return REQUEST$;
      }


  }

 /**
   *
   * Send request for offline export
   */

  public queueOfflineExport(request: any): Observable<number> {

    const URL = `${this.baseUri}${this.offlineExportUri}`;
    const OPTIONS = this.getOptionsForIACV();
    let newRequest: any = {};

      newRequest = {
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        filters: request,
        sourceSystem: 'Visibility',
        type: 'Order',
        batchId: request?.batchId //For DB Column
      };

      newRequest.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
      newRequest.filters.loginId = this.userDetailsStore.getUserId();
      newRequest.filters.loggedInUser = this.userDetailsStore.getUserId();

      newRequest.filters = JSON.stringify(newRequest.filters);


    const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: OfflineExport) => {
          let typed = response?.data?.SEQUENCE_ID ;
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('queueOfflineExport', error)(error);
        })
      );

    return REQUEST$;
  }


  /**
   *
   * Send request for Metrics offline export
   */

  public queueMetricsOfflineExport(request: any, type: ExportType): Observable<number> {

    const URL = `${this.baseUri}${this.offlineExportUri}`;
    const OPTIONS = this.getOptionsForIACV();

    let newRequest: any = {};
      newRequest = {
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        filters: request,
        sourceSystem: 'Visibility',
        type: type
      };
      newRequest.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
      newRequest.filters.loginId = this.userDetailsStore.getUserId();
      newRequest.filters.loggedInUser = this.userDetailsStore.getUserId();

      newRequest.filters = JSON.stringify(newRequest.filters);


      const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: OfflineExport) => {
          let typed = response?.data?.SEQUENCE_ID ;
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('queueMetricsOfflineExport', error)(error);
        })
      );

    return REQUEST$;
  }


   /**
   *
   * Send request for offline Adj export
   */

    public queueOfflineAdjExport(request: any): Observable<number> {

      const URL = `${this.baseUri}${this.offlineExportUri}`;
      const OPTIONS = this.getOptionsForIACV();
      let newRequest: any = {};
        newRequest = {
          employeeId: this.metadataStore.getMetadataEmployeeId(),
          loginId: this.userDetailsStore.getUserId(),
          userId: this.userDetailsStore.getImpersonationUserId(),
          filters: request,
          sourceSystem: 'Visibility',
          type:  'Order',
          batchId: request?.batchId //For DB Column
        };

        newRequest.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
        newRequest.filters.loginId = this.userDetailsStore.getUserId();
        newRequest.filters.loggedInUser = this.userDetailsStore.getUserId();

        newRequest.filters = JSON.stringify(newRequest.filters);


        const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
        .pipe(
          switchMap(response => {
            if (!response || !response.success) {
              return throwError(response);
            }

            return of(response);
          }),
          map( (response: OfflineExport) => {
            let typed = response?.data?.SEQUENCE_ID ;
            return typed;
          }),
          catchError(error => {
            // create operation mapping for http exception handling
            return this.ouxHandleError('queueOfflineAdjExport', error)(error);
          })
        );

      return REQUEST$;
    }


  public fetchBacklogForNodes(request: GoalToCashNodesRequest, nodeSummary: NodeSummaryModel[]): Observable<NodeSummary[]> {

    let OPTIONS = this.getOptions(request);
    const URL = `${this.baseUri}${this.goalToCashNodesUri}`;
    const REQUEST$ = this.http.get<GoalToCashNodesResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: GoalToCashNodesResponse) => {
          let typed = (response?.data?.NODESUMMARY || []).map(node => new NodeSummaryModel(node));
          return typed;
        }),
        tap( (typed: NodeSummary[]) => {
          // pass data to corresponding goal to cash nodes store for further processing
          this.goalToCashStore.setGoalToCashBacklogNodes(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchNodes', error)(error);
        })
      );
      return REQUEST$;

  }


  public fetchNodeAllocationsAmplifier(request: GoalToCashNodeAllocationsRequest): Observable<GoalToCashAllocations> {

    const URL = `${this.baseUri}${this.goalToCashAllocationAmplifierUri}`;
    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loginId', this.userDetailsStore.getUserId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
        .append('planID', `${request.planID}`)
        .append('allocationID', `${request.allocationID}`)
        .append('goalID', `${request.goalID}`)
        .append('nodeName', `${request.nodeName}`)
        .append('territoryTypeCode', `${request.territoryTypeCode}`)
    };

    const REQUEST$ = this.http.get<GoalToCashAllocationsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = response.data ? new GoalToCashAllocationsModel(response.data) : null;

          return typed;
        }),
        tap( (typed : GoalToCashAllocations) => {
          // pass data to corresponding goal to cash store for further processing
          this.goalToCashStore.setGoalToCashAllocations(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocations', error)(error);
        })
      );

    return REQUEST$;
  }



  public fetchNodeAllocations(request: GoalToCashNodeAllocationsRequest): Observable<GoalToCashAllocations> {

    const URL = `${this.baseUri}${this.goalToCashAllocationsUri}`;
    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loginId', this.userDetailsStore.getUserId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
        .append('planID', `${request.planID}`)
        .append('allocationID', `${request.allocationID}`)
        .append('goalID', `${request.goalID}`)
        .append('nodeName', `${request.nodeName}`)
        .append('territoryTypeCode', `${request.territoryTypeCode}`)
    };

    const REQUEST$ = this.http.get<GoalToCashAllocationsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map(response => {
          let typed = response.data ? new GoalToCashAllocationsModel(response.data) : null;

          return typed;
        }),
        tap( (typed : GoalToCashAllocations) => {
          // pass data to corresponding goal to cash store for further processing
          this.goalToCashStore.setGoalToCashAllocations(typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchAllocations', error)(error);
        })
      );

    return REQUEST$;
  }


  /**
   * Stages our Http Request Headers
   */
   private getOptions(request: (GoalToCashDetailsRequest | GoalToCashAllocationsRequest | GoalToCashNodesRequest)) : { headers: HttpHeaders, params : HttpParams } {

    let options : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loginId', this.userDetailsStore.getUserId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
        .append('planID', `${request.planID}`)
        .append('allocationID', `${request.allocationID}`)
        .append('goalID', `${request.goalID}`)
    };

    return options;
  }

  private getOptionsForIACV() : { headers: HttpHeaders } {

    const OPTIONS : { headers : HttpHeaders } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
    };

    return OPTIONS;
  }

  public getExportDataModel(myPYFlag: string, metrics: MetricsExportDetails[], currencyCode: string): ExportDataModel{
    if(myPYFlag === 'Y'){
      return new ExportDataModel({
        worksheetName: 'Metrics',
        headers: [
          [

            'PE Name',
            'Metric ID',
            'EDF Transaction ID',
            'Subscription Start Date',
            'Contract Start Week',
            'Source Transaction Date',
            'Transaction Date',
            'Period Fiscal Week',
            'Booked Fiscal Week',
            'Territory Name',
            'ATR Goaling Period Name',
            'SAV ID',
            'SAV Name',
            'Sales Value',
            'ATR Event Motion',
            'Metric Type',
            'Data Source',
            'PID Mapping Group',
            'Business Entity',
            'Sub Business Entity',
            'Software/Services',
            'CX Upsell Group',
            'ATR Baseline',
            'ATR Balance',
            //'ATR Weekly',
            '$Renewed ' + currencyCode + ' Amount',
            'Adj Reason Code',
            'MY Ratio',
            'MY Factor',
            'MY Eligible Amount',
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8',
            'Metric Lens Type',



          ]
        ],
        data: (metrics || [])
          .map(row => [

            row.PE_NAME || '',
            row.EDF_LINK_IDENTIFIER || '',
            row.EDF_TRANSACTION_ID || '',
            row.MAX_CONTRACT_START_DATE ? this.toDateTime(row.MAX_CONTRACT_START_DATE) : '',
            row.MAX_CONTRACT_START_DATE ? row.CONTRACT_START_WEEK : '',
            row.MAX_LINE_CREATION_DATE ? this.toDateTime(row.MAX_LINE_CREATION_DATE) : '',
            row.TRANSACTION_CREATION_DATE ? this.toDateTime(row.TRANSACTION_CREATION_DATE) : '',
            row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
            row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
            row.TERRITORY || '',
            row.ATR_GOALING_PERIOD || '',
            row.SAV_ID || 'NA',
            row.SAV_NAME || 'NA',
            new ExportCellModel({
              value: this.getSalesValue(row, myPYFlag),
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.METRIC_TYPE_CODE || '',
            row.METRIC_TYPE || '',
            row.DATA_SOURCE || '',
            row.BE_CX_LEVEL_3 || '',
            row.BE_CX_LEVEL_1 || '',
            row.BE_CX_LEVEL_2 || '',
            row.PRODUCT_SERVICE_FLAG || '',
            row.CX_UPSELL_GROUP || '',
            new ExportCellModel({
              value: row.BASELINE_ATR,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.ATR_BALANCE,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.RENEWED_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.IACV_WALK_REASON_CODE || '',
            new ExportCellModel({
              value: row.MY_RATIO,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.MY_FACTOR,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.MY_ELIGIBLE_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.SEGMENT1_LEVEL_1 || '',
            row.SEGMENT1_LEVEL_2 || '',
            row.SEGMENT1_LEVEL_3 || '',
            row.SEGMENT1_LEVEL_4 || '',
            row.SEGMENT1_LEVEL_5 || '',
            row.SEGMENT1_LEVEL_6 || '',
            row.SEGMENT1_LEVEL_7 || '',
            row.SEGMENT1_LEVEL_8 || '',
            row.METRIC_LENS_TYPE_NAME || '',



          ])
      });
    }

    else{
       return new ExportDataModel({
        worksheetName: 'Metrics',
        headers: [
          [

            'PE Name',
            'Metric ID',
            'EDF Transaction ID',
            'Subscription Start Date',
            'Contract Start Week',
            'Source Transaction Date',
            'Transaction Date',
            'Period Fiscal Week',
            'Booked Fiscal Week',
            'Territory Name',
            'ATR Goaling Period Name',
            'SAV ID',
            'SAV Name',
            'Sales Value',
            'ATR Event Motion',
            'Metric Type',
            'Data Source',
            'PID Mapping Group',
            'Business Entity',
            'Sub Business Entity',
            'Software/Services',
            'CX Upsell Group',
            'ATR Baseline',
            'ATR Balance',
            //'ATR Weekly',
            '$Renewed ' + currencyCode + ' Amount',
            'Adj Reason Code',
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8',
            'Metric Lens Type',


          ]
        ],
        data: (metrics || [])
          .map(row => [

            row.PE_NAME || '',
            row.EDF_LINK_IDENTIFIER || '',
            row.EDF_TRANSACTION_ID || '',
            row.MAX_CONTRACT_START_DATE ? this.toDateTime(row.MAX_CONTRACT_START_DATE) : '',
            row.MAX_CONTRACT_START_DATE ? row.CONTRACT_START_WEEK : '',
            row.MAX_LINE_CREATION_DATE ? this.toDateTime(row.MAX_LINE_CREATION_DATE) : '',
            row.TRANSACTION_CREATION_DATE ? this.toDateTime(row.TRANSACTION_CREATION_DATE) : '',
            row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
            row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
            row.TERRITORY || '',
            row.ATR_GOALING_PERIOD || '',
            row.SAV_ID || 'NA',
            row.SAV_NAME || 'NA',
            new ExportCellModel({
              value: this.getSalesValue(row, myPYFlag),
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.METRIC_TYPE_CODE || '',
            row.METRIC_TYPE || '',
            row.DATA_SOURCE || '',
            row.BE_CX_LEVEL_3 || '',
            row.BE_CX_LEVEL_1 || '',
            row.BE_CX_LEVEL_2 || '',
            row.PRODUCT_SERVICE_FLAG || '',
            row.CX_UPSELL_GROUP || '',
            new ExportCellModel({
              value: Number(row.BASELINE_ATR),
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.ATR_BALANCE,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.RENEWED_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.IACV_WALK_REASON_CODE || '',
            row.SEGMENT1_LEVEL_1 || '',
            row.SEGMENT1_LEVEL_2 || '',
            row.SEGMENT1_LEVEL_3 || '',
            row.SEGMENT1_LEVEL_4 || '',
            row.SEGMENT1_LEVEL_5 || '',
            row.SEGMENT1_LEVEL_6 || '',
            row.SEGMENT1_LEVEL_7 || '',
            row.SEGMENT1_LEVEL_8 || '',
            row.METRIC_LENS_TYPE_NAME || '',


          ])
      });
    }
  }

  public exportBookingsAndMetrics(bookings: BookingsExportDetails[], metrics: MetricsExportDetails[], exportDefinitions : ExportDefinitionsDetails[],myPYFlag: string, transactionType: 'ALL' | 'REV' | 'BKLG' | string, planElementType: string, currencyCode: string){
    let exportSheets: ExportDataModel[] = [];

    if(planElementType === 'dollarRenewals'){
      exportSheets.push(this.getExportDataModel(myPYFlag, metrics, currencyCode));
    }
    else{
    if(myPYFlag === 'Y'){
      exportSheets.push(new ExportDataModel({
        worksheetName: 'Metrics',
        headers: [
          [

            'PE Name',
            'Metric ID',
            'EDF Transaction ID',
            'Subscription Start Date',
            'Contract Start Week',
            'Source Transaction Date',
            'Transaction Date',
            'Period Fiscal Week',
            'Booked Fiscal Week',
            'Territory Name',
            'ATR Goaling Period Name',
            'SAV ID',
            'SAV Name',
            'Sales Value',
            'ATR Event Motion',
            'Metric Type',
            'Data Source',
            'PID Mapping Group',
            'Business Entity',
            'Sub Business Entity',
            'Software/Services',
            'CX Upsell Group',
            'ATR Baseline',
            'ATR Balance',
            //'ATR Weekly',
            'Cross Sell ' + currencyCode + ' Amount',
            'Upsell ' + currencyCode + ' Amount',
            'Upsell Adjustment ' + currencyCode + ' Amount',
            'Expansion iACV ' + currencyCode + ' Amount',
            'New Logo iACV ' + currencyCode + ' Amount',
            'TCV',
            'Adj Reason Code',
            'MY Ratio',
            'MY Factor',
            'MY Eligible Amount',
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8',
            'Metrics Lens Type',
          ]
        ],
        data: (metrics || [])
          .map(row => [

            row.PE_NAME || '',
            row.EDF_LINK_IDENTIFIER || '',
            row.EDF_TRANSACTION_ID || '',
            row.MAX_CONTRACT_START_DATE ? this.toDateTime(row.MAX_CONTRACT_START_DATE) : '',
            row.MAX_CONTRACT_START_DATE ? row.CONTRACT_START_WEEK : '',
            row.MAX_LINE_CREATION_DATE ? this.toDateTime(row.MAX_LINE_CREATION_DATE) : '',
            row.TRANSACTION_CREATION_DATE ? this.toDateTime(row.TRANSACTION_CREATION_DATE) : '',
            row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
            row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
            row.TERRITORY || '',
            row.ATR_GOALING_PERIOD || '',
            row.SAV_ID || 'NA',
            row.SAV_NAME || 'NA',
            new ExportCellModel({
              value: this.getSalesValue(row, myPYFlag),
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.METRIC_TYPE_CODE || '',
            row.METRIC_TYPE || '',
            row.DATA_SOURCE || '',
            row.BE_CX_LEVEL_3 || '',
            row.BE_CX_LEVEL_1 || '',
            row.BE_CX_LEVEL_2 || '',
            row.PRODUCT_SERVICE_FLAG || '',
            row.CX_UPSELL_GROUP || '',
            new ExportCellModel({
              value: Number(row.BASELINE_ATR),
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.ATR_BALANCE,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.XSELL_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.UPSELL_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.UPSELL_IACV_ADJ_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.EXP_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.NEW_LOGO_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.TCV_METRIC_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.IACV_WALK_REASON_CODE || '',
            new ExportCellModel({
              value: row.MY_RATIO,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.MY_FACTOR,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.MY_ELIGIBLE_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.SEGMENT1_LEVEL_1 || '',
            row.SEGMENT1_LEVEL_2 || '',
            row.SEGMENT1_LEVEL_3 || '',
            row.SEGMENT1_LEVEL_4 || '',
            row.SEGMENT1_LEVEL_5 || '',
            row.SEGMENT1_LEVEL_6 || '',
            row.SEGMENT1_LEVEL_7 || '',
            row.SEGMENT1_LEVEL_8 || '',
            row.METRIC_LENS_TYPE_NAME || '',
          ])
      }));
    }

    else{
      exportSheets.push(new ExportDataModel({
        worksheetName: 'Metrics',
        headers: [
          [

            'PE Name',
            'Metric ID',
            'EDF Transaction ID',
            'Subscription Start Date',
            'Contract Start Week',
            'Source Transaction Date',
            'Transaction Date',
            'Period Fiscal Week',
            'Booked Fiscal Week',
            'Territory Name',
            'ATR Goaling Period Name',
            'SAV ID',
            'SAV Name',
            'Sales Value',
            'ATR Event Motion',
            'Metric Type',
            'Data Source',
            'PID Mapping Group',
            'Business Entity',
            'Sub Business Entity',
            'Software/Services',
            'CX Upsell Group',
            'ATR Baseline',
            'ATR Balance',
            //'ATR Weekly',
            'Cross Sell ' + currencyCode + ' Amount',
            'Upsell ' + currencyCode + ' Amount',
            'Upsell Adjustment ' + currencyCode + ' Amount',
            'Expansion iACV ' + currencyCode + ' Amount',
            'New Logo iACV ' + currencyCode + ' Amount',
            'TCV',
            'Adj Reason Code',
            'Node 1',
            'Node 2',
            'Node 3',
            'Node 4',
            'Node 5',
            'Node 6',
            'Node 7',
            'Node 8',
            'Metric Lens Type',
          ]
        ],
        data: (metrics || [])
          .map(row => [

            row.PE_NAME || '',
            row.EDF_LINK_IDENTIFIER || '',
            row.EDF_TRANSACTION_ID || '',
            row.MAX_CONTRACT_START_DATE ? this.toDateTime(row.MAX_CONTRACT_START_DATE) : '',
            row.MAX_CONTRACT_START_DATE ? row.CONTRACT_START_WEEK : '',
            row.MAX_LINE_CREATION_DATE ? this.toDateTime(row.MAX_LINE_CREATION_DATE) : '',
            row.TRANSACTION_CREATION_DATE ? this.toDateTime(row.TRANSACTION_CREATION_DATE) : '',
            row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
            row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
            row.TERRITORY || '',
            row.ATR_GOALING_PERIOD || '',
            row.SAV_ID || 'NA',
            row.SAV_NAME || 'NA',
            new ExportCellModel({
              value: this.getSalesValue(row, myPYFlag),
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.METRIC_TYPE_CODE || '',
            row.METRIC_TYPE || '',
            row.DATA_SOURCE || '',
            row.BE_CX_LEVEL_3 || '',
            row.BE_CX_LEVEL_1 || '',
            row.BE_CX_LEVEL_2 || '',
            row.PRODUCT_SERVICE_FLAG || '',
            row.CX_UPSELL_GROUP || '',
            new ExportCellModel({
              value: Number(row.BASELINE_ATR),
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.ATR_BALANCE,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.XSELL_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.UPSELL_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.UPSELL_IACV_ADJ_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.EXP_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.NEW_LOGO_IACV_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            new ExportCellModel({
              value: row.TCV_METRIC_USD_AMOUNT,
              style: ExportCellModel.getNumberStyles(2)
            }),
            row.IACV_WALK_REASON_CODE || '',
            row.SEGMENT1_LEVEL_1 || '',
            row.SEGMENT1_LEVEL_2 || '',
            row.SEGMENT1_LEVEL_3 || '',
            row.SEGMENT1_LEVEL_4 || '',
            row.SEGMENT1_LEVEL_5 || '',
            row.SEGMENT1_LEVEL_6 || '',
            row.SEGMENT1_LEVEL_7 || '',
            row.SEGMENT1_LEVEL_8 || '',
            row.METRIC_LENS_TYPE_NAME || '',
          ])
      }));
    }
  }





    exportSheets.push(new ExportDataModel({
      worksheetName: 'SO Detail',
      headers: [
        [
          'PE Name',
          'Metric ID',
          'EDF Transaction ID',
          'Bookings Process Date',
          'Cisco Booked Date',
          'Period Fiscal Week',
          'Booked Fiscal Week',
          'Territory Name',
          'ATR Goaling Period Name',
          'SAV ID',
          'SAV Name',
          'Book Source',
          'PID Mapping Group',
          'Business Entity',
          'Sub Business Entity',
          'Service Flag',
          'CX Upsell Group',
          'New Logo Flag',
          'Subscription Reference ID',
          'Deal ID',
          'Web Order ID',
          'Prev Web Order ID',
          'Sales Order Number',
          'Sales Order Line ID',
          'Product ID',
          'Attribution Code',
          'Term Start Date',
          'Term End Date',
          'Annual Booknet',
          'Contract Duration',
          'TCV',
          'Sales Motion CD',
          'iACV Exception Reason',
          'Node 1',
          'Node 2',
          'Node 3',
          'Node 4',
          'Node 5',
          'Node 6',
          'Node 7',
          'Node 8',
          'Metric Lens Type',
        ]
      ],
      data: (bookings || [])
        .map(row => [
          row.PE_NAME || '',
          row.LINK_IDENTIFIER || '',
          row.EDF_TRANSACTION_ID || '',
          row.BOOKINGS_PROCESS_DATE ? this.toDateTime(row.BOOKINGS_PROCESS_DATE) : '',
          row.CISCO_BOOKED_DATE ? this.toDateTime(row.CISCO_BOOKED_DATE) : '',
          row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
          row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
          row.TERRITORY_NAME || '',
          row.ATR_GOAL_PERIOD_NAME || '',
          row.SAV_ID || 'NA',
          row.SAV_NAME || 'NA',
          row.BKGS_MEASURE_TRANS_TYPE_CODE || '',
          row.PID_MATCHING || '',
          row.BE || '',
          row.SBE || '',
          row.SERVICE_FLAG || '',
          row.CX_UPSELL_GROUP || '',
          row.NEW_LOGO_FLAG || '',
          row.SUBSCRIPTION_REFERENCE_ID || '',
          row.DEAL_ID || '',
          row.WEB_ORDER_ID || '',
          row.PREV_WEB_ORDER_ID || '',
          row.SALES_ORDER_NUMBER || '',
          row.LINE_ID || '',
          row.PART_NUMBER || '',
          row.ATTRIBUTION_CODE || '',
          row.TERM_START_DATETIME ? this.toDateTime(row.TERM_START_DATETIME) : '',
          row.TERM_END_DATETIME ? this.toDateTime(row.TERM_END_DATETIME) : '',
          new ExportCellModel({
            value: row.ACV_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          }),
          row.CONTRACT_TERM || '',
          new ExportCellModel({
            value: row.TCV_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          }),
          row.SALES_MOTION_CD || '',
          row.IACV_EXCEPTION_REASON || '',
          row.SEGMENT1_LEVEL_1 || '',
          row.SEGMENT1_LEVEL_2 || '',
          row.SEGMENT1_LEVEL_3 || '',
          row.SEGMENT1_LEVEL_4 || '',
          row.SEGMENT1_LEVEL_5 || '',
          row.SEGMENT1_LEVEL_6 || '',
          row.SEGMENT1_LEVEL_7 || '',
          row.SEGMENT1_LEVEL_8 || '',
          row.METRIC_LENS_TYPE_NAME || '',
        ])
    }));

    exportSheets.push(new ExportDataModel({
      worksheetName: 'Adj Code Definition',
      headers: [
        [
          'Adj Reason Code',
          'Adj Code Definition'

        ]
      ],
      data: (exportDefinitions || [])
      .filter(row=> row.TAB_NAME===ExportDefinitionType.UPSELL_ADJUSTMENT_DEF)
        .map(row => [
          row.REASON_CD || '',
          row.DESCRIPTION || ''
        ])
    }));

    exportSheets.push(new ExportDataModel({
      worksheetName: 'Attribute Definition',
      headers: [
        [
          'Attribute Code',
          'Attribute Definition',
          'Attribute Tab'

        ]
      ],
      data: (exportDefinitions || [])
      .filter(row=> row.TAB_NAME===ExportDefinitionType.GENERIC_DEF)
        .map(row => [
          row.REASON_CD || '',
          row.DESCRIPTION || '',
          row.ATTRIBUTE1 || ''
        ])
    }));


      this.exportService.exportExcel(exportSheets, this.getBookingsAndMetricsFileName(transactionType));
  }


  public getMetricsSOExportDataModel(sourcePage: string, metrics: MetricSOLinesSummary[], currencyCode: string, myFlag: string, ampIdentifier:string): ExportDataModel {
    // $Renewed Other plan element types Scenario
    // payments as source page
    if (sourcePage === 'payments') {
      // MY scenario
      if (myFlag === 'Y') {
        return new ExportDataModel({
          worksheetName: 'SO Details (RND)',
          headers: [
            [
              'Order Type',
              'Sales Order Number',
              'Book Date/Line creation Date',
              'Creation Date',
              'Revenue',
              'PO Number',
              'Deal ID',
              'Node/Territory Name',
              'CAV End Customer Name',
              ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
              'ATR Goaling Period Name',
              'Sales Order Line Number',
              'Territory Type',
              'SAV ID',
              'SAV Name',
              'Product Service Identifier',
              'Business Entity',
              'Sub Business Entity',
              'PID Mapping Group',
              'CX Upsell Group',
              'ATR Event Motion',
              'Product ID',
              'Offer Part Number',
              'Attribution Code',
              'Metric Lens Type',
              '$Renewed + Residual Annual',
              '$Renewed',
              'Residual Annual',
              'Total Contract Value',
              'MY Factor',
              'Split Percent',
              'Currency',
              'ATR Reference Type',
              'Metric Type',
              'Adj Reason Code',
              'Invoice Date',
              'Contract Start Date',
              'Contract End Date',
              'Contract Term',
              'Metric Fiscal Week',
              'Booked Fiscal Week',
              'Sales Level 1',
              'Sales Level 2',
              'Sales Level 3',
              'Sales Level 4',
              'Sales Level 5',
              'Sales Level 6',
              'Subscription Reference ID',
              'Ship to Customer',
              'Bill to Customer',
              'Sales Motion',
              'Product Family',
              'Business Unit',
              'Offer Name',
              'Offer Type Name',
              'SaaS Flag',
              'ELA Flag',
              'Buying Program Flag',
              'Buying Program Name',
              'Buying Program Group',
              'WPA Flag',
              'Data Source'
            ]
          ],
          data: (metrics || [])
            .map(row => [
              row.TYPE === 'Credit/Debit Memo' ? 'Direct' : row.TYPE || '',
              row.SO_NUMBER || '',
              row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
              row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
              new ExportCellModel({
                value: row.MY_PAYOUT_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.MY_PAYOUT_AMOUNT))
              }),
              row.PO_NUMBER || '',
              row.DEAL_ID || '',
              row.TERRITORY_CODE || '',
              row.END_CUSTOMER_NAME || '',
              row.PE_NAME || '',
              row.GOALING_PERIOD || '',
              row.LINE_ID || '',
              row.TERRITORY_TYPE_NAME || '',
              row.SAV_ID || '',
              row.SAV_NAME || '',
              row.PRODUCT_SERVICE_FLAG || '',
              row.BUSINESS_ENTITY || '',
              row.SUB_BUSINESS_ENTITY || '',
              row.PID_MAPPING_GROUP || '',
              row.CX_UPSELL_GROUP || '',
              row.METRIC_TYPE_CODE || '',
              row.PART_NUMBER || '',
              row.OFFER_PART_NUMBER || '',
              row.ATTRIBUTION_CODE || '',
              row.METRIC_LENS_TYPE || '',
              new ExportCellModel({
                value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }) :
              new ExportCellModel({
                value: row.ORG_RENEWED_USD_AMT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.ORG_RENEWED_USD_AMT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? 'NA' :
                new ExportCellModel({
                value: row.RESIDUAL_ANNUAL_USD_AMT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RESIDUAL_ANNUAL_USD_AMT))
              }),
              new ExportCellModel({
                value: row.TOTAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
              }),
              row.MY_PAYOUT_FACTOR || '',
              new ExportCellModel({
                value: row.SPLIT_PERCENT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
              }),
              row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
              row.ATR_REFERENCE_TYPE || '',
              row.METRIC_TYPE || '',
              row.IACV_WALK_REASON_CODE || '',
              row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
              row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
              row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
              row.CONTRACT_TERM || '',
              row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
              row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
              row.NODE_LEVEL_1 || '',
              row.NODE_LEVEL_2 || '',
              row.NODE_LEVEL_3 || '',
              row.NODE_LEVEL_4 || '',
              row.NODE_LEVEL_5 || '',
              row.NODE_LEVEL_6 || '',
              row.SUBSCRIPTION_REF_ID || '',
              row.SHIP_TO_CUSTOMER_NAME || '',
              row.BILL_TO_CUSTOMER_NAME || '',
              row.SALES_MOTION || '',
              row.PRODUCT_FAMILY || '',
              row.BUSINESS_UNIT || '',
              row.OFFER_NAME || '',
              row.OFFER_TYPE_NAME || '',
              row.SAAS_FLAG || '',
              row.ELA_FLAG || '',
              row.BUYING_PROGRAM_FLAG || '',
              row.BUYING_PROGRAM_NAME || '',
              row.BUYING_PROGRAM_GROUP || '',
              row.WPA_FLAG || '',
              row.DATA_SOURCE || ''
            ])
        });
      } else {
        // non MY scenario
        return new ExportDataModel({
          worksheetName: 'SO Details (RND)',
          headers: [
            [
              'Order Type',
              'Sales Order Number',
              'Book Date/Line creation Date',
              'Creation Date',
              'Revenue',
              'PO Number',
              'Deal ID',
              'Node/Territory Name',
              'CAV End Customer Name',
              ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
              'ATR Goaling Period Name',
              'Sales Order Line Number',
              'Territory Type',
              'SAV ID',
              'SAV Name',
              'Product Service Identifier',
              'Business Entity',
              'Sub Business Entity',
              'PID Mapping Group',
              'CX Upsell Group',
              'ATR Event Motion',
              'Product ID',
              'Offer Part Number',
              'Attribution Code',
              'Metric Lens Type',
              '$Renewed + Residual Annual',
              '$Renewed',
              'Residual Annual',
              'Total Contract Value',
              'Split Percent',
              'Currency',
              'ATR Reference Type',
              'Metric Type',
              'Adj Reason Code',
              'Invoice Date',
              'Contract Start Date',
              'Contract End Date',
              'Contract Term',
              'Metric Fiscal Week',
              'Booked Fiscal Week',
              'Sales Level 1',
              'Sales Level 2',
              'Sales Level 3',
              'Sales Level 4',
              'Sales Level 5',
              'Sales Level 6',
              'Subscription Reference ID',
              'Ship to Customer',
              'Bill to Customer',
              'Sales Motion',
              'Product Family',
              'Business Unit',
              'Offer Name',
              'Offer Type Name',
              'SaaS Flag',
              'ELA Flag',
              'Buying Program Flag',
              'Buying Program Name',
              'Buying Program Group',
              'WPA Flag',
              'Data Source'
            ]
          ],
          data: (metrics || [])
            .map(row => [
              row.TYPE === 'Credit/Debit Memo' ? 'Direct' : row.TYPE || '',
              row.SO_NUMBER || '',
              row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
              row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
              new ExportCellModel({
                value: row.REVENUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
              }),
              row.PO_NUMBER || '',
              row.DEAL_ID || '',
              row.TERRITORY_CODE || '',
              row.END_CUSTOMER_NAME || '',
              row.PE_NAME || '',
              row.GOALING_PERIOD || '',
              row.LINE_ID || '',
              row.TERRITORY_TYPE_NAME || '',
              row.SAV_ID || '',
              row.SAV_NAME || '',
              row.PRODUCT_SERVICE_FLAG || '',
              row.BUSINESS_ENTITY || '',
              row.SUB_BUSINESS_ENTITY || '',
              row.PID_MAPPING_GROUP || '',
              row.CX_UPSELL_GROUP || '',
              row.METRIC_TYPE_CODE || '',
              row.PART_NUMBER || '',
              row.OFFER_PART_NUMBER || '',
              row.ATTRIBUTION_CODE || '',
              row.METRIC_LENS_TYPE || '',
              new ExportCellModel({
                value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }) :
                new ExportCellModel({
                  value: row.ORG_RENEWED_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ORG_RENEWED_USD_AMT))
                }),
              row.PE_NAME?.substring(0,4) === '2023' ? 'NA' :
                new ExportCellModel({
                  value: row.RESIDUAL_ANNUAL_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RESIDUAL_ANNUAL_USD_AMT))
                }),
              new ExportCellModel({
                value: row.TOTAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
              }),
              new ExportCellModel({
                value: row.SPLIT_PERCENT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
              }),
              row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
              row.ATR_REFERENCE_TYPE || '',
              row.METRIC_TYPE || '',
              row.IACV_WALK_REASON_CODE || '',
              row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
              row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
              row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
              row.CONTRACT_TERM || '',
              row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
              row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
              row.NODE_LEVEL_1 || '',
              row.NODE_LEVEL_2 || '',
              row.NODE_LEVEL_3 || '',
              row.NODE_LEVEL_4 || '',
              row.NODE_LEVEL_5 || '',
              row.NODE_LEVEL_6 || '',
              row.SUBSCRIPTION_REF_ID || '',
              row.SHIP_TO_CUSTOMER_NAME || '',
              row.BILL_TO_CUSTOMER_NAME || '',
              row.SALES_MOTION || '',
              row.PRODUCT_FAMILY || '',
              row.BUSINESS_UNIT || '',
              row.OFFER_NAME || '',
              row.OFFER_TYPE_NAME || '',
              row.SAAS_FLAG || '',
              row.ELA_FLAG || '',
              row.BUYING_PROGRAM_FLAG || '',
              row.BUYING_PROGRAM_NAME || '',
              row.BUYING_PROGRAM_GROUP || '',
              row.WPA_FLAG || '',
              row.DATA_SOURCE || ''
            ])
        });
      }
    } else {
      // g2c and orders as source page
      // MY scenario
      if (myFlag === 'Y') {
        return new ExportDataModel({
          worksheetName: 'SO Details (RND)',
          headers: [
            [
              'Order Type',
              'Sales Order Number',
              'Book Date/Line creation Date',
              'Creation Date',
              'Revenue',
              'PO Number',
              'Deal ID',
              'Node/Territory Name',
              'CAV End Customer Name',
              ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
              'ATR Goaling Period Name',
              'Sales Order Line Number',
              'Territory Type',
              'SAV ID',
              'SAV Name',
              'Product Service Identifier',
              'Business Entity',
              'Sub Business Entity',
              'PID Mapping Group',
              'CX Upsell Group',
              'ATR Event Motion',
              'Product ID',
              'Offer Part Number',
              'Attribution Code',
              'Metric Lens Type',
              '$Renewed + Residual Annual',
              '$Renewed',
              'Residual Annual',
              'Total Contract Value',
              'MY Factor',
              'Split Percent',
              'Currency',
              'ATR Reference Type',
              'Metric Type',
              'Adj Reason Code',
              'Invoice Date',
              'Contract Start Date',
              'Contract End Date',
              'Contract Term',
              'Metric Fiscal Week',
              'Booked Fiscal Week',
              'Sales Level 1',
              'Sales Level 2',
              'Sales Level 3',
              'Sales Level 4',
              'Sales Level 5',
              'Sales Level 6',
              'Subscription Reference ID',
              'Ship to Customer',
              'Bill to Customer',
              'Sales Motion',
              'Product Family',
              'Business Unit',
              'Offer Name',
              'Offer Type Name',
              'SaaS Flag',
              'ELA Flag',
              'Buying Program Flag',
              'Buying Program Name',
              'Buying Program Group',
              'WPA Flag',
              'Data Source'
            ]
          ],
          data: (metrics || [])
            .map(row => [
              row.TYPE || '',
              row.SO_NUMBER || '',
              row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
              row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
              new ExportCellModel({
                value: row.MY_PAYOUT_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.MY_PAYOUT_AMOUNT))
              }),
              row.PO_NUMBER || '',
              row.DEAL_ID || '',
              row.TERRITORY_CODE || '',
              row.END_CUSTOMER_NAME || '',
              row.PE_NAME || '',
              row.GOALING_PERIOD || '',
              row.LINE_ID || '',
              row.TERRITORY_TYPE_NAME || '',
              row.SAV_ID || '',
              row.SAV_NAME || '',
              row.PRODUCT_SERVICE_FLAG || '',
              row.BUSINESS_ENTITY || '',
              row.SUB_BUSINESS_ENTITY || '',
              row.PID_MAPPING_GROUP || '',
              row.CX_UPSELL_GROUP || '',
              row.METRIC_TYPE_CODE || '',
              row.PART_NUMBER || '',
              row.OFFER_PART_NUMBER || '',
              row.ATTRIBUTION_CODE || '',
              row.METRIC_LENS_TYPE || '',
              new ExportCellModel({
                value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }) :
                new ExportCellModel({
                  value: row.ORG_RENEWED_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ORG_RENEWED_USD_AMT))
                }),
              row.PE_NAME?.substring(0,4) === '2023' ? 'NA' :
                new ExportCellModel({
                  value: row.RESIDUAL_ANNUAL_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RESIDUAL_ANNUAL_USD_AMT))
                }),
              new ExportCellModel({
                value: row.TOTAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
              }),
              row.MY_PAYOUT_FACTOR || '',
              new ExportCellModel({
                value: row.SPLIT_PERCENT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
              }),
              row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
              row.ATR_REFERENCE_TYPE || '',
              row.METRIC_TYPE || '',
              row.IACV_WALK_REASON_CODE || '',
              row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
              row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
              row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
              row.CONTRACT_TERM || '',
              row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
              row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
              row.NODE_LEVEL_1 || '',
              row.NODE_LEVEL_2 || '',
              row.NODE_LEVEL_3 || '',
              row.NODE_LEVEL_4 || '',
              row.NODE_LEVEL_5 || '',
              row.NODE_LEVEL_6 || '',
              row.SUBSCRIPTION_REF_ID || '',
              row.SHIP_TO_CUSTOMER_NAME || '',
              row.BILL_TO_CUSTOMER_NAME || '',
              row.SALES_MOTION || '',
              row.PRODUCT_FAMILY || '',
              row.BUSINESS_UNIT || '',
              row.OFFER_NAME || '',
              row.OFFER_TYPE_NAME || '',
              row.SAAS_FLAG || '',
              row.ELA_FLAG || '',
              row.BUYING_PROGRAM_FLAG || '',
              row.BUYING_PROGRAM_NAME || '',
              row.BUYING_PROGRAM_GROUP || '',
              row.WPA_FLAG || '',
              row.DATA_SOURCE || ''
            ])
        });
      } else {
        // non MY scenario
        return new ExportDataModel({
          worksheetName: 'SO Details (RND)',
          headers: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? [
            [
              'Non Eligibility Reason',
              'Order Type',
              'Sales Order Number',
              'Book Date/Line creation Date',
              'Creation Date',
              'Revenue',
              'PO Number',
              'Deal ID',
              'Node/Territory Name',
              'CAV End Customer Name',
              'ATR Goaling Period Name',
              'Sales Order Line Number',
              'Territory Type',
              'SAV ID',
              'SAV Name',
              'Product Service Identifier',
              'Business Entity',
              'Sub Business Entity',
              'PID Mapping Group',
              'CX Upsell Group',
              'ATR Event Motion',
              'Product ID',
              'Offer Part Number',
              'Attribution Code',
              'Metric Lens Type',
              'Total iACV',
              '$Renewed + Residual Annual',
              '$Renewed',
              'Residual Annual',
              'Non-Comm Bookings',
              'Total Annual Bookings',
              'Total Contract Value',
              'Split Percent',
              'Currency',
              'ATR Reference Type',
              'Metric Type',
              'Adj Reason Code',
              'Invoice Date',
              'Contract Start Date',
              'Contract End Date',
              'Contract Term',
              'Metric Fiscal Week',
              'Sales Level 1',
              'Sales Level 2',
              'Sales Level 3',
              'Sales Level 4',
              'Sales Level 5',
              'Sales Level 6',
              'Subscription Reference ID',
              'Ship to Customer',
              'Bill to Customer',
              'Sales Motion'
            ]
          ] : [
            [
              'Order Type',
              'Sales Order Number',
              'Book Date/Line creation Date',
              'Creation Date',
              'Bookings',
              'Backlog',
              'Revenue',
              'PO Number',
              'Deal ID',
              'Node/Territory Name',
              'CAV End Customer Name',
              ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
              'ATR Goaling Period Name',
              'Sales Order Line Number',
              'Territory Type',
              'SAV ID',
              'SAV Name',
              'Product Service Identifier',
              'Business Entity',
              'Sub Business Entity',
              'PID Mapping Group',
              'CX Upsell Group',
              'ATR Event Motion',
              'Product ID',
              'Offer Part Number',
              'Attribution Code',
              'Metric Lens Type',
              'Total iACV',
              '$Renewed + Residual Annual',
              '$Renewed',
              'Residual Annual',
              'Non-Comm Bookings',
              'Total Annual Bookings',
              'Total Contract Value',
              'Split Percent',
              'Currency',
              'ATR Reference Type',
              'Metric Type',
              'Adj Reason Code',
              'Invoice Date',
              'Contract Start Date',
              'Contract End Date',
              'Contract Term',
              'Metric Fiscal Week',
              'Booked Fiscal Week',
              'Sales Level 1',
              'Sales Level 2',
              'Sales Level 3',
              'Sales Level 4',
              'Sales Level 5',
              'Sales Level 6',
              'Subscription Reference ID',
              'Ship to Customer',
              'Bill to Customer',
              'Sales Motion',
              'Product Family',
              'Business Unit',
              'Offer Name',
              'Offer Type Name',
              'SaaS Flag',
              'ELA Flag',
              'Buying Program Flag',
              'Buying Program Name',
              'Buying Program Group',
              'WPA Flag',
              'Data Source'
            ]
          ],
          data: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? 
            (metrics || [])
            .map(row => [
              row.NON_ELIGIBILITY_REASON || '',
              row.TYPE || '',
              row.SO_NUMBER || '',
              row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
              row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
              new ExportCellModel({
                value: row.REVENUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
              }),
              row.PO_NUMBER || '',
              row.DEAL_ID || '',
              row.TERRITORY_CODE || '',
              row.END_CUSTOMER_NAME || '',
              row.GOALING_PERIOD || '',
              row.LINE_ID || '',
              row.TERRITORY_TYPE_CODE || '',
              row.SAV_ID || '',
              row.SAV_NAME || '',
              row.PRODUCT_SERVICE_FLAG || '',
              row.BUSINESS_ENTITY || '',
              row.SUB_BUSINESS_ENTITY || '',
              row.PID_MAPPING_GROUP || '',
              row.CX_UPSELL_GROUP || '',
              row.METRIC_TYPE_CODE || '',
              row.PART_NUMBER || '',
              row.OFFER_PART_NUMBER || '',
              row.ATTRIBUTION_CODE || '',
              row.METRIC_LENS_TYPE || '',
              new ExportCellModel({
                value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
              }),
              new ExportCellModel({
                value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }) :
                new ExportCellModel({
                  value: row.ORG_RENEWED_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ORG_RENEWED_USD_AMT))
                }),
              row.PE_NAME?.substring(0,4) === '2023' ? 'NA' :
                new ExportCellModel({
                  value: row.RESIDUAL_ANNUAL_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RESIDUAL_ANNUAL_USD_AMT))
                }),
              new ExportCellModel({
                value: row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT))
              }),
              new ExportCellModel({
                value: row.ANNUAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.ANNUAL_VALUE))
              }),
              new ExportCellModel({
                value: row.TOTAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
              }),
              new ExportCellModel({
                value: row.SPLIT_PERCENT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
              }),
              row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
              row.ATR_REFERENCE_TYPE || '',
              row.METRIC_TYPE || '',
              row.IACV_WALK_REASON_CODE || '',
              row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
              row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
              row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
              row.CONTRACT_TERM || '',
              row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
              row.NODE_LEVEL_1 || '',
              row.NODE_LEVEL_2 || '',
              row.NODE_LEVEL_3 || '',
              row.NODE_LEVEL_4 || '',
              row.NODE_LEVEL_5 || '',
              row.NODE_LEVEL_6 || '',
              row.SUBSCRIPTION_REF_ID || '',
              row.SHIP_TO_CUSTOMER_NAME || '',
              row.BILL_TO_CUSTOMER_NAME || '',
              row.SALES_MOTION || ''
            ]) :
            (metrics || [])
            .map(row => [
              row.TYPE || '',
              row.SO_NUMBER || '',
              row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
              row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
              new ExportCellModel({
                value: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? 'NA' : row.BACKLOG + row.REVENUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? null : row.BACKLOG + row.REVENUE))
              }),
              new ExportCellModel({
                value: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? 'NA' : row.BACKLOG,
                style: ExportCellModel.getNumberStyles(this.getPrecision(ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? null : row.BACKLOG))
              }),
              new ExportCellModel({
                value: row.REVENUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
              }),
              row.PO_NUMBER || '',
              row.DEAL_ID || '',
              row.TERRITORY_CODE || '',
              row.END_CUSTOMER_NAME || '',
              row.PE_NAME || '',
              row.GOALING_PERIOD || '',
              row.LINE_ID || '',
              row.TERRITORY_TYPE_NAME || '',
              row.SAV_ID || '',
              row.SAV_NAME || '',
              row.PRODUCT_SERVICE_FLAG || '',
              row.BUSINESS_ENTITY || '',
              row.SUB_BUSINESS_ENTITY || '',
              row.PID_MAPPING_GROUP || '',
              row.CX_UPSELL_GROUP || '',
              row.METRIC_TYPE_CODE || '',
              row.PART_NUMBER || '',
              row.OFFER_PART_NUMBER || '',
              row.ATTRIBUTION_CODE || '',
              row.METRIC_LENS_TYPE || '',
              new ExportCellModel({
                value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
              }),
              new ExportCellModel({
                value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
              }),
              row.PE_NAME?.substring(0,4) === '2023' ? new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }) :
                new ExportCellModel({
                  value: row.ORG_RENEWED_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ORG_RENEWED_USD_AMT))
                }),
              row.PE_NAME?.substring(0,4) === '2023' ? 'NA' :
                new ExportCellModel({
                  value: row.RESIDUAL_ANNUAL_USD_AMT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RESIDUAL_ANNUAL_USD_AMT))
                }),
              new ExportCellModel({
                value: row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT))
              }),
              new ExportCellModel({
                value: row.ANNUAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.ANNUAL_VALUE))
              }),
              new ExportCellModel({
                value: row.TOTAL_VALUE,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
              }),
              new ExportCellModel({
                value: row.SPLIT_PERCENT,
                style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
              }),
              row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
              row.ATR_REFERENCE_TYPE || '',
              row.METRIC_TYPE || '',
              row.IACV_WALK_REASON_CODE || '',
              row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
              row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
              row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
              row.CONTRACT_TERM || '',
              row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
              row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
              row.NODE_LEVEL_1 || '',
              row.NODE_LEVEL_2 || '',
              row.NODE_LEVEL_3 || '',
              row.NODE_LEVEL_4 || '',
              row.NODE_LEVEL_5 || '',
              row.NODE_LEVEL_6 || '',
              row.SUBSCRIPTION_REF_ID || '',
              row.SHIP_TO_CUSTOMER_NAME || '',
              row.BILL_TO_CUSTOMER_NAME || '',
              row.SALES_MOTION || '',
              row.PRODUCT_FAMILY || '',
              row.BUSINESS_UNIT || '',
              row.OFFER_NAME || '',
              row.OFFER_TYPE_NAME || '',
              row.SAAS_FLAG || '',
              row.ELA_FLAG || '',
              row.BUYING_PROGRAM_FLAG || '',
              row.BUYING_PROGRAM_NAME || '',
              row.BUYING_PROGRAM_GROUP || '',
              row.WPA_FLAG || '',
              row.DATA_SOURCE || ''
            ])
        });
      }
    }

  }

  public exportMetricSOLines(metrics: MetricSOLinesSummary[], exportDefinitions: ExportDefinitionsDetails[], sourcePage: string, fileName: string, peType: string, myFlag: string, ampIdentifier: string, currencyCode: string) {
    let exportSheets: ExportDataModel[] = [];

    if (peType === 'RND') {
      exportSheets.push(this.getMetricsSOExportDataModel(sourcePage, metrics, currencyCode, myFlag, ampIdentifier));
    }
    else {
      // iACV Scenario
      // payments as source page
      if (sourcePage === 'payments') {
        // MY scenario
        if (myFlag === 'Y') {
          exportSheets.push(new ExportDataModel({
            worksheetName: 'SO Details (IACV)',
            headers: [
              [
                'Order Type',
                'Sales Order Number',
                'Book Date/Line creation Date',
                'Creation Date',
                'Revenue',
                'PO Number',
                'Deal ID',
                'Node/Territory Name',
                'CAV End Customer Name',
                ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
                'ATR Goaling Period Name',
                'Sales Order Line Number',
                'Territory Type',
                'SAV ID',
                'SAV Name',
                'Product Service Identifier',
                'Business Entity',
                'Sub Business Entity',
                'PID Mapping Group',
                'CX Upsell Group',
                'ATR Event Motion',
                'Product ID',
                'Offer Part Number',
                'Attribution Code',
                'Metric Lens Type',
                'Total iACV',
                'Total Contract Value',
                'MY Factor',
                'Split Percent',
                'Currency',
                'ATR Reference Type',
                'Metric Type',
                'Adj Reason Code',
                'Invoice Date',
                'Contract Start Date',
                'Contract End Date',
                'Contract Term',
                'Metric Fiscal Week',
                'Booked Fiscal Week',
                'Sales Level 1',
                'Sales Level 2',
                'Sales Level 3',
                'Sales Level 4',
                'Sales Level 5',
                'Sales Level 6',
                'Subscription Reference ID',
                'Ship to Customer',
                'Bill to Customer',
                'Sales Motion',
                'Product Family',
                'Business Unit',
                'Offer Name',
                'Offer Type Name',
                'SaaS Flag',
                'ELA Flag',
                'Buying Program Flag',
                'Buying Program Name',
                'Buying Program Group',
                'WPA Flag',
                'Data Source'
              ]
            ],
            data: (metrics || [])
              .map(row => [
                row.TYPE === 'Credit/Debit Memo' ? 'Direct' : row.TYPE || '',
                row.SO_NUMBER || '',
                row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
                row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
                new ExportCellModel({
                  value: row.MY_PAYOUT_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.MY_PAYOUT_AMOUNT))
                }),
                row.PO_NUMBER || '',
                row.DEAL_ID || '',
                row.TERRITORY_CODE || '',
                row.END_CUSTOMER_NAME || '',
                row.PE_NAME || '',
                row.GOALING_PERIOD || '',
                row.LINE_ID || '',
                row.TERRITORY_TYPE_NAME || '',
                row.SAV_ID || '',
                row.SAV_NAME || '',
                row.PRODUCT_SERVICE_FLAG || '',
                row.BUSINESS_ENTITY || '',
                row.SUB_BUSINESS_ENTITY || '',
                row.PID_MAPPING_GROUP || '',
                row.CX_UPSELL_GROUP || '',
                row.METRIC_TYPE_CODE || '',
                row.PART_NUMBER || '',
                row.OFFER_PART_NUMBER || '',
                row.ATTRIBUTION_CODE || '',
                row.METRIC_LENS_TYPE || '',
                new ExportCellModel({
                  value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.TOTAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
                }),
                row.MY_PAYOUT_FACTOR || '',
                new ExportCellModel({
                  value: row.SPLIT_PERCENT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
                }),
                row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
                row.ATR_REFERENCE_TYPE || '',
                row.METRIC_TYPE || '',
                row.IACV_WALK_REASON_CODE || '',
                row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
                row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
                row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
                row.CONTRACT_TERM || '',
                row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
                row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
                row.NODE_LEVEL_1 || '',
                row.NODE_LEVEL_2 || '',
                row.NODE_LEVEL_3 || '',
                row.NODE_LEVEL_4 || '',
                row.NODE_LEVEL_5 || '',
                row.NODE_LEVEL_6 || '',
                row.SUBSCRIPTION_REF_ID || '',
                row.SHIP_TO_CUSTOMER_NAME || '',
                row.BILL_TO_CUSTOMER_NAME || '',
                row.SALES_MOTION || '',
                row.PRODUCT_FAMILY || '',
                row.BUSINESS_UNIT || '',
                row.OFFER_NAME || '',
                row.OFFER_TYPE_NAME || '',
                row.SAAS_FLAG || '',
                row.ELA_FLAG || '',
                row.BUYING_PROGRAM_FLAG || '',
                row.BUYING_PROGRAM_NAME || '',
                row.BUYING_PROGRAM_GROUP || '',
                row.WPA_FLAG || '',
                row.DATA_SOURCE || ''
              ])
          }));
        } else {
          // non MY scenario
          exportSheets.push(new ExportDataModel({
            worksheetName: 'SO Details (IACV)',
            headers: [
              [
                'Order Type',
                'Sales Order Number',
                'Book Date/Line creation Date',
                'Creation Date',
                'Revenue',
                'PO Number',
                'Deal ID',
                'Node/Territory Name',
                'CAV End Customer Name',
                ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
                'ATR Goaling Period Name',
                'Sales Order Line Number',
                'Territory Type',
                'SAV ID',
                'SAV Name',
                'Product Service Identifier',
                'Business Entity',
                'Sub Business Entity',
                'PID Mapping Group',
                'CX Upsell Group',
                'ATR Event Motion',
                'Product ID',
                'Offer Part Number',
                'Attribution Code',
                'Metric Lens Type',
                'Cross Sell  Amount',
                'Upsell  Amount',
                'New Logo iACV  Amount',
                'Total iACV',
                'Total Contract Value',
                'Split Percent',
                'Currency',
                'ATR Reference Type',
                'Metric Type',
                'Adj Reason Code',
                'Invoice Date',
                'Contract Start Date',
                'Contract End Date',
                'Contract Term',
                'Metric Fiscal Week',
                'Booked Fiscal Week',
                'Sales Level 1',
                'Sales Level 2',
                'Sales Level 3',
                'Sales Level 4',
                'Sales Level 5',
                'Sales Level 6',
                'Subscription Reference ID',
                'Ship to Customer',
                'Bill to Customer',
                'Sales Motion',
                'Product Family',
                'Business Unit',
                'Offer Name',
                'Offer Type Name',
                'SaaS Flag',
                'ELA Flag',
                'Buying Program Flag',
                'Buying Program Name',
                'Buying Program Group',
                'WPA Flag',
                'Data Source'
              ]
            ],
            data: (metrics || [])
              .map(row => [
                row.TYPE === 'Credit/Debit Memo' ? 'Direct' : row.TYPE || '',
                row.SO_NUMBER || '',
                row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
                row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
                new ExportCellModel({
                  value: row.REVENUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
                }),
                row.PO_NUMBER || '',
                row.DEAL_ID || '',
                row.TERRITORY_CODE || '',
                row.END_CUSTOMER_NAME || '',
                row.PE_NAME || '',
                row.GOALING_PERIOD || '',
                row.LINE_ID || '',
                row.TERRITORY_TYPE_NAME || '',
                row.SAV_ID || '',
                row.SAV_NAME || '',
                row.PRODUCT_SERVICE_FLAG || '',
                row.BUSINESS_ENTITY || '',
                row.SUB_BUSINESS_ENTITY || '',
                row.PID_MAPPING_GROUP || '',
                row.CX_UPSELL_GROUP || '',
                row.METRIC_TYPE_CODE || '',
                row.PART_NUMBER || '',
                row.OFFER_PART_NUMBER || '',
                row.ATTRIBUTION_CODE || '',
                row.METRIC_LENS_TYPE || '',
                new ExportCellModel({
                  value: row.XSELL_IACV_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.XSELL_IACV_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.TOTAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
                }),
                new ExportCellModel({
                  value: row.SPLIT_PERCENT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
                }),
                row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
                row.ATR_REFERENCE_TYPE || '',
                row.METRIC_TYPE || '',
                row.IACV_WALK_REASON_CODE || '',
                row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
                row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
                row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
                row.CONTRACT_TERM || '',
                row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
                row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
                row.NODE_LEVEL_1 || '',
                row.NODE_LEVEL_2 || '',
                row.NODE_LEVEL_3 || '',
                row.NODE_LEVEL_4 || '',
                row.NODE_LEVEL_5 || '',
                row.NODE_LEVEL_6 || '',
                row.SUBSCRIPTION_REF_ID || '',
                row.SHIP_TO_CUSTOMER_NAME || '',
                row.BILL_TO_CUSTOMER_NAME || '',
                row.SALES_MOTION || '',
                row.PRODUCT_FAMILY || '',
                row.BUSINESS_UNIT || '',
                row.OFFER_NAME || '',
                row.OFFER_TYPE_NAME || '',
                row.SAAS_FLAG || '',
                row.ELA_FLAG || '',
                row.BUYING_PROGRAM_FLAG || '',
                row.BUYING_PROGRAM_NAME || '',
                row.BUYING_PROGRAM_GROUP || '',
                row.WPA_FLAG || '',
                row.DATA_SOURCE || ''
              ])
          }));
        }
      } else {
        // g2c or orders as source page
        if (myFlag === 'Y') {
          // MY scenario
          exportSheets.push(new ExportDataModel({
            worksheetName: 'SO Details (IACV)',
            headers: [
              [
                'Order Type',
                'Sales Order Number',
                'Book Date/Line creation Date',
                'Creation Date',
                'Revenue',
                'PO Number',
                'Deal ID',
                'Node/Territory Name',
                'CAV End Customer Name',
                ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
                'ATR Goaling Period Name',
                'Sales Order Line Number',
                'Territory Type',
                'SAV ID',
                'SAV Name',
                'Product Service Identifier',
                'Business Entity',
                'Sub Business Entity',
                'PID Mapping Group',
                'CX Upsell Group',
                'ATR Event Motion',
                'Product ID',
                'Offer Part Number',
                'Attribution Code',
                'Metric Lens Type',
                'Total iACV',
                'Total Contract Value',
                'MY Factor',
                'Split Percent',
                'Currency',
                'ATR Reference Type',
                'Metric Type',
                'Adj Reason Code',
                'Invoice Date',
                'Contract Start Date',
                'Contract End Date',
                'Contract Term',
                'Metric Fiscal Week',
                'Booked Fiscal Week',
                'Sales Level 1',
                'Sales Level 2',
                'Sales Level 3',
                'Sales Level 4',
                'Sales Level 5',
                'Sales Level 6',
                'Subscription Reference ID',
                'Ship to Customer',
                'Bill to Customer',
                'Sales Motion',
                'Product Family',
                'Business Unit',
                'Offer Name',
                'Offer Type Name',
                'SaaS Flag',
                'ELA Flag',
                'Buying Program Flag',
                'Buying Program Name',
                'Buying Program Group',
                'WPA Flag',
                'Data Source'
              ]
            ],
            data: (metrics || [])
              .map(row => [
                row.TYPE || '',
                row.SO_NUMBER || '',
                row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
                row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
                new ExportCellModel({
                  value: row.MY_PAYOUT_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.MY_PAYOUT_AMOUNT))
                }),
                row.PO_NUMBER || '',
                row.DEAL_ID || '',
                row.TERRITORY_CODE || '',
                row.END_CUSTOMER_NAME || '',
                row.PE_NAME || '',
                row.GOALING_PERIOD || '',
                row.LINE_ID || '',
                row.TERRITORY_TYPE_NAME || '',
                row.SAV_ID || '',
                row.SAV_NAME || '',
                row.PRODUCT_SERVICE_FLAG || '',
                row.BUSINESS_ENTITY || '',
                row.SUB_BUSINESS_ENTITY || '',
                row.PID_MAPPING_GROUP || '',
                row.CX_UPSELL_GROUP || '',
                row.METRIC_TYPE_CODE || '',
                row.PART_NUMBER || '',
                row.OFFER_PART_NUMBER || '',
                row.ATTRIBUTION_CODE || '',
                row.METRIC_LENS_TYPE || '',
                new ExportCellModel({
                  value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.TOTAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
                }),
                row.MY_PAYOUT_FACTOR || '',
                new ExportCellModel({
                  value: row.SPLIT_PERCENT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
                }),
                row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
                row.ATR_REFERENCE_TYPE || '',
                row.METRIC_TYPE || '',
                row.IACV_WALK_REASON_CODE || '',
                row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
                row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
                row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
                row.CONTRACT_TERM || '',
                row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
                row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
                row.NODE_LEVEL_1 || '',
                row.NODE_LEVEL_2 || '',
                row.NODE_LEVEL_3 || '',
                row.NODE_LEVEL_4 || '',
                row.NODE_LEVEL_5 || '',
                row.NODE_LEVEL_6 || '',
                row.SUBSCRIPTION_REF_ID || '',
                row.SHIP_TO_CUSTOMER_NAME || '',
                row.BILL_TO_CUSTOMER_NAME || '',
                row.SALES_MOTION || '',
                row.PRODUCT_FAMILY || '',
                row.BUSINESS_UNIT || '',
                row.OFFER_NAME || '',
                row.OFFER_TYPE_NAME || '',
                row.SAAS_FLAG || '',
                row.ELA_FLAG || '',
                row.BUYING_PROGRAM_FLAG || '',
                row.BUYING_PROGRAM_NAME || '',
                row.BUYING_PROGRAM_GROUP || '',
                row.WPA_FLAG || '',
                row.DATA_SOURCE || ''
              ])
          }));
        } else {
          // non MY scenario
          exportSheets.push(new ExportDataModel({
            worksheetName: 'SO Details (IACV)',
            headers: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? [
              [
                'Non Eligibility Reason',
                'Order Type',
                'Sales Order Number',
                'Book Date/Line creation Date',
                'Creation Date',
                'Revenue',
                'PO Number',
                'Deal ID',
                'Node/Territory Name',
                'CAV End Customer Name',
                'ATR Goaling Period Name',
                'Sales Order Line Number',
                'Territory Type',
                'SAV ID',
                'SAV Name',
                'Product Service Identifier',
                'Business Entity',
                'Sub Business Entity',
                'PID Mapping Group',
                'CX Upsell Group',
                'ATR Event Motion',
                'Product ID',
                'Offer Part Number',
                'Attribution Code',
                'Metric Lens Type',
                'Cross Sell  Amount',
                'Upsell  Amount',
                'New Logo iACV  Amount',
                'Total iACV',
                '$Renewed + Residual Annual',
                'Non-Comm Bookings',
                'Total Annual Bookings',
                'Total Contract Value',
                'Split Percent',
                'Currency',
                'ATR Reference Type',
                'Metric Type',
                'Adj Reason Code',
                'Invoice Date',
                'Contract Start Date',
                'Contract End Date',
                'Contract Term',
                'Metric Fiscal Week',
                'Sales Level 1',
                'Sales Level 2',
                'Sales Level 3',
                'Sales Level 4',
                'Sales Level 5',
                'Sales Level 6',
                'Subscription Reference ID',
                'Ship to Customer',
                'Bill to Customer',
                'Sales Motion'
              ]
            ] : 
            [
              [
                'Order Type',
                'Sales Order Number',
                'Book Date/Line creation Date',
                'Creation Date',
                'Bookings',
                'Backlog',
                'Revenue',
                'PO Number',
                'Deal ID',
                'Node/Territory Name',
                'CAV End Customer Name',
                ampIdentifier.includes(AmpIdentifierKey.AMPLIFIER) ? 'Amplifier category':'Plan Element',
                'ATR Goaling Period Name',
                'Sales Order Line Number',
                'Territory Type',
                'SAV ID',
                'SAV Name',
                'Product Service Identifier',
                'Business Entity',
                'Sub Business Entity',
                'PID Mapping Group',
                'CX Upsell Group',
                'ATR Event Motion',
                'Product ID',
                'Offer Part Number',
                'Attribution Code',
                'Metric Lens Type',
                'Cross Sell  Amount',
                'Upsell  Amount',
                'New Logo iACV  Amount',
                'Total iACV',
                '$Renewed + Residual Annual',
                'Non-Comm Bookings',
                'Total Annual Bookings',
                'Total Contract Value',
                'Split Percent',
                'Currency',
                'ATR Reference Type',
                'Metric Type',
                'Adj Reason Code',
                'Invoice Date',
                'Contract Start Date',
                'Contract End Date',
                'Contract Term',
                'Metric Fiscal Week',
                'Booked Fiscal Week',
                'Sales Level 1',
                'Sales Level 2',
                'Sales Level 3',
                'Sales Level 4',
                'Sales Level 5',
                'Sales Level 6',
                'Subscription Reference ID',
                'Ship to Customer',
                'Bill to Customer',
                'Sales Motion',
                'Product Family',
                'Business Unit',
                'Offer Name',
                'Offer Type Name',
                'SaaS Flag',
                'ELA Flag',
                'Buying Program Flag',
                'Buying Program Name',
                'Buying Program Group',
                'WPA Flag',
                'Data Source'
              ]
            ],
            data: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? (metrics || [])
              .map(row => [
                row.NON_ELIGIBILITY_REASON || '',
                row.TYPE || '',
                row.SO_NUMBER || '',
                row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
                row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
                new ExportCellModel({
                  value: row.REVENUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
                }),
                row.PO_NUMBER || '',
                row.DEAL_ID || '',
                row.TERRITORY_CODE || '',
                row.END_CUSTOMER_NAME || '',
                row.GOALING_PERIOD || '',
                row.LINE_ID || '',
                row.TERRITORY_TYPE_CODE || '',
                row.SAV_ID || '',
                row.SAV_NAME || '',
                row.PRODUCT_SERVICE_FLAG || '',
                row.BUSINESS_ENTITY || '',
                row.SUB_BUSINESS_ENTITY || '',
                row.PID_MAPPING_GROUP || '',
                row.CX_UPSELL_GROUP || '',
                row.METRIC_TYPE_CODE || '',
                row.PART_NUMBER || '',
                row.OFFER_PART_NUMBER || '',
                row.ATTRIBUTION_CODE || '',
                row.METRIC_LENS_TYPE || '',
                new ExportCellModel({
                  value: row.XSELL_IACV_AMOUNT || row.XSELL_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.XSELL_IACV_AMOUNT || row.XSELL_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.ANNUAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ANNUAL_VALUE))
                }),
                new ExportCellModel({
                  value: row.TOTAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
                }),
                new ExportCellModel({
                  value: row.SPLIT_PERCENT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
                }),
                row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
                row.ATR_REFERENCE_TYPE || '',
                row.METRIC_TYPE || '',
                row.IACV_WALK_REASON_CODE || '',
                row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
                row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
                row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
                row.CONTRACT_TERM || '',
                row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
                row.NODE_LEVEL_1 || '',
                row.NODE_LEVEL_2 || '',
                row.NODE_LEVEL_3 || '',
                row.NODE_LEVEL_4 || '',
                row.NODE_LEVEL_5 || '',
                row.NODE_LEVEL_6 || '',
                row.SUBSCRIPTION_REF_ID || '',
                row.SHIP_TO_CUSTOMER_NAME || '',
                row.BILL_TO_CUSTOMER_NAME || ''
              ])
              : 
              (metrics || [])
              .map(row => [
                row.TYPE || '',
                row.SO_NUMBER || '',
                row.SOURCE_TRANSACTION_DATE ? this.toDateTime(row.SOURCE_TRANSACTION_DATE) : '',
                row.TRANSACTION_DATE ? this.toDateTime(row.TRANSACTION_DATE) : '',
                new ExportCellModel({
                  value: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? 'NA' : row.BACKLOG + row.REVENUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? null : row.BACKLOG + row.REVENUE))
                }),
                new ExportCellModel({
                  value: ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? 'NA' : row.BACKLOG,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? null : row.BACKLOG))
                }),
                new ExportCellModel({
                  value: row.REVENUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.REVENUE))
                }),
                row.PO_NUMBER || '',
                row.DEAL_ID || '',
                row.TERRITORY_CODE || '',
                row.END_CUSTOMER_NAME || '',
                row.PE_NAME || '',
                row.GOALING_PERIOD || '',
                row.LINE_ID || '',
                row.TERRITORY_TYPE_NAME || '',
                row.SAV_ID || '',
                row.SAV_NAME || '',
                row.PRODUCT_SERVICE_FLAG || '',
                row.BUSINESS_ENTITY || '',
                row.SUB_BUSINESS_ENTITY || '',
                row.PID_MAPPING_GROUP || '',
                row.CX_UPSELL_GROUP || '',
                row.METRIC_TYPE_CODE || '',
                row.PART_NUMBER || '',
                row.OFFER_PART_NUMBER || '',
                row.ATTRIBUTION_CODE || '',
                row.METRIC_LENS_TYPE || '',
                new ExportCellModel({
                  value: row.XSELL_IACV_AMOUNT || row.XSELL_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.XSELL_IACV_AMOUNT || row.XSELL_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.UPSELL_IACV_AMOUNT || row.UPSELL_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.NEW_LOGO_IACV_AMOUNT || row.NEW_LOGO_IACV_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.IACV_BOOKING_AMOUNT || row.IACV_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.RENEWAL_BOOKING_AMOUNT || row.RENEWAL_BOOKING_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.BOOKING_ADJ_AMOUNT || row.BOOKING_ADJ_USD_AMOUNT))
                }),
                new ExportCellModel({
                  value: row.ANNUAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.ANNUAL_VALUE))
                }),
                new ExportCellModel({
                  value: row.TOTAL_VALUE,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.TOTAL_VALUE))
                }),
                new ExportCellModel({
                  value: row.SPLIT_PERCENT,
                  style: ExportCellModel.getNumberStyles(this.getPrecision(row.SPLIT_PERCENT))
                }),
                row.GS_CURRENCY_CODE || row.CURRENCY_CODE || '',
                row.ATR_REFERENCE_TYPE || '',
                row.METRIC_TYPE || '',
                row.IACV_WALK_REASON_CODE || '',
                row.INVOICE_DATE ? this.toDateTime(row.INVOICE_DATE) : '',
                row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
                row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
                row.CONTRACT_TERM || '',
                row.FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.FISCAL_WEEK) : '',
                row.BOOKED_FISCAL_WEEK ? this.fiscalWeekFormat.transform(row.BOOKED_FISCAL_WEEK) : '',
                row.NODE_LEVEL_1 || '',
                row.NODE_LEVEL_2 || '',
                row.NODE_LEVEL_3 || '',
                row.NODE_LEVEL_4 || '',
                row.NODE_LEVEL_5 || '',
                row.NODE_LEVEL_6 || '',
                row.SUBSCRIPTION_REF_ID || '',
                row.SHIP_TO_CUSTOMER_NAME || '',
                row.BILL_TO_CUSTOMER_NAME || '',
                row.SALES_MOTION || '',
                row.PRODUCT_FAMILY || '',
                row.BUSINESS_UNIT || '',
                row.OFFER_NAME || '',
                row.OFFER_TYPE_NAME || '',
                row.SAAS_FLAG || '',
                row.ELA_FLAG || '',
                row.BUYING_PROGRAM_FLAG || '',
                row.BUYING_PROGRAM_NAME || '',
                row.BUYING_PROGRAM_GROUP || '',
                row.WPA_FLAG || '',
                row.DATA_SOURCE || ''
              ])
          }));
        }
      }

    }
    //Adj Code Definition sheet
    exportSheets.push(new ExportDataModel({
      worksheetName: 'Adj Code Definition',
      headers: [
        [
          'Adj Reason Code',
          'Adj Code Definition'

        ]
      ],
      data: (exportDefinitions || [])
        .filter(row => row.TAB_NAME === ExportDefinitionType.UPSELL_ADJUSTMENT_DEF)
        .map(row => [
          row.REASON_CD || '',
          row.DESCRIPTION || ''
        ])
    }));

    // Attibute definition sheet
    exportSheets.push(new ExportDataModel({
      worksheetName: 'Attribute Definition',
      headers: [
        [
          'Attribute Code',
          'Attribute Definition'

        ]
      ],
      data: (exportDefinitions || [])
        .filter(row => row.TAB_NAME === ExportDefinitionType.TRX_LEVEL_METRIC)
        .map(row => [
          row.REASON_CD || '',
          row.DESCRIPTION || ''
        ])
    }));

    this.exportService.exportExcel(exportSheets, fileName); //xlsx format
  }

  // Calls Mule > TD @ EDF side for getting ATR
  public fetchATRDetails(request: AtrDetailsRequest): Observable<AtrExportDetailsModel[]> {
    const OPTIONS = this.getOptionsForIACV();
    const URL = `${this.baseUri}${this.atrExportDetailsUri}`;
    let urlRequest:any;
    urlRequest ={
      "loginId": this.userDetailsStore.getUserId(),
      "userId": this.userDetailsStore.getImpersonationUserId(),
      "sourceSystem": request.sourceSystem,
      "iacvBkgTrxKey": request.iacvBkgTrxKey,
      "metricLensTypeName": request.metricLensTypeName,
      "serviceFlag": request.serviceFlag
    }

    const REQUEST$ = this.http.post<MetricsExportDetails[]>(URL, urlRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          //this.goalToCashStore.setMetricsExportDetails(response);
          return response;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchATRExportDetails', error)(error);
        })
      );
    return REQUEST$;
    //}
  }

  public exportATRDetails(atrExportDetailsModel: AtrExportDetailsModel[], transaction, transactionType) {
    let exportSheets: ExportDataModel[] = [];

    exportSheets.push(new ExportDataModel({
      worksheetName: 'ATR Details',
      // Added to stamp SO, Line and Book Date at the top of the ATR export sheet
      orderLineHeaders: transaction.GOALING_PERIOD === 'FY2024' ? [
        [
          'Current Order Details',
          '(All amounts are displayed in ' + (transaction.GS_CURRENCY_CODE || 'your Goaling Currency') + ')'
        ],
        [
          'SO Number',
          'Line Number',
          'Book Date',
          '$Renewed + Residual Annual',
          '$Renewed',
          'Residual Annual'
        ]
      ] : [
        [
          'Current Order Details',
          '(All amounts are displayed in ' + (transaction.GS_CURRENCY_CODE || 'your Goaling Currency') + ')'
        ],
        [
          'SO Number',
          'Line Number',
          'Book Date',
          '$Renewed'
        ]
      ],
      orderLineData: transaction.GOALING_PERIOD === 'FY2024' ? [
        [
          transaction.SO_NUMBER || '',
          transaction.LINE_ID || '',
          transaction.SOURCE_TRANSACTION_DATE ? moment(transaction.SOURCE_TRANSACTION_DATE).format('DD-MMM-yyyy') : '',
          new ExportCellModel({
            value: transactionType === 'BKLG' ? transaction.BACKLOG : transaction.REVENUE,
            style: ExportCellModel.getNumberStyles(2)
          }),
          new ExportCellModel({
            value: transaction.ORG_RENEWED_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          }),
          new ExportCellModel({
            value: transaction.RESIDUAL_ANNUAL_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          })
        ],
        [
          '',
          '',
          '',
          '',
          '',
          ''
        ]
      ] : [
        [
          transaction.SO_NUMBER || '',
          transaction.LINE_ID || '',
          transaction.SOURCE_TRANSACTION_DATE ? moment(transaction.SOURCE_TRANSACTION_DATE).format('DD-MMM-yyyy') : '',
          new ExportCellModel({
            value: transactionType === 'BKLG' ? transaction.BACKLOG : transaction.REVENUE,
            style: ExportCellModel.getNumberStyles(2)
          })
        ],
        [
          '',
          '',
          '',
          ''
        ]
      ],
      headers: [
        [
          'ATR Order details',
          '(All amounts are displayed in USD)'
        ],
        [
          'IACV Process Date',
          'ATR Transaction ID',
          'ATR USD AMT',
          'ATR Reference Type',
          'Contract number',
          'Original ATR',
          'Sliding Window Start Date',
          'Sliding Window End Date',
          'Sales Order Number',
          'Sales Order Line ID',
          'Web Order ID',
          'Subscription Reference ID',
          'SAV ID',
          'SAV Name',
          'Bundle Product Id',
          'CX Upsell Group',
          'PID Matching Logic',
          'Deal ID',
          'Contract Start Date',
          'Contract End Date',
          'Contract Cancellation Date',
          'Cancelled/Lost ATR Flag',
          'Early Renewal ATR Flag',
          'Early Bookings Flag',
          'Carry Forward ATR Flag',
          'LDOS ATR Flag',
          'ATR Active Flag',
          'ATR Effective Start Date',
          'ATR Effective End Date',
          'Future ATR Flag',
          'Lapsed Period End Date',
          'Goaling Period',
          'Goaling Lens CD',
          'CAV End Customer BU ID',
          'CAV End Customer BU Name',
          'Business Entity',
          'Business Entity Description',
          'Sub Business Entity',
          'Sub Business Entity Description',
          'Product ID',
          'Service Offer Type',
          'Transaction Type CD',
          'Contract Type CD',
          'ATR Adj Reason Code Description',
          'ATR Adjustment Requester',
          'ATR Adj Reason Code',
          'Attribution CD',
          'SWSS Flag',
          'Sweeps Flag',
          'Sales Rep Number',
          'Sales Rep Name',
          'Sales Level 1',
          'Sales Level 2',
          'Sales Level 3',
          'Sales Level 4',
          'Sales Level 5',
          'Sales Level 6',
          'Sales Territory Name',
          'Sales Market Segment',
          'Sub Sales Market Segment',
          'Metric Lens Type Name',
          'IACV Group Key',
          'Service Flag',
          'Contract Duration Days'
        ]
      ],
      data: (atrExportDetailsModel || [])
        .map(row => [
          row.IACV_PROCESS_DATE ? this.toDateTime(row.IACV_PROCESS_DATE) : '',
          row.ATR_TRX_ID || '',
          new ExportCellModel({
            value: row.ANNUALIZED_ATR_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          }),
          row.ATR_REFERENCE_TYPE || '',
          row.CONTRACT_NUMBER || '',
          new ExportCellModel({
            value: row.ORIG_ANNUALIZD_ATR_USD_AMT,
            style: ExportCellModel.getNumberStyles(2)
          }),
          row.ATR_SLIDING_WINDOW_START_DATE ? this.toDateTime(row.ATR_SLIDING_WINDOW_START_DATE) : '',
          row.ATR_SLIDING_WINDOW_END_DATE ? this.toDateTime(row.ATR_SLIDING_WINDOW_END_DATE) : '',
          row.BK_SO_NUMBER_INT || '',
          row.SALES_ORDER_LINE_ID || '',
          row.BK_WEB_ORDER_ID || '',
          row.SUBSCRIPTION_REFERENCE_ID || '',
          row.SAVM_GROUP_ID || '',
          row.SAVM_GROUP_NAME || '',
          row.TOP_SKU_PRODUCT_NAME || '',
          row.CX_UPSELL_GROUP_NAME || '',
          row.PID_MAPPING_LOGIC_CODE || '',
          row.BK_DEAL_ID || '',
          row.CONTRACT_START_DATE ? this.toDateTime(row.CONTRACT_START_DATE) : '',
          row.CONTRACT_END_DATE ? this.toDateTime(row.CONTRACT_END_DATE) : '',
          row.CANCELLATION_DATE ? this.toDateTime(row.CANCELLATION_DATE) : '',
          row.CANCELLED_ATR_FLAG || '',
          row.EARLY_ATR_FLAG || '',
          row.EARLY_BOOKINGS_FLAG || '',
          row.DV_CARRY_FWD_ATR_FLG || '',
          row.LDOS_ATR_FLG || '',
          row.ATR_ACTIVE_FLG || '',
          row.ATR_EFFECTIVE_START_DATE ? this.toDateTime(row.ATR_EFFECTIVE_START_DATE) : '',
          row.ATR_EFFECTIVE_END_DATE ? this.toDateTime(row.ATR_EFFECTIVE_END_DATE) : '',
          row.FUTURE_ATR_FLG || '',
          row.LAPSED_PERIOD_END_DATE ? this.toDateTime(row.LAPSED_PERIOD_END_DATE) : '',
          row.GOALING_PERIOD_NAME || '',
          row.GOALING_LENS_CD || '',
          row.CAV_BUSINESS_UNIT_ID || '',
          row.CAV_BUSINESS_UNIT_NAME || '',
          row.BK_BUSINESS_ENTITY_NAME || '',
          row.BUSINESS_ENTITY_DESCR || '',
          row.BK_SUB_BUSINESS_ENTITY_NAME || '',
          row.BUSINESS_SUB_ENTITY_DESCR || '',
          row.PRODUCT_NAME || '',
          row.SERVICE_OFFER_TYPE_CODE || '',
          row.TRANSACTION_TYPE_CD || '',
          row.CONTRACT_TYPE_CODE || '',
          row.ATR_ADJ_REASON_DESCR || '',
          row.ATR_ADJ_REQ_CISCO_WRKR_PRTY_KY || '',
          row.ATR_ADJ_REASON_CD || '',
          row.ATTRIBUTION_CD || '',
          row.SWSS_FLG || '',
          row.SWEEPS_FLG || '',
          row.SALES_REP_NUM || '',
          row.SALES_REP_NAME || '',
          row.L1_SALES_TERRITORY_DESCR || '',
          row.L2_SALES_TERRITORY_DESCR || '',
          row.L3_SALES_TERRITORY_DESCR || '',
          row.L4_SALES_TERRITORY_DESCR || '',
          row.L5_SALES_TERRITORY_DESCR || '',
          row.L6_SALES_TERRITORY_DESCR || '',
          row.SALES_TERRITORY_NAME_CODE || '',
          row.SALES_COVERAGE_CODE || '',
          row.SALES_SUBCOVERAGE_CODE || '',
          row.METRIC_LENS_TYPE_NAME || '',
          row.IACV_ASCN_BKG_TRX_KEY || '',
          row.SERVICE_FLG || '',
          row.DV_CONTRACT_DURATION_DAYS_CNT || '',
        ])
    }));


      this.exportService.exportExcel(exportSheets, 'ATR Details');
  }

  //to Convert timestamp to specific format and apply PST Timezone
  private toDateTime(secs) {

    var date = moment(secs).format('DD-MMM-yyyy');

    return date;
  }


  //to check type of Record
  public getSalesValue(record: MetricsExportDetails, myPYFlag: string): number {
    if (!record)
      return null;

    if (myPYFlag === 'Y') {
      return this.roundUpDecimal.transform(record.MY_ELIGIBLE_AMOUNT || 0.00)

    }
    else if (record.TRANS_GROUP_CODE.toLowerCase().includes("iacv")) {
      return record.IACV_USD_AMOUNT;

    }
    else {
      return record.RENEWED_USD_AMOUNT
    }
  }

  //to return human readable version of source type
  public metricSourceType(type: string): string{

    if(type.toUpperCase()==='IACV_MAN' || type.toUpperCase()==='$RN_MAN')
      return MetricType.Manual
    else
      return MetricType.Systematic
  }

  //Concat from filter B to filter A
  public combineFilters(filterA :IacvWeeklyDetailsFilterModel | BacklogTransactionsFilterViewModel, filterB :IacvWeeklyDetailsFilterModel | BacklogTransactionsFilterViewModel, ): IacvWeeklyDetailsFilterModel | BacklogTransactionsFilterViewModel{
    if(filterA && filterB){
      // filterA.savId.concat(filterB.savId);
      if(filterA.savId && filterB.savId)
        filterA.savId=[ ...new Set([...filterA.savId, ...filterB.savId])];
      else if(filterB.savId){
        filterA.savId=[];
        filterA.savId=[ ...new Set([...filterA.savId, ...filterB.savId])];
      }

      // filterA.beNames.concat(filterB.beNames);
      // filterA.beNames=[ ...new Set([...filterA.beNames, ...filterB.beNames])];
      if(filterA.beNames && filterB.beNames)
        filterA.beNames=[ ...new Set([...filterA.beNames, ...filterB.beNames])];
      else if(filterB.beNames){
        filterA.beNames=[];
        filterA.beNames=[ ...new Set([...filterA.beNames, ...filterB.beNames])];
      }
      // filterA.subBeNames.concat(filterB.subBeNames);
      // filterA.subBeNames=[ ...new Set([...filterA.subBeNames, ...filterB.subBeNames])];
      if(filterA.subBeNames && filterB.subBeNames)
        filterA.subBeNames=[ ...new Set([...filterA.subBeNames, ...filterB.subBeNames])];
      else if(filterB.subBeNames){
        filterA.subBeNames=[];
        filterA.subBeNames=[ ...new Set([...filterA.subBeNames, ...filterB.subBeNames])];
      }
      // filterA.pidNames.concat(filterB.pidNames);
      // filterA.pidNames=[ ...new Set([...filterA.pidNames, ...filterB.pidNames])];
      if(filterA.pidNames && filterB.pidNames)
        filterA.pidNames=[ ...new Set([...filterA.pidNames, ...filterB.pidNames])];
      else if(filterB.pidNames){
        filterA.pidNames=[];
        filterA.pidNames=[ ...new Set([...filterA.pidNames, ...filterB.pidNames])];
      }
      // filterA.cxUpsellGroups.concat(filterB.cxUpsellGroups);
      // filterA.cxUpsellGroups=[ ...new Set([...filterA.cxUpsellGroups, ...filterB.cxUpsellGroups])];
      if(filterA.cxUpsellGroups && filterB.cxUpsellGroups)
        filterA.cxUpsellGroups=[ ...new Set([...filterA.cxUpsellGroups, ...filterB.cxUpsellGroups])];
      else if(filterB.cxUpsellGroups){
        filterA.cxUpsellGroups=[];
        filterA.cxUpsellGroups=[ ...new Set([...filterA.cxUpsellGroups, ...filterB.cxUpsellGroups])];
      }
      // filterA.territory.concat(filterB.territory);
      // filterA.territory=[ ...new Set([...filterA.territory, ...filterB.territory])];
      if(filterA.territory && filterB.territory)
        filterA.territory=[ ...new Set([...filterA.territory, ...filterB.territory])];
      else if(filterB.territory){
        filterA.territory=[];
        filterA.territory=[ ...new Set([...filterA.territory, ...filterB.territory])];
      }
      // filterA.fiscalWeek.concat(filterB.fiscalWeek);
      // filterA.fiscalWeek=[ ...new Set([...filterA.fiscalWeek, ...filterB.fiscalWeek])];
      if(filterA.fiscalWeek && filterB.fiscalWeek)
        filterA.fiscalWeek=[ ...new Set([...filterA.fiscalWeek, ...filterB.fiscalWeek])];
      else if(filterB.fiscalWeek){
        filterA.fiscalWeek=[];
        filterA.fiscalWeek=[ ...new Set([...filterA.fiscalWeek, ...filterB.fiscalWeek])];
      }
    }
    else if(filterA)
      return filterA;
    else if(filterB)
      return filterB;
    else
      return null;
  }

  private getBookingsAndMetricsFileName(transactionType: 'ALL' | 'REV' | 'BKLG' | string): string {
    switch (transactionType?.toLowerCase()) {
      case 'all':
        return 'Bookings Export';
      case 'rev':
        return 'Revenue Export';
      case 'bklg':
        return 'Backlog Export';
      case 'metric':
        return 'Metric ID Export';
      //Weekly screen custom logic for filename
      case 'weekly':
        return 'Weekly_aggregatedMetrics_' + moment().format('DD-MMM-yyyy').toString();
      default:
        return 'Export';
    }
  }

  private getPrecision(value: number) {
    let str = value?.toString() || '';

    // Check for scientific notation
    let eIndex = str.indexOf('e');
    if (eIndex > -1) {
      return Math.abs(parseInt(str.slice(eIndex + 2)));
    } else {
      return str.split('.')[1] !== undefined ? str.split('.')[1].length : 2;
    }
  }

  public getGoalYear(goalSheet: string): string {
    const parts = goalSheet.split(' ');
    const year = parts[0];
    const code = parts[1];

    const goalYear = `${year} ${code}`;
    return goalYear;
  }

  public fetchGoalSheetStartDate(goalSheet: string){
    // Use regex to extract the first date from goalSheet
  const dateRegex = /\d{2}-\w{3}-\d{4}/;
  const match = goalSheet.match(dateRegex);
  const firstDate = match ? match[0] : '';

  return firstDate;
  }
}
