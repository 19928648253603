<section class="grid" *ngFor="let transaction of metricSOTransactions">
  <div class="grid__column brd-none">
    <div class="column__row" *ngIf="transaction?.NON_ELIGIBILITY_REASON">
      <span class="label">Non Eligibility Reason</span>
      <span class="value">{{ transaction?.NON_ELIGIBILITY_REASON }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Type</span>
      <span class="value">{{transactionType === 'BKLG' ? 'Backlog' : 'Revenue'}}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Book Date</span>
      <span class="value">{{ transaction?.SOURCE_TRANSACTION_DATE | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Invoice Date</span>
      <span class="value">{{ (transaction?.INVOICE_DATE) | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Creation Date</span>
      <span class="value">{{ transaction?.TRANSACTION_DATE | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Contract Start Date</span>
      <span class="value">{{ transaction?.CONTRACT_START_DATE | dateFormat }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Contract End Date</span>
      <span class="value">{{ transaction?.CONTRACT_END_DATE | dateFormat }}</span>
    </div><!-- /column__row -->
  </div>

  <div class="grid__column">
    <div class="column__row" *ngIf="peType === 'IACV'">
      <span class="label">{{transactionType === 'BKLG' && myFlag === 'N' ? 'Backlog Amount(Original)' : 'Invoice Line
        Amount(Original)'}}</span>
      <span *ngIf="transactionType === 'BKLG' && transaction?.BACKLOG !== 0  && myFlag === 'N'">
        <a (click)="toggleInvoiceSplit(transaction)" class="value --with-padding">{{ transaction?.BACKLOG | number:'1.2-2'}}
          <i [ngClass]="(toggleInvoice ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
      </span>
      <span *ngIf="transactionType === 'REV' && transaction?.REVENUE !== 0 && myFlag === 'N'">
        <a (click)="toggleInvoiceSplit(transaction)" class="value --with-padding">{{ transaction?.REVENUE | number:'1.2-2'}}
          <i [ngClass]="(toggleInvoice ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
      </span>
      <span class="value --with-padding" *ngIf="transactionType === 'BKLG' && transaction?.BACKLOG === 0 && myFlag === 'N'">
        {{ transaction?.BACKLOG | number:'1.2-2'}}
      </span>
      <span class="value --with-padding" *ngIf="transactionType === 'REV' && transaction?.REVENUE === 0 && myFlag === 'N'">
        {{ transaction?.REVENUE | number:'1.2-2'}}
      </span>
      <span class="value --with-padding" *ngIf="transactionType === 'REV' && myFlag === 'Y'">
        {{ transaction?.MY_PAYOUT_AMOUNT | number:'1.2-2'}}
      </span>
      <span></span>
      <span *ngIf="transaction?.isExpanded" class="sub-options-width padLeft">
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            New Logo
          </span>
          <span class="sub-options value-align">
            : {{ transaction.NEW_LOGO_IACV_AMOUNT | number:'1.2-2' }}
          </span>
        </span>
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            Cross-sell
          </span>
          <span class="sub-options value-align">
            : {{ transaction.XSELL_IACV_AMOUNT | number:'1.2-2' }}
          </span>
        </span>
        <span class="sub-options-width lineHt">
          <span class="sub-options label-width">
            Upsell
          </span>
          <span class="sub-options value-align">
            : {{ transaction.UPSELL_IACV_AMOUNT | number:'1.2-2' }}
          </span>
        </span>
      </span>
    
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="peType === 'RND'">
      <span class="label">{{transactionType === 'BKLG' && myFlag === 'N'  ? 'Backlog Amount(Original)' : 'Invoice Line
        Amount(Original)'}}</span>
      <span class="value --with-padding" *ngIf="transactionType === 'BKLG' && myFlag === 'N' ">
        {{ transaction?.BACKLOG | number:'1.2-2'}}
      </span>
      <span class="value --with-padding" *ngIf="transactionType === 'REV' && myFlag === 'N' ">
        {{ transaction?.REVENUE | number:'1.2-2'}}
      </span>
      <span class="value --with-padding" *ngIf="transactionType === 'REV' && myFlag === 'Y'">
        {{ transaction?.MY_PAYOUT_AMOUNT | number:'1.2-2'}}
      </span>
    </div>

    <div class="column__row">
      <span class="label">Split %</span>
      <span class="value --with-padding">{{ transaction?.SPLIT_PERCENT }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Sales Motion</span>
      <span class="value --with-padding">{{ transaction?.SALES_MOTION }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Annual</span>
      <span class="value --with-padding">{{ transaction?.ANNUAL_VALUE | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Total Contract Value</span>
      <span class="value --with-padding">{{ transaction?.TOTAL_VALUE | number:'1.2-2' }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Contract Term</span>
      <span class="value --with-padding">{{ transaction?.CONTRACT_TERM }}</span>
    </div><!-- /column__row -->

  </div>

  <div class="grid__column">
    <div class="column__row">
      <span class="label">Sav Name(ID)</span>
      <span class="value">{{ transaction?.SAV_NAME }} <span *ngIf="transaction?.SAV_ID !== null ">({{ transaction?.SAV_ID }})</span></span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Node</span>
      <span class="value value-wrap ">{{ transaction?.NODE_NAME_DESC }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Product ID</span>
      <span class="value">{{ transaction?.PART_NUMBER }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">Metric Type</span>
      <span class="value">{{ transaction?.METRIC_TYPE }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">ATR Reference</span>
      <span class="value">{{ transaction?.ATR_REFERENCE_TYPE }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="myFlag === 'Y'">
      <span class="label">MY Factor</span>
      <span class="value">{{ transaction?.MY_PAYOUT_FACTOR }}</span>
    </div><!-- /column__row -->

  </div>

  <div class="grid__column">
    <div class="column__row">
      <span class="label">BE</span>
      <span class="value">{{ transaction?.BUSINESS_ENTITY }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">SUB BE</span>
      <span class="value">{{ transaction?.SUB_BUSINESS_ENTITY }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">PID Mapping</span>
      <span class="value">{{ transaction?.PID_MAPPING_GROUP }}</span>
    </div><!-- /column__row -->
    <div class="column__row">
      <span class="label">CX Upsell Group</span>
      <span class="value">{{ transaction?.CX_UPSELL_GROUP }}</span>
    </div><!-- /column__row -->
    <div class="column__row" *ngIf="transaction?.METRIC_TYPE === '$RN' && myFlag === 'N' && !transaction?.NON_ELIGIBILITY_REASON">
      <span class="label">ATR Export</span>
      <span> <a class="link --export" [title]="'Download'"
          (click)="exportAtrExcel(transaction)">Download</a></span>
    </div><!-- /column__row -->
  </div>
</section>

<ng-template #invoiceLineAmountMyPayoutEligibleTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Revenue Eligible for Multi Year Payout Uplift
  </div>
</ng-template>

<ng-template #annualValueTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Annual Contract Value - Bookings value from first 12 months of a Multi-Year contract. If the contract term is less
    than 12 months, ACV would be Bookings value for that term (equivalent to TCV)
  </div>
</ng-template>

<ng-template #totalValueTooltip>
  <div class="transaction-line-details-grid-tooltip">
    Total Contract Value - Bookings value across the lifetime of the contract
  </div>
</ng-template>