/* Angular Imports */
import { NgModule }                       from '@angular/core';
import { CommonModule }                   from '@angular/common';
import { RouterModule }                   from '@angular/router';
/* Feature Imports */
import { SharedModule }                   from '../shared/shared.module';
import { LayoutModule }                   from '../layout/layout.module';

/* Feature Imports */
import {
  InterfaceRoutingModule,
  ROUTED_COMPONENTS
}                                         from './interface-routing.module';

/**
 * All entry level dependencies must be declared at this level
 */

@NgModule({

  imports: [
    CommonModule,
    SharedModule,
    InterfaceRoutingModule,
    LayoutModule
  ],

  declarations: [
    ROUTED_COMPONENTS
  ],

  providers: [],

  entryComponents: [
    /* Entry Components */
  ],

  exports: [
    RouterModule
  ]

})

export class InterfaceModule { }
