<credit-memo-transaction-lines-header (triggerTypeChange)="filterType$.next($event)"></credit-memo-transaction-lines-header>

<section class="table-controls">
  <oux-pagination *ngIf="(filterType$ | async) == 'revCredMemo'"
    [ngClass]="(paginationOptions.totalRecords > paginationOptions.pageSize) ? 'fadeIn animated' : 'fadeIn animated'"
    [options]="paginationOptions" 
    (onPageChange)="pageChange.next($event)">
    <ng-template [ouxPaginationRange] let-range>
      Showing {{ range.lower | number:'1.0-0' }} - {{ range.upper | number:'1.0-0' }} of {{ range.total | number:'1.0-0' }} {{ range.total == 1 ? 'line' : 'lines' }}
    </ng-template>
  </oux-pagination>

  <oux-pagination *ngIf="(filterType$ | async) == 'bookCredMemo'"
    [ngClass]="(paginationOptions.totalRecords > paginationOptions.pageSize) ? 'fadeIn animated' : 'fadeIn animated'"
    [options]="paginationOptions" 
    (onPageChange)="pageChange.next($event)">
    <ng-template [ouxPaginationRange] let-range>
      Showing {{ range.lower | number:'1.0-0' }} - {{ range.upper | number:'1.0-0' }} of {{ range.total | number:'1.0-0' }} {{ range.total == 1 ? 'line' : 'lines' }}
    </ng-template>
  </oux-pagination>

  <ng-container *ngIf="(paginationOptions.totalRecords > 0)">
    <credit-memo-transaction-lines-summary-table-export [request]="exportRequest"></credit-memo-transaction-lines-summary-table-export>
  </ng-container>
</section>

<credit-memo-transaction-lines-summary-table [filterType]="filterType$ | async"></credit-memo-transaction-lines-summary-table>