<div class="settings" ouxThemeable>
  <ul class="settings__list">
    <li class="item">
      <span class="item__header">Settings</span>
    </li>

    <!-- not part of day 1 scope - will be part of future scope - do not remove - 9/28/21 -->
    <!-- <li class="item">
      <a class="item__label" (click)="openModal('preferences', $event.preventDefault())">Preferences</a>
    </li> -->

    <li class="item">
      <a class="item__label" (click)="openModal('assignProxy', $event.preventDefault())">Assign Proxy</a>
    </li>
    <li class="item" *ngIf="isAdmin$ | async">
      <a class="item__label" (click)="goTo('bannersUrl', $event.preventDefault())">Manage Banners</a>
    </li>
    <li class="item" *ngIf="isAdmin$ | async">
      <a class="item__label" (click)="goTo('userBannersUrl', $event.preventDefault())">Manage User Banners</a>
    </li>
    <li class="item">
      <a class="item__label" (click)="goTo('feedbackUrl', $event.preventDefault())">Feedback</a>
    </li>
    <li class="item" (click)="openModal('logout', $event.preventDefault())">
      <span class="item__label">Logout</span>
      <i class="item__icon icon-power"></i>
    </li>
  </ul>
</div>