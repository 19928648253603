import {
  Component,
  Input,
  OnDestroy, }                                            from '@angular/core';
import { DatePipe }                                       from '@angular/common';
import { delay, tap }                                     from 'rxjs/operators';
import { Subscription }                                   from 'rxjs';
import {
  OuxLoggerService,
  OuxThemeType,
  OuxToastOptions,
  OuxToastService }                                       from '@cisco/oux-common';
import { ExportService }                                  from 'src/app/shared/services/export/export.service';
import { ExportDataModel }                                from 'src/app/shared/services/export/export-data.model';
import { ExportCellModel }                                from 'src/app/shared/services/export/export-cell.model';
import { ExportValueType }                                from 'src/app/shared/services/export/export-value.type';
import { OrdersLinesRequest }                             from 'src/app/shared/models/interface/request/orders-lines-request';
import { XaasTransactionLinesSummaryTableExportService }  from './xaas-transaction-lines-summary-table-export.service';
import { XaasOrderLinesSummary }                          from 'src/app/shared/models/interface/partials/xaas-order-lines-summary';
import { XaasOrderLinesSummaryModel }                     from 'src/app/shared/models/concrete/partials/xaas-order-lines-summary.model';


@Component({
  selector: 'xaas-transaction-lines-summary-table-export',
  templateUrl: './xaas-transaction-lines-summary-table-export.component.html',
  styleUrls: ['./xaas-transaction-lines-summary-table-export.component.scss'],
  host:{
    'class': 'xaas-transaction-lines-summary-table-export'
  }
})
export class XaasTransactionLinesSummaryTableExportComponent implements OnDestroy {

  private subscriptions:Subscription []= [];

  private excelFileName: string = 'Order Line Transactions';

  private _exportRequest: OrdersLinesRequest;

  @Input('request')
  public set exportRequest(value: OrdersLinesRequest) {
    this._exportRequest = value;
  }
  public get exportRequest() : OrdersLinesRequest {
    return this._exportRequest;
  }

  @Input('isNotEligibleOrder')
  public isNotEligibleOrder: boolean;

  @Input('notEligibleExportRequest')
  public notEligibleExportRequest: any;

  public loading: boolean = false;

  constructor(private ouxToastSvc: OuxToastService,
              private ouxLoggerSvc: OuxLoggerService,
              private datePipe: DatePipe,
              private exportSvc: ExportService,
              private xaasExportSvc: XaasTransactionLinesSummaryTableExportService) {

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public exportTableData(event: any) {

    this.loading = true;
    this.showExportNotification('Pending');

    if (this.isNotEligibleOrder) {
      this.notEligibleExportRequest.limit = 1000000;
      
      this.subscriptions.push(
        this.xaasExportSvc.fetchNotEligibleData(this.notEligibleExportRequest)
        .pipe(
          tap((collection: XaasOrderLinesSummary[]) => {
            this.initializeExport(collection);
          }),
          delay(5000),
          tap(() => {
            this.showExportNotification('success');
          })
        )
        .subscribe((collection: XaasOrderLinesSummary[]) => {},
        // has error
        (error: Error) => {
          this.ouxLoggerSvc.logError(`XaasTransactionLinesSummaryTableExportComponent: Failed to load Export Data: System Message -> ${error}`);
        },
        // is complete
        () => {
          this.loading = false;
        })
      );
    } else {
      this.subscriptions.push(
        this.xaasExportSvc.fetchData(this.exportRequest)
        .pipe(
          tap((collection: XaasOrderLinesSummary[]) => {
            this.initializeExport(collection);
          }),
          delay(5000),
          tap(() => {
            this.showExportNotification('success');
          })
        )
        .subscribe((collection: XaasOrderLinesSummary[]) => {},
        // has error
        (error: Error) => {
          this.ouxLoggerSvc.logError(`XaasTransactionLinesSummaryTableExportComponent: Failed to load Export Data: System Message -> ${error}`);
        },
        // is complete
        () => {
          this.loading = false;
        })
      );
    }

  }

  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private initializeExport(data: XaasOrderLinesSummary[]): void {
    let myFlag: boolean = false;
    let exportData = new ExportDataModel({
      headers: [],
      data: []
    });
    if(data){
      myFlag = data[0]?.MYPEFLAG == "Y" ? true : false;
    }


    exportData.headers = [this.columnHeaders(myFlag)];
    exportData.data = this.columnData(data, myFlag);

    this.exportSvc.exportExcel(exportData, this.excelFileName);
  }


  private columnHeaders(myFlag: boolean): string[] {
    if(myFlag){
      return [
      'SO Number',
      'Part Number',
      'Book Date',
      'TCV',
      'Total Annual Bookings',
      'Bookings',
      'Backlog',
      'Revenue (Original)',
      'Revenue (Multiplied)',
      'Revenue Multiplier',
      'Revenue (MY Payout) Eligible',
      'MY Uplift Factor',
      'AT Level 1',
      'AT Level 2',
      'AT Level 3',
      'AT Level 4'
    ];
    }
    else
    return this.isNotEligibleOrder ? [
      'Non Eligibility Reason',
      'SO Number',
      'SO/POS Line Number',
      'Book Date',
      'Offer Part Number',
      'Part Number',
      'Metric Type',
      'Contract Accepted Date',
      'Contract Start Date',
      'Contract End Date',
      'Contract Term',
      'Currency Code',
      'Revenue',
      'Annual Value',
      'Total Value',
      'Territory Name',
      'Territory Type',
      'Sales Level 2',
      'Sales Level 3',
      'Sales Level 4',
      'Sales Level 5',
      'Sales Level 6',
      'AT Level 1',
      'AT Level 2',
      'AT Level 3',
      'AT Level 4',
      'Deal ID',
      'PO Number',
      'Bill To Customer',
      'Ship To Customer',
      'End Customer',
      'Subscription Reference ID',
      'Subscription Code',
      'Order Type',
      'Sell Type',
      'Action',
      'MRR',
      'IMRR',
      'Remaining Term',
      'Renewal Term',
      'Subscription Credit',
      'Term Bound Credi',
    ] : [
      'SO Number',
      'Part Number',
      'Book Date',
      'TCV',
      'Total Annual Bookings',
      'Bookings',
      'Backlog',
      'Revenue (Original)',
      'Revenue (Multiplied)',
      'Revenue Multiplier',
      'AT Level 1',
      'AT Level 2',
      'AT Level 3',
      'AT Level 4'
    ];
  }

  private columnData(data: XaasOrderLinesSummary[], myFlag: boolean): ExportValueType[][] {

    let rows: XaasOrderLinesSummary[] = [];

    data.forEach((row: XaasOrderLinesSummary) => rows.push(new XaasOrderLinesSummaryModel(row)));

    if(myFlag){
      return rows.map((row: XaasOrderLinesSummary) => [
        // SO Number
        row.ORDER_NUMBER || 'N/A',
        // Part Number
        row.PART_NUMBER || 'N/A',
        // Book Date
        this.datePipe.transform(row.LINE_CREATION_DATE || row.SOURCE_TRANSACTION_DATE, 'dd-LLL-YYYY') || 'Not Available',
        // Total Contract Value
        new ExportCellModel({
          value: row.TOTAL_VALUE_1,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Annual Value
        new ExportCellModel({
          value: row.ANNUAL_VALUE_1,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Bookings
        new ExportCellModel({
          value: this.isNotEligibleOrder ? 'NA' : row.SPLIT_SALES_VALUE + row.BACKLOG_LINE,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Backlog
        new ExportCellModel({
          value: 'NA',
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Revenue Original
        new ExportCellModel({
          value: row.SPLIT_SALES_VALUE,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Revenue Multiplied
        new ExportCellModel({
          value: row.SPLIT_SALES_VALUE,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // Revenue Factor
        row.REV_MULTIPLIER_FACTOR === 0 ? '' : row.REV_MULTIPLIER_FACTOR,
        // My Payment
        new ExportCellModel({
          value: row.MY_PAYOUT_AMOUNT_1,
          style: ExportCellModel.getNumberStyles(2)
        }),
        // My Payment Factor
        new ExportCellModel({
          value: row.MY_PAYOUT_FACTOR,
          style: ExportCellModel.getNumberStyles(2)
        }),

        row.AT_LEVEL_1 || 'N/A',
        row.AT_LEVEL_2 || 'N/A',
        row.AT_LEVEL_3 || 'N/A',
        row.AT_LEVEL_4 || 'N/A',
      ]);
    }
    else
    return this.isNotEligibleOrder ? rows.map((row: XaasOrderLinesSummary) => [
      // Non Eligibility Reason
      row.NON_ELIGIBILITY_REASON || 'N/A',
      // SO Number
      row.SO_NUMBER || 'N/A',
      row.LINE_ID || 'N/A',
      this.datePipe.transform(row.SOURCE_TRANSACTION_DATE, 'dd-LLL-YYYY') || 'N/A',
      row.OFFER_PART_NUMBER || 'N/A',
      row.PART_NUMBER || 'N/A',
      row.METRIC_TYPE || 'N/A',
      this.datePipe.transform(row.CONTRACT_ACCEPTED_DATE, 'dd-LLL-YYYY') || 'N/A',
      this.datePipe.transform(row.CONTRACT_START_DATE, 'dd-LLL-YYYY') || 'N/A',
      this.datePipe.transform(row.CONTRACT_END_DATE, 'dd-LLL-YYYY') || 'N/A',
      new ExportCellModel({
        value: row.CONTRACT_TERM,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.CURRENCY_CODE || 'N/A',
      new ExportCellModel({
        value: row.REVENUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.ANNUAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      new ExportCellModel({
        value: row.TOTAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      row.TERRITORY_CODE || 'NA',
      row.TERRITORY_TYPE_CODE || 'NA',
      row.NODE_LEVEL_2 || 'NA',
      row.NODE_LEVEL_3 || 'NA',
      row.NODE_LEVEL_4 || 'NA',
      row.NODE_LEVEL_5 || 'NA',
      row.NODE_LEVEL_6 || 'NA',
      row.AT_LEVEL_1 || 'NA',
      row.AT_LEVEL_2 || 'NA',
      row.AT_LEVEL_3 || 'NA',
      row.AT_LEVEL_4 || 'NA',
      row.DEAL_ID || 'NA',
      row.PO_NUMBER || 'NA',
      row.BILL_TO_CUSTOMER || 'NA',
      row.SHIP_TO_CUSTOMER || 'NA',
      row.END_CUSTOMER || 'NA',
      row.SUB_REF_ID || 'NA',
      row.SUBSCRIPTION_CODE || 'NA',
      row.ORDER_TYPE || 'NA',
      row.SELL_TYPE || 'NA',
      row.ACTION || 'NA',
      row.MRR || 'NA',
      row.IMRR || 'NA',
      row.REMAINING_TERM || 'NA',
      row.RENEWAL_TERM || 'NA',
      row.SUBSCRIPTION_CREDIT || 'NA',
      row.TERM_BOUND_CREDIT || 'NA'

    ])
    :
    rows.map((row: XaasOrderLinesSummary) => [
      // SO Number
      row.ORDER_NUMBER || row.SO_NUMBER || 'N/A',
      // Part Number
      row.PART_NUMBER || row.OFFER_PART_NUMBER || 'N/A',
      // Book Date
      this.datePipe.transform(row.LINE_CREATION_DATE || row.SOURCE_TRANSACTION_DATE, 'dd-LLL-YYYY') || 'Not Available',
      // Total Contract Value
      new ExportCellModel({
        value: row.TOTAL_VALUE_1 || row.TOTAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Annual Value
      new ExportCellModel({
        value: row.ANNUAL_VALUE_1 || row.ANNUAL_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Bookings
      new ExportCellModel({
        value: this.isNotEligibleOrder ? 'NA' : row.SPLIT_SALES_VALUE + row.BACKLOG_LINE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Backlog
      new ExportCellModel({
        value: this.isNotEligibleOrder ? 'NA' : row.BACKLOG_LINE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Revenue Original
      new ExportCellModel({
        value: row.SPLIT_SALES_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Revenue Multiplied
      new ExportCellModel({
        value: row.SPLIT_SALES_VALUE,
        style: ExportCellModel.getNumberStyles(2)
      }),
      // Revenue Factor
      row.REV_MULTIPLIER_FACTOR === 0 ? '' : row.REV_MULTIPLIER_FACTOR,
      row.AT_LEVEL_1 || 'N/A',
      row.AT_LEVEL_2 || 'N/A',
      row.AT_LEVEL_3 || 'N/A',
      row.AT_LEVEL_4 || 'N/A',
    ]);
  }

  private showExportNotification(operation: string) : void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'Order Line Transactions Export',
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }

}
