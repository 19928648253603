import { IacvCategoryDetailsRequest } from '../../interface/request/iacv-category-details-request';

export class IacvCategoryDetailsRequestModel implements IacvCategoryDetailsRequest {

    public loginId: string;
    public goalId: Number;
    public userId: string;
    public employeeId: string;
    public quotaId: string;
    public source_system:string;
    public lockDate : string;
    
  constructor(args : Partial<IacvCategoryDetailsRequestModel>) {
    Object.assign(this, args);
  } 

}