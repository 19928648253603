<ng-container *ngIf="ordersMetricHeaderData as data">
  <section class="grid">
    <div class="grid__column">
      <div class="column__row">
        <div class="metric-id-label">
          <span class="label">Metric ID</span>

          <i class="icon-info-outline" [ouxTooltip]="metricIdTooltipTemplate" [ouxTooltipOptions]="metricIdLabelTooltipOptions"></i>

          <ng-template #metricIdTooltipTemplate>
            <div class="metric-id-label-tooltip">
              <p class="metric-id-label-tooltip-message">
                Each Metric ID accounts for all business booked to a SAV and PID Mapping Group/CX Upsell Group each goaling period. 
                A Metric ID may contain many Sales Orders. 
                In this screen, you will see the iACV/$Renewed portion of the Metric ID for the order you searched.
              </p>
            </div>
          </ng-template>
        </div>

        <span class="value --dominant">{{ requestParams.metricId }}</span>
        <div class="group --plan-description">
          <span 
            class="value --description" 
            ellipsis 
            [ouxTooltip]="planElementTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.PE_NAME }}
          </span>

          <ng-template #planElementTooltip>
            <p class="tooltip__text">{{ data?.PE_NAME || 'N/A' }}</p>
          </ng-template>

          <i  
            *ngIf="data?.PE_NAME"
            class="icon-info-outline"
            [ouxTooltip]="data?.PE_NAME ? planElementDescTemplate : ''"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>

          <ng-template #planElementDescTemplate>
            <p class="tooltip__text">Plan Element Description</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->

      <div class="column__row --meta-columns">
        <div class="group">
          <span class="label">Total Order Lines:</span>

          <ng-container>
            <span class="value">{{totalRecords}}</span>
          </ng-container>

        </div><!-- /group -->

        <div class="group">
          <span class="label">Currency:</span>
          <span 
            class="value" 
            ellipsis 
            [ouxTooltip]="currencyTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{data?.CURRENCY}}
          </span>

          <ng-template #currencyTooltip>
            <p class="tooltip__text">USD</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->
    </div>

    <div class="grid__column">
      <div class="column__row">
        <span class="label">Type</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="typeTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          Metric
        </span>

        <ng-template #typeTooltip>
          <p class="tooltip__text">Metric</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row" *ngIf="transGroupCode === '$RN'">
        <span class="label">$Renewed</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="newLogoTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.RENEWED_USD_AMOUNT ? (data.RENEWED_USD_AMOUNT | number:'1.2-2') : 0.00}}
        </span>
        <ng-template #newLogoTooltip>
          <p class="tooltip__text">{{ data.RENEWED_USD_AMOUNT ? (data.RENEWED_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
        </ng-template>
      </div>

      <div class="column__row" *ngIf="transGroupCode !== '$RN'">
        <span class="label">{{'Total iACV'}}</span>
        <span
          class="value" 
          ellipsis 
          [ouxTooltip]="totalIacvTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.IACV_USD_AMOUNT ? (data.IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}
        </span>

        <ng-template #totalIacvTooltip>
          <p class="tooltip__text">{{ data.IACV_USD_AMOUNT ? (data.IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row" *ngIf="myPYFlag === 'MY PAYOUT' && transGroupCode !== '$RN'">
        <span class="label">MY Eligible Amount</span>
        <span
          class="value" 
          ellipsis 
          [ouxTooltip]="totalIacvTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.MY_ELIGIBLE_AMOUNT ? (data.MY_ELIGIBLE_AMOUNT | number:'1.2-2') : 0.00}}
        </span>

        <ng-template #totalIacvTooltip>
          <p class="tooltip__text">{{ data.IACV_USD_AMOUNT ? (data.IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
        </ng-template>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label">Category</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="categoryTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.CATEGORY || 'N/A' }}
        </span>
        <ng-template #categoryTooltip>
          <p class="tooltip__text">{{ data?.CATEGORY || 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row" *ngIf="transGroupCode === '$RN'">
        <span class="label"> TCV</span>
        <span class="value">{{data.TCV_METRIC_USD_AMOUNT ? (data.TCV_METRIC_USD_AMOUNT | number:'1.2-2') : 0.00}}</span>
      </div>

      <div *ngIf="transGroupCode !== '$RN'" class="column__row">
        <span class="label">NEW LOGO</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="newLogoTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.NEW_LOGO_IACV_USD_AMOUNT ? (data.NEW_LOGO_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}
        </span>
        <ng-template #newLogoTooltip>
            <p class="tooltip__text">{{ data.NEW_LOGO_IACV_USD_AMOUNT ? (data.NEW_LOGO_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label">Node (Territory Type)</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="territoryTypeTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.TERRITORY_CODE || 'N/A' }} ({{data?.TERRITORY_TYPE_NAME || 'N/A'}})
        </span>
        <ng-template #territoryTypeTooltip>
          <p class="tooltip__text">{{ data?.TERRITORY_CODE || 'N/A' }} ({{data?.TERRITORY_TYPE_NAME || 'N/A'}})</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <ng-container *ngIf = "transGroupCode !== '$RN'">
          <span class="label">UPSELL</span>
          <span 
            class="value" 
            ellipsis 
            [ouxTooltip]="upsellTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data.UPSELL_IACV_USD_AMOUNT ? (data.UPSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}
          </span>
          <ng-template #upsellTooltip>
            <p class="tooltip__text">{{ data.UPSELL_IACV_USD_AMOUNT ? (data.UPSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
        </ng-container>
        <ng-container *ngIf = "myPYFlag === 'MY PAYOUT' && transGroupCode === '$RN'">
          <span class="label">MY Eligible Amount</span>
          <span
            class="value" 
            ellipsis 
            [ouxTooltip]="totalIacvTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data.MY_ELIGIBLE_AMOUNT ? (data.MY_ELIGIBLE_AMOUNT | number:'1.2-2') : 0.00}}
          </span>

          <ng-template #totalIacvTooltip>
            <p class="tooltip__text">{{ data.IACV_USD_AMOUNT ? (data.IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
        </ng-container>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div *ngIf="data.PID==='NA'" class="column__row">
        <span class="label">CX Upsell Group</span>
        <span class="value" ellipsis [ouxTooltip]="cxUpsellTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.CX_UPSELL_GROUP || 'N/A' }}
        </span>
    
        <ng-template #cxUpsellTooltip>
          <p class="tooltip__text">{{ data?.CX_UPSELL_GROUP || 'N/A' }}</p>
        </ng-template>
      </div>

      <div *ngIf="data.PID!=='NA'" class="column__row">
        <span class="label">PID Mapping Group</span>
        <span class="value" ellipsis [ouxTooltip]="pidTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.PID || 'N/A' }}
        </span>
    
        <ng-template #pidTooltip>
          <p class="tooltip__text">{{ data?.PID || 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <ng-container *ngIf = "transGroupCode !== '$RN'">
          <span class="label">UPSELL ADJ</span>
          <span 
            class="value" 
            ellipsis 
            [ouxTooltip]="upsellAdjTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data.UPSELL_IACV_ADJ_USD_AMOUNT ? (data.UPSELL_IACV_ADJ_USD_AMOUNT | number:'1.2-2') : 0.00}}
          </span>
          <ng-template #upsellAdjTooltip>
            <p class="tooltip__text">{{ data.UPSELL_IACV_ADJ_USD_AMOUNT ? (data.UPSELL_IACV_ADJ_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
        </ng-container>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label">SAV NAME(SAV ID)</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="savTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.SAV_NAME ? data.SAV_NAME  : 'N/A' }} {{ data.SAV_NAME ? (savId)  : '' }}
        </span>

        <ng-template #savTooltip>
          <p class="tooltip__text">{{ data.SAV_NAME ? data.SAV_NAME  : 'N/A' }} {{ data.SAV_NAME ? '('+savId+')'  : '' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <!-- <div class="column__row">
        <ng-container *ngIf = "transGroupCode !== '$RN'">
        <span class="label">CROSS SELL</span>
        <span 
          class="value" 
          ellipsis 
          [ouxTooltip]="crossSellTooltip" 
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.XSELL_IACV_USD_AMOUNT ? (data.XSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}
        </span>
        <ng-template #crossSellTooltip>
            <p class="tooltip__text">{{ data.XSELL_IACV_USD_AMOUNT ? (data.XSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
        </ng-container>
      </div> -->

      <div class="column__row">
        <div class="column__tcv_row">
          <div class="column__row">
            <ng-container *ngIf = "transGroupCode !== '$RN'">
              <span class="label">CROSS SELL</span>
              <span 
                class="value" 
                ellipsis 
                [ouxTooltip]="crossSellTooltip" 
                [ouxTooltipOptions]="templateTooltipOpts">
                {{ data.XSELL_IACV_USD_AMOUNT ? (data.XSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}
              </span>
              <ng-template #crossSellTooltip>
                  <p class="tooltip__text">{{ data.XSELL_IACV_USD_AMOUNT ? (data.XSELL_IACV_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
                </ng-template>
              </ng-container>
          </div>
          <div class="column__row" *ngIf="myPYFlag === 'MY PAYOUT' && transGroupCode !== '$RN'">
            <span class="label"> TCV</span>
            <span class="value">{{data.TCV_METRIC_USD_AMOUNT ? (data.TCV_METRIC_USD_AMOUNT | number:'1.2-2') : 0.00}}</span>
          </div>
        </div>
      </div> <!-- /column__row -->
    </div>



    <!-- <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label"> </span>
        <span class="value"> </span>

      </div>


      <div class="column__row">
        <ng-container *ngIf = "transGroupCode !== '$RN'">
          <span class="label">UPSELL ADJ</span>
          <span 
            class="value" 
            ellipsis 
            [ouxTooltip]="upsellAdjTooltip" 
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data.UPSELL_IACV_ADJ_USD_AMOUNT ? (data.UPSELL_IACV_ADJ_USD_AMOUNT | number:'1.2-2') : 0.00}}
          </span>
          <ng-template #upsellAdjTooltip>
            <p class="tooltip__text">{{ data.UPSELL_IACV_ADJ_USD_AMOUNT ? (data.UPSELL_IACV_ADJ_USD_AMOUNT | number:'1.2-2') : 0.00}}</p>
          </ng-template>
        </ng-container>
      </div>
    </div> -->
  </section>
</ng-container>


