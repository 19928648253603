import { 
  HttpClient, 
  HttpHeaders, 
  HttpParams }                  from '@angular/common/http';
import { Injectable }           from '@angular/core';
import { 
  Observable, 
  of, 
  throwError }                  from 'rxjs';
import { 
  catchError,
  map, 
  switchMap, 
  tap }                         from 'rxjs/operators';
import { 
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService, 
  OuxConfigService, 
  OuxLoggerService, 
  OuxToastService}            from '@cisco/oux-common';
import { UserDetailsStore }     from '../stores/user-details.store';
import { MetadataStore } from '../stores/metadata.store';
import { MetricDetailsFilterCriteriaResponse } from '../models/interface/response/metric-details-filter-criteria-response';
import { MetricDetailsFilterCriteria } from '../models/interface/partials/metric-details-filter-criteria';
import { MetricDetailsFilterCriteriaModel } from '../models/concrete/partials/metric-details-filter-criteria.model';
import { MetricDetailsSearchModel } from 'src/app/routes/metric-details/routes/metric-details-summary/models/metric-details-search.model';
import { MetricDeatilsRequestModel }             from 'src/app/shared/models/concrete/request/metric-details-request.model';
import { MetricDetailsIacv } from '../models/interface/partials/metric-details-iacv';
import { MetricDetailsStore } from '../stores/metric-details-store';
import { IacvCategoryDetailsRequestModel } from '../models/concrete/request/iacv-category-details-request.model';
import { GoalToCashDetailsRequestModel } from '../models/concrete/request/goal-to-cash-details-request.model';
import { IacvCategoryDetails } from '../models/interface/partials/iacv-category-details';
import { IacvCategoryDetailsModel } from '../models/concrete/partials/iacv-category-details.model';
import { IacvCategoryDetailsResponse } from '../models/interface/response/iacv-category-details-response';
import { WeeklyTransactionSummaryRequestModel } from '../models/concrete/request/weekly-transaction-summary-request.model';
import { OrdersMetricHeaderDetailsRequestModel } from '../models/concrete/request/orders-metric-header-details-request.model';
import { OrdersMetricHeaderDetailsResponse } from '../models/interface/response/orders-metric-header-details-response';
import { OrdersMetricHeaderDetailsModel } from '../models/concrete/partials/orders-metric-header-details.model';
import { OrdersMetricHeaderDetails } from '../models/interface/partials/orders-metric-header-details';

@Injectable({
providedIn: 'root'
})
export class MetricDetailsService {
private baseUri: string;
private metricDetailsDropdownUri: string;
private metricDetailsUri: string;
private iacvCategoryDetailsUri : string;

private ordersMetricHeaderDetailsUri : string;

private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('GoalToCashService');

constructor(
  private http: HttpClient,
  private ouxAuthSvc: OuxAuthenticationService,
  private ouxConfigSvc: OuxConfigService,
  private ouxExceptionsSvc: OuxExceptionsHandlerService,
  private metricDetailsStore: MetricDetailsStore,
  private userDetailsStore: UserDetailsStore,
  private metadataStore: MetadataStore
) {
  this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
  //this.metricDetailsDropdownUri = this.ouxConfigSvc.getAppConfigValue('apiUri').metricDetailsDropdown;
  //this.metricDetailsUri = this.ouxConfigSvc.getAppConfigValue('apiUri').metricDetailsUri;
  this.iacvCategoryDetailsUri = this.ouxConfigSvc.getAppConfigValue('apiUri').iacvCategoryDetails;

  this.ordersMetricHeaderDetailsUri = this.ouxConfigSvc.getAppConfigValue('apiUri').ordersMetricHeaderDetails;
  
  
  
}

public fetchiACVcategoryDetails(params: GoalToCashDetailsRequestModel): Observable<IacvCategoryDetails>{

  let URL = `${this.baseUri}${this.iacvCategoryDetailsUri}`;
  let OPTIONS = this.getOptions();

  let request = new IacvCategoryDetailsRequestModel({
    loginId: this.userDetailsStore.getUserId(),
    goalId: Number(params.goalID),
    userId: this.metadataStore.getMetadataEmployeeInfo().empEmail,
    employeeId: this.metadataStore.getMetadataEmployeeId(),
    quotaId: params.quotaId,
    source_system : this.ouxConfigSvc.getAppConfigValue('source_system'),
    lockDate : params.lockDate 
  })
  
  const REQUEST$ = this.http.post<IacvCategoryDetailsResponse>(URL, request, OPTIONS)
    .pipe(
      switchMap(response => {
        if (!response) {
          return throwError(response);
        }
        return of(response);
      }),
      map((response: IacvCategoryDetailsResponse) => {
        if(!response){
          return throwError(response);
        }
        let typed = new IacvCategoryDetailsModel(response.data);
        return typed;
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('fetchData', error)(error);
      })
    );

  return REQUEST$;
}

/**
 *  Fetches Metric Line Level Header Details
 * 
 */
public fetchOrdersMetricHeaderDetails(params: OrdersMetricHeaderDetailsRequestModel): Observable<OrdersMetricHeaderDetails[]>{

  let URL = `${this.baseUri}${this.ordersMetricHeaderDetailsUri}`;
  let OPTIONS = this.getOptions();

  let request = new OrdersMetricHeaderDetailsRequestModel({
    loginId: this.userDetailsStore.getUserId(),
    userId: this.metadataStore.getMetadataEmployeeInfo().empEmail,
    employeeId: this.metadataStore.getMetadataEmployeeId(),
    metricId: params.metricId, //1000052 ,
    goalId: params.goalId,
    quotaId: params.quotaId,
    sourceSystem : this.ouxConfigSvc.getAppConfigValue('source_system'), 
  })
  
  const REQUEST$ = this.http.post<OrdersMetricHeaderDetailsResponse>(URL, request, OPTIONS)
    .pipe(
      switchMap(response => {
        if (!response) {
          return throwError(response);
        }
        return of(response);
      }),
      map((response: OrdersMetricHeaderDetailsResponse) => {
        if(!response){
          return throwError(response);
        }
        //let typed = new OrdersMetricHeaderDetailsModel(response.data);
        return response;
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('fetchData', error)(error);
      })
    );

  return REQUEST$;
}


private getOptions() : { headers: HttpHeaders } { 

  const OPTIONS : { headers : HttpHeaders } = { 
    headers : new HttpHeaders() 
      .set('Authorization', this.ouxAuthSvc.getAuthToken()) 
      .append('Content-Type', 'application/json') 
  }; 

  return OPTIONS; 
}

/*public fetchFilterCriteraDetails(): Observable<MetricDetailsFilterCriteria>{

  let URL = `${this.baseUri}${this.metricDetailsDropdownUri}`;

  let OPTIONS = {
      headers : new HttpHeaders()
      .set('Authorization', this.ouxAuthSvc.getAuthToken())
      .append('Content-Type', 'application/json'),
    params: new HttpParams()
      .set('loggedmail', this.userDetailsStore.getUserId())
      .append('loginId', this.userDetailsStore.getUserId())
      .append('empmail', this.metadataStore.getMetadataEmployeeInfo().empEmail)
      .append('userName', this.metadataStore.getMetadataEmployeeInfo().empEmail)
  };

  let request$ = this.http.get<MetricDetailsFilterCriteriaResponse>(URL, OPTIONS)
    .pipe(
      map(response => {
        if (!response && !response.success) {
          return throwError(response);
        }
        let typed = new MetricDetailsFilterCriteriaModel(response.data);
        return typed;
        //return parsed && parsed.isValid() ? parsed : null;
      }),
      tap((typed:MetricDetailsFilterCriteria) => {
        
      }), 
      catchError(error => { 
        // create operation mapping for http exception handling 
        return this.ouxHandleError('fetchRefreshDate', error)(error); 
      })
    );

  
  return request$;
  //return request$;
}*/


/*public fetchMetricDetails(filterData:MetricDetailsSearchModel): Observable<MetricDetailsIacv[]>{
  const URL = `${this.baseUri}${this.metricDetailsUri}`;
  const OPTIONS = this.getOptions();

  let request  = new MetricDeatilsRequestModel ({
    loginId: this.userDetailsStore.getUserId(),
    goalId: null,
    userId: this.metadataStore.getMetadataEmployeeInfo().empEmail,
    quotaId: filterData.quotaId,
    planYear: String(filterData.planYear),
    goalFiscalYear: filterData.goalingFiscalInterval,
    savId: filterData.savId,
    territoryCode: filterData.nodeName,
    productServiceFlag: filterData.service,
    beCXLevel1: filterData.beName,
    beCXLevel2: filterData.subBeName,
    beCXLevel3: filterData.pidName,
    cxUpsellGroup : filterData.cxUpsellGroup,
    employeeId: this.metadataStore.getMetadataEmployeeId(),
    source_system: this.ouxConfigSvc.getAppConfigValue('source_system')
  })

  const REQUEST$ = this.http.post<MetricDetailsIacv[]>(URL, request, OPTIONS)
    .pipe(
      switchMap(response => {
        if (!response) {
          return throwError(response);
        }

        return of(response);
      }),
      map((response: MetricDetailsIacv[]) => {
        if(!response){
          return throwError(response);
        }

        this.metricDetailsStore.setMetricDetailsIacvData(response);
        return response;
      }),
      catchError(error => {
        // create operation mapping for http exception handling
        return this.ouxHandleError('fetchData', error)(error);
      })
    );

  return REQUEST$;
}*/



}