  <ng-container *ngIf="goalToCashDrillDownFlag">

  
    <ng-container *ngIf="categoryHeader">
        
      <oux-table 
      [records]="categoryHeader"
      [options]="tableOptions"
      [settings]="columnSettings"
      [themeOverrides]="tableThemeOverrides">

      <ng-template [ouxHeader]="'CATEGORY'">
          <div class="pad-left-sm">Category</div>
      </ng-template>

      <ng-template [ouxHeader]="'GOAL'">
          {{(periodCodeFlag) ? 'Plan Element Goal': 'Goal (' +  currency + ')'  }}
      </ng-template>

      <ng-template [ouxHeader]="'REVENUE'">
        {{(periodCodeFlag) ? 'MY Eligible Amount': 'Total ' + revenueType}}
        
    </ng-template>

      <ng-template [ouxHeader]="'TCV_METRIC_USD_AMOUNT'">
          <div class="pad-right-sm">Total TCV
          </div>
      </ng-template>

      <ng-template [ouxHeader]="'REVENUE_ATTAINMENT'">
        <ng-container *ngIf="periodCodeFlag">
          <div class="pad-right-sm">
            MY Revenue Attainment
          </div>
        </ng-container>
        <ng-container *ngIf="!periodCodeFlag">
          <div class="pad-right-sm">
            {{(myPYFlag === 'Y') ? 'MY Comp Attainment':'Revenue Attainment'}}
          </div>
        </ng-container>
      </ng-template>

      <ng-template [ouxHeader]="'ytdComm'">
        <div>GPTD Incentive ({{ ytdCommCurrency }})</div>
      </ng-template>
      
      <ng-template [ouxHeader]="'ytdPaid'">
        <div>GPTD Paid ({{ ytdCommCurrency }})</div>
      </ng-template>

      <ng-template [ouxHeader]="'paymentAmount'">
        <div class="pad-right-sm">Payment ({{ ytdCommCurrency }})</div>
      </ng-template>

      <ng-template [ouxColumn]="'REVENUE_ATTAINMENT'" let-record="record">
        <ng-container *ngIf="periodCodeFlag">
          <div class="pad-right-sm">
            {{record.REVENUE_ATTAINMENT | leadWithZero}}
          </div>
        </ng-container>
        <ng-container *ngIf="!periodCodeFlag">
          <div class="pad-right-sm">
            {{(myPYFlag === 'Y') ? (record.MY_COMP_ATTAINMENT | leadWithZero) : (record.REVENUE_ATTAINMENT | leadWithZero)}}
        </div>
        </ng-container>          
      </ng-template>

      <ng-template [ouxColumn]="'ytdComm'" let-record="record">
        {{ ytdIncentive | dollarFormat }}
      </ng-template>

      <ng-template [ouxColumn]="'CATEGORY'" let-record="record">
        <div class="pad-left-sm">
        {{ record.CATEGORY | dollarFormat }}
      </div>
      </ng-template>


      <ng-template [ouxColumn]="'ytdPaid'" let-record="record">
        {{ (ytdPaid | dollarFormat) || 0 }}
      </ng-template>
  
      <ng-template [ouxColumn]="'paymentAmount'" let-record="record">
        <div class="pad-right-sm">
          {{ ytdPayment | dollarFormat }}
        </div>
      </ng-template>


      <ng-template [ouxColumn]="'GOAL'" let-record="record">
          <ng-container *ngIf="(myPYFlag !=='Y'); else showGoalTemplate ">
              {{record?.GOAL | number:'1.2-2'}}
          </ng-container>
          <ng-template #showGoalTemplate>
              -
          </ng-template>
      </ng-template>

      <ng-template [ouxColumn]="'BOOKINGS'" let-record="record">
          <ng-container *ngIf="(myPYFlag !=='Y'); else showBookingsTemplate ">
              {{((record.BACKLOG ? record.BACKLOG : 0) + (record.REVENUE ? record.REVENUE : 0)) | number:'1.2-2' }}
          </ng-container>
          <ng-template #showBookingsTemplate>
              -
          </ng-template>
      </ng-template>

      <ng-template [ouxColumn]="'REVENUE'" let-record="record">
              {{record?.REVENUE | number:'1.2-2'}}
      </ng-template>

      <ng-template [ouxColumn]="'Service_Attainment'" let-record="record">
          {{record?.REVENUE_ATTAINMENT}}
      </ng-template>

      <ng-template [ouxColumn]="'BACKLOG'" let-record="record">
        
          <ng-container *ngIf="(myPYFlag !=='Y'); else showBacklogTemplate ">
              {{record?.BACKLOG | number:'1.2-2'}}
          </ng-container>
          <ng-template #showBacklogTemplate>
              -
          </ng-template>
      </ng-template>

      <ng-template [ouxColumn]="'TCV_METRIC_USD_AMOUNT'" let-record="record">
          {{record?.TCV_METRIC_USD_AMOUNT | number:'1.2-2'}}
      </ng-template>

      

      

      </oux-table>

  </ng-container>
</ng-container>

<ng-container *ngIf="!goalToCashDrillDownFlag">

  <ng-container *ngIf="(details$ | async) as data">
      <!-- <div class="header">
        <div class="goalsheet">Goal Sheet:</div>
        <a 
          *ngIf="data[0]?.goalSheetLink; else noLinkTemplate" 
          class="link" 
          [href]="data[0]?.goalSheetLink">
          {{ data[0]?.goalSheet }}
        </a>
        <ng-template #noLinkTemplate>
          <div class="goalsheet">{{ data[0]?.goalSheet }}</div>
        </ng-template>
    
        <div class="proration">
          ({{ data[0]?.planProrationType }}:{{ data[0]?.fiscalInterval }})
        </div>
      </div> -->
    
      <oux-table 
        [records]="data"
        [options]="tableOptions"
        [settings]="columnSettings"
        [themeOverrides]="tableThemeOverrides">
    
        <ng-template [ouxHeader]="'category'">
          <div class="pad-left-sm">Category</div>
        </ng-template>

       <ng-template [ouxHeader]="'revAttain'">
        Revenue Attainment
        </ng-template>
    
        <ng-template [ouxHeader]="'weight'">
          Weight
          <i 
            *ngIf="(data[0]?.weight != null)" 
            class="icon-info-outline"
            [ouxTooltip]="weightDescTemplate"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>
          <ng-template #weightDescTemplate>
            <!-- <p class="tooltip__text">Weight given is the corresponding regular plan element's weight.</p> -->
            <p class="tooltip__text">{{weightDescription}}</p>
          </ng-template>
        </ng-template>
    
        <ng-template [ouxHeader]="'goalAmt'">
          Goal ({{ data[0]?.goalCurrency }})
        </ng-template>

        <ng-template [ouxHeader]="'ytdComm'">
          <div>GPTD Incentive ({{ data[0]?.commCurrency }})</div>
        </ng-template>
        
        <ng-template [ouxHeader]="'ytdPaid'">
          <div>GPTD Paid ({{ data[0]?.commCurrency }})</div>
        </ng-template>
    
        <ng-template [ouxHeader]="'paymentAmount'">
          <div class="pad-right-sm">Payment ({{ data[0]?.commCurrency }})</div>
        </ng-template>
    
        <!-- start custom columns -->
    
        <ng-template [ouxColumn]="'category'" let-record="record">
          <div class="pad-left-sm">
            {{ record?.category }}
          </div>
        </ng-template>

        <ng-template [ouxColumn]="'revAttain'" let-record="record">
          {{ record?.revAttain | leadWithZero}}
       </ng-template>
    
        <ng-template [ouxColumn]="'goalAmt'" let-record="record">
          {{ record?.goalAmt | dollarFormat }}
        </ng-template>
    
        <ng-template [ouxColumn]="'ytdComm'" let-record="record">
          {{ record?.ytdComm | dollarFormat }}
        </ng-template>
    
        <ng-template [ouxColumn]="'ytdPaid'" let-record="record">
          {{ (record?.ytdPaid | dollarFormat) || 0 }}
        </ng-template>
    
        <ng-template [ouxColumn]="'paymentAmount'" let-record="record">
          <div class="pad-right-sm">
            {{ record?.paymentAmount | dollarFormat }}
          </div>
        </ng-template>
    
      </oux-table>
    </ng-container>
  </ng-container>
  