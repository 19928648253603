/* Angular Import */
import { Injectable }                                   from '@angular/core';
import { 
  combineLatest, 
  of,
  BehaviorSubject }                                     from 'rxjs';
import { map, tap }                                     from 'rxjs/operators';
/* Feature Imports */
import { 
  OuxStore,
  OuxLoggerService, 
  OuxModalService}                                      from '@cisco/oux-common';
import { OrderStore }                                   from './order.store';
import { MetricSOLinesSummary } from '../models/interface/partials/metric-so-lines-summary';
import { MetricSOTransactionSummaryDetails } from '../models/interface/partials/metric-so-transaction-summary-details';
import { MetricSOTransactionLineDetailsModalComponent } from '../components/metric-so-transaction-lines/partials/metric-so-transaction-line-details-modal/metric-so-transaction-line-details-modal.component';
import { MetricSONotEligibleLinesSummary } from '../models/interface/partials/metric-so-not-eligible-lines-summary';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';

/**
 * Creates our Transactions State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class MetricSOLineTransactionsStore extends OuxStore<MetricSOLinesSummary> {
  
  //private orderLineBacklogDetailsSubject = new BehaviorSubject<OrdersLinesBacklogDetails>(null);

  public orderLineTransactions$ = this.state$
    .pipe(
      map(details => details|| [])
    );


  constructor(private ouxLoggerSvc: OuxLoggerService,
              private ouxModalSvc: OuxModalService,
              private orderStore: OrderStore) {

    super (null);

  } 

  public setDetails(details: MetricSOLinesSummary | MetricSONotEligibleLinesSummary) : void {
    super.setState(details);

    this.ouxLoggerSvc.logDebug('MetricSOLineTransactionsStore - <Transactions[]>:', this.state);
  }

  public showMetricSOTransactionDetailsModal(selectedOrderLine: MetricSOTransactionSummaryDetails, transactionType: string, peType: string, myFlag: string, ampIdentifier: string) : void {
    let combinedOrderDetails$ = combineLatest([
      this.orderLineTransactions$,
      of(selectedOrderLine),
      of(transactionType),
      of(peType),
      of(myFlag),
      ampIdentifier === AmpIdentifierKey.NOTELIGIBLE ? this.orderStore.selectedNotEligibleOrder$ : this.orderStore.selectedOrder$
    ])
    .pipe(
      tap(data => this.ouxLoggerSvc.logDebug('Combined Order Line Transaction Data Stream:', data)),
      map(([metricSOTransactions, selectedOrderLine, transactionType, peType, myFlag, selectedOrder ]) => ({ metricSOTransactions, selectedOrderLine, transactionType, peType, myFlag, selectedOrder }))
    );

    if (selectedOrderLine) {
      this.ouxModalSvc.openModal(MetricSOTransactionLineDetailsModalComponent, combinedOrderDetails$);
    }
  }



  /**
   * Clear Order Lines Data Collection
   * ============================================================
   */
   public clearOrderLineTransactionsData(): void {
    this.setState(null);
    //this.setBacklogDetails(null);
  }

  /**
   * Convenience Methods
   * ============================================================
   */

}