import { GoalToCashDetails }      from "../../interface/partials/goal-to-cash-details";
import { PlanElementDetails }     from "../../interface/partials/plan-element-details";
import { PlanElementDetailsModel } from './plan-element-details.model';

export class GoalToCashDetailsModel implements GoalToCashDetails {
  public P_ERROR_MESSAGE: string;
  public peDetails: PlanElementDetails[];

  constructor(args : Partial<GoalToCashDetailsModel>) {
    Object.assign(this, args);

    if (this.peDetails) {
      this.peDetails = this.peDetails.map(x => new PlanElementDetailsModel(x));
    }
  }
}