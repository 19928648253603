import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OuxColumnSettings, OuxLoggerService, OuxModalService, OuxTableOptions, OuxTableThemeOverrides, OuxThemeType, OuxTooltipOptions, OuxTooltipPosition } from '@cisco/oux-common';
import { EMPTY, Subscription } from 'rxjs';
import { SortEvent } from 'src/app/shared/models/concrete/partials/sort-event.model';
import { MetricSOTransactionLineRequestModel } from 'src/app/shared/models/concrete/request/metric-order-line-request.model';
import { LoadingType } from 'src/app/shared/models/constants/loading-type.enum';
import { MetricSOTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/metric-so-transaction-summary-details';
import { OrdersMetricHeaderDetailsRequest } from 'src/app/shared/models/interface/request/orders-metric-header-details-request';
import { LoaderService } from 'src/app/core/services';
import { finalize, tap, catchError } from 'rxjs/operators';
import { OrderService } from 'src/app/shared/services/order.service';
import { MetricSOLineTransactionsStore } from 'src/app/shared/stores/metric-so-line-transactions.store';
import { MetricSOLinesSummary } from 'src/app/shared/models/interface/partials/metric-so-lines-summary';
import { ActivatedRoute } from '@angular/router';
import {AllocationTransactionType} from "../../../../models/types/allocation-transaction-type.enum";
import { MetricSONotEligibleTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/metric-so-not-eligible-transaction-summary-details';
import { MetricSONotEligibleLinesSummary } from 'src/app/shared/models/interface/partials/metric-so-not-eligible-lines-summary';
import { AmpIdentifierKey } from 'src/app/shared/models/types/amp-identifier-key.enum';
import { OrderStore } from 'src/app/shared/stores/order.store';

@Component({
  selector: 'metric-so-transaction-lines-summary',
  templateUrl: './metric-so-transaction-lines-summary.component.html',
  styleUrls: ['./metric-so-transaction-lines-summary.component.scss'],
  host: {
    'class': 'metric-so-transaction-lines-summary'
  }
})
export class MetricSOTransactionLinesSummaryComponent implements OnInit {

  @Input('metricSoTransactionSummaryDetails')
  public metricSoTransactionSummaryDetails: MetricSOTransactionSummaryDetails[];

  @Input('requestParams')
  public requestParams: OrdersMetricHeaderDetailsRequest;

  public myFlag: string;

  public peType: string;
  public type: string;
  public ampIdentifier: string;
  public AmpIdentifierKey = AmpIdentifierKey;


  public tableOpts: OuxTableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--fixed-header',
    formatCells: false,
    tableLayout: 'fixed',
    fixedHeight: '100%',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    trackRecordsProperty: "SAV_NAME",
    noRecordsMessage: 'There are no transactions to display at this time',
    highlightSelectedRows: false,
    missingDataMessage: ' ',
    toggleSelected: false
  });

  // column configurations for the metric so summary table data
  public columnSettings: OuxColumnSettings[] = [];
  // if the control comes to this page from Amplifiers G2C SO drill down
  public columnSettingsFromG2C: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'LINE_ID',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
      width: '15%'
    }),
    new OuxColumnSettings({
      header: 'TCV',
      primaryKey: 'TOTAL_VALUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Total Annual Bookings',
      primaryKey: 'ANNUAL_VALUE',
      thAlign: 'center',
      sortable: true,
      tdAlign: 'center',
      width: '15%'
    }),
    new OuxColumnSettings({
      header: 'Bookings',
      primaryKey: 'BOOKING',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Backlog',
      primaryKey: 'BACKLOG',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'REVENUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
      width: '15%'
    }),
    new OuxColumnSettings({
      header: 'Offer Part Number',
      primaryKey: 'OFFER_PART_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
      width: '15%'
    })
  ];

  // if the control comes to this page from Amplifiers Payments SO drill down
  public columnSettingsFromPayments: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'LINE_ID',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true
    }),
    new OuxColumnSettings({
      header: 'TCV',
      primaryKey: 'TOTAL_VALUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Total Annual Bookings',
      primaryKey: 'ANNUAL_VALUE',
      thAlign: 'center',
      sortable: true,
      tdAlign: 'center',
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'REVENUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Offer Part Number',
      primaryKey: 'OFFER_PART_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    })
  ];

  // if the PE is of type MY - IACV
  public columnSettingsIacvMY: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'LINE_ID',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true
    }),
    new OuxColumnSettings({
      header: 'TCV',
      primaryKey: 'TOTAL_VALUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'iACV',
      primaryKey: 'IACV_BOOKING_AMOUNT',
      thAlign: 'center',
      sortable: true,
      tdAlign: 'center',
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'REVENUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Contract Term',
      primaryKey: 'CONTRACT_TERM',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Offer Part Number',
      primaryKey: 'OFFER_PART_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    })
  ];

  // if the PE is of type MY - RND
  public columnSettingsRndMY: OuxColumnSettings[] = [
    new OuxColumnSettings({
      header: 'SO/POS Line Number',
      primaryKey: 'LINE_ID',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true
    }),
    new OuxColumnSettings({
      header: 'TCV',
      primaryKey: 'TOTAL_VALUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: '$Renewed + Residual Annual',
      primaryKey: 'RENEWAL_BOOKING_AMOUNT',
      thAlign: 'center',
      sortable: true,
      tdAlign: 'center',
      width: '230px'
    }),
    new OuxColumnSettings({
      header: 'Revenue',
      primaryKey: 'REVENUE',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Contract Term',
      primaryKey: 'CONTRACT_TERM',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Commission Status',
      primaryKey: 'COMMISSION_STATUS',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    }),
    new OuxColumnSettings({
      header: 'Offer Part Number',
      primaryKey: 'OFFER_PART_NUMBER',
      thAlign: 'center',
      tdAlign: 'center',
      sortable: true,
    })
  ];

  public tableThemeOverrides: OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  //  tool tip css override configs
  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '100px',
      'max-width': '250px'
    },
    context: {
      code: null
    }
  });

  public subscriptions: Subscription[] = [];

  @Output('onSort')
  public handleChange: EventEmitter<{ key: string, direction: 'asc' | 'desc' | '' }>;


  constructor(
    private ouxLoggerSvc: OuxLoggerService,
    private loaderSvc: LoaderService,
    private metricSOLineTransactionStore: MetricSOLineTransactionsStore,
    private ouxModalSvc: OuxModalService,
    private orderSvc: OrderService,
    private route: ActivatedRoute,
    public orderStore: OrderStore
  ) { this.handleChange = new EventEmitter(); }

  ngOnInit(): void {
    this.peType = this.requestParams.peType;
    this.myFlag = this.requestParams.myFlag;
    this.type = this.requestParams.type;
    this.columnSettings = this.myFlag === 'Y' ? (this.peType === 'IACV' ? this.columnSettingsIacvMY : this.columnSettingsRndMY) : ((this.requestParams.sourcePage === 'g2c' || this.requestParams.sourcePage === 'order') ? this.columnSettingsFromG2C : this.columnSettingsFromPayments);
    this.ampIdentifier = this.requestParams.ampIdentifier;
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];

    // clean up selected order - prevents memory leaks
    this.orderStore.clearSelectedOrder();
    this.orderStore.clearSelectedNotEligibleOrder();
  }

  // sorting
  public sortColumn(key: string, direction: 'asc' | 'desc' | '') {
    // emit sort request to consuming component
    this.handleChange.emit(new SortEvent({ key: key, direction: direction }));

  }

  // control drill down for the annual column for each row by adding isExpanded attribute in the response data
  public showDrillDown(record): void {
    let isExpanded = record.isExpanded;
    this.metricSoTransactionSummaryDetails.forEach(instance => instance.isExpanded = false);
    record.isExpanded = isExpanded ? false : true;
  }

  public showOrderLineDetails(record: MetricSOTransactionSummaryDetails, transactionType: 'REV' | 'BKLG', event: any): void {
    let params = this.route.snapshot.queryParamMap;
    this.ouxLoggerSvc.logDebug(`OrderLinesSummaryTableComponent - ${record.LINE_ID} Line Selected`, record);

    //  build request payload for the modal windows
    let request = new MetricSOTransactionLineRequestModel({
      quotaId: String(record.QUOTA_ID),
      goalId: record.GOAL_ID,
      salesOrderNumber: String(record.SO_NUMBER),
      transactionType: transactionType,
      lineId: record.LINE_ID,
      sourceSystem: "SAP",
      sourcePage: this.requestParams.sourcePage,
      startDate: params.has('searchStartDate') ? params.get('searchStartDate') : null,
      endDate: params.has('searchEndDate') ? params.get('searchEndDate') : null,
      limit: 20,
      offset: 0,
      sortBy: 'SAV_ID',
      sortOrder: 'desc'
    })

    // let request = new MetricSOTransactionLineRequestModel({
    //   "goalId": 3173912,
    //   "quotaId": "266330",
    //   "limit": 10,
    //   "offset": 0,
    //   "sortBy": "LINE_ID",
    //   "sortOrder": "desc",
    //   "employeeId": "389210",
    //   "loginId": "alislewi",
    //   "userId": "kawaters",
    //   "salesOrderNumber": "123456",
    //   "lineId": 1122334455
    // })

    this.loaderSvc.show(LoadingType.Full);
    this.fetchMetricSOLineDetailsData(request, record);

    // subscribe to close event and retrieve the closed value, if any
    this.subscriptions.push(
      this.ouxModalSvc.onClose()
        .subscribe((value: any) => {
          this.metricSOLineTransactionStore.clearOrderLineTransactionsData(); 

          this.ouxLoggerSvc.logDebug('OrderLinesSummaryTableComponent - Modal OnClose Value:', value ? value : 'No Value Passed');
        })
    );
  }

  //  call to api to get modal data for the backlog and revenue based on transactionType
  private fetchMetricSOLineDetailsData(request: MetricSOTransactionLineRequestModel, line: MetricSOTransactionSummaryDetails): void {
    if (this.ampIdentifier === AmpIdentifierKey.NOTELIGIBLE) {
      let params = this.route.snapshot.queryParamMap;

      let notEligibleRequest = {
        loginId: '',
        userId: '',
        employeeId: '',
        salesOrderNumber: request.salesOrderNumber,
        erpPosFlag: (params && params.has('erpPosFlag')) ? (params.get('erpPosFlag')) : '',
        trxType: this.type,
        lineId: request.lineId,
        limit: 10,
        offset: 0,
        sortBy: 'LINE_ID',
        sortOrder: 'desc'
      }

      this.subscriptions.push(this.orderSvc.fetchNotEligibleMetricOrderLineDetails(notEligibleRequest)
      .pipe(
        tap((details: MetricSONotEligibleLinesSummary[]) => {
          this.ouxLoggerSvc.logDebug(`MetricSOTransactionLinesSummaryComponent - Tap Details:`, details);
        }),
        catchError((error: Error) => {
          this.ouxLoggerSvc.logDebug('MetricSOTransactionLinesSummaryComponent - Error Transactions:', error);

          return EMPTY;
        }),
        finalize(() => {
          this.loaderSvc.hide(LoadingType.Full);
          // open metric so line transactions modal
          this.metricSOLineTransactionStore.showMetricSOTransactionDetailsModal(line, request.transactionType, this.peType, this.myFlag, this.ampIdentifier);
        })
      )
      .subscribe()
    );
    } else {
      this.subscriptions.push(this.orderSvc.fetchMetricOrderLineDetails(request)
      .pipe(
        tap((details: MetricSOLinesSummary[]) => {
          this.ouxLoggerSvc.logDebug(`MetricSOTransactionLinesSummaryComponent - Tap Details:`, details);
        }),
        catchError((error: Error) => {
          this.ouxLoggerSvc.logDebug('MetricSOTransactionLinesSummaryComponent - Error Transactions:', error);

          return EMPTY;
        }),
        finalize(() => {
          this.loaderSvc.hide(LoadingType.Full);
          // open metric so line transactions modal
          this.metricSOLineTransactionStore.showMetricSOTransactionDetailsModal(line, request.transactionType, this.peType, this.myFlag, this.ampIdentifier);
        })
      )
      .subscribe()
    );
    }
  }

  // css overrides for the commission status header tool tip
  public commissionStatusTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    alignAtCenter: true,
    position: OuxTooltipPosition.Left,
    theme: OuxThemeType.Background,
    cssOverrides: {
      'min-width': '400px',
      'max-width': '600px'
    },
    context: {
      code: null
    }
  });

  public get AllocationTransactionTypeManualRevenue() {
    return AllocationTransactionType.ManualRevenue;
  }
}
