import { Component, EventEmitter, OnInit, Input, SimpleChanges, Output } from '@angular/core';
import { MetricOrderDetails } from 'src/app/shared/models/interface/partials/metric-order-details';
import { OuxTooltipOptions, OuxTooltipPosition, OuxThemeType } from '@cisco/oux-common';
import { OrdersMetricHeaderDetailsRequest } from 'src/app/shared/models/interface/request/orders-metric-header-details-request';
import { Subscription } from 'rxjs';
import { MetricDetailsService } from 'src/app/shared/services/metric-details.service';
import { OrdersMetricHeaderDetailsModel } from 'src/app/shared/models/concrete/partials/orders-metric-header-details.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'metric-transaction-lines-header',
  templateUrl: './metric-transaction-lines-header.component.html',
  styleUrls: ['./metric-transaction-lines-header.component.scss'],
  host: {
    'class':'metric-transaction-lines-header'
  }
})
export class MetricTransactionLinesHeaderComponent implements OnInit {

  @Input('metricTransactionLineDetails')
  public metricTransactionLineDetails: MetricOrderDetails;

  @Input('requestParams')
  public requestParams : OrdersMetricHeaderDetailsRequest;

  @Input('totalRecords')
  public totalRecords : number;

  @Output('myPayoutFlag')
  public myPayoutFlag = new EventEmitter<string>();

  public savId: string;

  public subscriptions: Subscription[] = [];

  public ordersMetricHeaderData : OrdersMetricHeaderDetailsModel;

  public transGroupCode : string = 'iACV';

  public myPYFlag : string;

  public metricIdLabelTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    alignAtCenter: true,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '500px' 
    }
  });

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });
  
  constructor(private metricDetailsService: MetricDetailsService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    let params = this.route.snapshot.queryParamMap;
    this.savId = params.has('SAV_ID')?params.get('SAV_ID'):'NA',
    
    this.subscriptions.push(this.metricDetailsService.fetchOrdersMetricHeaderDetails(this.requestParams).subscribe(details=>{
      
      if(details && details.length>0){
        this.ordersMetricHeaderData = details[0];
        this.myPYFlag = this.ordersMetricHeaderData.MY_FLAG ? this.ordersMetricHeaderData.MY_FLAG : 'N';
        this.myPayoutFlag.emit(this.myPYFlag);

        
        if(this.ordersMetricHeaderData.TRANS_GROUP_CODE && (this.ordersMetricHeaderData.TRANS_GROUP_CODE === "$_RENEWED" || this.ordersMetricHeaderData.TRANS_GROUP_CODE === "$RN" || this.ordersMetricHeaderData.TRANS_GROUP_CODE === 'OT$R')){
          this.transGroupCode = '$RN';
        }
        else{
          this.transGroupCode = '$iACV';
        }
      }
    }));
  };

  public ngOnChanges(changes: SimpleChanges) : void {
    let totalRecordsChange = changes.totalRecords;

    if (totalRecordsChange && totalRecordsChange.currentValue !== totalRecordsChange.previousValue) {
      this.totalRecords = totalRecordsChange.currentValue;
    }
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach( (sub : Subscription) => sub.unsubscribe());
    this.subscriptions = [];
  }

}
