/* Angular Imports */
import { NgModule }                    from '@angular/core';
import {
  Routes,
  RouterModule }                       from '@angular/router';
import { IsAdminGuard } from '../guards/activate/is-admin.guard';
/* Feature Imports */
import { InterfaceComponent }          from './interface.component';


/**
 * Define Interface Routing Instructions
 */
const INTERFACE_ROUTES: Routes = [

  {
    path: '',
    component: InterfaceComponent,
    children: [
      {
        canActivate: [],
        path: 'backlog',
        loadChildren: () => import('./../routes/backlog/backlog.module').then(m => m.BacklogModule)
      },
      {
        canActivate: [IsAdminGuard],
        path: 'manage-banners',
        loadChildren: () => import('./../routes/manage-banners/manage-banners.module').then(m => m.ManageBannersModule)
      },
      {
        canActivate: [IsAdminGuard],
        path: 'manage-user-banners',
        loadChildren: () => import('./../routes/manage-user-banners/manage-user-banners.module').then(m => m.ManageUserBannersModule)
      },
      {
        canActivate: [],
        path: 'export-dashboard',
        loadChildren: () => import('./../routes/export-dashboard/export-dashboard.module').then(m => m.ExportDashboardModule)
      },
      {
        canActivate: [],
        path: 'goal-attainment',
        loadChildren: () => import('./../routes/goal-attainment/goal-attainment.module').then(m => m.GoalAttainmentModule)
      },
      {
        canActivate: [],
        path: 'team-goal-attainment',
        loadChildren: () => import('./../routes/team-goal-attainment/team-goal-attainment.module').then(m => m.TeamGoalAttainmentModule)
      },
      {
        canActivate: [],
        path: 'order-search',
        loadChildren: () => import('./../routes/order-search/order-search.module').then(m => m.OrderSearchModule)
      },
      {
        canActivate: [],
        path: 'payments',
        loadChildren: () => import('./../routes/payments/payments.module').then(m => m.PaymentsModule)
      },
      {
        canActivate: [],
        path: 'goal-to-cash',
        loadChildren: () => import('./../routes/goal-to-cash/goal-to-cash.module').then(m => m.GoalToCashModule)
      },
      {
        canActivate: [],
        path: 'metric-details',
        loadChildren: () => import('./../routes/metric-details/metric-details.module').then(m => m.MetricDetailsModule)
      },
      {
        canActivate: [],
        path: 'metric-order-view',
        loadChildren: () => import('./../routes/metric-order-view/metric-order-view.module').then(m => m.MetricOrderViewModule)
      }
    ]
  }

];


@NgModule({

  imports: [
    RouterModule.forChild(INTERFACE_ROUTES)
  ],

  exports: [
    RouterModule
  ]

})


export class InterfaceRoutingModule { }

/* Export Feature Components */
export const ROUTED_COMPONENTS = [
  InterfaceComponent
];
