import { Injectable } from '@angular/core';
import { Row, Workbook, Worksheet } from 'exceljs';
import * as fs from 'file-saver';
import { ExportCellModel } from './export-cell.model';
import { ExportDataTableModel } from './export-data-table.model';
import { ExportDataModel } from './export-data.model';
import { ExportValueType } from './export-value.type';
import {
  OuxConfigService,
  OuxLoggerService,
  OuxThemeType,
  OuxToastOptions,
  OuxToastService,
  OuxExceptionsHandleError,
  OuxExceptionsHandlerService, OuxAuthenticationService
} from '@cisco/oux-common';
import {PaymentLinesRequest} from "../../models/interface/request/payment-lines-request";
import {Observable, of, Subscription, throwError} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {UserDetailsStore} from "../../stores/user-details.store";
import {MetadataStore} from "../../stores/metadata.store";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ExportDuplicateCheck} from "../../models/interface/partials/export-duplicate-check";
import {ExportType} from "../../models/constants/export-type.enum";
import { AtrExportLabelType } from 'src/app/shared/models/types/atr-export-label-type.enum';



@Injectable({
  providedIn: 'root'
})
export class ExportService {

  public fileName: string;
  private baseUri: string;
  private checkDuplicateRequestUri: string;
  private ouxHandleError: OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('ExportService');

  constructor(private ouxToastSvc: OuxToastService,
              private ouxConfigSvc: OuxConfigService,
              private ouxLoggerSvc: OuxLoggerService,
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private http: HttpClient,
              private ouxAuthSvc: OuxAuthenticationService) {

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.checkDuplicateRequestUri = apiUri.checkDuplicateRequest;
  }

  public exportExcel(data: ExportDataModel | ExportDataModel[], fileName: string): void {
    let workbook = new Workbook();

    let worksheetsData = ((Array.isArray(data) ? data : [data]) || []).filter(x => x != null);

    worksheetsData.forEach(worksheetData => {
      let worksheet = workbook.addWorksheet(worksheetData.worksheetName || fileName);

      this.addTitleRow(worksheet, worksheetData.title);
      // Added for ATR export additional header data - SO, Line and Book Date
      if (worksheetData.orderLineHeaders)
        this.addHeaderRows(worksheet, worksheetData.orderLineHeaders);
      if (worksheetData.orderLineData) {
        this.addDataRows(worksheet, worksheetData.orderLineData);
      }
      this.addHeaderRows(worksheet, worksheetData.headers);
      this.addDataRows(worksheet, worksheetData.data);
      if (worksheetData.ampHeaders) this.addHeaderRows(worksheet, worksheetData.ampHeaders);
      if (worksheetData.ampData) {
        this.addDataRows(worksheet, worksheetData.ampData);
      }
      this.autoAdjustColumnWidth(worksheet);
    });

    this.exportFile(workbook, fileName);
  }

  public exportExcelTable(data: ExportDataTableModel | ExportDataTableModel[], fileName: string): void {
    let workbook = new Workbook();
    let worksheetsData = ((Array.isArray(data) ? data : [data]) || []).filter(x => x != null);

    worksheetsData.forEach(worksheetData => {
      let worksheet = workbook.addWorksheet(worksheetData.worksheetName || fileName);

      (worksheetData.table || []).forEach(table => {
        this.addTitleRow(worksheet, table.title);
        this.addHeaderRows(worksheet, table.headers);
        this.addDataRows(worksheet, table.data);
        if(table.ampHeaders) this.addHeaderRows(worksheet, table.ampHeaders);
        if(table.ampData){
          this.addDataRows(worksheet, table.ampData);
        }
        this.addDataRows(worksheet, [['']])
      });

      this.addHeaderRows(worksheet, worksheetData.totals);

      this.autoAdjustColumnWidth(worksheet);
    });

    this.exportFile(workbook, fileName);
  }




  private showExportNotification(operation: string) : void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'Weekly DrillDown Export',
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }


  public exportFile(workbook: Workbook, fileName: string): void {
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`);
    });
  }

  private addTitleRow(worksheet: Worksheet, titles: ExportValueType[]): void {
    if (!titles || titles.length == 0) {
      return;
    }

    let excelValues = titles.map(x => x instanceof ExportCellModel ? x.value : x);
    let row = worksheet.addRow(excelValues);

    this.formatCells(row, titles);
  }

  private addHeaderRows(worksheet: Worksheet, headers: ExportValueType[][]): void {
    if (!headers || headers.length == 0) {
      return;
    }

    headers.forEach(header => {
      let excelValues = header.map(x => x instanceof ExportCellModel ? x.value : x);
      let row = worksheet.addRow(excelValues);
      
      row.eachCell(cell => {
        if (!cell.value.toString().includes(AtrExportLabelType.ORDER_DETAILS_LABEL)) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' },
            bgColor: { argb: '' }
          }
          cell.font = {
            bold: true,
            size: 12
          }
        }
      });

      this.formatCells(row, header);
    });
  }

  private addDataRows(worksheet: Worksheet, values: ExportValueType[][]): void {
    if (!values || values.length == 0) {
      return;
    }

    values.forEach(value => {
      let excelValues = value.map(x => x instanceof ExportCellModel ? x.value : x);
      let row = worksheet.addRow(excelValues);

      this.formatCells(row, value);
    });
  }

  private formatCells(excelRow: Row, values: ExportValueType[]): void {
    values.forEach((value, index) => {
      if (value instanceof ExportCellModel) {
        let cell = excelRow.getCell(index + 1);
        if (cell) {
          value.mapToCell(cell);
        }
      }
    });
  }

  private autoAdjustColumnWidth(worksheet: Worksheet) {
    (worksheet.columns || []).forEach(column => {
      const lengths = column.values.map(v => v?.toString()?.length);
      const maxLength = Math.max(...[15], ...lengths.filter(v => typeof v === 'number'));

      column.width = maxLength < 170 ? maxLength + 2 : 170;
    });
  }


  /*
  * Export Duplicate Request API Call
  * */
  public checkForDuplicateExportRequest(request: any): Observable<ExportDuplicateCheck> {

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();
    request.loggedInUser = this.userDetailsStore.getUserId();

    const duplicateRequestPayload = {
      loginId: request.loginId,
      userId : request.userId,
      employeeId: request.employeeId,
      filters: JSON.stringify(request)
    };

    const url = `${this.baseUri}${this.checkDuplicateRequestUri}`;
    const options = this.getOptions();

    const request$ = this.http.post<any>(url, duplicateRequestPayload, options)
      .pipe(
        switchMap(response => {
          if (!response) {
            return throwError(response);
          }
          return of(response);
        }),
        map(response => {
          return response.data;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('checkForDuplicateExportRequest', error)(error);
        })
      );
    return request$;
  }

  /*
  * Get Header Options to Call Rest API
  * */
  private getOptions(): { headers: HttpHeaders } {

    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
    };

    return OPTIONS;
  }
}
