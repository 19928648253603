import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'trimGoalsheetString',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})
export class TrimGoalsheetStringPipe implements PipeTransform {

  transform(value : string) : string {
    if (!value) {
      return;
    }
    let input = value.substr(value.indexOf(" ") + 1);
    let output = input.substr(input.indexOf(" ") + 1);
    
    return output;
  }

}
