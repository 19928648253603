import { PaymentLines } from "../../interface/partials/payment-lines";
import { RefreshDates } from "../../interface/partials/refresh-dates";
import { DateRangeModel } from "./date-range.model";
import { DirectOrdersModel } from "./direct-orders.model";

export class PaymentLinesModel implements PaymentLines {

  public P_DATE_RANGE: DateRangeModel[];
  public P_DIRECT_ORDERS: DirectOrdersModel[];
  public P_ERROR_MESSAGE: string;
  public P_TOT_RECORDS: number;
  P_REFRESH_DATES: RefreshDates[];

  constructor(args: Partial<PaymentLinesModel>) {
    Object.assign(this, args);

    if (this.P_DATE_RANGE) {
      this.P_DATE_RANGE = this.P_DATE_RANGE.map(x => new DateRangeModel(x));
    }

    if (this.P_DIRECT_ORDERS) {
      this.P_DIRECT_ORDERS = this.P_DIRECT_ORDERS.map(x => new DirectOrdersModel(x));
    }
  }

}