/* Angular Imports */
import { 
  Component, 
  OnInit }                                    from '@angular/core';
/* RxJs Imports */
import {
  Subscription }                              from 'rxjs';
/* OneUX Imports */
import {
  OuxWindowRefService,
  OuxTooltipOptions, 
  OuxTooltipPosition,
  OuxThemeType }                              from '@cisco/oux-common';
import { GoogleAnalyticsService }             from 'src/app/core/services';


@Component({
  selector: 'toolbar-open-case',
  templateUrl: './toolbar-open-case.component.html',
  styleUrls: ['./toolbar-open-case.component.scss'],
  host: {
    'class': 'toolbar-open-case'
  }
})
export class ToolbarOpenCaseComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private subscriptions : Subscription[] = [];
  private windowRef : any;

  public tooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxWindowRefSvc: OuxWindowRefService,
              private googleAnalyticsSvc: GoogleAnalyticsService) {

    // assign our window object reference
    this.windowRef = this.ouxWindowRefSvc.nativeWindow;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  public ngOnDestroy() : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  public goToOpenCaseUrl(event: any) : void {
    this.googleAnalyticsSvc.eventEmitter('click', 'help', 'open case', 1);
    this.openNewWindow('https://customerservice.cloudapps.cisco.com');
  }


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private openNewWindow(url : string) : void {
    /**
     * Added rel="noreferrer noopener" to external links due to potential security/perfomance issues. 
     * Details here under "target" attribute: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
     */
    this.windowRef.open(url, '_blank', 'noopener,noreferrer');
  }

}
