import {
  HttpClient,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  combineLatest,
  Observable,
  of,
  Subject,
  throwError
} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxAuthenticationService,
  OuxConfigService,
  OuxLoggerService
} from '@cisco/oux-common';
import { UserDetailsStore } from '../stores/user-details.store';
import { PaymentLinesStore } from '../stores/payment-lines.store';
import { PaymentLinesRequest } from '../models/interface/request/payment-lines-request';
import { PaymentLines } from '../models/interface/partials/payment-lines';
import { PaymentLinesResponse } from '../models/interface/response/payment-lines-response';
import { PaymentLinesModel } from '../models/concrete/partials/payment-lines.model';
import { DirectOrdersModel } from '../models/concrete/partials/direct-orders.model';
import { MetadataStore } from '../stores/metadata.store';
import { PaymentAdjustmentLinesRequest } from '../models/interface/request/payment-adjustment-lines-request';
import { PaymentAdjustmentLines } from '../models/interface/partials/payment-adjustment-lines';
import { PaymentAdjustmentLinesResponse } from '../models/interface/response/payment-adjustment-lines-response';
import { PaymentAdjustmentLinesCountRequest } from '../models/interface/request/payment-adjustments-count-request';
import { PaymentAdjustmentLinesCountResponse } from '../models/interface/response/payment-adjustment-lines-count-response';
import { PaymentLinesCountRequest } from '../models/interface/request/payment-lines-count-request';
import { PaymenLinesCountResponse } from '../models/interface/response/payment-lines-count-response';
import { PaymentAdjustmentLinesModel } from '../models/concrete/partials/payment-adjustment-lines.model';
import { OfflinePaymentExportRequest } from '../components/offline-export-modal/models/offline-payment-export-request';
import { BaseResponse } from '../models/interface/response/base-response';
import { PaymentsPeriodTransactionsExportViewModel } from 'src/app/routes/payments/models/payments-period-transactions-export.view-model';
import { OfflineExport } from '../models/interface/response/offline-export-response';
import { VisibilityPageType } from '../models/types/visibility-page-type.enum';



@Injectable({ providedIn: 'root' })
export class PaymentLinesService {

  private baseUri: string;
  private paymentLinesUri: string;
  private paymentAmpLinesUri: string;
  private paymentLinesCountUri: string;
  private paymentAmpLinesCountUri: string;
  private paymentAdjustmentLinesUri: string;
  private paymentAdjustmentLinesCountUri: string;

  private offlineExportUri: string;

  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError: OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('PaymentLinesService');

  constructor(private http: HttpClient,
    private ouxExceptionsSvc: OuxExceptionsHandlerService,
    private ouxAuthSvc: OuxAuthenticationService,
    private ouxConfigSvc: OuxConfigService,
    private ouxLoggerSvc: OuxLoggerService,
    private userDetailsStore: UserDetailsStore,
    private metadataStore: MetadataStore,
    private paymentLinesStore: PaymentLinesStore) {


    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.paymentLinesUri = apiUri.paymentLines;
    this.paymentLinesCountUri = apiUri.paymentLinesCount;
    this.paymentAmpLinesUri = apiUri.paymentAmpLines;
    this.paymentAmpLinesCountUri = apiUri.paymentAmpLinesCount;
    this.paymentAdjustmentLinesUri = apiUri.paymentAdjustmentLines;
    this.paymentAdjustmentLinesCountUri = apiUri.paymentAdjustmentLinesCount;

    this.offlineExportUri = apiUri.orderExport;
  }


  ////////////////////////////////////////////////
  // Search Payment Lines - Default Request
  ////////////////////////////////////////////////

  /**
   * Search Lines
   * Default request method triggered by search() event
   * located in the payments-transactions.component.html template
   * Callers: PaymentsTransactionsComponent
   * @param request PaymentLinesRequest
   * @returns PaymentLines Response Object
   */
  public searchLines(request: PaymentLinesRequest, page: string): Observable<PaymentLines> {

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.fetchPaymentLines(request, true, page)
      .pipe(
        tap((collection: PaymentLines) => {
          // pass data to corresponding payment lines store for further processing
          this.paymentLinesStore.setLines(collection);
          this.ouxLoggerSvc.logDebug('PaymentLinesService: searchLines()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('searchLines', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Export - Fetch Payment Lines
   * Default request method triggered by loadPeriodTransactions() event
   * Callers: PaymentsPeriodTransactionsExportNotificationComponent
   * @param request PaymentLinesRequest
   * @returns PaymentLines Response Object
   */
  public fetchPaymentLinesExportData(request: PaymentLinesRequest, page): Observable<PaymentLines> {

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.fetchPaymentLines(request, false, page)
      .pipe(
        tap((collection: PaymentLines) => {
          this.ouxLoggerSvc.logDebug('PaymentLinesService: searchLines()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('searchLines', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Fetch Payment Lines - Default Method
   * Callers: fetchPaymentLinesExportData() | searchLines()
   * @param request PaymentLinesRequest
   * @returns PaymentLines Response Object
   */
  private fetchPaymentLines(request: PaymentLinesRequest, loadCount: boolean, page: string): Observable<PaymentLines> {

    const URL = page === 'AMP' ? `${this.baseUri}${this.paymentAmpLinesUri}` : `${this.baseUri}${this.paymentLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let countNotifier = new Subject<void>();

    const REQUEST$ = combineLatest([
      this.http.post<PaymentLinesResponse>(URL, request, OPTIONS)
        .pipe(
          tap(records => {
            if ((records?.data?.P_DIRECT_ORDERS?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
      loadCount ? this.fetchPaymentLinesCount(request, page)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
        : of(0)
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(new PaymentLinesModel({ ...response.data, ...{ P_TOT_RECORDS: count || response.data?.P_DIRECT_ORDERS?.length || 0 } }));
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchPaymentLines', error)(error);
        })
      );

    return REQUEST$;
  }


  /**
   * Fetch Payment Lines Count - Default Method
   * Callers: fetchPaymentLines()
   * @param request PaymentLinesRequest
   * @returns PaymentLines Response Object
   */
  private fetchPaymentLinesCount(request: PaymentLinesCountRequest, page: string): Observable<number> {

    const URL = page === 'AMP' ? `${this.baseUri}${this.paymentAmpLinesCountUri}` : `${this.baseUri}${this.paymentLinesCountUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<PaymenLinesCountResponse>(URL, request, OPTIONS)
      .pipe(
        map((response: PaymenLinesCountResponse) => {
          return response?.data?.P_TOT_RECORDS || 0;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchCount', error)(error);
        })
      );

    return REQUEST$;
  }


  ////////////////////////////////////////////////
  // Search Payment Lines - Adjustments Request
  ////////////////////////////////////////////////


  /**
   * Search Adjustment Lines
   * Triggered by: Payments Transactions Filter 
   * How: When a user selects either Revenue Adjustment or Manual Revenue
   * Caller: PaymentsTransactionsComponent
   * @param request PaymentAdjustmentLinesRequest
   * @returns Payment Adjustment Lines Response Object
   */
  public searchAdjustmentLines(request: PaymentAdjustmentLinesRequest): Observable<PaymentAdjustmentLines> {

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.fetchAdjustmentLines(request, true)
      .pipe(
        withLatestFrom(this.paymentLinesStore.state$),
        map(([adjustments, lines]) => {
          return {
            adjustments: adjustments,
            ranges: adjustments && adjustments.P_DATE_RANGE ? adjustments.P_DATE_RANGE : []
          };
        }),
        map(({ adjustments, ranges }) => {
          let typed = new PaymentLinesModel({
            P_DATE_RANGE: ranges,
            P_DIRECT_ORDERS: (adjustments.P_REV_ADJ || []).map(x => new DirectOrdersModel({
              AMP_IDENTIFIER: x.AMP_IDENTIFIER,
              ANNUAL_VALUE: x.ANNUAL_VALUE,
              AT_LEVEL_1: null,
              AT_LEVEL_2: null,
              AT_LEVEL_3: null,
              AT_LEVEL_4: null,
              BACKLOG: null,
              BILL_TO_CUSTOMER: null,
              BONUS_PLAN_CODE: null,
              BOOKING: null,
              BP_FLAG: null,
              BUSINESS_UNIT: null,
              BUYING_PROGRAM_NAME: null,
              COMMENTS: null,
              COMM_LINE_TYPE: null,
              COMM_REVENUE: null,
              CONTRACT_END_DATE: null,
              CONTRACT_NUMBER: null,
              CONTRACT_START_DATE: null,
              CONTRACT_TERM: null,
              DEAL_ID: null,
              DISTRIBUTOR_NAME: null,
              ELA_FLAG: null,
              END_CUSTOMER: x.END_USER || x.END_CUSTOMER,
              EXTD_MY_PAYOUT_AMOUNT: null,
              LINE_CREATION_DATE: null,
              LINE_ID: null,
              METRIC_TYPE: null,
              MULTI_FLAG: null,
              MYPEFLAG: null,
              MY_PAYOUT_AMOUNT: null,
              MY_PAYOUT_FACTOR: null,
              OFFER_NAME: null,
              OFFER_TYPE: null,
              OFFER_TYPE_NAME: null,
              ORDER_NUMBER: x.ORDER_NUMBER,
              PART_NUMBER: null,
              PO_NUMBER: x.PO_NUMBER,
              PRODUCT_FAMILY: null,
              PRODUCT_SPLIT_PERCENT: null,
              QUANTITY: null,
              RESELLER_NAME: null,
              REV_MULTIPLIER_FACTOR: null,
              SAAS_FLAG: null,
              SALES_MOTION: null,
              SALES_VALUE: x.SALES_VALUE,
              SALES_VALUE_MULTIPLIED: null,
              SELL_TYPE: null,
              SEQUENCE_ID: null,
              SERVICE_TYPE: null,
              SHIP_TO_CUSTOMER: null,
              SOURCE_TRANSACTION_DATE: x.SOURCE_TRANSACTION_DATE,
              SPLIT_AGENT: null,
              SPLIT_EFFECTIVE_DATE: null,
              SPLIT_PERCENT: null,
              TERRITORY: x.TERRITORY_CODE || x.TERRITORY,
              TOTAL_VALUE: x.TOTAL_VALUE,
              TRANSACTION_CREATION_DATE: x.CREATION_DATE,
              TRANSACTION_DATE: null,
              TRANSACTION_NUMBER: null,
              TRANS_DAILY_SUMM_ID: null,
              TRANS_GROUP_CODE: null,
              WPA_FLAG: null,
              TYPE: x.TYPE,
              NEW_LOGO_AMOUNT: x.NEW_LOGO_AMOUNT,
              UPSELL_AMOUNT: x.UPSELL_AMOUNT,
              CROSS_SELL_AMOUNT: x.CROSS_SELL_AMOUNT,
              IACV_AMOUNT: x.IACV_AMOUNT,
              RENEWED_AMOUNT: x.RENEWED_AMOUNT,
              NON_COMM_BOOKING: x.NON_COMM_BOOKING,
              CREATION_DATE: x.CREATION_DATE, // for Aug 2023 Release
              CURRENCY_CODE: x.BASE_CURRENCY_CODE
            })),
            P_ERROR_MESSAGE: adjustments.P_ERROR_MESSAGE,
            P_TOT_RECORDS: adjustments.P_TOT_RECORDS
          });

          return typed;
        }),
        tap((collection: PaymentLines) => {
          // pass data to corresponding payment lines store for further processing
          this.paymentLinesStore.setLines(collection);
          this.ouxLoggerSvc.logDebug('PaymentLinesService: searchAdjustmentLines()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('searchLines', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Export - Fetch Adjustment Lines
   * Triggered by: Payments Transactions Filter 
   * How: When a user selects either Revenue Adjustment or Manual Revenue
   * Caller: PaymentsPeriodTransactionsExportNotificationComponent
   * @param request PaymentAdjustmentLinesRequest
   * @returns Payment Adjustment Lines Response Object
   */
  public fetchPaymentAdjustmentLinesExportData(request: PaymentAdjustmentLinesRequest): Observable<PaymentAdjustmentLines> {

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.fetchAdjustmentLines(request, false)
      .pipe(
        tap((collection: PaymentAdjustmentLines) => {
          this.ouxLoggerSvc.logDebug('PaymentLinesService: fetchPaymentAdjustmentLinesExportData()', collection);
        }),
        catchError(error => {
          // create operation mapping for http exception handling 
          return this.ouxHandleError('searchLines', error)(error);
        })
      );

    return REQUEST$;
  }


  /**
   * Fetch Payment Adjustment Lines
   * Caller: searchAdjustmentLines
   * @param request PaymentLinesRequest
   * @returns PaymentLines Response Object
   */
  private fetchAdjustmentLines(request: PaymentAdjustmentLinesRequest, loadCount: boolean): Observable<PaymentAdjustmentLines> {

    const URL = `${this.baseUri}${this.paymentAdjustmentLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    let countNotifier = new Subject<void>();

    const REQUEST$ = combineLatest([
      this.http.post<PaymentAdjustmentLinesResponse>(URL, request, OPTIONS)
        .pipe(
          tap(records => {
            if ((records?.data?.P_REV_ADJ?.length || 0) == 0) {
              countNotifier.next();
            }
          })
        ),
      loadCount ? this.fetchAdjustmentLinesCount(request)
        .pipe(
          takeUntil(countNotifier),
          defaultIfEmpty(0),
          catchError(() => of(null))
        )
        :
        of(0)
    ])
      .pipe(
        switchMap(([response, count]) => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(new PaymentAdjustmentLinesModel({ ...response.data, ...{ P_TOT_RECORDS: count || response.data?.P_REV_ADJ?.length || 0 } }));
        }),
        tap(adjustments => {
          this.paymentLinesStore.setAdjustmentDetails(adjustments); // not sure about this one
        })
      );

    return REQUEST$;
  }

  /**
   * Fetch Adjustment Lines Count - Default Method
   * Callers: fetchAdjustmentLines()
   * @param request PaymentAdjustmentLinesCountRequest
   * @returns PaymentLines Count Response Object
   */
  private fetchAdjustmentLinesCount(request: PaymentAdjustmentLinesCountRequest): Observable<number> {

    const URL = `${this.baseUri}${this.paymentAdjustmentLinesCountUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<PaymentAdjustmentLinesCountResponse>(URL, request, OPTIONS)
      .pipe(
        map((response: PaymentAdjustmentLinesCountResponse) => {
          return response?.data?.P_TOT_RECORDS || 0;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('searchAdjustmentLinesCount', error)(error);
        })
      );

    return REQUEST$;
  }

  public queueOfflineExport(request: OfflinePaymentExportRequest, page: VisibilityPageType): Observable<number> {

    const URL = `${this.baseUri}${this.offlineExportUri}`;
    const OPTIONS = this.getOptions();

    let newRequest: any = {};

    //if (request instanceof PaymentsPeriodTransactionsExportViewModel) { // commenting this condition for May 2023 release as this check is not required  - request.filters is available in the other instances as well

      newRequest = {
        employeeId: this.metadataStore.getMetadataEmployeeId(),
        loginId: this.userDetailsStore.getUserId(),
        userId: this.userDetailsStore.getImpersonationUserId(),
        filters: request.filters,
        sourceSystem: 'Visibility',
        type: 'Revenue',
        page: page
      };

      newRequest.filters.employeeId = this.metadataStore.getMetadataEmployeeId();
      newRequest.filters.loginId = this.userDetailsStore.getUserId();
      newRequest.filters.loggedInUser = this.userDetailsStore.getUserId();

      if (newRequest.filters.erpPosFlag === 'ManualAdjRev' || newRequest.filters.erpPosFlag === 'revAdj') {
        newRequest.filters.adjustmentType = newRequest.filters.erpPosFlag;
        newRequest.filters.erpPosFlag = null;
      }

      newRequest.filters = JSON.stringify(newRequest.filters);
    //}

    const REQUEST$ = this.http.post<OfflineExport>(URL, newRequest, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response: OfflineExport) => {
          let typed = response?.data?.SEQUENCE_ID ;
          return typed;
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('queueOfflineExport', error)(error);
        })
      );

    return REQUEST$;
  }


  /**
   * Stages our Http Request Headers
   */
  private getOptions(): { headers: HttpHeaders } {

    const OPTIONS: { headers: HttpHeaders } = {
      headers: new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json')
    };

    return OPTIONS;
  }


}