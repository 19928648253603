import { CXUpsell, FiscalWeek, GoalingPeriod, GoalToCashSavWeeklyDropdownFilterCriteria,
  Header,
  l_BE_SubBE_PID,
  SavName,
  Territory

} from "../../interface/partials/goal-to-cash-sav-weekly-dropdown-filter-criteria";

export class GoalToCashSavWeeklyDropdownFilterModel implements GoalToCashSavWeeklyDropdownFilterCriteria {
  l_BE_SubBE_PID: l_BE_SubBE_PID[];
  P_ERROR_MESSAGE: string;
  l_header: Header[];
  l_goaling_period: GoalingPeriod[];
  l_territory: Territory[];
  l_sav_name: SavName[];
  l_fiscal_week: FiscalWeek[];
  l_CX_Upsell: CXUpsell[];

  constructor(args : Partial<GoalToCashSavWeeklyDropdownFilterModel>) {
    Object.assign(this, args);
  }
}