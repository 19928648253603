import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'roundUpDecimals',
  pure: true // enables synchronous filter - Note: may impact performance if item count is too large 
})
//Round off decimal values
export class RoundUpDecimalPipe implements PipeTransform {

  //round upto two places
  transform(value : number) : number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
     
  }

}
