/* Angular Import */
import { Injectable }             from '@angular/core';
/* Feature Imports */
import { 
  OuxStore,
  OuxLoggerService }              from '@cisco/oux-common';
import { BehaviorSubject } from 'rxjs';
import { map }                    from 'rxjs/operators';
import { OrderLinesSummary }      from '../models/interface/partials/order-lines-summary';
import { PagedResponse }          from '../models/interface/partials/paged-response';
import { XaasOrderLinesSummary } from '../models/interface/partials/xaas-order-lines-summary';

/**
 * Creates our OrdersSearchLines State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class OrderLinesStore extends OuxStore<PagedResponse<OrderLinesSummary>> {
  private xaasOrderLinesSubject = new BehaviorSubject<PagedResponse<XaasOrderLinesSummary>>(null);
  private xaasOrderLinesCountSubject = new BehaviorSubject<number>(0);

  public xaasOrderLines$ = this.xaasOrderLinesSubject.asObservable();
  public xaasOrderLinesCount$ = this.xaasOrderLinesCountSubject.asObservable()
    .pipe(
      map(count => count || 0)
    );

  public orderLines$ = this.state$;

  public orderLinesCount$ = this.state$
    .pipe(
      map(pagedResponse => pagedResponse && pagedResponse.total ? pagedResponse.total : 0)
    );

  constructor(private ouxLoggerSvc: OuxLoggerService) {

    super (null);
  }

  public setLines(orders: PagedResponse<OrderLinesSummary>) : void {

    super.setState(orders);
    this.ouxLoggerSvc.logDebug('OrderLinesStore - PagedResponse<OrderSummary>:', this.state);
  }

  public setXaasLines(orders: PagedResponse<XaasOrderLinesSummary>): void {
    this.xaasOrderLinesSubject.next(orders);

    this.setXaasLinesCount(orders.total);
  }

  public setXaasLinesCount(count: number): void {
    this.xaasOrderLinesCountSubject.next(count);
  }

  /**
   * Clear Order Lines Data Collection
   * ============================================================
   */
   public clearOrderLinesData(): void {
    this.setState(null);
    this.xaasOrderLinesSubject.next(null);
    this.xaasOrderLinesCountSubject.next(0);
  }

}