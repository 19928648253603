<oux-modal-content-container>
  <oux-modal-content-header [setOptions]="modalHeader"></oux-modal-content-header>
  <oux-modal-content-article>
    <p>{{ modalContent?.content }}</p>
    <div class="footer">
      <oux-button  
        class="mrg-right-sm" 
        [options]="{ label : 'Cancel', style : 'stateless', dataSelector : 'offline-export-modal-0ICMrrCFOp' }"
        [theme]="'neutral'" 
        (trigger)="onUserClickEvent('cancel')"> 
      </oux-button> 
      <oux-button 
        [options]="{ label : 'Submit Export Request', style : 'flat', dataSelector : 'offline-export-modal-oJalEZeAp7' }" 
        (trigger)="onUserClickEvent('submit')"> 
      </oux-button> 
    </div>
  </oux-modal-content-article>
</oux-modal-content-container>