<button 
  class="btn --stateless"
  [ouxTooltip]="tooltipTemplate"
  [ouxTooltipOptions]="tooltipOpts"
  (click)="goToOpenCaseUrl($event.preventDefault())">
  <i class="icon icon-bug"></i>
</button>

<ng-template #tooltipTemplate>
  <div class="tooltip__text">Open Case</div>
</ng-template>