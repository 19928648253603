/* Angular Imports */
import { 
  Component, 
  Inject, 
  OnInit }                                                from '@angular/core';
/* OneUX Imports */
import { 
  MODAL_DATA, 
  OuxModalService, 
  OuxThemeType, 
  OuxTooltipOptions,
  OuxTooltipPosition}                                     from '@cisco/oux-common';
import { DirectOrders }                                   from 'src/app/shared/models/interface/partials/direct-orders';



@Component({
  selector: 'payments-transactions-details-modal',
  templateUrl: './payments-transactions-details-modal.component.html',
  styleUrls: ['./payments-transactions-details-modal.component.scss'],
  host: {
    'class': 'payments-transactions-details-modal'
  }
})
export class PaymentTransactionsDetailsModalComponent implements OnInit {

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxModalSvc: OuxModalService,
              @Inject(MODAL_DATA) public data : DirectOrders) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeModal() : void {
    this.ouxModalSvc.closeModal('Closed - Payment Transaction Details Modal');
  }

}


