/* Angular Imports */
import { HttpClient }          from '@angular/common/http';
/* Translate Imports */
import { TranslateLoader }     from '@ngx-translate/core';
/* RxJs Imports */
import { forkJoin }            from "rxjs";
import { map }                 from 'rxjs/operators';

/**
 * This multi-translation-http-loader service supports and manages multiple layer translations.
 * This means we can maintain two sets of translations, one for core and the other for more
 * application specific translations. When building the app, it will use a CLI script to copy all the
 * Core translation in the root directory of said application.
 *
 * See glob strategy via angular-cli.json
 *
 * Example: Location app.module
 *
 *  {
 *    prefix: "./assets/i18n/core/",
 *    suffix: ".json"
 *  },
 *  {
 *    prefix: "./assets/i18n/",
 *    suffix: ".json"
 *  }
 */
export class MultiTranslateHttpLoader implements TranslateLoader {

  constructor(
    private http: HttpClient,
    public resources : { prefix : string, suffix : string }[] = [{
      prefix: '/assets/',
      suffix: '.json'
    }]
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string) : any {

    return forkJoin(this.resources.map( config => {
      return this.http.get(`${config.prefix}${lang}${config.suffix}`);
    }))
    .pipe(
      map( response => {
        return response.reduce((a, b) => {
          return Object.assign(a, b);
        });
      })
    );
  }
}
