import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OuxAuthenticationService, OuxConfigService, OuxExceptionsHandleError, OuxExceptionsHandlerService, OuxLoggerService } from '@cisco/oux-common';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { QuotaDetailsModel } from '../models/concrete/partials/get-e2e-quota-acv.model';
import { QuotaDetails } from '../models/interface/partials/quota-details';
import { QuotaDetailsResponse } from '../models/interface/response/quota-details-response';
import { QuotaDetailsStore } from '../stores/quota-details.store';
import { UserDetailsStore } from '../stores/user-details.store';

@Injectable({ providedIn: 'root' })
export class QuotaDetailsService {
  private baseUri: string;
  private quotaDetailsUri: string;

  private ouxHandleError: OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('QuotaDetailsService');

  constructor(
    private http: HttpClient,
    private ouxExceptionsSvc: OuxExceptionsHandlerService,
    private ouxAuthSvc: OuxAuthenticationService,
    private ouxConfigSvc: OuxConfigService,
    private ouxLoggerSvc: OuxLoggerService,
    private userDetailsStore: UserDetailsStore,
    private quotaDetailsStore: QuotaDetailsStore
  ) {
    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.quotaDetailsUri = this.ouxConfigSvc.getAppConfigValue('apiUri').e2eQuotaACV;
  }

  public fetchData(): Observable<QuotaDetails> {
    const URL = `${this.baseUri}${this.quotaDetailsUri}`;
    const OPTIONS = this.getOptions();

    const REQUEST$ = this.http.get<QuotaDetailsResponse>(URL, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map( (response : QuotaDetailsResponse) => {          
          let typed = response.data ? new QuotaDetailsModel(response.data) : null;

          return typed;
        }),
        tap( (typed : QuotaDetails) => {
          // pass data to corresponding payment details store for further processing
          this.quotaDetailsStore.setQuotaDetailsData(typed);
          this.ouxLoggerSvc.logDebug('QuotaDetailsService: fetchData()', typed);
        }),
        catchError(error => {
          // create operation mapping for http exception handling
          return this.ouxHandleError('fetchData', error)(error);
        })
      );

    return REQUEST$;
  }

  /**
   * Stages our Http Request Headers
   */
   private getOptions() : { headers: HttpHeaders, params : HttpParams } {

    const OPTIONS : { headers : HttpHeaders, params : HttpParams } = {
      headers : new HttpHeaders()
        .set('Authorization', this.ouxAuthSvc.getAuthToken())
        .append('Content-Type', 'application/json'),
      params : new HttpParams()
        .set('loggedmail', this.userDetailsStore.getUserId())
        .append('loginId', this.userDetailsStore.getUserId())
        .append('empmail', this.userDetailsStore.getImpersonationUserId())
        .append('userName', this.userDetailsStore.getImpersonationUserId())
    };

    return OPTIONS;
  }

}