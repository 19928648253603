/* Angular Imports */
import { NgModule }                                                   from '@angular/core';
import { CommonModule, DecimalPipe }                                  from '@angular/common';
import { TextFieldModule }                                            from '@angular/cdk/text-field';
import {
  FormsModule,
  ReactiveFormsModule }                                               from '@angular/forms';
import { TranslateModule }                                            from '@ngx-translate/core';
/* Highchart Imports */
import { HighchartsChartModule }                                      from 'highcharts-angular';
/* OneUX Imports */
import {
  OuxAuthModule,
  OuxAccordionNavModule,
  OuxAlertModule,
  OuxAutocompleteModule,
  OuxBadgeModule,
  OuxBreadcrumbsModule,
  OuxButtonModule,
  OuxCheckboxModule,
  OuxMultiSelectModule,
  OuxContainerModule,
  OuxDatePickerModule,
  OuxDrawerModule,
  OuxDropdownModule,
  OuxExampleViewerModule,
  OuxFormPartialsModule,
  OuxGaugeModule,
  OuxGenericDropdownModule,
  OuxLoaderModule,
  OuxModalModule,
  OuxModalLayoutModule,
  OuxPaginationModule,
  OuxRadioButtonModule,
  OuxSwitchModule,
  OuxSyntaxHighlighterModule,
  OuxTabsModule,
  OuxTableModule,
  OuxTableLayoutModule,
  OuxTagModule,
  OuxThemeModule,
  OuxToastModule,
  OuxTooltipModule,
  OuxTooltipComponent,
  OuxUtilitiesModule,
  OuxSpinnerModule,
  OuxFilePickerModule
}                                                                   from '@cisco/oux-common';  

/* Global Shared Service Imports */
import { InstanceService }                                          from './services';
import { TranslateUtilsService }                                    from './services';


/* Filters */
import { DateFormatPipe }                                           from './pipes/date-format.pipe';
import { DateTimeFormatPipe }                                       from './pipes/date-time-format.pipe';
import { DateLocalFormatPipe }                                      from './pipes/date-local-format.pipe';
import { DollarFormatPipe }                                         from './pipes/dollar-format.pipe';
import { SafeUrlPipe }                                              from './pipes/safe-url.pipe';
import { StatusFilterPipe }                                         from './pipes/status-filter.pipe';
import { TruncateStringPipe }                                       from './pipes/truncate-string.pipe';
import { TrimStringPipe }                                           from './pipes/trim-string.pipe';
import { ShortNumberPipe }                                          from './pipes/short-number.pipe';
import { MomentFromNowFormatPipe }                                  from './pipes/moment-from-now-format.pipe';
import { DropdownComponent }                                        from './components/dropdown/dropdown.component';
import { DropdownItemComponent }                                    from './components/dropdown/dropdown-item/dropdown-item.component';
import { MomentFormatPipe }                                         from './pipes/moment-format.pipe';
import { ExternalLinkDirective }                                    from './directives/external-link.directive';
import { TrimGoalsheetStringPipe }                                  from './pipes/trim-goalsheet-string.pipe';
import { FiscalWeekFormatPipe }                                     from './pipes/fiscal-week-format.pipe';
import { LeadingZeroToPercentagePipe }                              from './pipes/leading-zero-to-percentage';
import { RoundUpDecimalPipe }                                       from './pipes/round-up-decimal';

/* Components */
import { DirectTransactionLineDetailsModalComponent }               from './components/direct-transaction-lines/partials/direct-transaction-line-details-modal/direct-transaction-line-details-modal.component';
import { DirectTransactionLinesComponent }                          from './components/direct-transaction-lines/direct-transaction-lines.component';
import { DirectTransactionLinesHeaderComponent }                    from './components/direct-transaction-lines/partials/direct-transaction-lines-header/direct-transaction-lines-header.component';
import { DirectTransactionLinesSummaryTableComponent }              from './components/direct-transaction-lines/partials/direct-transaction-lines-summary-table/direct-transaction-lines-summary-table.component';
import { DirectTransactionLinesSummaryTableExportComponent }        from './components/direct-transaction-lines/partials/direct-transaction-lines-summary-table-export/direct-transaction-lines-summary-table-export.component'
import { XaasTransactionLinesComponent }                            from './components/xaas-transaction-lines/xaas-transaction-lines.component';
import { XaasTransactionLinesHeaderComponent }                      from './components/xaas-transaction-lines/partials/xaas-transaction-lines-header/xaas-transaction-lines-header.component';
import { XaasTransactionLinesSummaryTableComponent }                from './components/xaas-transaction-lines/partials/xaas-transaction-lines-summary-table/xaas-transaction-lines-summary-table.component';
import { XaasTransactionLinesSummaryTableExportComponent }          from './components/xaas-transaction-lines/partials/xaas-transaction-lines-summary-table-export/xaas-transaction-lines-summary-table-export.component';
import { XaasTransactionLineDetailsModalComponent }                 from './components/xaas-transaction-lines/partials/xaas-transaction-line-details-modal/xaas-transaction-line-details-modal.component';
import { TransactionLineDetailsGridComponent }                      from './components/transaction-line-details-grid/transaction-line-details-grid.component';
import { POSTransactionDetailsComponent }                           from './components/pos-transaction-details/pos-transaction-details.component';
import { POSTransactionDetailsHeaderComponent }                     from './components/pos-transaction-details/partials/pos-transaction-details-header/pos-transaction-details-header.component';
import { POSTransactionDetailsGridComponent }                       from './components/pos-transaction-details/partials/pos-transaction-details-grid/pos-transaction-details-grid.component';
import { POSTransactionDetailsGridExportComponent }                 from './components/pos-transaction-details/partials/pos-transaction-details-grid-export/pos-transaction-details-grid-export.component';
import { CreditMemoTransactionLinesComponent }                      from './components/credit-memo-transaction-lines/credit-memo-transaction-lines.component';
import { CreditMemoTransactionLinesHeaderComponent }                from './components/credit-memo-transaction-lines/partials/credit-memo-transaction-lines-header/credit-memo-transaction-lines-header.component';
import { CreditMemoTransactionLinesSummaryTableComponent }          from './components/credit-memo-transaction-lines/partials/credit-memo-transaction-lines-summary-table/credit-memo-transaction-lines-summary-table.component';
import { CreditMemoTransactionLinesSummaryTableExportComponent }    from './components/credit-memo-transaction-lines/partials/credit-memo-transaction-lines-summary-table-export/credit-memo-transaction-lines-summary-table-export.component';
import { CreditMemoTransactionLineDetailsModalComponent }           from './components/credit-memo-transaction-lines/partials/credit-memo-transaction-line-details-modal/credit-memo-transaction-line-details-modal.component';
import { OfflineExportModalComponent }                               from './components/offline-export-modal/offline-export-modal.component';
import { MetricTransactionLinesComponent }                          from './components/metric-transaction-lines/metric-transaction-lines.component';
import { MetricTransactionLinesHeaderComponent }                    from './components/metric-transaction-lines/partials/metric-transaction-lines-header/metric-transaction-lines-header.component';
import { MetricTransactionLinesSummaryComponent }                   from './components/metric-transaction-lines/partials/metric-transaction-lines-summary/metric-transaction-lines-summary.component';
import { MetricTransactionLinesSummaryExportComponent }             from './components/metric-transaction-lines/partials/metric-transaction-lines-summary-export/metric-transaction-lines-summary-export.component';
import { MetricSOTransactionLinesComponent }                          from './components/metric-so-transaction-lines/metric-so-transaction-lines.component';
import { MetricSOTransactionLinesHeaderComponent }                    from './components/metric-so-transaction-lines/partials/metric-so-transaction-lines-header/metric-so-transaction-lines-header.component';
import { MetricSOTransactionLinesSummaryComponent }                   from './components/metric-so-transaction-lines/partials/metric-so-transaction-lines-summary/metric-so-transaction-lines-summary.component';
import { MetricSOTransactionLinesSummaryExportComponent }             from './components/metric-so-transaction-lines/partials/metric-so-transaction-lines-summary-export/metric-so-transaction-lines-summary-export.component';
import { MetricSOTransactionLineDetailsModalComponent }               from './components/metric-so-transaction-lines/partials/metric-so-transaction-line-details-modal/metric-so-transaction-line-details-modal.component';
import { MetricSOTransactionLineDetailsGridComponent }                      from './components/metric-so-transaction-line-details-grid/metric-so-transaction-line-details-grid.component';
import { IacvCategoryDetailsTableComponent } from './components/iacv-category-details-table/iacv-category-details-table.component';
import { UserBannerModalComponent } from '../routes/manage-user-banners/partials/user-banner-modal/user-banner-modal.component'    


// imports: imports the module's exports. which are usually
// declarables(components / directives / pipes) and providers.
// in our case the FilterTextModule has a provider.
//
// exports: exports modules AND declarables (components/directives/pipes) that other modules may want to use
// SharedModule does not use CommonModule, but does use FormsModule.
// Even so, we import/export both of these because most other modules will import SharedModule and will need them.
@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    HighchartsChartModule,
    TranslateModule,

    // UI Common Imports
    OuxAlertModule,
    OuxAccordionNavModule,
    OuxAuthModule,
    OuxAutocompleteModule,
    OuxBadgeModule,
    OuxBreadcrumbsModule,
    OuxButtonModule,
    OuxCheckboxModule,
    OuxMultiSelectModule,
    OuxContainerModule,
    OuxDatePickerModule,
    OuxFilePickerModule,
    OuxDrawerModule,
    OuxDropdownModule,
    OuxExampleViewerModule,
    OuxFormPartialsModule,
    OuxGaugeModule,
    OuxGenericDropdownModule,
    OuxLoaderModule,
    OuxModalModule,
    OuxModalLayoutModule,
    OuxPaginationModule,
    OuxRadioButtonModule,
    OuxSpinnerModule,
    OuxSwitchModule,
    OuxSyntaxHighlighterModule,
    OuxTabsModule,
    OuxTableModule,
    OuxTableLayoutModule,
    OuxTagModule,
    OuxThemeModule,
    OuxToastModule,
    OuxTooltipModule,
    OuxUtilitiesModule
  ],

  declarations: [
    DropdownComponent,
    DropdownItemComponent,

    TransactionLineDetailsGridComponent,

    DirectTransactionLinesComponent,
    DirectTransactionLinesHeaderComponent,
    DirectTransactionLinesSummaryTableComponent,
    DirectTransactionLinesSummaryTableExportComponent,
    DirectTransactionLineDetailsModalComponent,

    POSTransactionDetailsComponent,
    POSTransactionDetailsHeaderComponent,
    POSTransactionDetailsGridComponent,
    POSTransactionDetailsGridExportComponent,

    XaasTransactionLinesComponent,
    XaasTransactionLinesHeaderComponent,
    XaasTransactionLinesSummaryTableComponent,
    XaasTransactionLinesSummaryTableExportComponent,
    XaasTransactionLineDetailsModalComponent,

    CreditMemoTransactionLinesComponent,
    CreditMemoTransactionLinesHeaderComponent,
    CreditMemoTransactionLinesSummaryTableComponent,
    CreditMemoTransactionLinesSummaryTableExportComponent,
    CreditMemoTransactionLineDetailsModalComponent,

    OfflineExportModalComponent,

    /* Begin Feature Directives */
    ExternalLinkDirective,

    /* Begin Feature Pipes */
    DateFormatPipe,
    DateTimeFormatPipe,
    DateLocalFormatPipe,
    DollarFormatPipe,
    MomentFormatPipe,
    MomentFromNowFormatPipe,
    SafeUrlPipe,
    ShortNumberPipe,
    StatusFilterPipe,
    TruncateStringPipe,
    TrimStringPipe,
    TrimGoalsheetStringPipe,
    FiscalWeekFormatPipe,
    MetricTransactionLinesComponent,
    MetricTransactionLinesHeaderComponent,
    MetricTransactionLinesSummaryComponent,
    MetricTransactionLinesSummaryExportComponent,
    MetricSOTransactionLinesComponent,
    MetricSOTransactionLinesHeaderComponent,
    MetricSOTransactionLinesSummaryComponent,
    MetricSOTransactionLinesSummaryExportComponent,
    MetricSOTransactionLineDetailsModalComponent,
    MetricSOTransactionLineDetailsGridComponent,
    IacvCategoryDetailsTableComponent,
    LeadingZeroToPercentagePipe,
    RoundUpDecimalPipe,
    UserBannerModalComponent,
  ],

  providers: [
    InstanceService,
    TranslateUtilsService,
    ShortNumberPipe,
    DollarFormatPipe,
    DecimalPipe,
    FiscalWeekFormatPipe,
    RoundUpDecimalPipe
  ],

  entryComponents: [
    OuxTooltipComponent,
    DirectTransactionLineDetailsModalComponent,
    XaasTransactionLineDetailsModalComponent,
    CreditMemoTransactionLineDetailsModalComponent,
    OfflineExportModalComponent,
    MetricSOTransactionLineDetailsModalComponent,
    UserBannerModalComponent
  ],

  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextFieldModule,
    HighchartsChartModule,
    TranslateModule,

    DropdownComponent,
    DropdownItemComponent,

    TransactionLineDetailsGridComponent,

    DirectTransactionLinesComponent,
    DirectTransactionLinesHeaderComponent,
    DirectTransactionLinesSummaryTableComponent,
    DirectTransactionLinesSummaryTableExportComponent,
    DirectTransactionLineDetailsModalComponent,

    POSTransactionDetailsComponent,
    POSTransactionDetailsHeaderComponent,
    POSTransactionDetailsGridComponent,
    POSTransactionDetailsGridExportComponent,

    XaasTransactionLinesComponent,
    XaasTransactionLinesHeaderComponent,
    XaasTransactionLinesSummaryTableComponent,
    XaasTransactionLinesSummaryTableExportComponent,
    XaasTransactionLineDetailsModalComponent,

    CreditMemoTransactionLinesComponent,
    CreditMemoTransactionLinesHeaderComponent,
    CreditMemoTransactionLinesSummaryTableComponent,
    CreditMemoTransactionLinesSummaryTableExportComponent,
    CreditMemoTransactionLineDetailsModalComponent,

    OfflineExportModalComponent,

    MetricTransactionLinesComponent,
    MetricTransactionLinesHeaderComponent,
    MetricTransactionLinesSummaryComponent,
    MetricTransactionLinesSummaryExportComponent,

    MetricSOTransactionLinesComponent,
    MetricSOTransactionLinesHeaderComponent,
    MetricSOTransactionLinesSummaryComponent,
    MetricSOTransactionLinesSummaryExportComponent,
    MetricSOTransactionLineDetailsModalComponent,
    MetricSOTransactionLineDetailsGridComponent,

    IacvCategoryDetailsTableComponent,

    /* Begin UI Common Module Imports */
    OuxAlertModule,
    OuxAccordionNavModule,
    OuxAuthModule,
    OuxAutocompleteModule,
    OuxBadgeModule,
    OuxBreadcrumbsModule,
    OuxButtonModule,
    OuxCheckboxModule,
    OuxMultiSelectModule,
    OuxContainerModule,
    OuxDatePickerModule,
    OuxFilePickerModule,     
    OuxDrawerModule,
    OuxDropdownModule,
    OuxExampleViewerModule,
    OuxFormPartialsModule,
    OuxGaugeModule,
    OuxGenericDropdownModule,
    OuxLoaderModule,
    OuxModalModule,
    OuxModalLayoutModule,
    OuxPaginationModule,
    OuxRadioButtonModule,
    OuxSpinnerModule,
    OuxSwitchModule,
    OuxSyntaxHighlighterModule,
    OuxTabsModule,
    OuxTableModule,
    OuxTableLayoutModule,
    OuxTagModule,
    OuxThemeModule,
    OuxToastModule,
    OuxTooltipModule,
    OuxUtilitiesModule,

    /* Begin Feature Directives */
    ExternalLinkDirective,

    /* Begin Feature Pipes */
    DateFormatPipe,
    DateTimeFormatPipe,
    DateLocalFormatPipe,
    DollarFormatPipe,
    MomentFormatPipe,
    MomentFromNowFormatPipe,
    SafeUrlPipe,
    ShortNumberPipe,
    StatusFilterPipe,
    TruncateStringPipe,
    TrimStringPipe,
    TrimGoalsheetStringPipe,
    FiscalWeekFormatPipe,
    LeadingZeroToPercentagePipe,
    RoundUpDecimalPipe,
    UserBannerModalComponent
  ]

})

export class SharedModule { }
