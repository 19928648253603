import { 
  Component, 
  OnDestroy, 
  OnInit }                                  from '@angular/core';
import { 
  ActivatedRoute, 
  ParamMap, 
  Router }                                  from '@angular/router';
import { 
  Observable, 
  Subscription }                            from 'rxjs';
import { 
  filter, 
  finalize, 
  map,  
  tap }                                     from 'rxjs/operators';
import { OuxLoggerService }                 from '@cisco/oux-common';
import { OrdersLinesRequestModel }          from 'src/app/shared/models/concrete/request/orders-lines-request.model';
import { OrdersLinesRequest }               from 'src/app/shared/models/interface/request/orders-lines-request';
import { OrderService }                     from 'src/app/shared/services/order.service';
import { OrderLinesStore }                  from 'src/app/shared/stores/order-lines.store';
import { UserDetailsStore }                 from 'src/app/shared/stores/user-details.store';
import { LoaderService }                    from 'src/app/core/services';
import { LoadingType }                      from 'src/app/shared/models/constants/loading-type.enum';
import { AmpIdentifierKey }                 from 'src/app/shared/models/types/amp-identifier-key.enum';



@Component({
  selector: 'xaas-transaction-lines',
  templateUrl: './xaas-transaction-lines.component.html',
  styleUrls: ['./xaas-transaction-lines.component.scss'],
  host: {
    'class': 'xaas-transaction-lines'
  }
})
export class XaasTransactionLinesComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private orderLinesRequest$: Observable<OrdersLinesRequest> = this.route.queryParamMap
    .pipe(
      filter((params: ParamMap) => params && params.has('soNumber') 
                              && params.has('planId') 
                              && params.has('srpGoalHeaderId') 
                              && params.has('allocationId') 
                              && params.has('erpPosFlag')
      ),
      map(params => {
        return new OrdersLinesRequestModel({
          soNumber: Number(params.get('soNumber')),
          planId: Number(params.get('planId')),
          srpGoalHeaderId: Number(params.get('srpGoalHeaderId')),
          allocationId: Number(params.get('allocationId')),
          erpPosFlag: params.get('erpPosFlag'),
          limit: 75
        })
      })
    );

  private impersonationChange$ = this.userDetailsStore.impersonationChange$
    .pipe(
      filter(impersonation => impersonation != null),
      tap(() => {
        this.router.navigate(['/goal-attainment'])
      })
    );

  /**
   * Used for XaasTransactionLinesSummaryTableExportComponent
   */
  public exportRequest: OrdersLinesRequest; 
  public showExport: boolean;
  public isNotEligibleOrder: boolean;
  public notEligibleExportRequest;
  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private orderSvc: OrderService,
              private loaderService: LoaderService,
              private orderLinesStore: OrderLinesStore,
              private userDetailsStore: UserDetailsStore,
              private ouxLoggerSvc: OuxLoggerService) {
    
  }

  public ngOnInit(): void {
    let params = this.route.snapshot.queryParamMap;
    this.isNotEligibleOrder = params.has('category') ? (params.get('category') === AmpIdentifierKey.NOTELIGIBLE ? true : false) : false;
    
    if (!this.isNotEligibleOrder) {
      this.subscriptions.push(
        this.orderLinesRequest$.subscribe(request => {
  
          this.ouxLoggerSvc.logDebug('XaasTransactionLinesComponent - Request:', request);
          this.loaderService.show(LoadingType.Table);
  
          // stage export request XaasTransactionLinesSummaryTableExportComponent
          this.exportRequest = OrdersLinesRequestModel.createExportRequest(request);
          
          this.subscriptions.push(this.orderSvc.fetchXaasOrderLines(request)
            .pipe(
              finalize(() => this.loaderService.hide(LoadingType.Table))
            )
            .subscribe()
          );
      }));
    } else {
      let notEligibleRequest = {
        loginId: '',
        userId: '',
        employeeId: '',
        salesOrderNumber: (params && params.has('soNumber')) ? Number(params.get('soNumber')) : '',
        trxType: (params && params.has('type')) ? params.get('type') : '',
        erpPosFlag: (params && params.has('erpPosFlag')) ? params.get('erpPosFlag') : '',
        metricType: (params && params.has('metricType')) ? params.get('metricType') : '',
        limit: 10,
        offset: 0,
        sortBy: 'employee_id',
        sortOrder: 'desc'
      }

      this.loaderService.show(LoadingType.Table);

      this.notEligibleExportRequest = notEligibleRequest;
      
      this.subscriptions.push(this.orderSvc.fetchNotEligibleXaasOrderLines(notEligibleRequest)
        .pipe(
          finalize(() => this.loaderService.hide(LoadingType.Table))
        )
        .subscribe()
      );
    }

    this.subscriptions.push(this.impersonationChange$.subscribe());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];

    this.orderLinesStore.clearOrderLinesData();
  }

}