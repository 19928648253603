import {  
  HttpClient,  
  HttpHeaders }                                           from '@angular/common/http'; 
import { Injectable }                                     from '@angular/core'; 
import {  
  Observable,  
  of,  
  throwError }                                            from 'rxjs'; 
import {  
  catchError, 
  map,  
  switchMap }                                             from 'rxjs/operators';
import {  
  OuxAuthenticationService,  
  OuxConfigService,  
  OuxExceptionsHandleError, 
  OuxExceptionsHandlerService }                          from '@cisco/oux-common'; 

import { XaasOrdersLinesResponse } from 'src/app/shared/models/interface/response/xaas-orders-lines-response';
import { XaasOrderLinesSummaryModel } from 'src/app/shared/models/concrete/partials/xaas-order-lines-summary.model';
import { UserDetailsStore } from 'src/app/shared/stores/user-details.store';
import { MetadataStore } from 'src/app/shared/stores/metadata.store';
import { QuotaDetailsStore } from 'src/app/shared/stores/quota-details.store';
import { OrdersLinesRequest } from 'src/app/shared/models/interface/request/orders-lines-request';
import { XaasOrderLinesSummary } from 'src/app/shared/models/interface/partials/xaas-order-lines-summary';



@Injectable({ providedIn: 'root' })
export class XaasTransactionLinesSummaryTableExportService {

  private baseUri: string;
  private orderSearchLinesUri: string;
  private orderSearchNotEligibleLinesUri: string;

  /** 
   * Create service mapping for http exception handling 
   */ 
   private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('OrderService'); 

   constructor(private http: HttpClient, 
              private ouxAuthSvc: OuxAuthenticationService, 
              private ouxConfigSvc: OuxConfigService, 
              private ouxExceptionsSvc: OuxExceptionsHandlerService, 
              private userDetailsStore: UserDetailsStore,
              private metadataStore: MetadataStore, 
              private quotaDetailsStore: QuotaDetailsStore) {

    let apiUri = this.ouxConfigSvc.getAppConfigValue('apiUri');

    this.baseUri = `${this.ouxConfigSvc.getAppConfigValue('gatewayUri')}${this.ouxConfigSvc.getAppConfigValue('organizationUri')}${this.ouxConfigSvc.getAppConfigValue('apiVersion')}`;
    this.orderSearchLinesUri = apiUri.orderLines;
    this.orderSearchNotEligibleLinesUri = apiUri.notEligibleRegularTransactions;
  }

  /** 
   * Stages our Http Request Headers 
   */ 
   private getOptions() : { headers: HttpHeaders } { 
 
    const OPTIONS : { headers : HttpHeaders } = { 
      headers : new HttpHeaders() 
        .set('Authorization', this.ouxAuthSvc.getAuthToken()) 
        .append('Content-Type', 'application/json') 
    }; 
 
    return OPTIONS; 
  }


  public fetchData(request: OrdersLinesRequest): Observable<XaasOrderLinesSummary[]> {

    const URL = `${this.baseUri}${this.orderSearchLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<XaasOrdersLinesResponse>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || !response.success) {
            return throwError(response);
          }

          return of(response);
        }),
        map((response : XaasOrdersLinesResponse) => {

          let typed = (response?.data?.P_ORDER_SUMMARY || [])
            .map(order => {
              let isACV = this.quotaDetailsStore.isACV(request.allocationId);
              let isMulti = this.quotaDetailsStore.isMulti(request.allocationId, request.planId);
              let updates: Partial<XaasOrderLinesSummaryModel> = {
                BOOKING_AMT: order.COMM_BOOKING
              };

              if (isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.EXTD_MY_PAYOUT_AMOUNT;
              } else if (isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.SALES_VALUE_MULTIPLIED;
              } else if (!isMulti && order.MYPEFLAG == 'Y') {
                order.COMM_REVENUE = order.MY_PAYOUT_AMOUNT;
              } else if (!isMulti && order.MYPEFLAG == 'N') {
                order.COMM_REVENUE = order.COMM_REVENUE;
              }

              // if (!isMulti) {
              //   order.REV_MULTIPLIER_FACTOR = 1;
              // }

              if (isACV) {
                updates.BOOKING_AMT = order.COMM_REVENUE + order.BACKLOG_LINE;
              }

              return new XaasOrderLinesSummaryModel({...order, ...updates});
            });
          
          return typed;
        }),
        catchError(error => { 
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchData', error)(error); 
        }) 
      );

    return REQUEST$;
  }

  public fetchNotEligibleData(request: OrdersLinesRequest): Observable<XaasOrderLinesSummary[]> {

    const URL = `${this.baseUri}${this.orderSearchNotEligibleLinesUri}`;
    const OPTIONS = this.getOptions();

    request.employeeId = this.metadataStore.getMetadataEmployeeId();
    request.loginId = this.userDetailsStore.getUserId();
    request.userId = this.userDetailsStore.getImpersonationUserId();

    const REQUEST$ = this.http.post<XaasOrderLinesSummary[]>(URL, request, OPTIONS)
      .pipe(
        switchMap(response => {
          if (!response || (response?.length > 0 && !response[0])) {
            return throwError(response);
          }

          return of(response);
        }),
        catchError(error => { 
          // create operation mapping for http exception handling 
          return this.ouxHandleError('fetchData', error)(error); 
        }) 
      );

    return REQUEST$;
  }

}