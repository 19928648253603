/* Angular Imports */
import { 
  Component, 
  Inject, 
  OnDestroy, 
  OnInit }                                  from '@angular/core';
import { ActivatedRoute } from '@angular/router';
/* OneUX Imports */
import { 
  MODAL_DATA, 
  OuxColumnSettings, 
  OuxModalService, 
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxThemeType,
  OuxTooltipOptions,
  OuxTooltipPosition }                      from '@cisco/oux-common';
import { Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { XaasOrderLineDetails }             from 'src/app/shared/models/interface/partials/xaas-order-line-details';
import { XaasOrderLinesSummary } from 'src/app/shared/models/interface/partials/xaas-order-lines-summary';
import { QuotaDetailsStore } from 'src/app/shared/stores/quota-details.store';
import { AmpIdentifierKey }                 from 'src/app/shared/models/types/amp-identifier-key.enum';



@Component({
  selector: 'xaas-transaction-line-details-modal',
  templateUrl: './xaas-transaction-line-details-modal.component.html',
  styleUrls: ['./xaas-transaction-line-details-modal.component.scss'],
  host: {
    'class': 'xaas-transaction-line-details-modal'
  }
})
export class XaasTransactionLineDetailsModalComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  private isMulti$ = this.route.queryParamMap
    .pipe(
      map(params => {
        let allocationId = params.has('allocationId') ? parseFloat(params.get('allocationId')) : null;
        let planId = params.has('planId') ? parseFloat(params.get('planId')) : null;

        return allocationId && planId ? this.quotaDetailsStore.isMulti(allocationId, planId) : false;
      }),
      tap(isMulti => this.isMulti = isMulti)
    );

  public isMulti: boolean = false;
  public hasMYPEColumns: boolean = false;
  public isNotEligibleOrder: boolean;

  public tableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--compact',
    formatCells: false,
    tableLayout: 'fixed',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'Offer Attribution not available for this Part Number.',
    trackRecordsProperty: 'PART_NUMBER',
    highlightSelectedRows: false,
    missingDataMessage: '',
    toggleSelected: false
  });

  public columnSettings: OuxColumnSettings[] = [];

  public tableThemeOverrides: OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(
    private route: ActivatedRoute,
    private ouxModalSvc: OuxModalService,
    private quotaDetailsStore: QuotaDetailsStore,
    @Inject(MODAL_DATA) public data : { selectedPartNumber: string, lineDetails: XaasOrderLineDetails, attributions: XaasOrderLinesSummary[] }
  ) {
    this.columnSettings = this.getColumnSettings(this.data?.attributions);
    this.subscriptions.push(this.isMulti$.subscribe());
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void {
    let params = this.route.snapshot.queryParamMap;
    this.isNotEligibleOrder = params.has('category') ? (params.get('category') === AmpIdentifierKey.NOTELIGIBLE ? true : false) : false;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeModal() : void {
    this.ouxModalSvc.closeModal('Closed - XAAS Line Details Modal');
  }

  private getColumnSettings(lines: XaasOrderLinesSummary[]): OuxColumnSettings[] {
    let columnSettings: OuxColumnSettings[] = [
      new OuxColumnSettings({
        header: 'SKU',
        primaryKey: 'PART_NUMBER'
      }),
      new OuxColumnSettings({
        header: '% of Total',
        primaryKey: 'OA_PERCENT',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Bookings',
        primaryKey: 'BOOKING_AMT',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Backlog',
        primaryKey: 'BACKLOG_LINE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue (Original)',
        primaryKey: 'SPLIT_SALES_VALUE',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue (Multiplied)',
        primaryKey: 'REV_MULTI',
        thAlign: 'right',
        tdAlign: 'right'
      }),
      new OuxColumnSettings({
        header: 'Revenue Multiplier',
        primaryKey: 'REV_MULTIPLIER_FACTOR',
        thAlign: 'right',
        tdAlign: 'right'
      })
    ];

    if (lines?.length > 0 && lines[0]?.MYPEFLAG == 'Y') {
      this.hasMYPEColumns = true;

      columnSettings.push(...[
        new OuxColumnSettings({
          header: 'Revenue (MY Payout) Eligible',
          primaryKey: 'MY_PAYOUT_AMOUNT_1',
          thAlign: 'right',
          tdAlign: 'right'
        }),
        new OuxColumnSettings({
          header: 'MY Uplift Factor',
          primaryKey: 'MY_PAYOUT_FACTOR',
          thAlign: 'right',
          tdAlign: 'right'
        })
      ]);
    }

    return columnSettings;
  }
}


