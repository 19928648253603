import { Filters } from '../../interface/partials/filters';
import { OrdersGoalToCashRequest } from '../../interface/request/orders-goal-to-cash-request';

export class OrdersGoalToCashRequestModel implements OrdersGoalToCashRequest {
  public employeeId: string;
  public userId: string;
  public loginId: string;
  public sourceSystem: string;
  public groupBy: string;
  public isPretty: boolean;
  public limit: number;
  public offset: number;
  public sortBy: string;
  public sortOrder: string;
  public srpGoalHeaderId: number;
  public srpGoalQuotaId: number;
  public soNumber: number;
  public territory: string;
  public filters: Filters[];
  public metricId: string;
  public salesOrderNumber: number;
  public batch: boolean = false;
  public batchId?: number;
  public allocationId: number;
  public planId: number;
  public requestedExportCategory: string;
  public peType: string;
  public ACVFlag: boolean;
  public MYPEFlag: boolean;
  public MultiFlag: boolean;
  public MyPayoutFlag: boolean;
  public ampFlag: boolean;
  public flow: string;
  public trxType: string;
  public exportNode: string;
  public level: string;
  public myFlag: string;
  public metricFlag: string;
  public calcEngine: string;
  public ampIdentifier: string;
  public RTMType: string;
  public page: string;
  public longCategory: string;
  public goalSheetStartDate: string;
  public exportType: string;

  constructor(options: Partial<OrdersGoalToCashRequestModel>) {
    Object.assign(this, options);
  }

  public static createExportRequest(options: Omit<Partial<OrdersGoalToCashRequest>, 'limit' | 'offset'>): OrdersGoalToCashRequest {
    return new OrdersGoalToCashRequestModel({ ...options, ...{ limit: 1000000, offset: 0 } });
  }

  public static setFiltersForRequest(options: Omit<Partial<OrdersGoalToCashRequest>, 'filters' | 'batchId' | 'batch' | 'RTMType'>, filters: Filters[], batchId: number, batch: boolean, RTMType: string): OrdersGoalToCashRequest {
    return new OrdersGoalToCashRequestModel({ ...options, ...{ filters: filters }, batchId, batch, RTMType });
  }

}