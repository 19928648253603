/* Angular Imports */
import { Injectable }               from '@angular/core';
/* Package Imports */
import { OuxLoggerService }         from '@cisco/oux-common';


@Injectable()
export class InstanceService {

  constructor(private loggerSvc : OuxLoggerService) { }

  public instanceMountedAt(name : string, selector : string) : void {
    const date : string = new Date().toLocaleTimeString();
    this.loggerSvc.logDebug(`${name} mounted at ${date}: `, { selector : `<${selector}>`} );
  }

}
