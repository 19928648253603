/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy, 
  forwardRef }                           from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouterEvent,
  ActivatedRoute}                        from '@angular/router';
import { Subscription }                  from 'rxjs';
/* OneUX Imports */
import {
  OuxRouterService,
  OuxLoggerService,
  OuxConfigService,
  OuxWindowRefService, 
  OUX_THEMEABLE_COMPONENT, 
  OuxBreadcrumbModel}                    from '@cisco/oux-common';
/* Feature Imports */
import { GoogleAnalyticsModel }          from 'src/app/shared/models/concrete/google-analytics.model';
import { DynamicScriptLoaderService }    from 'src/app/core/services/dynamic-script-loader.service';
import { AppDynamicsService }            from 'src/app/core/services/app-dynamics.service';
import { InstanceService }               from './shared/services';
import { filter, map, mergeMap }         from 'rxjs/operators';
import { BreadcrumbsStore }              from './layout/breadcrumbs/breadcrumbs.store';

declare let gtag : Function;

@Component({
  selector: 'app-shell',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  host: {
    'class': 'app-shell'
  },
  providers: [
    {
      provide: OUX_THEMEABLE_COMPONENT,
      useExisting: forwardRef(() => AppComponent),
      multi: false
    }
  ]
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions : Subscription[] = [];
  private trackId : GoogleAnalyticsModel;

  /**
   * Represents native window object
   */
  private windowRef : any;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private router : Router,
              private ouxConfigSvc : OuxConfigService,
              private ouxRouterSvc : OuxRouterService,
              private ouxLoggerSvc : OuxLoggerService,
              private ouxWindowRefSvc : OuxWindowRefService,
              private scriptLoaderSvc : DynamicScriptLoaderService,
              private appDynamicsSvc : AppDynamicsService,
              private instanceSvc : InstanceService,
              private route: ActivatedRoute,
              private breadcrumbsStore: BreadcrumbsStore) {

    this.windowRef = this.ouxWindowRefSvc.nativeWindow;

    // Initializes the route history feature
    this.ouxRouterSvc.loadRouteHistory();
    // Initializes the dynamic title builder feature
    this.ouxRouterSvc.initDynamicTitleBuilder();
    // Retrieve GA environment specific tracking id
    this.trackId = this.ouxConfigSvc.getAppConfigValue('googleAnalyticsId');

    // Initializes Google Analytics Tagging
    this.subscriptions.push(
      this.router.events.subscribe( (event : RouterEvent) => {
        if (event instanceof NavigationEnd) {
          gtag('config', this.trackId, { 'page_path': event.urlAfterRedirects });
        }
      })
    );

    // Appends adrum-config to the native window object
    this.appDynamicsSvc.insertWindowConfig();

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.updateBreadcrumbsBasedOnRouteData();

    // Load external scripts
    this.loadScripts();

    this.instanceSvc.instanceMountedAt('AppComponent', 'app-shell');

    /**
     * Return the lastest route tree
     */
    this.subscriptions.push(
      this.ouxRouterSvc.getRouteHistory()
        .subscribe(
          (history : string) => {
          this.ouxLoggerSvc.logDebug('Route History:', history);
      })
    );

    /**
     * Returns a boolean
     * Title changed due to route changes (true/false)
     */
    this.subscriptions.push(
      this.ouxRouterSvc.routeTitleChanged()
        .subscribe(
          (value : boolean) => {
          this.ouxLoggerSvc.logDebug('Route Changed:', value);
        })
    );

  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  /**
   * Responsible for loading external scripts into our index.html file (dynamically)
   */
  private loadScripts() : void {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.scriptLoaderSvc.load('gtagJS')
      .then(_ => {
        this.ouxLoggerSvc.logInfo('Google Analytic: gtagJS loaded successfully');
      })
      .catch( (error : Error) => this.ouxLoggerSvc.logDebug('Encountered issue loading gtagJS. Message:', error));

    // if adrum-config exists as part of the native window object and has appKey for corresponding environment
    if (this.windowRef['adrum-config'] && this.windowRef['adrum-config'].appKey) {
      this.scriptLoaderSvc.load('appDynamicJS')
        .then(_ => {
          this.ouxLoggerSvc.logInfo('App Dynamics: appDynamicJS loaded successfully');
        })
        .catch( (error : Error) => this.ouxLoggerSvc.logDebug('Encounter issue loading appDynamicJS. Message:', error));
    }


    /////////////////////////////////////////////////////////////
    // Load Chatbot Dependancies
    /////////////////////////////////////////////////////////////

    /* Commenting it out as Dynamic Loading is not triggering the chatBot
    this.scriptLoaderSvc.load('chatBotMashupJS')
      .then(_ => {
        this.ouxLoggerSvc.logInfo('ChatBot Mashup: chatBotMashupJS loaded successfully');
      })
      .catch( (error : Error) => this.ouxLoggerSvc.logDebug('Encountered issue loading chatBotMashupJS. Message:', error));


    this.scriptLoaderSvc.load('chatBotPegaHelperJS')
      .then(_ => {
        this.ouxLoggerSvc.logInfo('ChatBot Pega Helper: chatBotPegaHelperJS loaded successfully');
      })
      .catch( (error : Error) => this.ouxLoggerSvc.logDebug('Encountered issue loading chatBotPegaHelperJS. Message:', error));

  
    this.scriptLoaderSvc.load('chatBotPegaHelpExtensionJS')
      .then(_ => {
        this.ouxLoggerSvc.logInfo('ChatBot Pega Helper Extension: chatBotPegaHelpExtensionJS loaded successfully');
      })
      .catch( (error : Error) => this.ouxLoggerSvc.logDebug('Encountered issue loading chatBotPegaHelpExtensionJS. Message:', error));
    */
    /////////////////////////////////////////////////////////////

  }

  private updateBreadcrumbsBasedOnRouteData(): void {
    this.subscriptions.push(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(data => {
        if (data.breadcrumbs) {
          this.breadcrumbsStore.setBreadcrumbs(data.breadcrumbs ? data.breadcrumbs.map(x => new OuxBreadcrumbModel(x)) : []);
        }
      })
    );
  }

}
