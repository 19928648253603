import { DirectTransactionLinesSummaryTableExportRequest } from "./direct-transaction-lines-summary-table-export-request";

export class DirectTransactionLinesSummaryTableExportRequestModel implements DirectTransactionLinesSummaryTableExportRequest {

  public loginId: string;
  public sourceSystem: string = 'Visibility';
  public userId: string;
  public employeeId: string;
  public srpGoalHeaderId: number;
  public planId: number;
  public allocationId: number;
  public nodeName: string = '';
  public territoryTypeCode: string = '';
  public erpPosFlag: string;
  public adjustmentType: string;
  public soNumber: number;
  public readonly limit: number;
  public offset: number = 0;
  
  constructor(options: Partial<DirectTransactionLinesSummaryTableExportRequestModel>) {
    Object.assign(this, options);

    this.limit = 1000000;
  }

} 