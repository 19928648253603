/* Angular Import */
import { Injectable }                 from '@angular/core';
import { 
  OuxLoggerService, 
  OuxStore }                          from '@cisco/oux-common';
import { MaskUserDetails }            from '../models/interface/partials/mask-user-details';
import { UserRoleType }               from '../models/types/user-role-type.enum';
import { MASKED_USERS }               from '../models/constants/MaskedUsers';

/**
 * Creates our Demo User State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
 @Injectable({
  providedIn: 'root'
})
export class DemoUserStore extends OuxStore<boolean> {

  constructor(private ouxLoggerSvc: OuxLoggerService) {
    super (null);
  }

  /**
   * Default Method
   * ============================================================
   */

  public setState(state: boolean): void {
    super.setState(state);
    this.ouxLoggerSvc.logDebug('Set Demo User Experience:', this.state);
  }

  /**
   * Convenience Methods
   * ============================================================
   */

  public resetState(): void {
    this.setState(null);
  }

  public getMaskedUserDetailsByRoleType(role: UserRoleType) : MaskUserDetails {
    let maskedUsers = MASKED_USERS.find(x => x.roleType == role);
    this.ouxLoggerSvc.logDebug('Masked User Details Based On Role Type:', maskedUsers);
    
    return maskedUsers;
  }


}

