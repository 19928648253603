import {
  Pipe,
  PipeTransform } from '@angular/core';


@Pipe({
  name: 'truncateString'
})
/**
 * Filters on inbound resource, creating a new string based on
 * the character limit pass via the consumer.
 * 
 * Ex: Create a new string based on the provided number value assign to the consumer
 *  <span class="quote --id">
 *    Quote #: {{ quote.id | truncate : truncateId : '' }}
 *    <span *ngIf="quote.id.length > truncateId" [ouxTooltip]="quote.id" [ouxTooltipOptions]="{ theme: 'light', position: 'right', enableClick: false }">...</span>
 *  </span>
 */
export class TruncateStringPipe implements PipeTransform {

  transform(text : string, length : number, end : any) : string {

    if (isNaN(length)) {
      length = 20;
    }

    if (end === undefined) {
      end = '...';
    }

    if (text.length <= length || text.length - end.length <= length) {
      return text;
    }
    else {
      return String(text).substring(0, length - end.length) + end;
    }

  }

}
