import { MetricTransactionOrderSearchRequest } from '../../interface/request/metic-transaction-order-search';

export class MetricTransactionOrderSearchModel implements MetricTransactionOrderSearchRequest {

    public loginId: string;
    public userId: string;
    public employeeId: string;
    public metricId: string;
    public salesOrderNumber:string;
    public sourceSystem:string;
    public groupBy: string;
    public limit: number;
    public offset: number;
    public sortBy: string;
    public sortOrder: string;
    public quotaId:string;
    public goalId:string;
    
  constructor(args : Partial<MetricTransactionOrderSearchModel>) {
    Object.assign(this, args);
  } 

}