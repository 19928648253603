import { Injectable }                       from '@angular/core';
import {  
  CanActivate,  
  NavigationExtras,  
  Router }                                  from '@angular/router';
import { 
  Observable, 
  of, 
  Subscriber, 
  throwError }                               from 'rxjs';
import { 
  catchError, 
  map, 
  switchMap, 
  tap }                                      from 'rxjs/operators';
import {
  OuxExceptionsHandlerService,
  OuxExceptionsHandleError,
  OuxWindowRefService,
  OuxLoggerService }                         from '@cisco/oux-common';
import { UserDetails }                       from 'src/app/shared/models/interface/partials/user-details';
import { UserDetailsService }                from 'src/app/shared/services/user-details.service';
import { UserDetailsStore }                  from 'src/app/shared/stores/user-details.store';
import { MetadataService }                   from 'src/app/shared/services/metadata.service';
import { MetadataStore }                     from 'src/app/shared/stores/metadata.store';
import { QuotaDetailsService }               from 'src/app/shared/services/quota-details.service';
import { DemoUserStore }                     from 'src/app/shared/stores/demo-user.store';
import { DEMO_USERS }                        from 'src/app/shared/models/constants/DemoUsers';

@Injectable({ providedIn: 'root' })
export class CanComponentActivateService implements CanActivate {

  private windowRef: any;
  /**
   * Create service mapping for http exception handling
   */
  private ouxHandleError : OuxExceptionsHandleError = this.ouxExceptionsSvc.createHandleError('CanComponentActivateService');

  constructor(private router : Router,
              private ouxExceptionsSvc: OuxExceptionsHandlerService,
              private ouxWindowSvc: OuxWindowRefService,
              private ouxLoggerSvc: OuxLoggerService,
              private userDetailsSvc: UserDetailsService,
              private metadataSvc: MetadataService,
              private quotaDetailsSvc: QuotaDetailsService,
              private metadataStore: MetadataStore,
              private userDetailsStore: UserDetailsStore,
              private demoUserStore: DemoUserStore) {

    this.windowRef = this.ouxWindowSvc.nativeWindow;           
  }
  
  public canActivate(): boolean | Observable<boolean> | Promise<boolean> {

    let observer: Subscriber<boolean>;
    let canActivate$ = new Observable<boolean>(o => { observer = o; });

    let userDetails = this.userDetailsStore.getUserDetailsFromSessionStorage();

    of(userDetails)
      .pipe(
        switchMap(user => {
          // check if details exist in sessions storage
          if (!user || !user.uid) {
            // no - fetch details
            return this.userDetailsSvc.loadUserDetails()
              .pipe(
                tap( (user: UserDetails) => {
                  if (user) {
                    // write user details to corresponding store
                    this.userDetailsStore.setUserDetails(user);
                  } 
                  else {
                    throwError('Unknown Error')
                  }
                })
              );
          }

          return of(user);
        }),
        tap(user => {
          // check if user is part of demoUser list
          if (DEMO_USERS.includes(user.uid)) {
            this.demoUserStore.setState(true);
          } 
        }),
        // if no error's, initialize metadata fetch 
        switchMap(user => 
          // pass impersonated user details as required query param if available
          this.metadataSvc.fetchData(user.impersonation?.emailId || null)
            .pipe(
              tap(metadata => {
                this.metadataStore.setLoggedInUserMetadata(metadata);
              }),
              map(metadata => {
                return ({user, metadata})
              })
            )
        ),
        tap(({user, metadata}) => {
          let name = metadata && metadata.P_EMPINFO && metadata.P_EMPINFO.length > 0 ? metadata.P_EMPINFO[0]?.emp_name : user.uid;
          this.userDetailsStore.setUserFullName(name);
        }),
        switchMap(() => this.quotaDetailsSvc.fetchData()),
        catchError(error => {
          this.onError(error, observer);
          // create operation mapping for http exception handling
          return this.ouxHandleError('canActivate', error)(error);
        })
      )
      .subscribe(() => {
        observer.next(true);
        observer.complete();
      })

    return canActivate$;
  }

  public logout(url : string, extras : NavigationExtras) : void {
    this.windowRef.sessionStorage.clear();
    this.router.navigate([url], extras);
  }


  private onError(response: UserDetails | any, observer: Subscriber<boolean>) : void {
    const URL = this.router.url || '';

    this.ouxLoggerSvc.logDebug('CanComponentActivateService: Failed to retrieve user details', response);

    if (!URL || URL == '/') {
      this.logout('/login', {queryParams: {sessionTimeout: true}});
    } 
    else {
      this.router.navigate(['/unauthorized']);
    }

    observer.next(false);
    observer.complete();
  }

}