/* Angular Imports */
import { 
  Component, 
  Inject, 
  OnDestroy, 
  OnInit }                                  from '@angular/core';
import { ActivatedRoute }                   from '@angular/router';
import { Subscription }                     from 'rxjs';
/* OneUX Imports */
import { 
  MODAL_DATA, 
  OuxColumnSettings, 
  OuxModalService, 
  OuxTableOptions,
  OuxTableThemeOverrides,
  OuxThemeType,
  OuxTooltipOptions,
  OuxTooltipPosition }                      from '@cisco/oux-common';
import { GTCCreditMemoAdjustments }         from 'src/app/shared/models/interface/partials/gtc-credit-memo-adjustments';
import { QuotaDetailsStore }                from 'src/app/shared/stores/quota-details.store';


@Component({
  selector: 'credit-memo-transaction-line-details-modal',
  templateUrl: './credit-memo-transaction-line-details-modal.component.html',
  styleUrls: ['./credit-memo-transaction-line-details-modal.component.scss'],
  host: {
    'class': 'credit-memo-transaction-line-details-modal'
  }
})
export class CreditMemoTransactionLineDetailsModalComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  public tableOptions = new OuxTableOptions({
    tableTitle: '',
    tableType: '--compact',
    formatCells: false,
    tableLayout: 'fixed',
    loading: false,
    spinnerSize: 'medium',
    tooltipTheme: OuxThemeType.Background,
    toolTipTrigger: 'hover',
    disableTooltip: true,
    wrapColumns: true,
    overlayZIndex: 2999,
    noRecordsMessage: 'Offer Attribution not available for this Part Number.',
    trackRecordsProperty: 'PART_NUMBER',
    highlightSelectedRows: false,
    missingDataMessage: '',
    toggleSelected: false
  });

  public columnSettings: OuxColumnSettings[] = [];

  public tableThemeOverrides: OuxTableThemeOverrides = {
    accentColor: '',
    backgroundColor: 'var(--background)',
    backgroundColorHover: 'var(--surface)',
    backgroundColorStriped: 'var(--background)',
    borderColor: 'var(--divider)',
    textColor: 'var(--on-background)'
  };

  public templateTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Top,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  public infoTooltipOptions = new OuxTooltipOptions({
    enableClick: false,
    position: OuxTooltipPosition.Right,
    theme: OuxThemeType.Background
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxModalSvc: OuxModalService,
              @Inject(MODAL_DATA) public data : { selectedPartNumber: string, lineDetails: GTCCreditMemoAdjustments }) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  public closeModal() : void {
    this.ouxModalSvc.closeModal('Closed - Credit Memo Details Modal');
  }

}


