import { Pipe, PipeTransform } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'momentFromNowFormat',
  pure: true
})
export class MomentFromNowFormatPipe implements PipeTransform {
  public transform(value: moment.Moment): Observable<string> {
    let timer$ = timer(0, 10000)
      .pipe(
        map(() => {
          if (typeof value === 'undefined' || value === null || !value.isValid())
            return '';

          return value.fromNow();
        })
      );

    return timer$;
  }
}