import { Component, OnInit, Input } from '@angular/core';
import { MetricOrderDetails } from 'src/app/shared/models/interface/partials/metric-order-details';
import { ExportService } from 'src/app/shared/services/export/export.service';
import { DatePipe } from '@angular/common';
import { OrdersMetricHeaderDetailsRequestModel } from 'src/app/shared/models/concrete/request/orders-metric-header-details-request.model';
import { forkJoin, Subscription } from 'rxjs';
import { GoalToCashService } from 'src/app/shared/services/goal-to-cash.service';
import { WeeklyTransactionSummaryDetails } from 'src/app/shared/models/interface/partials/weekly-transaction-summary-details';
import { BookingsExportRequestModel } from 'src/app/shared/models/concrete/request/bookings-export-details-request.model';
import { MetricsExportRequestModel } from 'src/app/shared/models/concrete/request/metrics-export-details-request.model';
import { OuxThemeType, OuxToastOptions, OuxToastService } from '@cisco/oux-common';
import { BookingsExportDetailsModel } from 'src/app/shared/models/concrete/partials/bookings-export-details.model';
import { MetricsExportDetailsModel } from 'src/app/shared/models/concrete/partials/metrics-export-details.model';
import { ExportDefinitionsModel } from 'src/app/shared/models/concrete/partials/export-definitions.model';
import { ActivatedRoute } from '@angular/router';
import { dollarRenewalArray, iACVArray, otrArray } from 'src/app/shared/models/types/metric-types-array';
import { CurrencyCodeRequestModel } from 'src/app/shared/models/concrete/request/currency-code-request.model';

@Component({
  selector: 'metric-transaction-lines-summary-export',
  templateUrl: './metric-transaction-lines-summary-export.component.html',
  styleUrls: ['./metric-transaction-lines-summary-export.component.scss'],
  host:{
    'class':'metric-transaction-lines-summary-export'
  }
})
export class MetricTransactionLinesSummaryExportComponent implements OnInit {

  @Input('metricTransactionLineDetails')
  public metricTransactionLineDetails: MetricOrderDetails;

  @Input('requestParams')
  public requestParams : OrdersMetricHeaderDetailsRequestModel;

  @Input('totalRecords')
  public totalRecords : number;
  public bookingsExportDetailsModel: BookingsExportDetailsModel[];
  public metricsExportDetailsModel: MetricsExportDetailsModel[];
  public exportDefinitionsModel: ExportDefinitionsModel[];
  public currencyCode: string;

  @Input('myFlag')
  public myFlag : string;

  public subscriptions : Subscription[] = [];

  public exportingToast = new OuxToastOptions({
    icon: 'icon-export',
    header: 'Order Lines Export',
    message: 'Orders are being exported.',
    autoDismiss: false,
    scaleOnHover: false,
    theme: OuxThemeType.Info
  });


  public weeklyTransactionSummaryDetails: WeeklyTransactionSummaryDetails[];

  constructor(
    private exportService:ExportService,
    private datePipe: DatePipe,
    private goalToCashService: GoalToCashService,
    private ouxToastSvc: OuxToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  public exportToExcel(event:any){

    let bookingsRequest = new BookingsExportRequestModel({
      goalID: String(this.requestParams.goalId),
      quotaId: String(this.requestParams.quotaId),
      metricId: this.requestParams.metricId
    });

    let metricsRequest = new MetricsExportRequestModel({
      goalID: String(this.requestParams.goalId),
      quotaId: String(this.requestParams.quotaId),
      metricId: this.requestParams.metricId
    });
    this.ouxToastSvc.addToast(this.exportingToast);

    let currencyCodeRequest = new CurrencyCodeRequestModel({
      goalID: String(this.requestParams.goalId),
      quotaId: String(this.requestParams.quotaId),
    })

    forkJoin({
      metrics: this.goalToCashService.fetchMetricsExportDetails(metricsRequest),
      bookings: this.goalToCashService.fetchBookingsExportDetails(bookingsRequest),
      upsellAdj: this.goalToCashService.fetchExportDefinitionsDetails(null),
      currencyCode: this.goalToCashService.fetchCurrencyCode(currencyCodeRequest)
    }).subscribe(({metrics, bookings, upsellAdj, currencyCode})=>{
      this.metricsExportDetailsModel = metrics;
      this.bookingsExportDetailsModel = bookings;
      this.exportDefinitionsModel = upsellAdj;
      //If currency code doesn't return response then it is a USD currency
      this.currencyCode = currencyCode.CURRENCY!=null ? currencyCode.CURRENCY : 'USD';
      this.export();
    },
      error => {
        this.ouxToastSvc.dismissToast(this.exportingToast);
        this.ouxToastSvc.addToast(new OuxToastOptions({
          icon: 'icon-export',
          header: 'Export',
          message: 'Failed to export orders.',
          autoDismiss: true,
          dismissTimeout: 5,
          scaleOnHover: false,
          theme: OuxThemeType.Error
        }));
      });
  }

  public export(){
    if(this.bookingsExportDetailsModel && this.metricsExportDetailsModel){
      this.ouxToastSvc.dismissToast(this.exportingToast);
      this.showExportNotification('success');
      let params = this.route.snapshot.queryParamMap;
      let planElementType;
      let planElement = params.has('PE_NAME') ? params.get('PE_NAME') : 'iACV';

      if (iACVArray.some(substring => planElement.includes(substring))) {
        planElementType = "iACV"; //IACV Plan Elements
      }
      else if (dollarRenewalArray.some(substring => planElement.includes(substring))) {
        planElementType = "dollarRenewals"; //  $RENEW Plan Elements
      }
      else if (otrArray.some(substring => planElement.includes(substring))) {
        planElementType = "OTR"; //  $RENEW Plan Elements
      }
      else {
        planElementType = "others"; //Normal Plan Elements
      }

      this.goalToCashService.exportBookingsAndMetrics(this.bookingsExportDetailsModel, this.metricsExportDetailsModel, this.exportDefinitionsModel, ((this.myFlag === 'MY PAYOUT') ? 'Y' : 'N'), 'metric', planElementType, this.currencyCode);
      this.bookingsExportDetailsModel = null;
      this.metricsExportDetailsModel = null;
    }
  }

  private showExportNotification(operation: string) : void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'Transaction Summary Export',
        message: operation === 'success' ? 'Export complete' : 'Generating export...',
        icon: operation === 'success' ? 'icon-check' : 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: operation === 'success' ? OuxThemeType.Success : OuxThemeType.Info
      })
    )
  }

}
