/* Angular Imports */
import { 
  Component, 
  OnDestroy }                         from '@angular/core';
/* RxJS Imports */
import { Subscription }               from 'rxjs';
/* OneUX Imports */
import {
  OuxThemeType,
  OuxTooltipOptions, 
  OuxTooltipPosition, 
  OuxRadioButtonItemModel, 
  OuxThemeService }                   from '@cisco/oux-common';
import { GoogleAnalyticsService }     from 'src/app/core/services';


@Component({
  selector: 'toolbar-theme',
  templateUrl: './toolbar-theme.component.html',
  styleUrls: ['./toolbar-theme.component.scss'],
  host: {
    'class': 'toolbar-theme'
  }
})
export class ToolbarThemeComponent implements OnDestroy {

  /**
   * Manage subscription - remember to unsubscribe when component is destroyed
   */
  private subscriptions: Subscription[] = [];

  public injectedThemeTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: true,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '100px', 
      'max-width': '200px' 
    },
    context: { 
      code : null 
    }
  });

  public selectedTheme: string = this.ouxThemeSvc.theme;
  /**
   * Theme dropdown options
   */
  public themeRadioItems: {primary: string, background: string, radio: OuxRadioButtonItemModel}[] = [];

  constructor(private ouxThemeSvc : OuxThemeService,
              private googleAnalyticsSvc: GoogleAnalyticsService) {

    /**
     * Responsible for building out our theme options
     */
    this.ouxThemeSvc.themes.forEach(theme => {
      this.themeRadioItems.push({
        primary: theme.properties.primary,
        background: theme.properties.background,
        radio: new OuxRadioButtonItemModel({
          value: theme.name,
          display: this.updateDisplayText(theme.display)
        })
      });
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
  }

  public toggleThemes(event: any) : void {
    this.googleAnalyticsSvc.eventEmitter('click', 'themes', 'toggle dropdown', 1);
  }

  public selectTheme(): void {
    this.ouxThemeSvc.setTheme(this.selectedTheme);
    this.googleAnalyticsSvc.eventEmitter('click', 'themes', `theme selected - ${this.selectedTheme}`, 1);
  }

  private updateDisplayText(value: string) : string {
    if (!value) {
      return;
    }

    let input = value.substr(value.indexOf(" ") + 1);
    let output = input.substr(input.indexOf(" ") + 1);

    return output;
  }

}
