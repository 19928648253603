import { NodeSummary } from "../../interface/partials/node-summary";

export class NodeSummaryModel implements NodeSummary {

  public STRUCTURE_NODE_NAME: string;
  public acvFlag: string;
  public backlog: number;
  public booking: number;
  public currency: string;
  public nodeDesc: string;
  public nodeName: string;
  public nonComBooking: number;
  public revenue: number;
  public revenueMUL: number;
  public terTypeCode: string;
  public type: string;

  //For IACV
  public ATR_USD_AMOUNT: number;
  public ATTRITION_USD_AMOUNT: number;
  public CUM_IACV_USD_AMOUNT: number;
  public EMPLOYEE_ID: number;
  public GOAL_ID: number;
  public IACV_USD_AMOUNT: number;
  public MANUL_REV_IACV: number;
  public MANUL_REV_RN: number;
  public NEW_LOGO_IACV_USD_AMOUNT: number;
  public OTR_AMOUNT: number;
  public RENEWAL_BOOKING_USD_AMOUNT: number;
  public RENEWED_USD_AMOUNT: number;
  public MANUAL_REV_OTR: number;
  public SOURCE_SYSTEM: string;
  public TERRITORY_CODE: string;
  public TERRITORY_TYPE_CODE: string;
  public TRANS_GROUP_CODE: string;
  public UPSELL_IACV_ADJ_USD_AMOUNT: number;
  public UPSELL_IACV_USD_AMOUNT: number;
  public XSELL_IACV_USD_AMOUNT: number;
  public BASELINE_ATR: string;
  public CUMM_ATR: string;

  public bklg_ATR_USD_AMOUNT: number;
  public bklg_ATTRITION_USD_AMOUNT: number;
  public bklg_CUM_IACV_USD_AMOUNT: number;
  public bklg_IACV_USD_AMOUNT: number;
  public bklg_NEW_LOGO_IACV_USD_AMOUNT: number;
  public bklg_OTR_USD_AMOUNT: number;
  public bklg_RENEWAL_BOOKING_USD_AMT: number;
  public bklg_UPSELL_ADJ_IACV_AMOUNT: number;
  public bklg_UPSELL_IACV_USD_AMOUNT: number;
  public bklg_XSELL_IACV_USD_AMOUNT: number;

  constructor(args : Partial<NodeSummaryModel>) {
    Object.assign(this, args);
  }

}