/* Angular Imports */
import {
  Component,
  OnInit,
  OnDestroy,
  ViewContainerRef }                   from '@angular/core';
/* RxJS Imports */
import { Subscription }                from 'rxjs';
/* Package Imports */
import {
  OuxRouterService,
  OuxLayoutService,
  OuxLoggerService }                   from '@cisco/oux-common';
/* Feature Imports */
import { HeroWhiteList }               from './whitelist';
import { InstanceService }             from '../../shared/services/instance.service';


/**
 * Component Decorator - provides metadata describing the Component
 */
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  host: {
    'class': 'app-hero'
  }
})
export class HeroComponent implements OnInit, OnDestroy {

  private subscriptions : Subscription[] = [];

  /**
   * Stand up native element ref
   */
  private hero : Node | any;

  /**
   * Manages router endstate titles
   */
  public title : string = null;

  public showHero : boolean;

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private vcRef : ViewContainerRef,
              private routerSvc : OuxRouterService,
              private layoutSvc : OuxLayoutService,
              private loggerSvc : OuxLoggerService,
              private instanceSvc : InstanceService) {

    this.setHeroTitle();

    this.hero = this.vcRef.element.nativeElement;

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {
    this.instanceSvc.instanceMountedAt('HeroComponent', 'app-hero');
    this.setHeroState();
  }

  ngOnDestroy() : void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  ////////////////////////////////////////////////
  // Feature Methods
  ////////////////////////////////////////////////

  private setHeroTitle() : void {

    this.subscriptions.push(
      this.routerSvc.getActiveRouteData()
        .subscribe(
          (data : any) => {
            this.title = data.title;
            this.showHero = HeroWhiteList.showHero(data.title);
            this.loggerSvc.logDebug('Current Hero Title:', this.title);
        })
    );

  }

  /**
   * Hides our Hero Container located inside the Interface Template
   */
  private setHeroState() : void {
    this.subscriptions.push(
      this.layoutSvc.getHeroState()
        .subscribe(
          (state : boolean) => {
            this.showHero = state;
        })
    );
  }


}
