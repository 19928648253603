/* Angular Imports */
import { 
  Component, 
  OnInit }                          from '@angular/core';
/* Package Imports */
import { 
  OuxConfigService, 
  OuxLoginThemeOverrides }          from '@cisco/oux-common';
/* Feature Imports */
import { InstanceService }          from '../../shared/services/instance.service';


@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  host: {
    'class': 'app-login'
  }
})
export class RootLoginComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  public header : string = 'Visibility';
  public message : string = 'Cisco uses your network username and password to login. Continue to Visibility login through your network';
  public redirectAfter : string = '/goal-attainment';

  /**
   * The following properties are managed in 'app/config/app.{environment}.json'
   */
  public gatewayUri : string;
  public enableDeepLinking : boolean;

  public loginThemeOverrides: OuxLoginThemeOverrides = {
    color: 'var(--default-text)',
    backgroundColor: '',
    contentColor: '',
    contentBackgroundColor: 'var(--background)',
    headerColor: '',
    headerBorderColor: 'var(--divider)'
  };

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private configSvc : OuxConfigService,
              private instanceSvc : InstanceService) {

  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {

    this.instanceSvc.instanceMountedAt('LoginComponent', 'app-login');
    /**
     * Init login configuration
     */
    this.configureLogin();

  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////


  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private configureLogin() : void {
    this.gatewayUri = this.configSvc.getAppConfigValue('gatewayUri');
    this.enableDeepLinking = this.configSvc.getAppConfigValue('enableDeepLinking');
  }

}
