<ng-container *ngIf="data">

  <oux-modal-content-container>
    <oux-modal-content-header [setOptions]="{ icon: 'icon-tags', heading: data?.selectedPartNumber + ' - Transaction ID' }"></oux-modal-content-header>
    <oux-modal-content-article>

      <ng-container *ngIf="data; else exceptionTemplate">
        <section class="grid">

          <div class="grid__column brd-none">

            <div class="column__row">
              <span class="label">Book Date</span>
              <span class="value">{{ data?.lineDetails.TRANSACTION_DATE | dateFormat }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Creation Date</span>
              <span class="value">{{ data?.lineDetails.CREATION_DATE | dateFormat }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Adjustment Period</span>
              <span class="value">{{ data?.lineDetails.TERM }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Contract Start Date</span>
              <span class="value">{{ data?.lineDetails.CONTRACT_START_DATE | dateFormat }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Contract End Date</span>
              <span class="value">{{ data?.lineDetails.CONTRACT_END_DATE | dateFormat }}</span>
            </div><!-- /column__row -->

          </div><!-- /grid__column -->
      
          <div class="grid__column">

            <div class="column__row">
              <span class="label">Transaction Reference</span>
              <span class="value --with-padding">{{ data?.lineDetails.TRANSACTION_ID }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Credit Memo Number</span>
              <span class="value --with-padding">{{ data?.lineDetails.CRED_MEMO }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Part Number</span>
              <span class="value --with-padding">{{ data?.lineDetails.PART_NUMBER }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Sales Motion</span>
              <span class="value --with-padding">{{ data?.lineDetails.SALES_MOTION }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Total Contract Value <i class="icon info-tooltip-trigger icon-info-outline" [ouxTooltip]="totalValueTooltip" [ouxTooltipOptions]="infoTooltipOptions"></i></span>
              <span class="value --with-padding">{{ data?.lineDetails.TOTAL_VALUE }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Contract Term</span>
              <span class="value --with-padding">{{ data?.lineDetails.CONTRACT_TERM }}</span>
            </div><!-- /column__row -->

          </div><!-- /grid__column -->
      
          <div class="grid__column">

            <div class="column__row">
              <span class="label">Adjustment Type</span>
              <span class="value">{{ data?.lineDetails.TYPE  }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Program Name</span>
              <span class="value">{{ data?.lineDetails.PROGRAM_NAME }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Comment</span>
              <span class="value">{{ data?.lineDetails.COMMENTS }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Reason</span>
              <span class="value">{{ data?.lineDetails.REASON }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Created By</span>
              <span class="value">{{ data?.lineDetails.CREATED_BY }}</span>
            </div><!-- /column__row -->

            <div class="column__row">
              <span class="label">Metric Type</span>
              <span class="value">{{ data?.lineDetails.METRIC_TYPE }}</span>
            </div><!-- /column__row -->

          </div><!-- /grid__column -->
        </section>
      </ng-container>

      <ng-template #exceptionTemplate>
        <p>No additional information at this time.</p>
      </ng-template>

      <ng-template #totalValueTooltip>
        <div class="transaction-line-details-grid-tooltip">
          Total Contract Value - Bookings value across the lifetime of the contract
        </div>
      </ng-template>

      <div class="footer">
        <oux-button 
          [options]="{ label : 'Close', style : 'flat', dataSelector : 'credit-memo-transaction-line-details-modal-oJVlEZeAp7' }" 
          (trigger)="closeModal()"> 
        </oux-button> 
      </div>

    </oux-modal-content-article>
  </oux-modal-content-container>

</ng-container>