import {
  Pipe,
  PipeTransform }                  from '@angular/core';
import { DatePipe }                from '@angular/common';


@Pipe({
  name: 'dateLocalFormat'
})
/**
 * Transforms inbound date from utc to local
 *
 * Ex: {{ expirationDate | dateLocalFormat }}
 */
export class DateLocalFormatPipe implements PipeTransform {
  transform(utcDate: string, format: any) {
    // return if input date is null or undefined
    if (!utcDate) {
      return;
    }
    // append 'Z' to the date string to indicate UTC time if the timezone isn't already specified
    if (utcDate.indexOf('Z') === -1 && utcDate.indexOf('+') === -1) {
      utcDate += 'Z';
    }
    else {
      // convert and format date using the built in angularjs date filter
      return new DatePipe('date').transform(new Date(utcDate), format);
    }
  }

}
