import { DateRange } from "../../interface/partials/date-range";
import { PaymentAdjustmentLines }            from "../../interface/partials/payment-adjustment-lines";
import { PaymentRevenueAdjustment }          from "../../interface/partials/payment-revenue-adjustment";
import { PaymentRevenueAdjustmentModel } from './payment-revenue-adjustment.model';

export class PaymentAdjustmentLinesModel implements PaymentAdjustmentLines {

    public P_DATE_RANGE: DateRange[];
    public P_ERROR_MESSAGE: string;
    public P_TOT_RECORDS: number;
    public P_REV_ADJ: PaymentRevenueAdjustment[];

  constructor(args : Partial<PaymentAdjustmentLinesModel>) {
    Object.assign(this, args);

    if (this.P_REV_ADJ) {
      this.P_REV_ADJ = this.P_REV_ADJ.map(x => new PaymentRevenueAdjustmentModel(x));
    }
  }

}