import { CreditMemoTransactionLinesAdjustmentsRequest } from "../../interface/request/credit-memo-transaction-lines-adjustments-request";

export class CreditMemoTransactionLinesAdjustmentsRequestModel implements CreditMemoTransactionLinesAdjustmentsRequest {

  public employeeId: string;
  public loginId: string;
  public userId: string;
  public planId: number;
  public allocationId: number;
  public srpGoalHeaderId: number;
  public erpPosFlag: string;
  public adjustmentType: string = 'revCredMemo';
  public sourceSystem: string = 'Visibility';
  public nodeName: string;
  public territoryTypeCode: string;
  public limit: number = 10;
  public offset: number = 0;

  constructor(args : Partial<CreditMemoTransactionLinesAdjustmentsRequestModel>) {
    Object.assign(this, args);
  } 

}