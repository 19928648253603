import {
  Pipe,
  PipeTransform }               from '@angular/core';
import { DatePipe }             from '@angular/common';
import { DATE_TIME_FORMAT }     from '../models/constants';

@Pipe({
  name: 'dateFormat'
})
/**
 * Transforms inbound date into specified format via DATE_TIME_FORMAT
 *
 * Ex: {{ expirationDate | dateFormat }}
 */
export class DateFormatPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe
  ) {

  }

  public transform(value: string | number | Date, convertDateToPST?: boolean): string | null {
    if (typeof value === 'number' || convertDateToPST === true) {
      return this.datePipe.transform(typeof value === 'string' ? new Date(value) : value, DATE_TIME_FORMAT.DATE_FMT, '-7');
    }

    return this.datePipe.transform(value, DATE_TIME_FORMAT.DATE_FMT);
  }
}
