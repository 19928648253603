import { XaasOrderLinesSummary } from "../../interface/partials/xaas-order-lines-summary";

export class XaasOrderLinesSummaryModel implements XaasOrderLinesSummary {
  public ACTION: string;
  public ANNUAL_VALUE: number;
  public ANNUAL_VALUE_1: number;
  public ATCCV_FLAG: any;
  public ATTRIB_FLAG: string;
  public BACKLOG_LINE: number;
  public BILL_TO_CUSTOMER: any;
  public BONUS_PLAN_CODE: any;
  public BOOKING_AMT: number;
  public BOOKING_RECOGNITION_TYPE: string;
  public BOOKING_SOURCE: string;
  public BOOK_TYPE: any;
  public BP_FLAG: any;
  public BUSINESS_UNIT: any;
  public BUYING_PROGRAM_NAME: string;
  public COMM_BACKLOG: number;
  public COMM_BOOKING: number;
  public COMM_LINE_TYPE: any;
  public COMM_REVENUE: number;
  public CONTRACT_ACCEPTED_DATE: any;
  public CONTRACT_END_DATE: number;
  public CONTRACT_NUMBER: any;
  public CONTRACT_START_DATE: number;
  public CONTRACT_TERM: number;
  public DISTRIBUTOR_NAME: any;
  public ELA_FLAG: string;
  public END_CUSTOMER: string;
  public EXTD_MY_PAYOUT_AMOUNT: number;
  public EXTD_MY_PAYOUT_AMOUNT_1: number;
  public GS_CURRENCY_CODE: string;
  public IMRR: number;
  public LA_SELL_TYPE: string;
  public LINE_CREATION_DATE: number;
  public LINE_FLAG: string;
  public LINE_ID: number;
  public METRIC_TYPE: string;
  public MRR: number;
  public MYPEFLAG: any;
  public MY_PAYOUT_AMOUNT: number;
  public MY_PAYOUT_AMOUNT_1: number;
  public MY_PAYOUT_FACTOR: number;
  public NON_COMM_BKG_AMT: number;
  public OA_PERCENT: number;
  public OFFER_NAME: string;
  public OFFER_PART_NUMBER: string;
  public OFFER_TYPE: any;
  public OFFER_TYPE_NAME: string;
  public ORDER_NUMBER: number;
  public PART_NUMBER: string;
  public PLAN_TITLE: string;
  public PRODUCT_FAMILY: string;
  public REMAINING_TERM: number;
  public RENEWAL_TERM: 1
  public REV_MULTI: number;
  public REV_MULTIPLIER_FACTOR: number;
  public SAAS_FLAG: any;
  public SALES_VALUE_MULTIPLIED: number;
  public SELL_TYPE: string;
  public SEQUENCE_ID: number;
  public SHIP_TO_CUSTOMER: any;
  public SPLIT_SALES_VALUE: number;
  public SUBSCRIPTION_CODE: string;
  public SUBSCRIPTION_CREDIT: number;
  public SUB_REF_ID: string;
  public TCV_SALES_VALUE: number;
  public TERM: number;
  public TERM_BOUND_CREDIT: any;
  public TOTAL_SALES_VALUE: number;
  public TOTAL_VALUE: number;
  public TOTAL_VALUE_1: number;
  public TRANSACTION_NUMBER: number;
  public AT_LEVEL_1: string;
  public AT_LEVEL_2: string;
  public AT_LEVEL_3: string;
  public AT_LEVEL_4: string;
  public SO_NUMBER: any;
  public SOURCE_TRANSACTION_DATE: any;
  public NON_ELIGIBILITY_REASON: any;
  public CURRENCY_CODE: string;
  public DEAL_ID: any;
  public NODE_LEVEL_2: string;
  public NODE_LEVEL_3: string;
  public NODE_LEVEL_4: string;
  public NODE_LEVEL_5: string;
  public NODE_LEVEL_6: string;
  public ORDER_TYPE: string;
  public PO_NUMBER: any;
  public REVENUE: number;
  public TERRITORY_CODE: string;
  public TERRITORY_TYPE_CODE: string;

  constructor(args : Partial<XaasOrderLinesSummaryModel>) {
    Object.assign(this, args);
  }

}
