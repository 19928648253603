import { UserDetails } from "../../interface/partials/user-details";
import { UserDrillDownDetails } from '../../interface/partials/user-drill-down-details';
import { UserList } from '../../interface/partials/user-list';

export class UserDetailsModel implements UserDetails {

  public uid: string;
  public accessLevel: string;
  public name: string;
  public impersonation: UserList;
  public drilldown: UserDrillDownDetails;

  constructor(args : Partial<UserDetails>) {
    Object.assign(this, args);
  }

}