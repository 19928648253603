import {
  Component,
  Input,
  OnDestroy, } from '@angular/core';
import {
  catchError,
  finalize,
  map,
  take,
  tap, 
  timeout} from 'rxjs/operators';
import {
  Subscription, throwError
} from 'rxjs';
import {
  OuxConfigService,
  OuxModalService,
  OuxThemeType,
  OuxToastOptions,
  OuxToastService
} from '@cisco/oux-common';
import { CreditMemoTransactionLinesAdjustmentStore } from 'src/app/shared/stores/credit-memo-transaction-lines-adjustment.store';
import { CreditMemoTransactionLinesAdjustmentsRequest } from 'src/app/shared/models/interface/request/credit-memo-transaction-lines-adjustments-request';
import { OrdersExportFiltersModel } from 'src/app/shared/models/concrete/partials/orders-export-filters.model';
import { OrdersExportRequestModel } from 'src/app/shared/models/concrete/request/orders-export-request.model';
import { CreditMemoTransactionLinesAdjustmentsService } from 'src/app/shared/services/credit-memo-transaction-lines-adjustments.service';
import {OfflineExportModalModel} from "../../../offline-export-modal/offline-export-modal.model";
import {OfflineExportHeaderModel} from "../../../offline-export-modal/offline-export-header.model";
import {OfflineExportContentModel} from "../../../offline-export-modal/offline-export-content.model";
import {ExportType} from "../../../../models/constants/export-type.enum";
import {OfflineExportModalComponent} from "../../../offline-export-modal/offline-export-modal.component";
import {SubmitExportService} from "../../../../services/export/submit-export.service";
import {ExportService} from "../../../../services/export/export.service";
import { VisibilityPageType } from 'src/app/shared/models/types/visibility-page-type.enum';
import { ExportDisplayType} from 'src/app/shared/models/types/offline-export-type.enum';
import { OrderSearchSearchType } from 'src/app/shared/models/types/order-search-search-type.enum';


@Component({
  selector: 'credit-memo-transaction-lines-summary-table-export',
  templateUrl: './credit-memo-transaction-lines-summary-table-export.component.html',
  styleUrls: ['./credit-memo-transaction-lines-summary-table-export.component.scss'],
  host:{
    'class': 'credit-memo-transaction-lines-summary-table-export'
  }
})
export class CreditMemoTransactionLinesSummaryTableExportComponent implements OnDestroy {

  private subscriptions: Subscription[] = [];
  private exportSubscription: Subscription;
  private cmDmTransactionSummaryPayload: any;

  public currency$ = this.adjustmentsStore.creditMemoAdjustmentsCurrency$;

  public loading: boolean = false;

  private _exportRequest: CreditMemoTransactionLinesAdjustmentsRequest;

  @Input('request')
  public set exportRequest(value: CreditMemoTransactionLinesAdjustmentsRequest) {
    this._exportRequest = value;
  }
  public get exportRequest() : CreditMemoTransactionLinesAdjustmentsRequest {
    return this._exportRequest;
  }

  constructor(private ouxToastSvc: OuxToastService,
              private adjustmentsSvc: CreditMemoTransactionLinesAdjustmentsService,
              private ouxConfigSvc: OuxConfigService,
              private adjustmentsStore: CreditMemoTransactionLinesAdjustmentStore,
              private ouxModalSvc: OuxModalService,
              private submitExportService: SubmitExportService,
              private exportService: ExportService) {

  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
    this.subscriptions = [];
    this.exportSubscription?.unsubscribe();
    this.exportSubscription = undefined;
  }

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /* Entry point called from html Export hyperlink */
  public export(){
    this.buildCMDMTransactionSummaryPayload();
    this.checkDuplicateExportRequest(this.cmDmTransactionSummaryPayload);
  }

  /* To check for duplicate export request
  * Possible output - Y or N
  * If Y - show a popup to the user that it is a duplicate request
  * If N - submit offline export request */
  private checkDuplicateExportRequest(request): void {
    this.exportSubscription = this.exportService.checkForDuplicateExportRequest(request.filters)
      .pipe(
        tap(response => {
          response.p_export_request_is_duplicate === 'N' ? this.submitExportService.queueCmDmExport(request) : this.initOfflineExport(request);
        }),
        catchError(error => {
          this.ouxToastSvc.addToast(new OuxToastOptions({
            autoDismiss: true,
            dismissTimeout: 10,
            header: `Error`,
            icon: 'icon-export',
            message: `Export Request could not be submitted. Please contact IT Support`,
            theme: OuxThemeType.Warning,
            scaleOnHover: false
          }));

          return throwError(error);
        })
      )
      .subscribe({
        complete: () => {
        }
      });
  }

  /* Build Credit Memo Debit Memo Input payload request */
  public buildCMDMTransactionSummaryPayload(): void {
    this.loading = true;
    this.showExportNotification();

    this.subscriptions.push(this.currency$
      .pipe(
        timeout(this.ouxConfigSvc.getAppConfigValue('exportTimeout')),
        take(1),
        map(currency => {
          let filters = new OrdersExportFiltersModel({
            allocationId: this.exportRequest?.allocationId,
            currency: currency,
            employeeId: this.exportRequest?.employeeId,
            erpPosFlag: null,
            limit: this.exportRequest?.limit || 1000000,
            loginId: this.exportRequest?.loginId,
            nodeName: this.exportRequest?.nodeName,
            offset: this.exportRequest?.offset || 0,
            planId: this.exportRequest?.planId,
            sourceSystem: this.exportRequest?.sourceSystem,
            srpGoalHeaderId: this.exportRequest?.srpGoalHeaderId,
            territoryTypeCode: this.exportRequest?.territoryTypeCode,
            userId: this.exportRequest?.userId,
            RTMType: OrderSearchSearchType.CreditDebitMemo,
            exportType: ExportDisplayType.Regular,
            page: VisibilityPageType.OrderSearch,
            adjustmentType: 'revCredMemo'
          });

          let request = new OrdersExportRequestModel({
            sourceSystem: 'Visibility',
            type: 'Line',
            filters: filters
          });
          this.cmDmTransactionSummaryPayload = request;
        }),
        catchError(error => {
          return throwError(error);
        }),
        finalize(() => this.loading = false)
      )
      .subscribe()
    );
  }

  // Show Export notification for the Export in Order Drill Down Screen
  private showExportNotification(): void {
    this.ouxToastSvc.addToast(
      new OuxToastOptions({
        header: 'Credit Debit Memo Line Transactions',
        message: 'Submitting Export',
        icon: 'icon-export',
        autoDismiss: true,
        dismissTimeout: 5,
        theme: OuxThemeType.Info
      })
    );
  }

  /* Triggered upon user confirmation in the duplicate request pop up - Export hyperlink
  * type - CreditDebitMemo
  * cmDmTransactionSummaryPayload - same payload that is used for the Export */
  private initOfflineExport(request) {
    const modalData = new OfflineExportModalModel({
      header: new OfflineExportHeaderModel({ icon: '', heading: '' }),
      content: new OfflineExportContentModel({ content: '' }),
      type: ExportType.CreditDebitMemo,
      offlineRequest: request
    });

    this.ouxModalSvc.openModal(OfflineExportModalComponent, modalData);
  }

}
