/* Angular Imports */
import { 
  Component, 
  OnInit }                                    from '@angular/core';
/* OneUX Imports */
import {
  OuxWindowRefService,
  OuxTooltipOptions, 
  OuxTooltipPosition,
  OuxThemeType }                              from '@cisco/oux-common';
import { GoogleAnalyticsService }             from 'src/app/core/services';


@Component({
  selector: 'toolbar-dashboards',
  templateUrl: './toolbar-dashboards.component.html',
  styleUrls: ['./toolbar-dashboards.component.scss'],
  host: {
    'class': 'toolbar-dashboards'
  }
})
export class ToolbarDashboardsComponent implements OnInit {

  ////////////////////////////////////////////////
  // Members
  ////////////////////////////////////////////////

  private windowRef : any;

  private nextGenClaimingDynamicLink: string = 'https://ngct.cloudapps.cisco.com/';
  private planEstimatorLink: string = 'https://salesconnect.cisco.com/#/program/PAGE-1912';

  public injectedDashboardsTooltipOpts: OuxTooltipOptions = new OuxTooltipOptions({
    enableClick: true,
    position: OuxTooltipPosition.Bottom,
    theme: OuxThemeType.Background,
    cssOverrides: { 
      'min-width': '200px', 
      'max-width': '250px' 
    },
    context: { 
      code : null 
    }
  });

  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private ouxWindowRefSvc: OuxWindowRefService,
              private googleAnalyticsSvc: GoogleAnalyticsService) {

    // assign our window object reference
    this.windowRef = this.ouxWindowRefSvc.nativeWindow;
  }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void {}

  ////////////////////////////////////////////////
  // Public Methods
  ////////////////////////////////////////////////

  /**
   * Menu actions - User Settings
   */
   public goTo(caller : string, event : any) : void {
    switch (caller) {
      case 'claimingUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'dashboards', 'next gen claiming', 1);
        this.openNewWindow(this.nextGenClaimingDynamicLink);
        break;
      }
      case 'calendarUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'dashboards', 'sales incentive calendar', 1);
        this.openNewWindow('https://cisco.box.com/s/hiuwy6gzb5vx4r4wpjy1gvmp1omlzkoi');
        break;
      }
      case 'estimatorUrl' : {
        this.googleAnalyticsSvc.eventEmitter('click', 'dashboards', 'sales incentive plan estimator', 1);
        this.openNewWindow(this.planEstimatorLink);
        break;
      }
    }
  }



  ////////////////////////////////////////////////
  // Private Methods
  ////////////////////////////////////////////////

  private openNewWindow(url : string) : void {
    /**
     * Added rel="noreferrer noopener" to external links due to potential security/perfomance issues. 
     * Details here under "target" attribute: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
     */
    this.windowRef.open(url, '_blank', 'noopener,noreferrer');
  }

}
