import { PaymentSummaryModel } from '../../models/concrete/partials/payment-summary.model';
import { PaymentSummary } from '../../models/interface/partials/payment-summary';
import { ExportValueType } from './export-value.type';

export class ExportDataModel {
  public worksheetName: string;
  public title: ExportValueType[] = [];
  public orderLineHeaders: ExportValueType[][] = [];
  public orderLineData: ExportValueType[][] = [];
  public headers: ExportValueType[][] = [];
  public data: ExportValueType[][] = [];
  public ampHeaders?: ExportValueType[][] = [];
  public ampData?: ExportValueType[][] = [];

  constructor(args: Partial<ExportDataModel>) {
    Object.assign(this, args);
  }
}