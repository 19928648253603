import { OrdersLinesDetails }       from "../../interface/partials/orders-lines-details";
import { OrderAttributes }          from "../../interface/partials/order-attributes";
import { Transactions }             from "../../interface/partials/transactions";
import { TransactionsModel }        from './transactions.model';

export class OrdersLinesDetailsModel implements OrdersLinesDetails {
  public P_TRANSACTIONS: Transactions[];
  public P_ERROR_MESSAGE: string;
  public P_TOT_RECORDS: number;
  public P_ORDER_ATTRIBUTES: OrderAttributes;

  constructor(args : Partial<OrdersLinesDetailsModel>) {
    Object.assign(this, args);

    if (this.P_TRANSACTIONS) {
      this.P_TRANSACTIONS =  this.P_TRANSACTIONS.map(transactions => new TransactionsModel(transactions));
    }
  }
}