<ng-container *ngIf="(mappedData$ | async) as data">

  <oux-modal-content-container>
    <oux-modal-content-header [setOptions]="{ icon: 'icon-tags', heading: data?.selectedOrderLine?.LINE_ID + ' - Order Line Transactions' }"></oux-modal-content-header>
    <oux-modal-content-article>
      <transaction-line-details-grid 
        [transactions]="data?.transactions" 
        [transactionType]="data?.transactionType"
        [selectedOrderLine]="data?.selectedOrderLine"
        [selectedOrder]="data?.selectedOrder">
      </transaction-line-details-grid>

      <div class="footer">
        <oux-button 
          [options]="{ label : 'Close', style : 'flat', dataSelector : 'lines-transactions-modal-oJVlEZeAp7' }" 
          (trigger)="closeModal()"> 
        </oux-button> 
      </div>

    </oux-modal-content-article>
  </oux-modal-content-container>

</ng-container>