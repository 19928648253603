import { ElementConfig }                from '../../interface/partials/elementconfig';
import { EmployeeInfo }                 from '../../interface/partials/emp-info';
import { Metadata, BannerMsg, UserBannerMsg }          from '../../interface/partials/metadata';
import { ScreenConfig }                 from '../../interface/partials/screenconfig';


export class MetadataModel implements Metadata {
  
  public P_BANNERMSG: BannerMsg[];
  public P_ELEMENTCONFIG: ElementConfig[];
  public P_EMPINFO: EmployeeInfo[];
  public P_SCREENCONFIG: ScreenConfig[];
  public P_USER_BANNERMSG: UserBannerMsg[];

  constructor(args : Partial<MetadataModel>) {
    Object.assign(this, args);
  }

}