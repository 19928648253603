<ng-container *ngIf="requestParams as data">
  <section class="grid">
    <div class="grid__column">
      <div class="column__row">
        <div class="metric-id-label">
          <span class="label">Order Number</span>
        </div>

        <span class="value --dominant">{{ data?.orderNumber }}</span>
        <div class="group --plan-description">
          <span class="value --description" ellipsis [ouxTooltip]="planElementTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.savTitle }}
          </span>

          <ng-template #planElementTooltip>
            <p class="tooltip__text">{{ data?.savTitle || 'N/A' }}</p>
          </ng-template>

          <i *ngIf="data?.savTitle" class="icon-info-outline"
            [ouxTooltip]="data?.savTitle ? planElementDescTemplate : ''" [ouxTooltipOptions]="templateTooltipOpts">
          </i>

          <ng-template #planElementDescTemplate>
            <p class="tooltip__text">Plan Element Description</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->

      <div class="column__row --meta-columns">
        <div class="group">
          <span class="label">Total Order Lines:</span>

          <ng-container>
            <span class="value">{{totalRecords}}</span>
          </ng-container>

        </div><!-- /group -->

        <div class="group">
          <span class="label">Currency:</span>
          <span class="value" ellipsis [ouxTooltip]="currencyTooltip" [ouxTooltipOptions]="templateTooltipOpts">
            {{data?.currency}}
          </span>

          <ng-template #currencyTooltip>
            <p class="tooltip__text">USD</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->
    </div>

    <div class="grid__column">
      <div class="column__row --data">
        <span class="label">Book Date</span>
        <span class="value --data" *ngIf="data?.sourceTransactionDate !== null">
          {{data?.sourceTransactionDate | dateFormat}}
        </span>
        <span class="value --data" *ngIf="data?.sourceTransactionDate === null">
          N/A
        </span>
      </div><!-- /column__row -->

      <div class="column__row --data"
        *ngIf="data.sourcePage === 'g2c' || data.sourcePage === 'order' && myFlag === 'N'">
        <span class="label">Bookings</span>
        <div *ngIf="ampIdentifier === AmpIdentifierKey.NOTELIGIBLE; else defaultBookings">
          <span class="value --data">{{ data.booking }}</span>
        </div>
        <ng-template #defaultBookings>
          <span class="value --data" *ngIf="data.booking != 0 && data.peType === 'IACV'">
            <a (click)="toggleBookingSplit()">{{ (data.booking) | number:'1.2-2' }} <i
                [ngClass]="(toggleBookings ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
          </span>
          <span class="value --data" *ngIf="data.booking != 0 && data.peType === 'RND'">
            {{ (data.booking) | number:'1.2-2' }}
          </span>
          <span class="value --data" *ngIf="data.booking == 0">
            {{ (data.booking) | number:'1.2-2' }}
          </span>
  
          <span *ngIf="toggleBookings && data.peType === 'IACV'" class="sub-options-width">
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                New Logo
              </span>
              <span class="sub-options value-align">
                : {{ (data.newLogoBklgAmount + data.newLogoRevAmount) | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Cross-sell
              </span>
              <span class="sub-options value-align">
                : {{ (data.crossSellBklgAmount + data.crossSellRevAmount) | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Upsell
              </span>
              <span class="sub-options value-align">
                : {{ (data.upsellBklgAmount + data.upsellRevAmount) | number:'1.2-2' }}
              </span>
            </span>
          </span>
        </ng-template>

      </div>
      <!-- <div class="column__row" *ngIf="data.sourcePage === 'payments'">
        <span class="label">Bookings</span>
        <span class="value"> -
        </span>
      </div> -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row --data">
        <span class="label">Category</span>
        <span class="value --data" ellipsis [ouxTooltip]="categoryTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.ampIdentifier || 'N/A' }}
        </span>
        <ng-template #categoryTooltip>
          <p class="tooltip__text">{{ data?.ampIdentifier || 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row --data"
        *ngIf="data.sourcePage === 'g2c' || data.sourcePage === 'order' && myFlag === 'N'">
        <span class="label">Backlog</span>
        <div *ngIf="ampIdentifier === AmpIdentifierKey.NOTELIGIBLE; else defaultBacklog">
          <span class="value --data">{{ data.backlog }}</span>
        </div>
        <ng-template #defaultBacklog>
          <span class="value --data" *ngIf="data.backlog != 0 && data.peType === 'IACV'">
            <a (click)="toggleBacklogSplit()">{{ data.backlog | number:'1.2-2' }} <i
                [ngClass]="(toggleBacklog ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
          </span>
          <span class="value --data" *ngIf="data.backlog != 0 && data.peType === 'RND'">
            {{ data.backlog | number:'1.2-2' }}
          </span>
          <span class="value --data" *ngIf="data.backlog == 0">
            {{ data.backlog | number:'1.2-2' }}
          </span>
          <span *ngIf="toggleBacklog && data.peType === 'IACV'" class="sub-options-width">
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                New Logo
              </span>
              <span class="sub-options value-align">
                : {{ data.newLogoBklgAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Cross-sell
              </span>
              <span class="sub-options value-align">
                : {{ data.crossSellBklgAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Upsell
              </span>
              <span class="sub-options value-align">
                : {{ data.upsellBklgAmount | number:'1.2-2' }}
              </span>
            </span>
          </span>
        </ng-template>
      </div><!-- /column__row -->
      <!-- <div class="column__row" *ngIf="data.sourcePage === 'payments'">
        <span class="label">Backlog</span>
        <span class="value"> -
        </span>
      </div> -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row --data">
        <span class="label">Node (Territory Type)</span>
        <span class="value --data" ellipsis [ouxTooltip]="nodeTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.territory || 'N/A' }}
        </span>
        <ng-template #nodeTooltip>
          <p class="tooltip__text">{{ data?.territory || 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row --data">
        <ng-container>
          <span class="label">Revenue</span>
          <span class="value --data" *ngIf="data.commRevenue != 0  && data.peType === 'IACV' && myFlag === 'N'">
            <a (click)="toggleRevenueSplit()">{{ data.commRevenue | number:'1.2-2' }} <i
                [ngClass]="(toggleRevenue ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
          </span>
          <span class="value --data" *ngIf="data.commRevenue != 0 && data.peType === 'RND' && myFlag === 'N'">
            {{ data.commRevenue | number:'1.2-2' }}
          </span>
          <span class="value --data" *ngIf="data.commRevenue == 0 && myFlag === 'N'">
            {{ data.commRevenue | number:'1.2-2' }}
          </span>
          <span class="value --data" *ngIf="myFlag === 'Y'">
            {{ data.myPayoutAmount | number:'1.2-2' }}
          </span>
          <span *ngIf="toggleRevenue  && data.peType === 'IACV' && myFlag === 'N'" class="sub-options-width">
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                New Logo
              </span>
              <span class="sub-options value-align">
                : {{ data.newLogoRevAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Cross-sell
              </span>
              <span class="sub-options value-align">
                : {{ data.crossSellRevAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Upsell
              </span>
              <span class="sub-options value-align">
                : {{ data.upsellRevAmount | number:'1.2-2' }}
              </span>
            </span>
          </span>
        </ng-container>
      </div><!-- /column__row -->
      <!-- <div class="column__row" *ngIf="data.sourcePage === 'payments'">
        <span class="label">Revenue</span>
        <span class="value">
          -
        </span>
      </div> -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row --data">
        <span class="label">Type</span>
        <span class="value --data" ellipsis [ouxTooltip]="typeTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.type || 'N/A' }}
        </span>

        <ng-template #typeTooltip>
          <p class="tooltip__text">{{ data?.type || 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row --data" *ngIf="data.sourcePage !== 'payments'">
        <ng-container *ngIf="myFlag === 'N'">
          <span class="label">Annual</span>
          <span class="value --data" *ngIf="data.annualValue != 0">
            <a (click)="toggleAnnualSplit()">{{ data.annualValue && data.annualValue !==null ? (data.annualValue |
              number:'1.2-2') :
              0.00}} <i [ngClass]="(toggleAnnual ? 'icon-chevron-up' : 'icon-chevron-down')"></i></a>
          </span>
          <span class="value --data" *ngIf="data.annualValue == 0">
            {{data.annualValue | number:'1.2-2'}}
          </span>
          <span *ngIf="toggleAnnual" class="sub-options-width">
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                iACV
              </span>
              <span class="sub-options value-align">
                : {{ data.iacvAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                $Renewed+Residual Annual
              </span>
              <span class="sub-options value-align">
                : {{ data.renewedAmount | number:'1.2-2' }}
              </span>
            </span>
            <span class="sub-options-width lineHt">
              <span class="sub-options label-width">
                Non-Comm Booking
              </span>
              <span class="sub-options value-align">
                : {{ data.nonCommBooking | number:'1.2-2' }}
              </span>
            </span>
          </span>
        </ng-container>
        <ng-container *ngIf="myFlag === 'Y' && peType === 'IACV'">
          <span class="label">iACV</span>
          <span class="value --data">
            {{data.iacvAmount | number:'1.2-2'}}
          </span>
        </ng-container>
        <ng-container *ngIf="myFlag === 'Y' && peType === 'RND'">
          <span class="label">$Renewed + Residual Annual</span>
          <span class="value --data">
            {{data.renewedAmount | number:'1.2-2'}}
          </span>
        </ng-container>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row --data">
        <span class="label">End Customer</span>
        <span class="value --data" ellipsis [ouxTooltip]="customerTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{ data.endCustomer ? data.endCustomer : 'N/A' }}
        </span>
        <ng-template #customerTooltip>
          <p class="tooltip__text">{{ data.endCustomer ? data.endCustomer : 'N/A' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row --data">
        <span class="label"> TCV</span>
        <span class="value --data" ellipsis [ouxTooltip]="tcvTooltip" [ouxTooltipOptions]="templateTooltipOpts">
          {{data.totalValue ? (data.totalValue | number:'1.2-2') : 0.00}}
        </span>
        <ng-template #tcvTooltip>
          <p class="tooltip__text">{{ data.totalValue ? (data.totalValue | number:'1.2-2') : 0.00 }}</p>
        </ng-template>
      </div> <!-- /column__row -->
    </div>
  </section>
</ng-container>
