import { Injectable }                               from '@angular/core';
import { BehaviorSubject, Observable }                          from 'rxjs';
import { MetricDetailsIacvModel } from '../models/concrete/partials/metric-details-iacv.model';
import { OrdersSearchOrder }      from '../models/interface/partials/orders-search-order';
import { PagedResponse }          from '../models/interface/partials/paged-response';
import { 
  OuxStore,
  OuxLoggerService }              from '@cisco/oux-common';


@Injectable({
  providedIn: 'root'
})
export class MetricDetailsStore{
  
  private metricDetailsIacvDataSubject = new BehaviorSubject<MetricDetailsIacvModel[]>(null);

  


  public metricDetailsIacvData$ = this.metricDetailsIacvDataSubject.asObservable();

  constructor() { }

  public setMetricDetailsIacvData(value: MetricDetailsIacvModel[]):void {
      this.metricDetailsIacvDataSubject.next(value);
  }

  /**
   * Clear selected Goalsheet
   * ============================================================
   */
   public clearMetricDetailsIacvData(): void {
    this.metricDetailsIacvDataSubject.next(null);
  }

}