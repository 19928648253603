import { NodeAllocationSummary } from "../../interface/partials/node-allocation-summary";

export class NodeAllocationSummaryModel implements NodeAllocationSummary {

  public acvFlag: string;
  public backlog: string | number;
  public booking: string | number;
  public nonComBooking: string | number;
  public revenue: string | number;
  public revenueMUL: string | number;
  public type: string;
  public allocationID: string;
  public goalID: string;
  public planID: string;
  public ampIdentifier: string;
  public calcEngine: string;
  public metricFlag: string;
  public peType?:  string;
  public myPYFlag?: string;
  public performanceType?: string;

  constructor(args : Partial<NodeAllocationSummaryModel>) {
    Object.assign(this, args);
  }

}
