<oux-spinner [show]="loading$ | async" [hasOverlay]="true"></oux-spinner>

<div class="header">
  <h4>POS Transaction Details</h4>

  <pos-transaction-details-grid-export [notEligibleExportRequest]="notEligibleExportRequest" [isNotEligibleOrder]="isNotEligibleOrder" *ngIf="(showExport$ | async) || isNotEligibleOrder"></pos-transaction-details-grid-export>
</div>

<div class="columns" *ngIf="hasTransactions">
  <div class="column">
    <div class="column__label">Distributor</div>
    <div class="column__value">{{attributes?.DISTRIBUTOR_NAME || 'Not Available'}}</div>
  </div>

  <div class="column">
    <div class="column__label">Ship to Customer</div>
    <div class="column__value">{{attributes?.SHIP_TO_CUSTOMER || 'Not Available'}}</div>
  </div>

  <div class="column">
    <div class="column__label">Bill to Customer</div>
    <div class="column__value">{{attributes?.BILL_TO_CUSTOMER || 'Not Available'}}</div>
  </div>
  
  <div class="column">
    <div class="column__label">End Customer</div>
    <div class="column__value">{{attributes?.END_CUSTOMER || 'Not Available'}}</div>
  </div>
</div>

<transaction-line-details-grid 
  [transactions]="transactions$ | async"
  [transactionType]="'revenue'"
  [selectedOrder]="selectedOrder$ | async"
  [selectedOrderLine]="null">
</transaction-line-details-grid>

<ng-container *ngIf="loading$ | async as loading">
  <div *ngIf="(!hasTransactions && !loading); else awaitTemplate" class="no-records">
    No records found.
  </div>
  <ng-template #awaitTemplate>
    <div class="no-records">Requesting lines...</div></ng-template>
</ng-container>
