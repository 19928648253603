import { MetricsExportRequest } from "../../interface/request/metrics-export-request";
import { FauxFilterType } from "../../types/faux-filter-type.enum";

export class MetricsExportRequestModel implements MetricsExportRequest {
  public planID: string;
  public allocationID: string;
  public periodCode: string;
  public goalID: string;
  public userName: string;
  public loginId: string;
  public transGroupCode: string;
  public performanceType: string;
  public savId: String[];
  public lockDate: string;
  public quotaId: string;
  public planTitle: string;
  public beCxLevel1: String[];
  public beCxLevel2: String[];
  public beCxLevel3: String[];
  public cxUpsellGroup: String[];
  public goalSheetViewHeaderPlanType: string;
  public selectedFiscalYear : string;
  public routePath : string;
  public planElementType : string;
  public fiscalWeek: String[];
  public territory: String[];
  public limit: number;
  public offset: number;
  public sortBy: string;
  public sortOrder: string;
  public transactionType: string;
  public metricId: number;
   
  public fauxFilterType?: FauxFilterType;
  public transactionFilterType?: string;
  public nodeFilterValue?: string;

  constructor(args : Partial<MetricsExportRequestModel>) {
    Object.assign(this, args);
  } 
}