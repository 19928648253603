import { Injectable }                 from '@angular/core';
import { BehaviorSubject }            from 'rxjs';
import { map, tap }                   from 'rxjs/operators';
import { 
  OuxLoggerService, 
  OuxStore }                          from '@cisco/oux-common';
import { Payment }                    from '../models/interface/partials/payment';
import { PaymentAllocation }          from '../models/interface/partials/payment-allocation';
import { PeriodPayments }             from '../models/interface/partials/period-payments';
import { PaymentSummary }             from '../models/interface/partials/payment-summary';



@Injectable({ providedIn: 'root' })
export class PaymentStore extends OuxStore<Payment> {

  /**
   * Stage action stream to enable multicast strategy
   */
  private selectedPeriodTransactionPaymentSubject = new BehaviorSubject<PaymentSummary>(null);
  private selectedIntervalSubject = new BehaviorSubject<string>(null);
  private selectedCurrencySubject = new BehaviorSubject<string>(null);
  private currentPaymentPeriodSubject = new BehaviorSubject<PeriodPayments>(null);
  private paymentAllocationsSubject = new BehaviorSubject<PaymentAllocation>(null);

  public selectedPeriodTransactionPayment$ = this.selectedPeriodTransactionPaymentSubject.asObservable();
  public selectedInterval$ = this.selectedIntervalSubject.asObservable();
  public selectedCurrency$ = this.selectedCurrencySubject.asObservable();
  public currentPaymentPeriod$ = this.currentPaymentPeriodSubject.asObservable();
  public paymentAllocations$ = this.paymentAllocationsSubject.asObservable();

  /**
   * Stand up our payments action stream (will retain the next emitted value)
   * This allows for us to react to changes made to the model
   */
  public payments$ = this.state$; // set by this.setPaymentsData
  public paymentTransactionHeader$ = this.selectedPeriodTransactionPayment$
    .pipe(
      map((payment : PaymentSummary) => payment && payment.category ? payment.category : ''),
      tap(header => this.ouxLoggerSvc.logDebug('PaymentStore - Payment Transaction Header:', header))
    );

  public paymentDate$ = this.selectedPeriodTransactionPayment$
    .pipe(
      map((payment : PaymentSummary) => payment && payment.paymentDate ? payment.paymentDate : ''),
      tap(date => this.ouxLoggerSvc.logDebug('PaymentStore - Payment Date:', date))
    );

  constructor(private ouxLoggerSvc: OuxLoggerService) { 
    super (null);
  }

  /**
   * Default Method - This is where it all begins
   * Responsible for setting the original object state at 
   * the PaymentsSummaryComponent level
   * Evoked by: fetchData()
   * ============================================================
   */
  public setPaymentData(value: Payment) : void {
    super.setState(value);
    this.ouxLoggerSvc.logDebug('PaymentStore - Payment In-Memory:', this.state);
  }

 /**
   * Extracts and sets the selected Goalsheet ID
   * ============================================================
   */
  public setSelectedPeriodTransactionPaymentData(payment : PaymentSummary) : void {

    if (payment == undefined || payment == null) {
      return;
    }

    this.selectedPeriodTransactionPaymentSubject.next(payment);
    this.ouxLoggerSvc.logDebug('PaymentStore: Last Selected Period Transaction Record', this.selectedPeriodTransactionPaymentData);
  }

  public setSelectedInterval(interval: string): void {
    this.ouxLoggerSvc.logDebug('PaymentStore: Last Selected Interval', interval);
    this.selectedIntervalSubject.next(interval);
  }

  public setSelectedCurrency(currency: string): void {
    this.selectedCurrencySubject.next(currency);
  }

  public setCurrentPaymentPeriod(value: PeriodPayments): void {
    this.ouxLoggerSvc.logDebug('PaymentStore: Current Payment Period', value);
    this.currentPaymentPeriodSubject.next(value);
  }

  public setPaymentAllocations(value: PaymentAllocation): void {
    this.ouxLoggerSvc.logDebug('PaymentStore: Set Payment Allocations', value);
    this.paymentAllocationsSubject.next(value);
  }

  /**
   * Clear Payment Data Collection
   * ============================================================
   */
  public clearSelectedPeriodTransactionPaymentData() : void {
    this.selectedPeriodTransactionPaymentSubject.next(null)
  }

  public clearSelectedInterval() : void {
    this.selectedIntervalSubject.next(null);
  }

  /**
   * Convenience Methods
   * ============================================================
   */
   public get selectedPeriodTransactionPaymentData() : PaymentSummary {
    return this.selectedPeriodTransactionPaymentSubject.getValue();
  }


}