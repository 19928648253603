<ng-container>
  <oux-modal-content-container *ngIf="(userFullName$ | async) as userFullName">
    <oux-modal-content-header *ngIf="(userId$ | async) as userId"
      [setOptions]="{ icon: 'icon-comment', heading: 'Important Message for '+ userFullName+ '('+ userId +')' }"></oux-modal-content-header>
    <oux-modal-content-article>

      <ng-container *ngIf="(userBanners$ | async) as modalData">
        <div [innerHTML]="linkify(modalData)"></div>
      </ng-container>

      <div class="footer">
        <oux-button [options]="{ label : 'Close', style : 'flat', dataSelector : 'user-banner-modal-oJVlEZeAp7' }"
          (trigger)="closeModal()">
        </oux-button>
      </div>

    </oux-modal-content-article>
  </oux-modal-content-container>
</ng-container>