/* Angular Import */
import { Injectable }                 from '@angular/core';
/* Feature Imports */
import { 
  OuxStore,
  OuxLoggerService }                  from '@cisco/oux-common';
import { map }                        from 'rxjs/operators';
import { PagedResponseModel }         from '../models/concrete/partials/paged-response.model';
import { GTCCreditMemoAdjustments }   from '../models/interface/partials/gtc-credit-memo-adjustments';
import { PagedResponse }              from '../models/interface/partials/paged-response';

/**
 * Creates our OrdersSearchLines State injectable
 * Feature specific stores are Angular Injectables extending the abstract OuxStore (i.e., class):
 */
@Injectable({ providedIn: 'root' })
export class CreditMemoTransactionLinesAdjustmentStore extends OuxStore<PagedResponse<GTCCreditMemoAdjustments>> {

  public creditMemoAdjustments$ = this.state$;
  public creditMemoAdjustmentsCount$ = this.state$
    .pipe(
      map(pagedResponse => pagedResponse && pagedResponse.total ? pagedResponse.total : 0)
    );

  public creditMemoAdjustmentsCurrency$ = this.state$
    .pipe(
      map(pagedResponse => pagedResponse && pagedResponse.records.length < 0 ? pagedResponse.records[0].GS_CURRENCY_CODE : 'USD')
    )

  constructor(private ouxLoggerSvc: OuxLoggerService) {

    super (new PagedResponseModel<GTCCreditMemoAdjustments>({}));
  }

  
  public setLines(lines: PagedResponse<GTCCreditMemoAdjustments>) : void {

    super.setState(lines);
    this.ouxLoggerSvc.logDebug('CreditMemoTransactionLinesAdjustmentStore - PagedResponse<GTCCreditMemoAdjustments>:', this.state);
  }


  /**
   * Clear Adjustment Lines Data Collection
   * ============================================================
   */
   public clearCreditMemoLinesAdjustmentsData(): void {
    this.setState(null);
  }

}