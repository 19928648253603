
<ng-container *ngIf="requestParams">

  <metric-transaction-lines-header (myPayoutFlag) = "myPayoutFlag($event)" [requestParams]="requestParams" [totalRecords]="paginationOptions.totalRecords"></metric-transaction-lines-header>


  <section class="table-controls" [ngClass]="{'my-payout-height': myFlag === 'MY PAYOUT'}">
    <oux-pagination 
      
      [options]="paginationOptions" 
      (onPageChange)="search($event, this.lastSortUsed)">
      <ng-template [ouxPaginationRange] let-range>
        Showing {{ range.lower | number:'1.0-0' }} - {{ range.upper | number:'1.0-0' }} of {{ range.total | number:'1.0-0' }} {{ range.total == 1 ? 'order' : 'orders' }}
      </ng-template>
    </oux-pagination>
  
    <ng-container *ngIf="(paginationOptions.totalRecords > 0)">
      <metric-transaction-lines-summary-export [ngClass]="(paginationOptions.totalRecords > 0) ? '' : 'fadeIn animated'"
        [requestParams]="requestParams" [totalRecords]="paginationOptions.totalRecords" [myFlag]="myFlag">
      </metric-transaction-lines-summary-export>
    </ng-container>
  </section>

  <ng-container *ngIf=" weeklyTransactionSummaryDetails && weeklyTransactionSummaryDetails.length > 0 ">
    <metric-transaction-lines-summary [weeklyTransactionSummaryDetails]="weeklyTransactionSummaryDetails" (onSort)="search(this.lastPageUsed, $event)"></metric-transaction-lines-summary>
    <!-- <iacv-weekly-transaction-summary-table class="table-container" [weeklyTransactionSummaryDetails]= 'weeklyTransactionSummaryFilteredDetails'></iacv-weekly-transaction-summary-table> -->
  </ng-container>

</ng-container>

