/* Angular Imports */
import { Component, OnInit }                from '@angular/core';
/* Feature Imports */
import { InstanceService }                  from '../../shared/services/instance.service';


@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  host: {
    'class': 'app-toolbar'
  }
})
export class ToolbarComponent implements OnInit {


  ////////////////////////////////////////////////
  // Dependency Injection
  ////////////////////////////////////////////////

  constructor(private instanceSvc : InstanceService) {

   }

  ////////////////////////////////////////////////
  // Lifecycle Hooks
  ////////////////////////////////////////////////

  ngOnInit() : void { 
    this.instanceSvc.instanceMountedAt('ToolbarComponent', 'app-toolbar');
  }

  ////////////////////////////////////////////////
  // Methods
  ////////////////////////////////////////////////

}