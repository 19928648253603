export class MetricSOTransactionLineDetailsGridViewModel {

  public BOOKING_ADJ_AMOUNT: number;
  public BUYING_PROGRAM_FLAG: string;
  public SALESREP_NUMBER: number;
  public IACV_MEASURE_KEY: number;
  public GOAL_ID: number;
  public RENEWAL_BOOKING_AMOUNT: number;
  public TERRITORY_TYPE_NAME: string;
  public BUYING_PROGRAM_GROUP: string;
  public NODE_LEVEL_6: string;
  public SAV_ID: number;
  public OFFER_TYPE_NAME: string;
  public PRODUCT_FAMILY: string;
  public PART_NUMBER: string;
  public ELA_FLAG: string;
  public NODE_LEVEL_5: string;
  public UPSELL_IACV_AMOUNT: number;
  public IACV_WALK_REASON_CODE: string;
  public CONTRACT_TERM: number;
  public MY_PAYOUT_FACTOR: number;
  public MY_PAYOUT_AMOUNT: number;
  public BOOKING: number;
  public METRIC_MANUAL_REVENUE_AMOUNT: number;
  public DATA_SOURCE: string;
  public INVOICE_DATE: string;
  public SHIP_TO_CUSTOMER_NAME: string;
  public PE_NAME: string;
  public NODE_LEVEL_1: string;
  public SUB_BUSINESS_ENTITY: string;
  public SUBSCRIPTION_REF_ID: string;
  public PRODUCT_SERVICE_FLAG: string;
  public BUSINESS_UNIT: string;
  public METRIC_TYPE_CODE: string;
  public SO_NUMBER: number;
  public SAAS_FLAG: string;
  public NODE_LEVEL_4: string;
  public QUOTA_ID: number;
  public COMP_PLAN_ID: number;
  public ATTRIBUTION_CODE: string;
  public NODE_LEVEL_3: string;
  public TOTAL_VALUE: number;
  public METRIC_LENS_TYPE: string;
  public SALES_MOTION: string;
  public SOURCE_TRANSACTION_DATE: string;
  public END_CUSTOMER_NAME: string;
  public TRANS_GROUP_CODE: string;
  public NODE_LEVEL_2: string;
  public XSELL_IACV_AMOUNT: number;
  public OFFER_PART_NUMBER: string;
  public BILL_TO_CUSTOMER_NAME: string;
  public SPLIT_PERCENT: number;
  public BACKLOG: number;
  public DEAL_ID: number;
  public CONTRACT_END_DATE: string;
  public REVENUE: number;
  public NEW_LOGO_IACV_AMOUNT: number;
  public GOALING_PERIOD: string;
  public EXP_IACV_AMOUNT: number;
  public METRIC_TYPE: string;
  public PO_NUMBER: string;
  public WPA_FLAG: string;
  public IACV_BOOKING_AMOUNT: number;
  public TERRITORY_CODE: string;
  public OFFER_NAME: string;
  public ANNUAL_VALUE: number;
  public EMPLOYEE_ID: number;
  public LINE_ID: number;
  public FISCAL_WEEK: number;
  public BOOKING_TRANSACTION_TYPE: string;
  public CX_UPSELL_GROUP: string;
  public CONTRACT_START_DATE: string;
  public TERRITORY_TYPE_CODE: string;
  public BUYING_PROGRAM_NAME: string;
  public ATR_REFERENCE_TYPE: string;
  public BOOKED_FISCAL_WEEK: number;
  public GS_CURRENCY_CODE: string;
  public CONTRACT_START_WEEK: number;
  public BUSINESS_ENTITY: string;
  public PID_MAPPING_GROUP: string;
  public SAV_NAME: string
  public isExpanded: boolean;
  public TRANSACTION_DATE: string;
  public IACV_ASCN_BKG_TRX_KEY: number;
  public NODE_NAME_DESC: string;
  public RESIDUAL_ANNUAL_USD_AMT: number;
  public ORG_RENEWED_USD_AMT: number;
  public NON_ELIGIBILITY_REASON: string;

  constructor(args: Partial<MetricSOTransactionLineDetailsGridViewModel>) {
    Object.assign(this, args);
  }
}
