export class TransactionLineDetailsGridViewModel {
  public bookDate: number;
  public transactionDate: number;
  public expectedShipDate: number;
  public transCreationDate: number;
  public contractStartDate: number;
  public contractEndDate: number;

  public transactionAmount: number;
  public myPEFlag: string;
  public extdMyPayoutAmount: number;
  public myPayoutAmount: number;
  public commBacklogAmt: number;
  public splitPercent: number;
  public salesMotion: string;
  public annualValue: number;
  public totalValue: number;
  public contractTerm: number;

  public partNumber: string;
  public productSplit: number;
  public businessUnit: string;
  public revenueMultiplierFactor: number;
  public myPayoutFactor: number;

  public atLevel1: string;
  public atLevel2: string;
  public atLevel3: string;
  public atLevel4: string;
  public metricType: string;
  public nonEligibilityReason: string;

  constructor(args: Partial<TransactionLineDetailsGridViewModel>) {
    Object.assign(this, args);
  }
}