<ng-container *ngIf="(data$ | async) as data">
  <section class="grid">

    <div class="grid__column">

      <div class="column__row">
        <span class="label">SO/POS Number</span>
        <span class="value --dominant">{{ data?.order?.SO_NUMBER || 'Not Available' }}</span>
        <div class="group --plan-description">
          <span
            class="value --description"
            ellipsis
            [ouxTooltip]="planElementTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.order?.PLAN_ELEMENT_DESCR || 'Not Available'}}
          </span>

          <ng-template #planElementTooltip>
            <p class="tooltip__text">{{ data?.order?.PLAN_ELEMENT_DESCR || 'Not Available' }}</p>
          </ng-template>

          <i
            *ngIf="data?.order?.PLAN_ELEMENT_DESCR"
            class="icon-info-outline"
            [ouxTooltip]="data?.order?.PLAN_ELEMENT_DESCR ? planElementDescTemplate : ''"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>

          <ng-template #planElementDescTemplate>
            <p class="tooltip__text">Plan Element Description</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->

      <div class="column__row --meta-columns">
        <div class="group">
          <span class="label">Total Lines:</span>

          <ng-container *ngIf="hasOrderLinesData; else showLoaderTemplate">
            <span class="value">{{ data?.count ? data?.count : 0 }}</span>
          </ng-container>

          <ng-template #showLoaderTemplate>
            <i class="icon-animation"></i>
          </ng-template>
        </div><!-- /group -->

        <div class="group">
          <span class="label">Currency:</span>
          <span
            class="value"
            ellipsis
            [ouxTooltip]="currencyTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ currency$ | async }}
          </span>

          <ng-template #currencyTooltip>
            <p class="tooltip__text">{{ currency$ | async }}</p>
          </ng-template>
        </div><!-- /group -->
      </div><!-- /column__row -->


    </div><!-- /grid__column -->
    <div class="grid__column">

      <div class="column__row">
        <span class="label">Book Date</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="bookDateTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ (data?.order?.SO_DATE | dateFormat) || 'Not Available' }}
        </span>

        <ng-template #bookDateTooltip>
          <p class="tooltip__text">{{ (data?.order?.SO_DATE | dateFormat) || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Bookings</span>
        <a
          class="value link"
          ellipsis
          [title]="'Bookings'"
          [ouxTooltip]="bookingTooltip"
          [ouxTooltipOptions]="templateTooltipOpts"
          (click)="triggerTypeChange('bookCredMemo')">
          {{ (data?.order?.BOOKING | number:'1.2-2') || 'Not Available' }}
        </a>

        <ng-template #bookingTooltip>
          <p class="tooltip__text">{{ (data?.order?.BOOKING | number:'1.2-2') || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

    </div><!-- /grid__column -->
    <div class="grid__column brd-none">

      <div class="column__row">
        <span class="label">Deal ID</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="dealIdTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.DEAL_ID || 'Not Available' }}
        </span>

        <ng-template #dealIdTooltip>
          <p class="tooltip__text">{{ data?.order?.DEAL_ID || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->


      <div class="column__row">
        <span class="label">Backlog</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="backlogTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ (data?.order?.BACKLOG | number:'1.2-2') || 'Not Available' }}
        </span>

        <ng-template #backlogTooltip>
          <p class="tooltip__text">{{ (data?.order?.BACKLOG | number:'1.2-2') || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

    </div><!-- /grid__column -->
    <div class="grid__column brd-none">

      <div class="column__row">
        <span class="label">End Customer</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="endCustomerTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.END_CUSTOMER || 'Not Available' }}
        </span>

        <ng-template #endCustomerTooltip>
          <p class="tooltip__text">{{ data?.order?.END_CUSTOMER || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Revenue</span>
        <a
          class="value link --has-tooltip"
          [title]="'Revenue'"
          (click)="triggerTypeChange('revCredMemo')">
          {{ (data?.order?.REVENUE | number:'1.2-2') || 'Not Available' }}
          <i
            *ngIf="data?.order?.REVENUE"
            class="icon-info-outline"
            [ouxTooltip]="revenueDescTemplate"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>
        </a>

        <ng-template #revenueDescTemplate>
          <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
        </ng-template>
      </div><!-- /column__row -->

    </div><!-- /grid__column -->
    <div class="grid__column">

      <div class="column__row">
        <span class="label">Type</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="typeTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.TYPE || 'Not Available' }}
        </span>

        <ng-template #typeTooltip>
          <p class="tooltip__text">{{ data?.order?.TYPE || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">PO Number</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="poNumberTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.PO_NUMBER || 'Not Available' }}
        </span>

        <ng-template #poNumberTooltip>
          <p class="tooltip__text">{{ data?.order?.PO_NUMBER || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

    </div><!-- /grid__column -->
    <div class="grid__column brd-none">

      <div class="column__row">
        <span class="label">Category</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="categoryTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.AMP_IDENTIFIER || 'Not Available' }}
        </span>

        <ng-template #categoryTooltip>
          <p class="tooltip__text">{{ data?.order?.AMP_IDENTIFIER || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Node</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="nodeTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.order?.NODE_NAME_DESC || 'Not Available' }}
        </span>

        <ng-template #nodeTooltip>
          <p class="tooltip__text">{{ data?.order?.NODE_NAME_DESC || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

    </div><!-- /grid__column -->

  </section>
</ng-container>


