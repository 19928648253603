/*
 * Angular Imports
 */
import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { RouterModule }                     from '@angular/router';
/*
 * Package Imports
 */
import {
  OuxGlobalNavModule,
  OuxSideNavModule }                        from '@cisco/oux-common';
/* 
 * Per Dependancies
 */
import { SharedModule }                     from '../shared/shared.module';
/* 
 * Feature Imports
 */
import { HeaderComponent }                  from './header/header.component';
import { HeaderLogoComponent }              from './header/header-logo/header-logo.component';
import { HeaderUserSearchComponent }        from './header/header-user-search/header-user-search.component';

import { ToolbarComponent }                 from './toolbar/toolbar.component';
import { ToolbarUserComponent }             from './toolbar/toolbar-user/toolbar-user.component';
import { ToolbarSettingsComponent }         from './toolbar/toolbar-settings/toolbar-settings.component';
import { ToolbarThemeComponent }            from './toolbar/toolbar-theme/toolbar-theme.component';
import { ToolbarDashboardsComponent }       from './toolbar/toolbar-dashboards/toolbar-dashboards.component';
// import { ToolbarNotificationsComponent }    from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarHelpComponent }             from './toolbar/toolbar-help/toolbar-help.component';
import { ToolbarOpenCaseComponent }         from './toolbar/toolbar-open-case/toolbar-open-case.component';
/*
 * Toolbar Modals
 */
import { LogoutModalComponent }             from './toolbar/logout-modal/logout-modal.component';
// import { PreferencesModalComponent }        from './toolbar/preferences-modal/preferences-modal.component';
import { ProxyModalComponent }              from './toolbar/proxy-modal/proxy-modal.component';

import { BreadcrumbsComponent }             from './breadcrumbs/breadcrumbs.component';
import { CrumbContainerDirective }          from './breadcrumbs/directives/crumb-container.directive';

import { AsideComponent }                   from './aside/aside.component';
import { BodyComponent }                    from './body/body.component';
import { HeroComponent }                    from './hero/hero.component';
import { ShelfComponent }                   from './shelf/shelf.component';
import { FooterComponent }                  from './footer/footer.component';
import { RootLogoutComponent }              from '../guards/logout/logout.component';
import { DrillDownUserComponent }           from './breadcrumbs/partials/drill-down-user/drill-down-user.component';
import { ToolbarExportComponent } from './toolbar/toolbar-export/toolbar-export.component';




@NgModule({

  imports: [
    CommonModule,
    RouterModule,
    OuxGlobalNavModule,
    OuxSideNavModule,
    SharedModule
  ],

  declarations: [
    HeaderComponent,
    HeaderLogoComponent,
    HeaderUserSearchComponent,
    ToolbarComponent,
    ToolbarUserComponent,
    ToolbarSettingsComponent,
    ToolbarThemeComponent,
    ToolbarDashboardsComponent,
    ToolbarExportComponent,
    // ToolbarNotificationsComponent, not part of day 1 scope - will be part of future scope - do not remove - 9/28/21
    ToolbarHelpComponent,
    ToolbarOpenCaseComponent,

    // Modals
    LogoutModalComponent,
    // PreferencesModalComponent, not part of day 1 scope - will be part of future scope - do not remove - 9/28/21
    ProxyModalComponent,

    BreadcrumbsComponent,
    CrumbContainerDirective,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent,
    DrillDownUserComponent
  ],

  providers: [

  ],

  entryComponents: [
    LogoutModalComponent,
    // PreferencesModalComponent, not part of day 1 scope - will be part of future scope - do not remove - 9/28/21
    ProxyModalComponent,
    ToolbarSettingsComponent
  ],

  exports: [
    RouterModule,
    HeaderComponent,
    HeaderLogoComponent,
    BreadcrumbsComponent,
    AsideComponent,
    BodyComponent,
    HeroComponent,
    ShelfComponent,
    FooterComponent,
    RootLogoutComponent,
    DrillDownUserComponent
  ]

})

export class LayoutModule { }
