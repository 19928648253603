<ng-container *ngIf="(tableData$ | async) as tableData">
  <oux-table
    [records]="tableData?.lines"
    [options]="tableOpts"
    [settings]="tableData?.columnSettings"
    [themeOverrides]="tableThemeOverrides">

    <!-- start custom table headers for last two columns -->
    <ng-template [ouxHeader]="'OFFER_PART_NUMBER'">
      <div class="pad-left-sm">Part Number</div>
    </ng-template>

    <ng-template [ouxHeader]="'TOTAL_VALUE'">
      TCV
      <i  
        class="icon-info-outline"
        [ouxTooltip]="tcvDescTemplate"
        [ouxTooltipOptions]="templateTooltipOpts">
      </i>
      <ng-template #tcvDescTemplate>
        <p class="tooltip__text">Total Contract Value - Bookings value across the lifetime of the contract.</p>
      </ng-template>
    </ng-template>

    <ng-template [ouxHeader]="'ANNUAL_VALUE'">
      Total Annual Bookings
      <i  
        class="icon-info-outline"
        [ouxTooltip]="annualDescTemplate"
        [ouxTooltipOptions]="templateTooltipOpts">
      </i>
      <ng-template #annualDescTemplate>
        <p class="tooltip__text">
          Annual Contract Value - Bookings value from first 12 months of a Multi-Year contract. If the contract term is less than 12 months, ACV would be Bookings value for that term (equivalent to TCV).
        </p>
      </ng-template>
    </ng-template>

    <ng-template [ouxHeader]="'REV_MULTIPLIER_FACTOR'">
      <div [ngClass]="{ 'pad-right-sm': !hasMYPEColumns }" class="ellipsis">Revenue Multiplier</div>
    </ng-template>

    <ng-template [ouxHeader]="'MY_PAYOUT_FACTOR'">
      <div class="pad-right-sm ellipsis">MY Uplift Factor</div>
    </ng-template>

    <!-- /end custom table headers -->

    <!-- start custom table columns -->
    <ng-template [ouxColumn]="'OFFER_PART_NUMBER'" let-record="record">
      <div class="pad-left-sm">
        <a 
          class="link"
          [title]="record?.OFFER_PART_NUMBER"
          (click)="showOrderLineDetails(record, $event.preventDefault())">
          {{ record?.OFFER_PART_NUMBER }}
        </a>
      </div>
    </ng-template>

    <ng-template [ouxColumn]="'LINE_CREATION_DATE'" let-record="record">
      {{ (record?.LINE_CREATION_DATE | dateFormat) || (record?.SOURCE_TRANSACTION_DATE | dateFormat) }}
    </ng-template>

    <ng-template [ouxColumn]="'TOTAL_VALUE'" let-record="record">
      {{ record?.TOTAL_VALUE | number:'1.2-2' }}
    </ng-template>

    <ng-template [ouxColumn]="'ANNUAL_VALUE'" let-record="record">
      {{ record?.ANNUAL_VALUE | number:'1.2-2' }}
    </ng-template>

    <ng-template [ouxColumn]="'BOOKING_AMT'" let-record="record">
      <div *ngIf="isNotEligibleOrder; else defaultBooking">
        NA
      </div>
      <ng-template #defaultBooking>
        {{ record?.TOTAL_SALES_VALUE + record?.COMM_BACKLOG | number:'1.2-2' }}
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'COMM_BACKLOG'" let-record="record">
      <div *ngIf="isNotEligibleOrder; else defaultBacklog">
        NA
      </div>
      <ng-template #defaultBacklog>
        {{ ampIdentifier !== AmpIdentifierKey.MY ? (record?.COMM_BACKLOG | number:'1.2-2') : 'NA' }}
      </ng-template>
    </ng-template>

    <ng-template [ouxColumn]="'TOTAL_SALES_VALUE'" let-record="record">
      {{ (record?.TOTAL_SALES_VALUE | number:'1.2-2') || (record?.REVENUE | number:'1.2-2') }}
    </ng-template>

    <ng-template [ouxColumn]="'REV_MULTI'" let-record="record">
      {{ (record?.REV_MULTI | number:'1.2-2') || (record?.REVENUE | number:'1.2-2')}}
    </ng-template>

    <ng-template [ouxColumn]="'REV_MULTIPLIER_FACTOR'" let-record="record">
      <div [ngClass]="{ 'pad-right-sm': !hasMYPEColumns }" class="ellipsis">{{ record?.REV_MULTIPLIER_FACTOR == 0 ? '' : record?.REV_MULTIPLIER_FACTOR  }}</div>
    </ng-template>

    <ng-template [ouxColumn]="'MY_PAYOUT_AMOUNT'" let-record="record">
      <ng-container *ngIf="record?.MYPEFLAG == 'Y' && isMulti">
        {{record?.EXTD_MY_PAYOUT_AMOUNT | number:'1.2-2'}}
      </ng-container>

      <ng-container *ngIf="record?.MYPEFLAG == 'Y' && !isMulti">
        {{record?.MY_PAYOUT_AMOUNT | number:'1.2-2'}}
      </ng-container>
    </ng-template>

    <ng-template [ouxColumn]="'MY_PAYOUT_FACTOR'" let-record="record">
      <div *ngIf="record?.MYPEFLAG == 'Y' && record?.REV_MULTIPLIER_FACTOR != null && record?.MY_PAYOUT_FACTOR != 0" class="pad-right-sm ellipsis">
        {{record?.MY_PAYOUT_FACTOR | number:'1.2-2'}}
      </div>
    </ng-template>

    <!-- end custom table columns -->
  </oux-table>
</ng-container>
