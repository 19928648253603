<ng-container *ngIf="(data$ | async) as data">
  <section class="grid">

    <div class="grid__column">
      <div class="column__row">
        <span class="label">Order Number</span>
        <span class="value --dominant">{{ data?.selectedOrder?.SO_NUMBER || 'Not Available' }}</span>
        <div class="group --plan-description">
          <span
            class="value --description"
            ellipsis
            [ouxTooltip]="planElementTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.selectedOrder?.PLAN_ELEMENT_DESCR }}
          </span>

          <ng-template #planElementTooltip>
            <p class="tooltip__text">{{ data?.selectedOrder?.PLAN_ELEMENT_DESCR || 'Not Available'}}</p>
          </ng-template>

          <i
            *ngIf="data?.selectedOrder?.PLAN_ELEMENT_DESCR"
            class="icon-info-outline"
            [ouxTooltip]="data?.selectedOrder?.PLAN_ELEMENT_DESCR ? planElementDescTemplate : ''"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>

          <ng-template #planElementDescTemplate>
            <p class="tooltip__text">Plan Element Description</p>
          </ng-template>

        </div><!-- /group -->
      </div><!-- /column__row -->

      <div class="column__row --meta-columns">
        <div class="group">
          <span class="label">Total Order Lines:</span>

          <ng-container *ngIf="!isLoading; else showLoaderTemplate">
            <span class="value">{{ transactionsCount$ | async }}</span>
          </ng-container>

          <ng-template #showLoaderTemplate>
            <i class="icon-animation"></i>
          </ng-template>

        </div><!-- /group -->

        <div class="group">
          <span class="label">Currency:</span>
          <span
            class="value"
            ellipsis
            [ouxTooltip]="currencyTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.selectedOrder?.CURRENCY_CODE || 'Not Available' }}
          </span>

          <ng-template #currencyTooltip>
            <p class="tooltip__text">{{ data?.selectedOrder?.CURRENCY_CODE || 'Not Available' }}</p>
          </ng-template>
        </div><!-- /group -->
      </div><!-- /column__row -->

    </div><!-- /grid__column -->

    <div class="grid__column">
      <div class="column__row">
        <span class="label">Book Date</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="bookDateTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ (data?.selectedOrder?.SO_DATE | dateFormat) || 'Not Available' }}
        </span>

        <ng-template #bookDateTooltip>
          <p class="tooltip__text">{{ (data?.selectedOrder?.SO_DATE | dateFormat) || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Bookings</span>
        <span *ngIf="isNotEligibleOrder; else defaultBookings" class="value">
          NA
        </span>
        <ng-template #defaultBookings>
          <span class="value">
            <a
              *ngIf="!isLoading && data?.selectedOrder?.BOOKING != null"
              class="link"
              (click)="loadTransactionGroup(transactionGroupCodes.Bookings)">
              {{ (data?.selectedOrder?.BOOKING | number:'1.2-2') || 'Not Available'}}
            </a>
  
            <ng-container *ngIf="isLoading || data?.selectedOrder?.BOOKING == null">
              {{ (data?.selectedOrder?.BOOKING | number:'1.2-2') || 'Not Available' }}
            </ng-container>
          </span>
        </ng-template> 
      </div><!-- /column__row -->
    </div>

    <!-- New Column -->
    <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label">Deal ID</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="dealIDTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.selectedOrder?.DEAL_ID || 'Not Available' }}
        </span>

        <ng-template #dealIDTooltip>
          <p class="tooltip__text">{{ data?.selectedOrder?.DEAL_ID || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Backlog</span>
        <span *ngIf="isNotEligibleOrder; else defaultBacklog" class="value">
          NA
        </span>
        <ng-template #defaultBacklog>
          <span
            class="value"
            ellipsis
            [ouxTooltip]="backlogTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.selectedOrder?.AMP_IDENTIFIER !== AmpIdentifierKey.MY ? ((data?.selectedOrder?.BACKLOG | number:'1.2-2') || 'Not Available') : 'NA' }}
          </span>

          <ng-template #backlogTooltip>
            <p class="tooltip__text">{{ data?.selectedOrder?.AMP_IDENTIFIER !== AmpIdentifierKey.MY ? ((data?.selectedOrder?.BACKLOG | number:'1.2-2') || 'Not Available') : 'NA' }}</p>
          </ng-template>
        </ng-template>
      </div><!-- /column__row -->
    </div>

    <!-- New Column -->
    <div class="grid__column brd-none">

      <div class="column__row">
        <span class="label">End Customer</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="endCustomerTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.selectedOrder?.END_CUSTOMER || data?.selectedOrder?.END_CUSTOMER_NAME || 'Not Available' }}
        </span>

        <ng-template #endCustomerTooltip>
          <p class="tooltip__text">{{ data?.selectedOrder?.END_CUSTOMER || data?.selectedOrder?.END_CUSTOMER_NAME || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Revenue</span>
        <span class="value --has-tooltip">
          <a
            class="link"
            (click)="loadTransactionGroup(transactionGroupCodes.Revenue)"
            *ngIf="!isLoading && data?.selectedOrder?.REVENUE_MULTI != null">
            {{ (data?.selectedOrder?.REVENUE_MULTI | number:'1.2-2') || (data?.selectedOrder?.REVENUE | number:'1.2-2') || 'Not Available' }}
          </a>

          <ng-container *ngIf="isLoading || data?.selectedOrder?.REVENUE_MULTI == null">
            {{ (data?.selectedOrder?.REVENUE_MULTI | number:'1.2-2') || (data?.selectedOrder?.REVENUE | number:'1.2-2') || 'Not Available' }}
          </ng-container>

          <i
            *ngIf="data?.selectedOrder?.REVENUE_MULTI"
            class="icon-info-outline"
            [ouxTooltip]="revenueDescriptionTemplate"
            [ouxTooltipOptions]="templateTooltipOpts">
          </i>
        </span>

        <ng-template #revenueDescriptionTemplate>
          <p class="tooltip__text">Multiplied Revenue after factors applied.</p>
        </ng-template>
      </div><!-- /column__row -->
    </div>

    <!-- New Column -->
    <div class="grid__column">
      <div class="column__row">
        <span class="label">Type</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="typeTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.selectedOrder?.TYPE || 'Not Available' }}
        </span>

        <ng-template #typeTooltip>
          <p class="tooltip__text">{{ data?.selectedOrder?.TYPE || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">PO Number</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="poNumberTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ (data?.selectedOrder?.PO_NUMBER == '-1' ? '' : data?.selectedOrder?.PO_NUMBER) || 'Not Available' }}
        </span>

        <ng-template #poNumberTooltip>
          <p class="tooltip__text">{{ (data?.selectedOrder?.PO_NUMBER == '-1' ? '' : data?.selectedOrder?.PO_NUMBER) || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->
    </div>

    <div class="grid__column brd-none">
      <div class="column__row">
        <span class="label">Category</span>
        <span *ngIf="isNotEligibleOrder; else defaultCategory" class="value">
          {{ AmpIdentifierKey.NOTELIGIBLE }}
        </span>
        <ng-template #defaultCategory>
          <span
            class="value"
            ellipsis
            [ouxTooltip]="categoryTooltip"
            [ouxTooltipOptions]="templateTooltipOpts">
            {{ data?.selectedOrder?.AMP_IDENTIFIER || 'Not Available' }}
          </span>

          <ng-template #categoryTooltip>
            <p class="tooltip__text">{{ data?.selectedOrder?.AMP_IDENTIFIER || 'Not Available' }}</p>
          </ng-template>
        </ng-template>
      </div><!-- /column__row -->

      <div class="column__row">
        <span class="label">Node</span>
        <span
          class="value"
          ellipsis
          [ouxTooltip]="nodeTooltip"
          [ouxTooltipOptions]="templateTooltipOpts">
          {{ data?.selectedOrder?.NODE_NAME_DESC || 'Not Available' }}
        </span>

        <ng-template #nodeTooltip>
          <p class="tooltip__text">{{ data?.selectedOrder?.NODE_NAME_DESC || 'Not Available' }}</p>
        </ng-template>
      </div><!-- /column__row -->
    </div><!-- /grid__column -->
  </section>
</ng-container>


