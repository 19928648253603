import { NgModule }                                 from '@angular/core';
import { CommonModule }                             from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule }                                from '@angular/common/http';
/* OneUX Imports */
import { OuxExceptionsModule }                      from '@cisco/oux-common';

/* Keep for furture use */
//import { OUX_CUSTOM_BEHAVIORS }                   from '@cisco/oux-common';

/* Import Interceptors Deps */
import { AuthErrorInterceptor }                     from './interceptors/auth-error.interceptor';
import { LoaderService }                            from './services/loader.service';
import { LoaderInterceptor }                        from './interceptors/loader.interceptor';

/* Custom Behaviors - Keep for furture use */
//import { CustomNotificationBehavior }             from './custom-behaviors/custom-notification-behavior';

@NgModule({

  imports: [
    CommonModule,
    HttpClientModule,
    OuxExceptionsModule
  ],

  providers: [
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true
    }
    // Placing here for future reference on setup
    // CustomNotificationBehavior,
    // {
    //   provide: OUX_CUSTOM_BEHAVIORS,
    //   useClass: CustomNotificationBehavior,
    //   multi: true
    // },
  ],

  declarations: []

})

export class CoreModule { }
